import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestEndpoint } from "../../data/requestEndpoint";
import { NotificationService } from "../../services/Notification.service";
import { post } from "../../services/useFetchAction";
import { useStorePageManager } from "../../store/PageManage";
import ContainerPersoModal from "../biggest/ContainerPersoModal";

export default function BottomCancelConditionsReservation({
  t,
  data,
  canAskReservation,
  handleSaveInfo,
  badRenseignement,
  handleJustReserveBilling,
  reservationCard,
  dataPaiement,
  setStateName,
  setStateTel,
  setStateMail,
  setStateAdress,
  setStateVille,
  setStateZip,
}) {
  const navigate = useNavigate();
  const [openConfirmReservation, setOpenConfirmReservation] = useState(false);
  const [saveBilling, setSaveBilling] = useState(false);
  const [newCondition, setNewConditionState] = useState([]);

  useEffect(() => {
    if (!data) return;
    const position1Value = {
      id: 1,
      value: Object.values(data?.residence?.refundGrid)[0],
    };
    const position2Value = {
      id: 2,
      value: Object.values(data?.residence?.refundGrid)[1],
    };
    const position3Value = {
      id: 3,
      value: Object.values(data?.residence?.refundGrid)[2],
    };
    const position4Value = {
      id: 4,
      value: Object.values(data?.residence?.refundGrid)[3],
    };
    const position5Value = {
      id: 5,
      value: Object.values(data?.residence?.refundGrid)[4],
    };
    let newConditionLoad = [
      position1Value,
      position2Value,
      position3Value,
      position4Value,
      position5Value,
    ];
    setNewConditionState(newConditionLoad);
  }, [data]);

  const handleCheckForReservation = () => {
    setOpenConfirmReservation(false);
    setSaveBilling(true);
  };
  const [newWindow, setNewWindow] = useState(null);
  const [urlPaid, setUrlPaid] = useState(null);
  const [openNewWindowPaid, setOpenNewWindow] = useState(false);

  const handleGoPay = async () => {
    if (urlPaid) {
      useStorePageManager.getState().handleUpdateBodyOverflow(false);
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      setOpenNewWindow(true);
      return;
    }
    if (dataPaiement.currentPaiement == null) return;
    if (!reservationCard) return;
    // if (canAskReservation) return;
    try {
      useStorePageManager.getState().handleUpdateBlockScreen(true);
      const response = await post(requestEndpoint.payment, {
        contact: dataPaiement.tel,
        zip_code: dataPaiement.zip,
        countryISO2: dataPaiement.country.code.toUpperCase(),
        provinceISO: dataPaiement.province
          ? dataPaiement.province.iso.toUpperCase()
          : null,
        city: dataPaiement.ville,
        address: dataPaiement.adress,
        email: dataPaiement.mail,
        firstname: dataPaiement.name.split(" ")[0],
        lastname: dataPaiement.name.replace(
          dataPaiement.name.split(" ")[0] + " ",
          ""
        ),
        payment_method_id: dataPaiement.currentPaiement.id,
        booking_id: reservationCard.id,
      });
      // console.log(response);
      let newUrl = response.data.payment_url;
      setUrlPaid(newUrl);
      setOpenNewWindow(true);
      useStorePageManager.getState().handleUpdateBodyOverflow(false);
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      // openNewWindow(newUrl);
    } catch (error) {
      console.log(error);
      useStorePageManager.getState().handleUpdateBodyOverflow(true);
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      NotificationService.addToQueue(
        2,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.errorPayment"
        )
      );
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.paymentInProgress"
        )
      );
    }
  };
  const openNewWindow = (url) => {
    // let nW = window.open(url, "_blank");
    // window.location.href = url;
    let nW = window.open(url, "_blank");
    if (!nW) {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.paymentBlocked"
        )
      );
      return;
    }
    clearTimeout(timeoutCount);
    setTimeout(() => {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.waitingPayment"
        )
      );
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      useStorePageManager.getState().handleUpdateBodyOverflow(true);
      navigate("/profilereservation");
    }, 15000);
    if (nW) {
      nW.addEventListener("beforeunload", handleWindowClose);
    }
    setNewWindow(nW);
  };

  //fait un countdown de 5 minutes
  const [timeoutCount, setTimeoutCount] = useState();
  useEffect(() => {
    if (!urlPaid) {
      if (timeoutCount) clearTimeout(timeoutCount);
      return;
    }
    // console.log(urlPaid);
    let newTimeout = setTimeout(() => {
      setUrlPaid(null);
      setOpenNewWindow(false);
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.linkExpired"
        )
      );
    }, 300100);
    setTimeoutCount(newTimeout);
    return () => {
      clearTimeout(timeoutCount);
    };
  }, [urlPaid]);
  const handleGoOnReservationPanel = () => {
    clearTimeout(timeoutCount);
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    setTimeout(() => {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.paymentDoneReload"
        )
      );
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      useStorePageManager.getState().handleUpdateBodyOverflow(true);
      navigate("/profilereservation");
    }, 5000);
  };
  const handleWindowClose = () => {
    useStorePageManager.getState().handleUpdateBlockScreen(false);
    useStorePageManager.getState().handleUpdateBodyOverflow(true);
    NotificationService.addToQueue(
      1,
      t(
        "reservationPaiement.modals.bottomCancelConditions.notification.waitingPayment"
      )
    );
    NotificationService.addToQueue(
      1,
      t(
        "reservationPaiement.modals.bottomCancelConditions.notification.mailConfirmation"
      )
    );
    navigate("/profilereservation");
  };
  const handleJustReserve = () => {
    setOpenConfirmReservation(false);
    setSaveBilling(false);
    handleJustReserveBilling();
    // navigate("/reservation-paiement");
  };
  const handleReserveSave = () => {
    handleSaveInfo();
    // setOpenConfirmReservation(false);
    // setSaveBilling(false);
    // navigate("/reservation-paiement");
  };
  const handleScrollSmoothToId = (id) => {
    let element = document.getElementById(id);
    if (!element) return;
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  const handleCheckData = () => {
    let currentRenseignement = badRenseignement;
    //mettre le isError a true pour chaque input
    if (!currentRenseignement.name) {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.nameError"
        )
      );
      setStateName((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("name-billing");
      return false;
    }
    if (!currentRenseignement.tel) {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.telError"
        )
      );
      setStateTel((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("tel-billing");
      return false;
    }
    if (!currentRenseignement.mail) {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.emailError"
        )
      );
      setStateMail((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("email-billing");
      return false;
    }
    if (!currentRenseignement.adress) {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.adressError"
        )
      );
      setStateAdress((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("adress-billing");
      return false;
    }
    if (!currentRenseignement.ville) {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.villeError"
        )
      );
      setStateVille((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("ville-billing");
      return false;
    }
    if (!currentRenseignement.zip) {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.codeZipError"
        )
      );
      setStateZip((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("zip-billing");
      return false;
    }
    if (!currentRenseignement.country) {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.countryError"
        )
      );
      handleScrollSmoothToId("country-billing");
      return false;
    }

    if (!currentRenseignement.paiement && reservationCard) {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.paiementError"
        )
      );
      handleScrollSmoothToId("paiement-billing");
      return false;
    }

    if (
      currentRenseignement.country &&
      !canAskReservation &&
      !currentRenseignement.province
    ) {
      NotificationService.addToQueue(
        1,
        t(
          "reservationPaiement.modals.bottomCancelConditions.notification.provinceError"
        )
      );
      handleScrollSmoothToId("province-billing");
      return false;
    }
    return true;
  };
  const handleCheckInfoForCanBooking = () => {
    if (!handleCheckData()) return;
    setOpenConfirmReservation(true);
  };
  return (
    <>
      <div className="cancel-condition cancel-condition-residence | flcolm">
        <label>
          {t("reservationPaiement.modals.bottomCancelConditions.title")}
        </label>
        <ul className="list-condition | flcolm addgap24">
          <li className="flcolm liner addgap8">
            <p>
              {t(
                "reservationPaiement.modals.bottomCancelConditions.conditions.condition1"
              )}
            </p>
            <span>
              {newCondition[0]?.value == 0
                ? t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.notRemboursement"
                  )
                : `${t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.amountToGiveTxt"
                  )}${" "}${newCondition[0]?.value}% ${t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.totalMontantTxt"
                  )}`}
            </span>
          </li>
          <li className="flcolm liner addgap8">
            <p>
              {t(
                "reservationPaiement.modals.bottomCancelConditions.conditions.condition2"
              )}
            </p>
            <span>
              {newCondition[1]?.value == 0
                ? t("notRemboursement")
                : `${t("amountToGiveTxt")}${" "}${newCondition[1]?.value}% ${t(
                    "totalMontantTxt"
                  )}`}
            </span>
          </li>
          <li className="flcolm liner addgap8">
            <p>
              {t(
                "reservationPaiement.modals.bottomCancelConditions.conditions.condition3"
              )}
            </p>
            <span>
              {newCondition[2]?.value == 0
                ? t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.notRemboursement"
                  )
                : `${t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.amountToGiveTxt"
                  )}${" "}${newCondition[2]?.value}% ${t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.totalMontantTxt"
                  )}`}
            </span>
          </li>
          <li className="flcolm liner addgap8">
            <p>
              {t(
                "reservationPaiement.modals.bottomCancelConditions.conditions.condition4"
              )}
            </p>
            <span>
              {newCondition[3]?.value == 0
                ? t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.notRemboursement"
                  )
                : `${t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.amountToGiveTxt"
                  )}${" "}${newCondition[3]?.value}% ${t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.totalMontantTxt"
                  )}`}
            </span>
          </li>
          <li className="flcolm liner addgap8">
            <p>
              {t(
                "reservationPaiement.modals.bottomCancelConditions.conditions.condition5"
              )}
            </p>
            <span>
              {newCondition[4]?.value == 0
                ? t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.notRemboursement"
                  )
                : `${t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.amountToGiveTxt"
                  )}${" "}${newCondition[4]?.value}% ${t(
                    "reservationPaiement.modals.bottomCancelConditions.txts.totalMontantTxt"
                  )}`}
            </span>
          </li>
        </ul>
      </div>
      <div className="btn-infos | flcolm">
        <p>
          {t(
            "reservationPaiement.modals.bottomCancelConditions.reglements.infoReglementTxt1"
          )}{" "}
          <a href="/conditions" target="_blank">
            {t(
              "reservationPaiement.modals.bottomCancelConditions.reglements.infoReglementTxt2"
            )}
          </a>
          ,{" "}
          <a href="/politique" target="_blank">
            {t(
              "reservationPaiement.modals.bottomCancelConditions.reglements.infoReglementTxt3"
            )}
          </a>{" "}
          {t(
            "reservationPaiement.modals.bottomCancelConditions.reglements.infoReglementTxt4"
          )}{" "}
          <a href="/conditions" target="_blank">
            {t(
              "reservationPaiement.modals.bottomCancelConditions.reglements.infoReglementTxt5"
            )}
          </a>{" "}
          {t(
            "reservationPaiement.modals.bottomCancelConditions.reglements.infoReglementTxt6"
          )}
        </p>

        {reservationCard ? (
          <button
            onClick={() => {
              if (!handleCheckData()) return;
              handleGoPay();
            }}
            //disabled={!canAskReservation || openNewWindowPaid}
            disabled={openNewWindowPaid}
            className="btn pry-btn"
          >
            {t(
              "reservationPaiement.modals.bottomCancelConditions.txts.proceedToPaymentTxt"
            )}
          </button>
        ) : (
          <button
            onClick={() => handleCheckInfoForCanBooking()}
            // disabled={!canAskReservation}
            className="btn pry-btn"
          >
            {t(
              "reservationPaiement.modals.bottomCancelConditions.txts.btnAskReservationTxt"
            )}
          </button>
        )}
      </div>
      <ContainerPersoModal
        isOpen={openConfirmReservation}
        toggle={setOpenConfirmReservation}
        title={t(
          "reservationPaiement.modals.bottomCancelConditions.miniModal.titleConfirm"
        )}
        baseTop={true}
        className="request-conf"
      >
        <div className="modal-request-conf | flcolm">
          <p style={{ marginBottom: 16 }} className="p-just -c">
            {t(
              "reservationPaiement.modals.bottomCancelConditions.miniModal.confirmInfoTxt"
            )}
          </p>
          <button
            style={{ borderRadius: 6 }}
            onClick={handleCheckForReservation}
            className="btn pry-btn"
          >
            <span>
              {t(
                "reservationPaiement.modals.bottomCancelConditions.miniModal.continueText"
              )}
            </span>
          </button>
        </div>
      </ContainerPersoModal>
      <ContainerPersoModal
        isOpen={saveBilling}
        toggle={setSaveBilling}
        title={t(
          "reservationPaiement.modals.bottomCancelConditions.miniModal.titleSaveBillingTxt"
        )}
        baseTop={true}
        className="request-conf"
      >
        <div className="modal-request-conf save-billing | flcolm">
          <p style={{ marginTop: 15, marginBottom: 16 }} className="p-just -c">
            {t(
              "reservationPaiement.modals.bottomCancelConditions.miniModal.confirmSaveBillingTxt"
            )}
          </p>
          <button
            style={{ borderRadius: 6, marginBottom: 6 }}
            onClick={handleReserveSave}
            className="btn pry-btn"
          >
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {t(
                "reservationPaiement.modals.bottomCancelConditions.miniModal.saveAndReserveBillingTxt"
              )}
            </span>
          </button>
          <button onClick={handleJustReserve} className="btn pryy-btn">
            <span>
              {t(
                "reservationPaiement.modals.bottomCancelConditions.miniModal.justReserveTxt"
              )}
            </span>
          </button>
        </div>
      </ContainerPersoModal>
      <ContainerPersoModal
        isOpen={openNewWindowPaid}
        toggle={() => ""}
        title={t(
          "reservationPaiement.modals.bottomCancelConditions.miniModal.titlePaidWindowTxt"
        )}
        fullBlock={true}
        baseTop={true}
        className="paid-window"
      >
        <div className="modal-paid flcolm addgap16">
          <div className="flcolm addgap8">
            <p className="p-just">
              {t(
                "reservationPaiement.modals.bottomCancelConditions.miniModal.paidWindowInfoTxt"
              )}
            </p>
            <p>
              {t(
                "reservationPaiement.modals.bottomCancelConditions.miniModal.paidWindowInfoTxt2"
              )}
            </p>
          </div>
          <a
            onClick={handleGoOnReservationPanel}
            href={urlPaid}
            target="_blank"
            rel="noreferrer"
          >
            {t(
              "reservationPaiement.modals.bottomCancelConditions.miniModal.continuePaidWindowTxt"
            )}
          </a>
        </div>
      </ContainerPersoModal>
    </>
  );
}
