import React, { useEffect } from "react";
import ConditionsContentEn from "../components/biggest/cgu/ConditionsContentEn";
import ConditionsContentFr from "../components/biggest/cgu/ConditionsContentFr";
import Footer from "../components/biggest/Footer";
import i18n from "../globalTraduction";
import { smoothScrollUp } from "../services/utils";
import { useStorePageManager } from "../store/PageManage";

export default function ConditionsPage() {
  const language = i18n.language;
  const handleUpdateStorePage = useStorePageManager(
    (state) => state.handleUpdateStorePage
  );
  useEffect(() => {
    handleUpdateStorePage(false);
  }, []);
  useEffect(() => {
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);
  return (
    <>
      {/* <Header /> */}
      <main>
        {language === "fr" ? <ConditionsContentFr /> : <ConditionsContentEn />}
        <Footer />
      </main>
    </>
  );
}
