import React from "react";

import { useTranslation } from "react-i18next";
import i18n from "../../globalTraduction";

export default function ComoditiesModal({ handleOutModal, data }) {
  const { t } = useTranslation();
  const language = i18n.language;
  return (
    <div className="comodities-modal | flcolm">
      <div className="title-modal flcolm addgap8">
        <label>{t("residencePage.comodities.modal.title")}</label>
      </div>
      <div className="contents | flrow">
        {data.map((comoditie) => (
          <div key={comoditie.id} className="comoditie | flrow liner">
            <img src={comoditie.thumb} alt={`commoditie-${comoditie.title}`} />
            <span>
              {language == "fr" ? comoditie.title : comoditie.titleEng}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
