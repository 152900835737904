/* eslint-disable array-callback-return */
import "moment/locale/fr";
import moment from "moment/moment";
import json from "../data/countries.json";
import { requestEndpoint } from "../data/requestEndpoint";
import { useStoreAllStaticData } from "../store/dataForAll";
import { get, post } from "./useFetchAction";
export const getDateFormat = (
  timestamp,
  format = "dddd, D MMMM YYYY",
  directTime = false
) => {
  const timestampCalcul = parseInt(timestamp, 10) * 1000;
  let newDate = moment(timestampCalcul);
  if (directTime) newDate = moment(timestamp);
  return newDate.format(format);
};
export const formatEachFirstLetterInWord = (value) => {
  if (!value) return "";
  return value
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};
export const checkDateJust = (date) => {
  if (!moment(date).isValid()) {
    return false;
  }
  return true;
};
export const getJustNumber = (value) => {
  if (!value) return "";
  return value.replace(/[^0-9]/g, "");
};
export const getJustDateFormat = (
  date,
  format = "dddd, D MMMM YYYY",
  returnVal = "Date"
) => {
  if (!moment(date).isValid()) {
    if (returnVal) return returnVal;
    return "Date";
  }
  const currentLanguage = localStorage.getItem("tc-language-active");
  moment.locale(currentLanguage);
  return moment(date).format(format);
};

export function smoothScrollUp(justTop = false, posSearch = 0) {
  const targetPosition = posSearch + 20;
  window.scroll({
    top: justTop ? 0 : targetPosition,
    behavior: "smooth",
  });
}

export const compressImage = async (file) => {
  return file;
};

export const getLatLngFromCountryCityOnly = (address) => {
  const geocoder = new window.google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        resolve({ lat, lng });
      } else {
        reject(status);
      }
    });
  });
};

export const getLatLngFromAddress = (address) => {
  const geocoder = new window.google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, async (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const placeId = results[0].place_id;
        let lat = null;
        let lng = null;
        let viewport = null;
        if (placeId) {
          const responseGeo = await get(
            requestEndpoint.reverseGeo + `?search=${placeId}`
          );
          lat = responseGeo.data.result.geometry.location.lat;
          lng = responseGeo.data.result.geometry.location.lng;
          viewport = responseGeo.data.result.geometry.viewport;
          // console.log(responseGeo.data.result);
        }
        resolve({ lat, lng, viewport });
      } else {
        reject({ lat: null, lng: null, viewport: null });
      }
    });
  });
};
export function detectCreditCardType(cardNumber) {
  const cleanedNumber = cardNumber.replace(/\D/g, "");

  const visaRegex = /^4\d{12}(\d{3})?$/;
  const mastercardRegex = /^5[1-5]\d{14}$/;

  if (visaRegex.test(cleanedNumber)) {
    return "Visa";
  } else if (mastercardRegex.test(cleanedNumber)) {
    return "Mastercard";
  } else {
    return "Unknown";
  }
}

export function formatStringToOneUpperCase(data = "") {
  let value = data.toLowerCase();
  // mettre en majuscule la première lettre de chaque mot en prenant en compte les accents etc
  value = value.replace(/(^|\s)([a-zéèàêëîïôöûüç])/g, function (m, p1, p2) {
    return p1 + p2.toUpperCase();
  });
  return value;
}

export const generateUniqueKey = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const checkIfPassIsGood = (value) => {
  let isGood = false;

  //verifier si le mot de passe contient au moins 8 caractères
  if (value.length >= 8) {
    //verifier si le mot de passe contient au moins une lettre minuscule
    if (value.match(/[a-z]/)) {
      //verifier si le mot de passe contient au moins une lettre majuscule
      if (value.match(/[A-Z]/)) {
        //verifier si le mot de passe contient au moins un chiffre
        if (value.match(/[0-9]/) || value.match(/[^a-zA-Z\d]/)) {
          //verifier si le mot de passe contient au moins un caractère spécial
          isGood = true;
        }
      }
    }
  }
  return isGood;
};
export const createParameterRequest = (object) => {
  const parameters = [];

  function buildParameters(key, value) {
    if (Array.isArray(value) && value.length > 0) {
      value.forEach((element, index) => {
        buildParameters(`${key}[${index}]`, element);
      });
    } else if (typeof value === "object" && value !== null) {
      Object.entries(value).forEach(([prop, val]) => {
        buildParameters(`${key}.${prop}`, val);
      });
    } else if (value !== undefined && value !== null && value !== "") {
      parameters.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  }

  Object.entries(object).forEach(([key, value]) => {
    buildParameters(key, value);
  });

  return parameters.join("&");
};
export const createParameterRequestCard = (object) => {
  const parameters = [];

  function buildParameters(key, value) {
    if (Array.isArray(value) && value.length > 0) {
      value.forEach((element, index) => {
        buildParameters(`${key}[${index}]`, element);
      });
    } else if (typeof value === "object" && value !== null) {
      Object.entries(value).forEach(([prop, val]) => {
        buildParameters(`${key}.${prop}`, val);
      });
    } else if (value !== undefined && value !== null && value !== "") {
      parameters.push(`${key}=${value}`);
    }
  }

  Object.entries(object).forEach(([key, value]) => {
    buildParameters(key, value);
  });

  return parameters.join("&");
};

export function SearchAutocomplete(search) {
  const apiKey = "AIzaSyD4U9RVHhJFdiwwVVoiCgD3NswM7HdXKmQ";
  const apiUrl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${search}&types=geocode&key=${apiKey}`;

  fetch(apiUrl)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
}
export function formatAndCheckDate(inputDate, language = "fr") {
  const momentDate = moment(inputDate);

  // Formater la date en DD MM YYYY
  const formattedDate = momentDate
    .locale(language)
    .format(language === "fr" ? "DD MMM YYYY" : "DD MMM YYYY");

  // Vérifier si la date est aujourd'hui
  if (momentDate.isSame(moment(), "day")) {
    return language === "fr" ? "aujourd'hui" : "today";
  }

  // Vérifier si la date est hier
  if (momentDate.isSame(moment().subtract(1, "day"), "day")) {
    return language === "fr" ? "hier" : "yesterday";
  }

  return formattedDate;
}

export function FormatterDate(date) {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");
  let formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}
export function FormatterDate2(date) {
  return new Date(moment(date).format("YYYY-MM-DD"));
}

export function filterObject(obj) {
  // Vérifie si la valeur est undefined, null, ou un tableau vide
  let newObject = {};
  // console.log(obj);
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
      if (!Array.isArray(obj[key])) {
        newObject[key] = obj[key];
      } else {
        if (obj[key].length > 0) {
          newObject[key] = obj[key];
        }
      }
    }
    //verifier si c'est un tableau vide
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      newObject[key] = obj[key];
    }
  });
  return newObject;

  const isUndesiredValue = (value) =>
    value === undefined ||
    value === null ||
    (Array.isArray(value) && value.length === 0);

  // Fonction récursive pour filtrer l'objet
  const filterRecursive = (input) => {
    if (typeof input !== "object" || input === null) {
      // Si la valeur n'est pas un objet, ou si elle est nulle, la renvoyer telle quelle
      return input;
    }

    if (Array.isArray(input)) {
      // Si la valeur est un tableau, filtrer ses éléments récursivement
      return input
        .map(filterRecursive)
        .filter((value) => !isUndesiredValue(value));
    }

    // Si la valeur est un objet, filtrer ses propriétés récursivement
    const result = {};
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        const filteredValue = filterRecursive(input[key]);
        if (!isUndesiredValue(filteredValue)) {
          result[key] = filteredValue;
        }
      }
    }
    return result;
  };

  return filterRecursive(obj);
}

export async function createGetRoute(baseRoute, params) {
  Object.keys(params).forEach((key, index) => {
    if (Array.isArray(params[key])) {
      let queryArray = params[key];

      queryArray.forEach((queryArrayValue, queryArrayIndex) => {
        if (index > 0) {
          baseRoute += "&";
        } else {
          baseRoute += "?";
        }

        baseRoute += `${key}[${queryArrayIndex}]=${queryArrayValue}`;
      });
    } else {
      if (index > 0) {
        baseRoute += "&";
      } else {
        baseRoute += "?";
      }

      baseRoute += key + "=" + params[key];
    }
  });

  return baseRoute;
}

export function generateDateRangeArray(fromDate, toDate) {
  // Format de retour : [new Date(2023, 11, 20), ...]
  let dateArray = [];
  let currentDate = new Date(fromDate);
  let stopDate = new Date(toDate);
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate.getTime())); // Utilisez getTime() pour obtenir une copie indépendante
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
}

let currentRequest = null;

export const handlePreviewPrice = async (
  fromDate,
  toDate,
  personneNumber,
  residenceId,
  canAbort = true,
  reservationPreviewPrice
) => {
  if (currentRequest && canAbort) {
    currentRequest.abort(); // Cancel the current request
  }
  if (reservationPreviewPrice) {
    return reservationPreviewPrice;
  }

  const controller = new AbortController();
  const { signal } = controller;

  try {
    currentRequest = controller; // Store the current request

    const sendData = {
      residence: residenceId,
      fromDate: fromDate ? new Date(fromDate).toISOString() : undefined,
      toDate: toDate ? new Date(toDate).toISOString() : undefined,
      adults: personneNumber,
    };
    let { data } = await post(requestEndpoint.previewBooking, sendData, {
      signal,
    });

    return data;
  } catch (error) {
    console.log(error);
  } finally {
    currentRequest = null; // Reset the current request
  }
};

export const fetchCountryInfo = async () => {
  let countries = null;
  const countrieDb = await get(requestEndpoint.getCountries);
  countries = countrieDb.data;
  try {
    const response = await fetch("https://restcountries.com/v3.1/all");
    if (response.ok) {
      const data = await response.json();
      const countryData = data.map((country) => ({
        countryCode: country.cca2,
        flagPath: country.flags.svg,
      }));
      countries = countryData;
      let currentDataPays = [];
      for (let index = 0; index < json.length; index++) {
        for (let jindex = 0; jindex < countryData.length; jindex++) {
          if (
            json[index].code.toString().toUpperCase() ==
            countryData[jindex].countryCode.toString().toUpperCase()
          ) {
            currentDataPays.push({
              ...json[index],
              flag: countryData[jindex].flagPath,
            });
            // break;
          }
        }
      }
      const countrieDb = await get(requestEndpoint.getCountries);
      let newCurrentDataPays = [];
      countrieDb.data.forEach((element) => {
        let find = false;
        let currentElement = { ...element };
        let finded = {};
        for (let index = 0; index < currentDataPays.length; index++) {
          if (
            currentDataPays[index].code.toString().toUpperCase() ==
            currentElement.iso2.toString().toUpperCase()
          ) {
            finded = currentDataPays[index];
            find = true;
            break;
          }
        }
        if (!find) {
          newCurrentDataPays.push({
            ...currentElement,
            flag: null,
          });
        } else {
          newCurrentDataPays.push({
            ...finded,
            name: currentElement.name,
            id: currentElement.id,
            province: currentElement.province,
          });
        }
      });

      countries = newCurrentDataPays;
      return { countries };
    } else {
      return { countries, error: true };
    }
  } catch (error) {
    return { countries, error: true };
  }
};

export const handleSepareContact = (contact) => {
  let contactVal = contact;
  // console.log(contactVal);
  contactVal = contactVal.replace(/[^0-9]/g, "");
  if (contactVal.length > 2) {
    contactVal = contactVal.slice(0, 2) + " " + contactVal.slice(2);
  }
  if (contactVal.length > 6) {
    contactVal = contactVal.slice(0, 6) + " " + contactVal.slice(6);
  }
  if (contactVal.length > 10) {
    contactVal = contactVal.slice(0, 10) + " " + contactVal.slice(10);
  }
  return contactVal;
};
export const handleHideSomeNumberInRib = (rib) => {
  let newRib = rib;
  newRib = newRib.replace(/[^0-9]/g, "");
  newRib = newRib.replace(/[^0-9]/g, "");
  if (newRib.length > 5) {
    newRib = newRib.slice(0, 5) + "-" + newRib.slice(5);
  }
  if (newRib.length > 14) {
    newRib =
      newRib.slice(0, newRib.length - 2) +
      "-" +
      newRib.slice(newRib.length - 2);
  }

  //remplace par des X les chiffres entre 6 et 10
  newRib = newRib.replace(newRib.slice(8, newRib.length - 3), "XXXXXXX");
  return newRib;
};

export const findVersementMethod = (id) => {
  let myPaiement = null;
  const listPaiementMethod = useStoreAllStaticData.getState().versementMethod;
  listPaiementMethod.map((item) => {
    if (item.id === id) myPaiement = item;
  });
  return myPaiement;
};

export const generateUniqueStringId = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const clearEmptyParams = () => {
  //clear juste dans l'url
  const getallUrl = window.location.href;
  const url = new URL(getallUrl);
  const params = new URLSearchParams(url.search);
  for (let key of params.keys()) {
    let value = params.get(key);
    if (value === "" || value === null || value === undefined) {
      params.delete(key);
    }
  }
  window.history.replaceState({}, "", `${url.pathname}?${params}`);
};

export const handleConvertToNumberInObject = (obj) => {
  const newObj = {};
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      newObj[key] = parseFloat(obj[key]);
    } else if (typeof obj[key] === "object") {
      newObj[key] = handleConvertToNumberInObject(obj[key]);
    }
  }
  return newObj;
};
