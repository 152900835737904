import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../globalTraduction";
import editIcon from "../../images/icons/edit-02.svg";
import {
  handleFormatToLocalString,
  justRetireLetter,
} from "../../services/usePersoFormat";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
import { UserPreferences } from "../../store/UserPreferences";
import "../../styles/components/addResidencePart/step14.css";
import Tooltip from "../molecules/Tooltip";
const currentStep = 14;

export default function Step14Price({
  step,
  handleBlockBtn,
  price,
  setPrice,
  setCurrentP,
  currentP,
}) {
  const { t } = useTranslation();
  const [focus, setFocus] = useState(false);
  const devise = UserPreferences((state) => state.devise);
  const showDevise = UserPreferences((state) => state.showDevise);
  const language = i18n.language;
  const refInput = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;
    if (price.toString().length > 2) {
      setIsLoaded(true);
      setCurrentP(
        handleFormatToLocalString(price.toString().slice(0, 7), language)
      );
      refInput.current.value = price;
    }
  }, [price]);
  useEffect(() => {
    if (currentStep == step) {
      if (
        devise.toString().toLocaleLowerCase() ===
          "Franc CFA".toLocaleLowerCase() &&
        price < 1000
      ) {
        handleBlockBtn(true);
        return;
      } else if (
        devise.toString().toLocaleLowerCase() === "Euro".toLocaleLowerCase() &&
        price < 2
      ) {
        handleBlockBtn(true);
        return;
      } else if (
        devise.toString().toLocaleLowerCase() ===
          "Dollar US".toLocaleLowerCase() &&
        price < 2
      ) {
        handleBlockBtn(true);
        return;
      }
      handleBlockBtn(false);
    }
  }, [step, price]);
  const handleOnChange = (e) => {
    let newValueSepared = handleFormatToLocalString(
      e.target.value.toString().slice(0, 7),
      language
    );

    let valueClassed = justRetireLetter(e.target.value.toString().slice(0, 7));
    setPrice(isNaN(valueClassed) ? 0 : valueClassed);
    e.target.value = valueClassed;
    setCurrentP(newValueSepared.length > 0 ? newValueSepared : "0");
  };
  let recievePrice = 0;
  if (price > 0) {
    recievePrice = price * 0.1;
    recievePrice = price - recievePrice;
    if (recievePrice.toString().split(".")[1])
      recievePrice = recievePrice.toFixed(0);
    if (recievePrice.toString().split(".")[0].length > 3) {
      recievePrice = handleFormatToLocalString(recievePrice, language);
    }
  } else {
    recievePrice = 0;
  }
  const checkIfBlock = useCallback(() => {
    if (
      devise.toString().toLocaleLowerCase() ===
        "Franc CFA".toLocaleLowerCase() &&
      price < 1000
    ) {
      return true;
    } else if (
      devise.toString().toLocaleLowerCase() === "Euro".toLocaleLowerCase() &&
      price < 2
    ) {
      return true;
    } else if (
      devise.toString().toLocaleLowerCase() ===
        "Dollar US".toLocaleLowerCase() &&
      price < 2
    ) {
      return true;
    }
    return false;
  }, [price]);
  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && !checkIfBlock()) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [checkIfBlock, step, price]);
  return (
    <section
      className={`step-1-annonce step-14 ${
        currentStep == step ? "visible" : ""
      } 
          ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>{t("addResidence.step14.label")}</label>
            <p>{t("addResidence.step14.description")}</p>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="content | flcolm addgap16">
            <div className="price-content | flrow ">
              <div className="price-manager | flcolm liner rlve">
                <p className="price-show | rlve">
                  <span
                    style={{
                      color:
                        currentP == 0
                          ? "var(--gray-500)"
                          : "var(--shade-black)",
                    }}
                    className={`${focus ? "focus" : ""} price`}
                  >
                    {currentP}
                  </span>
                  <span className="devise">{showDevise}</span>
                  <button
                    onClick={() => refInput.current.focus()}
                    className={`${focus ? "focus" : ""} btn`}
                  >
                    <img src={editIcon} alt="edit-icon" />
                  </button>
                </p>
                <input
                  ref={refInput}
                  placeholder="0"
                  onChange={handleOnChange}
                  onFocus={() => {
                    setFocus(true);
                    // refInput.current.selectionStart = 0;
                  }}
                  onBlur={() => setFocus(false)}
                  type="number"
                />
                <p
                  style={{
                    maxWidth: "80%",
                  }}
                >
                  {t("addResidence.step14.paragraph")}{" "}
                  <p
                    style={{
                      display: "inline-block",
                      margin: 0,
                      textDecoration: "underline",
                      textUnderlineOffset: 2,
                    }}
                    className="hv-tooltip"
                  >
                    {t("addResidence.step14.commission")}
                    <Tooltip minWidth={200} maxWidth={260} zIndex={6}>
                      {t("addResidence.step14.tooltip.commission")}
                    </Tooltip>
                  </p>{" "}
                  {t("addResidence.step14.tooltip.eachReservation")}
                  <p
                    style={{
                      margin: 0,
                      marginLeft: 4,
                      display: "inline-block",
                      fontFamily: "Inter",
                      fontWeight: 800,
                    }}
                  >{`  ${recievePrice} ${showDevise}`}</p>{" "}
                  {t("addResidence.step14.tooltip.price")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
