/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrayParam,
  NumberParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { dataComodities } from "../../data/dataComodities";
import dataSocialsActivities from "../../data/dataSocialsActivities";
import i18n from "../../globalTraduction";
import chevronRight from "../../images/icons/chevron-right.svg";
import ArrowStoreListing from "../../images/icons/localisation.svg";
import settingsIcon from "../../images/icons/settings-04.svg";
import useCurrentWidth from "../../services/useCurrentWidth";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import { useStoreComodities } from "../../store/dataComodities";
import "../../styles/components/filtersResidence.css";
import FiltersExtendPanel from "../cells/FiltersExtendPanel";
import SearchStore from "../cells/SearchStore";
import SelectFiltersOptionCard from "../molecules/SelectFiltersOptionCard";
export default function FiltersResidence({ priceRange = [1000, 200000] }) {
  const { t } = useTranslation();
  const [query] = useQueryParams({
    minprice: withDefault(NumberParam, null),
    maxprice: withDefault(NumberParam, null),
    activitiesId: withDefault(ArrayParam, []),
    assetId: withDefault(ArrayParam, []),
  });
  const comodities = useStoreComodities((state) => state.comodities);
  const language = i18n.language;
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const [mapOpen] = useQueryParam("mapOpen");
  const storeParameters = useStoreManager((state) => state.storeParameters);

  const [filters, setFilters] = useState(dataComodities.data);
  const [filtersSocialsActivities, setFiltersSocialsActivities] = useState([]);
  const handleUpdateStoreParameters = useStoreManager(
    (state) => state.handleUpdateStoreParameters
  );
  const refreshStore = useStoreManager((state) => state.refreshStore);
  useEffect(() => {
    if (!comodities) return;
    const urlAsset = query.assetId.map((id) => parseInt(id));
    let newTab = [];
    comodities.map((comoditie) => {
      newTab.push({
        ...comoditie,
        selected: urlAsset.includes(comoditie.id),
      });
    });
    setFilters(newTab);
  }, [comodities]);
  useEffect(() => {
    if (!dataSocialsActivities) return;
    let newTab = [];
    dataSocialsActivities.data.map((social) => {
      const urlActivities = query.activitiesId.map((id) => parseInt(id));
      social.selectables.map((selectable) => {
        newTab.push({
          ...selectable,
          selected: urlActivities.includes(selectable.id),
        });
      });
    });
    setFiltersSocialsActivities(newTab);
  }, [dataSocialsActivities]);

  const scrollableRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartX(e.pageX - scrollableRef.current.offsetLeft);
    setScrollLeft(scrollableRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - scrollableRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    scrollableRef.current.scrollLeft = scrollLeft - walk;
  };

  const [filtersExtendPanel, setFiltersExtendPanel] = useState(false);

  useEffect(() => {
    handleUpdateBodyOverflow(!filtersExtendPanel);
  }, [filtersExtendPanel]);

  const handleUpdateFilterComodities = async (mineFilter) => {
    const newFilters = filters.map((filter) => {
      if (filter.id === mineFilter.id) {
        return { ...filter, selected: !filter.selected };
      }
      return filter;
    });
    let newCommodites = [];
    newFilters.map((filterComoditie) => {
      if (filterComoditie.selected) {
        newCommodites.push(filterComoditie.id);
      }
    });
    let pageSend = 1;
    if (filtersExtendPanel == true) pageSend = storeParameters.page;
    await handleUpdateStoreParameters(
      {
        ...storeParameters,
        page: pageSend,
        commodites: newCommodites,
      },
      "handleUpdateFilterComodities"
    );
    setFilters(newFilters);
    if (filtersExtendPanel == false) {
      await useStoreManager.getState().handleUpdateSearchStoreParameters();
      useStorePageManager.getState().handleUpdateCurrentMapTouched(null);
      if (refreshStore) refreshStore();
    }
  };
  const handleUpdateSocialActivities = async (mineFilter) => {
    const newFilters = filtersSocialsActivities.map((filter) => {
      if (filter.id === mineFilter.id) {
        return { ...filter, selected: !filter.selected };
      }
      return filter;
    });
    let newSocialsActivities = [];
    newFilters.map((filterSocial) => {
      if (filterSocial.selected) {
        newSocialsActivities.push(filterSocial.id);
      }
    });
    await handleUpdateStoreParameters(
      {
        ...storeParameters,
        activitiesIds: newSocialsActivities,
      },
      "handleUpdateSocialActivities"
    );
    setFiltersSocialsActivities(newFilters);
    if (filtersExtendPanel == false) {
      useStoreManager.getState().handleUpdateSearchStoreParameters();
      useStorePageManager.getState().handleUpdateCurrentMapTouched(null);
      if (refreshStore) refreshStore();
    }
  };

  const handleScrollToRight = () => {
    if (scrollableRef.current) {
      const currentPosition = scrollableRef.current.scrollLeft;
      const duration = 500;
      const startTime = performance.now();

      const scroll = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        scrollableRef.current.scrollLeft = currentPosition + progress * 1000;

        if (elapsed < duration) {
          requestAnimationFrame(scroll);
        }
      };

      requestAnimationFrame(scroll);
    }
  };
  const [priceRangeState, setPriceRangeState] = useState([0, 200000]);
  useEffect(() => {
    if (query.minprice && query.maxprice) {
      setPriceRangeState([
        query.minprice >= priceRange[0] ? query.minprice : priceRange[0],
        query.maxprice <= priceRange[1] ? query.maxprice : priceRange[1],
      ]);
    } else setPriceRangeState([priceRange[0], priceRange[1]]);
  }, [priceRange]);

  const selectComoditiesToShow = (allIdsToShow) => {
    let newFilters = [];
    let newFiltersComodities = filters.slice();
    newFiltersComodities.map((filter) => {
      if (allIdsToShow.includes(filter.id)) {
        newFilters.push(filter);
      }
    });
    return newFilters;
  };
  const locateUserOnMap = useStorePageManager((state) => state.locateUserOnMap);
  // GESTION DE FILTER MOBILE

  const { windowWidth } = useCurrentWidth();

  return (
    <>
      <div className="filters-replace"></div>
      <div
        style={{ height: windowWidth > 550 ? "auto" : 85 }}
        className="filters-complete | flcolm rlve max-container max-container-store"
      >
        {/* <div className="contents"></div> */}
        {windowWidth > 550 && (
          <SearchStore handleFilters={setFiltersExtendPanel} />
        )}
        <div className="filters | flrow">
          <div
            ref={scrollableRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            className="btns-filter scrollable | flrow addgap16"
          >
            {filters &&
              selectComoditiesToShow([
                1, 2, 3, 4, 8, 9, 11, 25, 12, 13, 14, 15, 17, 18, 19, 21, 22,
                23, 24, 7, 40,
              ]).map((filter) => (
                <React.Fragment key={filter.id}>
                  <SelectFiltersOptionCard
                    language={language}
                    mine={filter}
                    setFilters={handleUpdateFilterComodities}
                  />
                </React.Fragment>
              ))}
          </div>
          <button onClick={handleScrollToRight} className="btn-next-step | btn">
            <img src={chevronRight} alt="settings" />
          </button>
          <div className="flters-right-btn flrow liner">
            <button
              onClick={() => setFiltersExtendPanel(true)}
              className="show-filter | btn opt-btn"
            >
              <img
                src={settingsIcon}
                alt="icon-settings"
                style={{ width: 18, height: 18 }}
              />
              <span>{t("store.top.btnFilters")}</span>
            </button>

            <button
              onClick={() => {
                if (locateUserOnMap) locateUserOnMap(true);
              }}
              style={
                mapOpen == true
                  ? {
                      width: "0px",
                      borderWidth: "0px",
                      transition: "all 0.15s ease",
                      opacity: 0,
                    }
                  : {
                      display: "block",
                      transition: "all 0.15s ease",
                      opacity: 1,
                    }
              }
              className="btn arrow-localisation"
            >
              <img
                style={{
                  transform: "translate(-1px, 3px)",
                }}
                src={ArrowStoreListing}
                alt="localisation"
              />
            </button>
          </div>
        </div>
      </div>
      {windowWidth <= 550 && (
        <SearchStore
          className="for-mobile-part"
          handleFilters={setFiltersExtendPanel}
          isStore={true}
        />
      )}
      <FiltersExtendPanel
        setPriceRangeStateCurrent={setPriceRangeState}
        priceRangeStateCurrent={priceRangeState}
        priceRange={priceRange}
        language={language}
        filtersComodities={filters}
        setFiltersComodities={setFilters}
        filtersSocialsActivities={filtersSocialsActivities}
        setFiltersSocialsActivities={setFiltersSocialsActivities}
        filtersExtendPanel={filtersExtendPanel}
        setFiltersExtendPanel={setFiltersExtendPanel}
        handleUpdateFilterComodities={handleUpdateFilterComodities}
        handleUpdateStoreParameters={handleUpdateStoreParameters}
        handleUpdateSocialActivities={handleUpdateSocialActivities}
        storeParameters={storeParameters}
      />
    </>
  );
}
