import React, { useEffect, useState } from "react";
import FiltersResidence from "../components/biggest/FiltersResidence";
import StoreResidenceList from "../components/biggest/StoreResidenceList";
import { requestEndpoint } from "../data/requestEndpoint";
import { get } from "../services/useFetchAction";
import { smoothScrollUp } from "../services/utils";
import { useStorePageManager } from "../store/PageManage";
import "../styles/pages_styles/storePage.css";
export default function StorePage() {
  const [priceRange, setPriceRange] = useState([1000, 150000]);
  useEffect(() => {
    useStorePageManager.getState().handleUpdateDesactiveHeader(false);
    useStorePageManager.getState().handleUpdateStorePage(true);
    return () => {
      useStorePageManager.getState().handleUpdateStorePage(false);
    };
  }, []);
  useEffect(() => {
    const searchPrice = async () => {
      try {
        const response = await get(requestEndpoint.residencePriceRange);
        setPriceRange([response.data.min, response.data.max]);
      } catch (error) {
        console.log(error);
      }
    };
    searchPrice();
  }, []);
  const lastTouchedResidenceId = useStorePageManager(
    (state) => state.lastResidenceTouched
  );
  useEffect(() => {
    if (lastTouchedResidenceId) {
      window.scroll({
        top: 0,
      });
      return;
    }
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);

  return (
    <>
      <main>
        <FiltersResidence priceRange={priceRange} />
        <StoreResidenceList priceRange={priceRange} />
      </main>
    </>
  );
}
