import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../globalTraduction";
import { Star } from "../../images/icons/JsxIcons";
import tempProfilPicture from "../../images/temps/user-02.svg";
import { NotificationService } from "../../services/Notification.service";
import "../../styles/components/commentaryModal.css";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import NewCommentaryModal from "./NewCommentaryModal";

export default function CommentaryModal({
  residence,
  allCommentary,
  totalCalcul,
  propriete,
  emplacement,
  rapportQualite,
  assets,
  communication,
}) {
  const { t } = useTranslation();
  const [canSendCommentary, setCanSendCommentary] = useState(false);
  const language = i18n.language;
  const [openNewComment, newCommentModal] = useState(false);
  const handleOpenNewComment = () => {
    if (canSendCommentary == false) {
      NotificationService.addToQueue(
        1,
        t("residencePage.commentary.notification.notAllow")
      );
      return;
    }
    newCommentModal(true);
  };
  const handleCloseModalSend = () => {
    newCommentModal(false);
    setCanSendCommentary(false);
  };
  useEffect(() => {
    if (residence) {
      setCanSendCommentary(residence.isAllowComment);
    }
  }, [residence]);
  return (
    <>
      <div className="contents-commentary | flrow">
        <div className="commentary-recap | flcolm">
          <div className="title | flrow liner addgap8">
            <Star />
            <label>
              {residence.stars
                ? parseFloat(residence.stars)
                    .toFixed(1)
                    .replace(".", language == "fr" ? "," : ".")
                : "N/A"}{" "}
              ({allCommentary.length}{" "}
              {t("residencePage.commentary.txts.comment")}
              {allCommentary.length > 1 ? "s" : ""})
            </label>
          </div>
          <div className="bar-state | flcolm addgap8">
            <div className="property | flrow liner btw">
              <span>
                {t("residencePage.commentary.modals.txts.proprieteTxt")}
              </span>
              <div className="right-side | flrow liner addgap8">
                <div className="bar | rlve">
                  <div
                    style={{ width: `calc(${propriete / 5} * 100%)` }}
                    className="remplissage"
                  ></div>
                </div>
                <span>
                  {propriete
                    .toFixed(1)
                    .replace(".", language == "fr" ? "," : ".")}
                </span>
              </div>
            </div>
            <div className="property | flrow liner btw">
              <span>
                {t("residencePage.commentary.modals.txts.emplacementTxt")}
              </span>
              <div className="right-side | flrow liner addgap8">
                <div className="bar | rlve">
                  <div
                    style={{ width: `calc(${emplacement / 5} * 100%)` }}
                    className="remplissage"
                  ></div>
                </div>
                <span>
                  {emplacement
                    .toFixed(1)
                    .replace(".", language == "fr" ? "," : ".")}
                </span>
              </div>
            </div>
            <div className="property | flrow liner btw">
              <span>
                {t("residencePage.commentary.modals.txts.rapportQualiteTxt")}
              </span>
              <div className="right-side | flrow liner addgap8">
                <div className="bar | rlve">
                  <div
                    style={{ width: `calc(${rapportQualite / 5} * 100%)` }}
                    className="remplissage"
                  ></div>
                </div>
                <span>
                  {rapportQualite
                    .toFixed(1)
                    .replace(".", language == "fr" ? "," : ".")}
                </span>
              </div>
            </div>
            <div className="property | flrow liner btw">
              <span>{t("residencePage.commentary.modals.txts.assetsTxt")}</span>
              <div className="right-side | flrow liner addgap8">
                <div className="bar | rlve">
                  <div
                    style={{ width: `calc(${assets / 5} * 100%)` }}
                    className="remplissage"
                  ></div>
                </div>
                <span>
                  {assets.toFixed(1).replace(".", language == "fr" ? "," : ".")}
                </span>
              </div>
            </div>
            <div className="property | flrow liner btw">
              <span>
                {t("residencePage.commentary.modals.txts.communicationTxt")}
              </span>
              <div className="right-side | flrow liner addgap8">
                <div className="bar | rlve">
                  <div
                    style={{ width: `calc(${communication / 5} * 100%)` }}
                    className="remplissage"
                  ></div>
                </div>
                <span>
                  {communication
                    .toFixed(1)
                    .replace(".", language == "fr" ? "," : ".")}
                </span>
              </div>
            </div>
          </div>
          <button
            onClick={handleOpenNewComment}
            className={`open-new-comment-btn | btn pry-btn ${
              canSendCommentary ? "" : "disabled"
            }`}
          >
            {t("residencePage.commentary.actions.leaveCommentTxt")}
          </button>
        </div>
        <div className="commentary-list | flcolm ">
          {allCommentary.map((commentary) => {
            let totalName = "";
            if (residence) {
              totalName = commentary.name.toLocaleLowerCase();
              totalName = totalName.toLowerCase();
              totalName = totalName.replace(
                /(^|\s)([a-zéèàêëîïôöûüç])/g,
                function (m, p1, p2) {
                  return p1 + p2.toUpperCase();
                }
              );
            }
            return (
              <div
                key={"commentary-" + commentary.id}
                className="commentary flcolm"
              >
                <p className="comment">{commentary.message}</p>
                <div className="comment-person | flrow liner">
                  <img
                    src={
                      commentary.thumb
                        ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PROFIL_PICTURE}${commentary.thumb}`
                        : tempProfilPicture
                    }
                    alt="commentary-profil"
                  />
                  <div className="title-pers | flcolm">
                    <span>{totalName}</span>
                    <div className="flrow liner">
                      <Star />
                      <span>
                        {parseFloat(commentary.stars)
                          .toFixed(1)
                          .replace(".", language == "fr" ? "," : ".")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ContainerPersoModal
        className="new-commentary-modal"
        title={t("redigerCommentaryTxt")}
        isOpen={openNewComment}
        toggle={newCommentModal}
      >
        <NewCommentaryModal
          handleCloseModalSend={handleCloseModalSend}
          residence={residence}
          noteCommentary={totalCalcul}
        />
      </ContainerPersoModal>
    </>
  );
}
