import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/components/faqPart.css";
import FaqItem from "../cells/FaqItem";

export default function FaqPart() {
  const { t } = useTranslation();
  const [faqContent, setFaqContent] = useState([
    {
      title: t("listPopuResidence.faq.cards.one.title"),
      description: (
        <div className="flcolm faq-description">
          <p>{t("listPopuResidence.faq.cards.one.subtitle")}</p>
          <ul className="flcolm">
            <li>{t("listPopuResidence.faq.cards.one.one")}</li>
            <li>{t("listPopuResidence.faq.cards.one.two")}</li>
            <li>{t("listPopuResidence.faq.cards.one.three")}</li>
            <li>
              {t("listPopuResidence.faq.cards.one.four")}{" "}
              <span>{t("listPopuResidence.faq.cards.one.four2")}</span>
            </li>
            <li>
              {t("listPopuResidence.faq.cards.one.five")}{" "}
              <span>{t("listPopuResidence.faq.cards.one.five2")}</span>
            </li>
          </ul>
          <p>{t("listPopuResidence.faq.cards.one.six")}</p>
        </div>
      ),
    },
    {
      title: t("listPopuResidence.faq.cards.two.title"),
      description: (
        <div className="flcolm faq-description">
          <ul style={{ marginTop: 20 }} className="flcolm">
            <li>
              {t("listPopuResidence.faq.cards.two.one")}{" "}
              <span>{t("listPopuResidence.faq.cards.two.one2")}</span>
            </li>
            <li>{t("listPopuResidence.faq.cards.two.two")}</li>
          </ul>
          <p>{t("listPopuResidence.faq.cards.two.three")}</p>
        </div>
      ),
    },
    {
      title: t("listPopuResidence.faq.cards.three.title"),
      description: (
        <div className="flcolm faq-description">
          <p>{t("listPopuResidence.faq.cards.three.description")}</p>
        </div>
      ),
    },
  ]);
  return (
    <section className="paiement faq-part-section  | flcolm liner max-container rlve">
      <div className="contains | flcolm jcenter">
        <div className="title | flcolm liner addgap8">
          <h2>{t("listPopuResidence.faq.title")}</h2>
          <p>{t("listPopuResidence.faq.description")}</p>
        </div>
        <div className="contents-faq | flcolm addgap16 jcenter liner">
          {faqContent.map((faq) => (
            <FaqItem title={faq.title}>{faq.description}</FaqItem>
          ))}
        </div>
      </div>
    </section>
  );
}
