import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../globalTraduction";
import { useStoreAllStaticData } from "../../store/dataForAll";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
import "../../styles/components/addResidencePart/step2.css";
const currentStep = 1;
export default function Step2TypeResidence({
  step,
  handleBlockBtn,
  currentTypeResi,
  setTypeResi,
}) {
  const { t } = useTranslation();
  const language = i18n.language;
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(currentTypeResi == 0);
  }, [step, currentTypeResi]);
  const typeResi = useStoreAllStaticData((state) => state.typeResi);

  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && currentTypeResi != 0) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [step, currentTypeResi]);
  return (
    <section
      className={`step-1-annonce step-2 ${currentStep == step ? "visible" : ""} 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>{t("addResidence.step2.label")}</label>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="contains-input | flcolm jcenter liner">
            <div className="filter-selected-panel | flcolm">
              <div className="residence-type | flrow">
                {typeResi.map((type, index) => {
                  return (
                    <button
                      key={type.id + "-resi-" + index}
                      onClick={() => {
                        setTypeResi(type.id);
                      }}
                      className={`${
                        currentTypeResi == type.id && "selected-type | "
                      } btn flcolm`}
                    >
                      <img className="svg-img-show" src={type.icon} />

                      <span>{language == "fr" ? type.name : type.nameEng}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
