import Cookies from "js-cookie";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../data/requestEndpoint";
import { NotificationService } from "../../services/Notification.service";
import { post } from "../../services/useFetchAction";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreUserManager } from "../../store/UserManage";
import "../../styles/components/signalementModal.css";
import ContainerPersoModal from "../biggest/ContainerPersoModal";

export default function SignalementModal({ handleOutModal, data }) {
  const [currentSelect, toogleSelect] = useState(0);
  const [openModalWrite, setOpenModalWrite] = useState(false);
  const { t } = useTranslation();
  const handleCheckIfAlreadySignaled = (justCheck = false) => {
    const cookieData = Cookies.get("signalementSave");
    const myArray = cookieData ? JSON.parse(cookieData) : [];
    let isAlreadySignaled = false;
    myArray.map((signalement) => {
      if (
        signalement.residenceId == data.id &&
        signalement.userId == useStoreUserManager.getState().user.id
      ) {
        isAlreadySignaled = true;
      }
    });
    if (isAlreadySignaled && justCheck == false) {
      NotificationService.addToQueue(
        1,
        t("modals.signalement.notification.alreadySignaled")
      );
    }
    return isAlreadySignaled;
  };

  const handleSaveToCookies = (dataSignalement) => {
    const cookieData = Cookies.get("signalementSave");
    const myArray = cookieData ? JSON.parse(cookieData) : [];
    myArray.push(dataSignalement);
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 1);
    Cookies.set("signalementSave", JSON.stringify(myArray), {
      expires: expirationDate,
    });
  };
  const refTextComment = useRef(null);
  const [currentComment, setCurrentComment] = useState("");
  const handleChangeComment = (e) => {
    refTextComment.current.value = e.target.value.slice(0, 300);
    setCurrentComment(refTextComment.current.value);
  };

  const handleSendSignalement = async () => {
    if (currentSelect == 0) return;
    if (useStoreUserManager.getState().user.connected == false) {
      NotificationService.addToQueue(
        1,
        t("modals.signalement.notification.youNeedConnect")
      );
      useStorePageManager.getState().handleUpdateConnexionPanelState(true);
      return;
    }
    //verifier si l'utilisateur n'a pas déjà signalé cette annonce

    if (handleCheckIfAlreadySignaled()) {
      return;
    }
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    let signaleReason = "";
    let signaleReasonEn = "";
    switch (currentSelect) {
      case 1:
        signaleReason = t("modals.signalement.champs.reason1", { lng: "fr" });
        signaleReasonEn = t("modals.signalement.champs.reason1", { lng: "en" });
        break;
      case 2:
        signaleReason = t("modals.signalement.champs.reason2", { lng: "fr" });
        signaleReasonEn = t("modals.signalement.champs.reason2", { lng: "en" });
        break;
      case 3:
        signaleReason = t("modals.signalement.champs.reason3", { lng: "fr" });
        signaleReasonEn = t("modals.signalement.champs.reason3", { lng: "en" });
        break;
      case 4:
        signaleReason = t("modals.signalement.champs.reason4", { lng: "fr" });
        signaleReasonEn = t("modals.signalement.champs.reason4", { lng: "en" });
        break;
      case 5:
        signaleReason = currentComment;
        signaleReasonEn = currentComment;
        break;
      default:
        break;
    }
    try {
      let dataSend = {
        residenceId: data.id,
        reason: signaleReason,
        reasonEn: signaleReasonEn,
      };
      const response = await post(
        requestEndpoint.signalementResidence,
        dataSend
      );
      handleOutModal();
      NotificationService.addToQueue(
        0,
        t("modals.signalement.notification.signalDone")
      );

      let dataSignalement = {
        residenceId: data.id,
        userId: useStoreUserManager.getState().user.id,
      };
      handleSaveToCookies(dataSignalement);
    } catch (error) {
      console.log(error);
      NotificationService.addToQueue(
        1,
        t("modals.signalement.notification.errorSignal")
      );
    }
    useStorePageManager.getState().handleUpdateBlockScreen(false);
  };

  const leftLenght = 150 - currentComment.length;
  return (
    <>
      <div className="signalement-modal | flcolm">
        <div className="title-modal flcolm addgap8">
          <label>{t("modals.signalement.label")}</label>
          <p>{t("modals.signalement.description")}</p>
        </div>
        <div className="contents | flcolm">
          <div className="selectors | flcolm">
            <button
              onClick={() => toogleSelect(2)}
              className="select-btn | btn btw rlve"
            >
              <span>{t("modals.signalement.champs.reason2")}</span>
              <div className="selector-circle rlve">
                {currentSelect == 2 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
            <button
              onClick={() => toogleSelect(3)}
              className="select-btn | btn btw rlve"
            >
              <span>{t("modals.signalement.champs.reason3")}</span>
              <div className="selector-circle rlve">
                {currentSelect == 3 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
            <button
              onClick={() => toogleSelect(4)}
              className="select-btn | btn btw rlve"
            >
              <span>{t("modals.signalement.champs.reason4")}</span>
              <div className="selector-circle rlve">
                {currentSelect == 4 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
            <button
              onClick={() => toogleSelect(5)}
              className="select-btn | btn btw rlve"
            >
              <span>{t("modals.signalement.champs.reason5")}</span>
              <div className="selector-circle rlve">
                {currentSelect == 5 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
          </div>
        </div>
        {currentSelect == 5 ? (
          <button
            disabled={openModalWrite}
            onClick={() => setOpenModalWrite(true)}
            className="validate-btn | btn pry-btn"
          >
            <span>{t("modals.signalement.action.next")}</span>
          </button>
        ) : (
          <button
            style={
              handleCheckIfAlreadySignaled(true)
                ? {
                    opacity: 1,
                    background: "var(--gray-500)",
                    cursor: "not-allowed",
                  }
                : {}
            }
            onClick={handleSendSignalement}
            disabled={currentSelect == 0}
            className="validate-btn | btn pry-btn"
          >
            <span>{t("modals.signalement.action.send")}</span>
          </button>
        )}
      </div>
      <ContainerPersoModal
        isOpen={openModalWrite}
        toggle={setOpenModalWrite}
        title={t("modals.signalement.miniModal.title")}
        baseTop={true}
        noOverflow={true}
        className="modal-write"
      >
        <div className="modal-write-send flcolm addgap8">
          <div className="comment-div | flcolm">
            <textarea
              ref={refTextComment}
              maxLength={150}
              onChange={handleChangeComment}
              className="comment-part"
              placeholder={t("modals.signalement.miniModal.placeholder")}
            ></textarea>
            <span>{leftLenght}/150</span>
          </div>
          <button
            style={
              handleCheckIfAlreadySignaled(true)
                ? {
                    opacity: 1,
                    borderRadius: 8,
                    background: "var(--gray-500)",
                    cursor: "not-allowed",
                  }
                : {
                    borderRadius: 8,
                  }
            }
            disabled={leftLenght > 145}
            onClick={handleSendSignalement}
            className="btn pry-btn"
          >
            <span>{t("modals.signalement.action.send")}</span>
          </button>
        </div>
      </ContainerPersoModal>
    </>
  );
}
