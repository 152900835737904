/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../../data/requestEndpoint";
import { UploadImg } from "../../../images/icons/JsxIcons";
import { NotificationService } from "../../../services/Notification.service";
import { post } from "../../../services/useFetchAction";
import { compressImage } from "../../../services/utils";
import { useStorePageManager } from "../../../store/PageManage";
import { useStoreUserManager } from "../../../store/UserManage";
import ContainerPersoModal from "../../biggest/ContainerPersoModal";

export default function AddPiecePart({
  handleUpdateUser,
  handleUpdateBlockScreen,
  userData,
  maxSizeInBytes,
}) {
  const { t } = useTranslation();
  const [currentFile, setCurrentFile] = useState([]);
  const [modalReconfirm, setModalReconfirm] = useState(false);
  const [imgPreview, setImgPreview] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const onDropFunction = useCallback(async (acceptedFiles, nexStep = null) => {
    let newFile = [];
    await Promise.all(
      acceptedFiles.map(async (file) => {
        if (
          ["image/png", "image/jpeg", "application/pdf"].includes(file.type)
        ) {
          if (file.size <= maxSizeInBytes && file.type != "application/pdf") {
            // const compressedFile = await compressImage(file);
            const compressedFile = file;
            newFile.push(compressedFile);
          } else {
            if (file.type == "application/pdf" && file.size <= maxSizeInBytes) {
              newFile.push(file);
            } else
              NotificationService.addToQueue(
                2,
                t("profile.notification.fileSizeNotAccepted")
              );
          }
        } else {
          NotificationService.addToQueue(
            2,
            t("profile.notification.fileTypeNotAccepted")
          );
        }
      })
    );
    if (newFile.length == 0) return;
    setCurrentFile(newFile);
    if (userData.pieceChecked != 1) setOpenModal(true);
    else setModalReconfirm(true);
  }, []);
  useEffect(() => {
    const getImgUrl = async () => {
      if (userData.pieceChecked == 1) {
        if (currentFile.length > 0 && openModal) return;
        const extension = userData.identityDoc.split(".").pop();
        setFileExtension(extension);
        const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PROFIL_DOC}${userData.identityDoc}`;
        setImgPreview(url);
      }
    };
    getImgUrl();
  }, [userData, currentFile, openModal]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (file) => onDropFunction(file),
    accept: {
      "image/*": [".jpeg", ".png", ".pdf"],
      "application/*": ".pdf",
    },
    multiple: false,
  });

  const handleSendPiece = async (update = false) => {
    handleUpdateBlockScreen(true);
    const data = new FormData();
    data.append("file", currentFile[0]);

    await post(
      update ? requestEndpoint.modifyPiece : requestEndpoint.uploadPiece,
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .then((res) => {
        // console.log(res);
        setCurrentFile([]);
        useStoreUserManager.getState().handleUpdateUser({ pieceChecked: 1 });
        useStoreUserManager
          .getState()
          .handleUpdateUser({ identityDoc: res.data.identityDoc });

        if (update) {
          setModalReconfirm(false);
          setOpenModal(false);
          NotificationService.addToQueue(
            0,
            t("profile.notification.pieceUploadDone")
          );
        } else
          NotificationService.addToQueue(
            0,
            t("profile.notification.pieceUploadDone")
          );
      })
      .catch((err) => {
        console.log(err);
        if (update) {
          //on verifie si il ya un message d'erreur
          if (err.data && err.data.message) {
            NotificationService.addToQueue(2, err.data.message);
          } else
            NotificationService.addToQueue(
              2,
              t("profile.notification.pieceUploadError")
            );
        } else
          NotificationService.addToQueue(
            2,
            t("profile.notification.pieceUploadError")
          );
      })
      .finally(() => {
        handleUpdateBlockScreen(false);
        useStorePageManager.getState().handleUpdateBodyOverflow(true);
        if (!update) setOpenModal(false);
      });
  };
  const handleChangementFichier = async (e) => {
    const fichierSelectionne = e.target.files[0];

    let newFile = [];

    // Vérifier si un fichier a été sélectionné
    if (fichierSelectionne) {
      if (
        ["image/png", "image/jpeg", "application/pdf"].includes(
          fichierSelectionne.type
        )
      ) {
        if (
          fichierSelectionne.size <= maxSizeInBytes &&
          fichierSelectionne.type != "application/pdf"
        ) {
          const compressedFile = await compressImage(fichierSelectionne);
          newFile.push(compressedFile);
        } else {
          if (
            fichierSelectionne.type == "application/pdf" &&
            fichierSelectionne.size <= maxSizeInBytes
          ) {
            newFile.push(fichierSelectionne);
          } else
            NotificationService.addToQueue(
              2,
              t("profile.notification.fileSizeNotAccepted")
            );
        }
        //si l'event provient de la refNewFile on open le modal2
      } else {
        NotificationService.addToQueue(
          2,
          t("profile.notification.fileTypeNotAccepted")
        );
      }
    }
    if (newFile.length == 0) return;
    setCurrentFile(newFile);
    setOpenModal(true);
    if (e.target == refNewFile.current) {
      setModalReconfirm(true);
    }
  };
  const refNewFile = useRef(null);
  // console.log(userData);
  return (
    <div className="fichier-upload | flcolm jcenter liner">
      {userData.pieceChecked == 0 ? (
        <>
          <button
            // {...getRootProps()}
            className="content-upload | flcolm jcenter liner rlve"
          >
            <input
              type="file"
              id="inputFile"
              onChange={handleChangementFichier}
              style={{
                cursor: "pointer",
                zIndex: 4,
                pointerEvents: "all",
                userSelect: "auto",
                position: "absolute",
                opacity: 0,
                transform: "scale(1)",
                left: 0,
                width: "100%",
                height: "100%",
              }}
              accept="image/png, image/jpeg, application/pdf"
            />
            <UploadImg />
            <div className="upload-txt | flcolm jcenter liner">
              <span>{t("profile.piece.infos")}</span>
              <p>{t("profile.piece.pieceAcceptsTxt")}</p>
            </div>
          </button>
          <div className="bot-info | flcolm jcenter liner">
            <span style={{ textAlign: "center" }}>
              {t("profile.piece.completeProfileTxt")}
            </span>
            <p style={{ textAlign: "center" }}>
              {t("profile.piece.addPiceIdentityTxt")}
            </p>
          </div>
        </>
      ) : (
        <>
          <button
            onClick={() => {
              setOpenModal(true);
              setCurrentFile([]);
            }}
            className="btn content-upload inload | flrow jcenter liner"
          >
            <div
              style={{
                padding: 0,
              }}
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              {fileExtension == "pdf" ? (
                <span className="pdf-file">
                  {t("profile.piece.confirm.label")}
                </span>
              ) : (
                <img
                  src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PROFIL_DOC}${userData.identityDoc}`}
                  alt="Pièce PDF"
                />
              )}
            </div>
            <div className="upload-txt | flrow jcenter liner">
              <span>{t("profile.piece.confirm.check")}</span>
            </div>
          </button>
          <div className="bot-info | flcolm jcenter liner">
            <span style={{ textAlign: "center" }}>
              {t("profile.piece.confirm.usedId")}
            </span>
            <p style={{ textAlign: "center" }}>
              {t("profile.piece.confirm.idInfoClick")}
            </p>
          </div>
        </>
      )}
      <ContainerPersoModal
        bottomChildren={true}
        baseTop={true}
        title={
          userData.identityDoc
            ? t("modals.piece.title")
            : t("modals.piece.titleCheck")
        }
        isOpen={openModal}
        toggle={setOpenModal}
        className="piece-send-modal"
      >
        <div
          style={{
            width: 420,
            maxWidth: "100%",
          }}
          className="modal-piece | flcolm jcenter liner"
        >
          {!!userData.identityDoc && (
            <span
              style={{
                width: "100%",
                fontSize: 16,
                fontWeight: 500,
              }}
              className="just-t"
            >
              {t("modals.piece.txt.p1")}
            </span>
          )}
          {(currentFile.length > 0 || userData.identityDoc) &&
            (fileExtension == "pdf" ? (
              <a
                href={imgPreview}
                target="_blank"
                className="pdf-file"
                rel="noreferrer"
              >
                {" "}
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {t("modals.piece.txt.file")}
                </span>{" "}
                <span
                  style={{
                    fontSize: 15,
                  }}
                  className="just-t flash-me"
                >
                  {t("modals.piece.txt.p2")}
                </span>
              </a>
            ) : (
              <img src={imgPreview} alt="Pièce PDF" />
            ))}

          {userData.identityDoc ? (
            <>
              <input
                ref={refNewFile}
                type="file"
                style={{ width: 0, height: 0, visibility: "hidden" }}
                onChange={handleChangementFichier}
                accept="image/png, image/jpeg, application/pdf"
              />
              <button
                onClick={() => refNewFile.current.click()}
                style={{
                  marginTop: 20,
                  fontWeight: 600,
                }}
                className="btn pry-btn"
              >
                {t("modals.piece.txt.p3")}
              </button>
            </>
          ) : (
            <>
              {" "}
              <span>{t("modals.piece.txt.confirmTxt")}</span>
              <button onClick={handleSendPiece} className="btn pry-btn">
                {t("modals.piece.action.confirm")}
              </button>
            </>
          )}
        </div>
      </ContainerPersoModal>
      <ContainerPersoModal
        bottomChildren={true}
        baseTop={true}
        title={t("modals.piece.titleCheck")}
        brum={true}
        isOpen={modalReconfirm}
        toggle={setModalReconfirm}
        className="piece-send-modal"
      >
        <div
          style={{
            width: 350,
            maxWidth: "100%",
          }}
          className="modal-piece | flcolm jcenter liner"
        >
          <span
            style={{
              width: "100%",
              fontSize: 16,
              fontWeight: 500,
            }}
            className="just-t"
          >
            {t("modals.piece.txt.p4")}
          </span>
          {currentFile.length > 0 &&
            (currentFile[0].type == "application/pdf" ? (
              <a
                href={URL.createObjectURL(currentFile[0])}
                target="_blank"
                className="pdf-file"
                rel="noreferrer"
              >
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  FICHIER PDF
                </span>{" "}
                <span
                  style={{
                    fontSize: 15,
                  }}
                  className="just-t flash-me"
                >
                  {t("modals.piece.txt.p5")}
                </span>
              </a>
            ) : (
              <img
                src={URL.createObjectURL(currentFile[0])}
                alt="Pièce d'identité"
                className="preview-pièce"
              />
            ))}
          <span>{t("modals.piece.txt.confirmTxt")}</span>
          <button
            style={{
              fontWeight: 600,
            }}
            onClick={() => handleSendPiece(true)}
            className="btn pry-btn"
          >
            {t("modals.piece.action.confirm")}
          </button>
        </div>
      </ContainerPersoModal>
    </div>
  );
}
