import React, { useEffect, useRef, useState } from "react";
import { requestEndpoint } from "../../../data/requestEndpoint";
import { NotificationService } from "../../../services/Notification.service";
import { post, put, remove } from "../../../services/useFetchAction";
import { fetchCountryInfo, findVersementMethod } from "../../../services/utils";
import { useStorePageManager } from "../../../store/PageManage";
import { useStoreUserManager } from "../../../store/UserManage";
import { useStoreAllStaticData } from "../../../store/dataForAll";

function ModifVersement({
  t,
  data,
  userDataVersement,
  setDataVersement,
  setEditPanelOpen,
  typeEdit = true,
  disabled = false,
  fetchDataVersement,
  inReservationPanel = false,
  setTempVersementData,
  tempVersementData,
}) {
  // console.log(inReservationPanel, "inReservationPanel");
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const handleUpdateUser = useStoreUserManager(
    (state) => state.handleUpdateUser
  );
  const handleDelete = async (temporary = false) => {
    if (data.default) {
      NotificationService.addToQueue(
        2,
        t(
          "modals.personalInfo.paiementMethod.modals.notification.deleteDefaultMethod"
        )
      );
      return;
    }
    if (userDataVersement.length === 1 && !temporary) {
      NotificationService.addToQueue(
        2,
        t(
          "modals.personalInfo.paiementMethod.modals.notification.oneMethodError"
        )
      );
      return;
    }
    try {
      handleUpdateBlockScreen(true);
      if (!temporary)
        await remove(requestEndpoint.deleteHoteVersement + data.id);
      if (temporary) {
        let temporaryVersement = JSON.parse(
          sessionStorage.getItem("temporaryVersement")
        );
        if (temporaryVersement === null) {
          temporaryVersement = [];
        }
        temporaryVersement = temporaryVersement.filter(
          (item) => item.id !== data.id
        );
        sessionStorage.setItem(
          "temporaryVersement",
          JSON.stringify(temporaryVersement)
        );
      }
      if (!temporary)
        setDataVersement((prev) => {
          let list = [...prev];
          list = list.filter((item) => item.id !== data.id);
          return list;
        });
      if (temporary)
        setTempVersementData((prev) => {
          let list = [...prev];
          list = list.filter((item) => item.id !== data.id);
          return list;
        });
      NotificationService.addToQueue(
        0,
        t("modals.personalInfo.paiementMethod.modals.notification.deleteDone")
      );
      if (setEditPanelOpen) setEditPanelOpen(false);
      handleUpdateBlockScreen(false);
    } catch (error) {
      handleUpdateBlockScreen(false);
      if (error.response)
        NotificationService.addToQueue(2, error.response.data.message);
    }
  };
  const handleModify = async (newData, temporary = false) => {
    let newList = [];
    try {
      if (!temporary) {
        let sendData = newData;
        delete sendData.id;
        delete sendData.default;
        sendData.hostMethodId = data.id;
        handleUpdateBlockScreen(true);
        const response = await put(
          requestEndpoint.updateHoteVersement,
          sendData
        );
        NotificationService.addToQueue(
          0,
          t("modals.personalInfo.paiementMethod.modals.notification.editDone")
        );
        let list = [...userDataVersement];
        // eslint-disable-next-line array-callback-return
        list.map((item) => {
          if (item.id === data.id) {
            newList.push(response.data);
          } else {
            newList.push(item);
          }
        });
      } else {
        let temporaryVersement = JSON.parse(
          sessionStorage.getItem("temporaryVersement")
        );
        if (temporaryVersement === null) {
          temporaryVersement = [];
        }
        temporaryVersement = temporaryVersement.map((item) => {
          if (item.id === data.id) {
            return { ...newData };
          }
          return item;
        });
        sessionStorage.setItem(
          "temporaryVersement",
          JSON.stringify(temporaryVersement)
        );
        let list = [...tempVersementData];
        newList = [];
        // eslint-disable-next-line array-callback-return
        list.map((item) => {
          if (item.id === data.id) {
            newList.push({ ...newData });
          } else {
            newList.push(item);
          }
        });
        NotificationService.addToQueue(
          0,
          t(
            "modals.personalInfo.paiementMethod.modals.notification.editTemporaryDone"
          )
        );
      }
      if (!temporary) setDataVersement(newList);
      else setTempVersementData(newList);
      if (setEditPanelOpen) setEditPanelOpen(false);
      handleUpdateBlockScreen(false);
    } catch (error) {
      handleUpdateBlockScreen(false);
      if (error.response)
        NotificationService.addToQueue(2, error.response.data.message);
    }
  };
  const handleSetDefault = async (temporary = false) => {
    if (temporary) {
      NotificationService.addToQueue(
        1,
        t(
          "modals.personalInfo.paiementMethod.modals.notification.errorDefaultMethodTemporary"
        )
      );
      return;
    }
    if (data.default) {
      NotificationService.addToQueue(
        1,
        t(
          "modals.personalInfo.paiementMethod.modals.notification.errorAlreadyDefault"
        )
      );
      return;
    }
    try {
      handleUpdateBlockScreen(true);
      await put(requestEndpoint.updateHoteVersement, {
        hostMethodId: data.id,
        default: true,
      });

      handleUpdateUser({ payment_method_id: data.payment_method_id });
      NotificationService.addToQueue(
        0,
        t(
          "modals.personalInfo.paiementMethod.modals.notification.methodEditDefaultDone"
        )
      );
      setDataVersement((prev) => {
        let list = [...prev];
        list.map((item) => {
          if (item.id === data.id) {
            item.default = true;
          } else {
            item.default = false;
          }
        });
        return list;
      });
      if (setEditPanelOpen) setEditPanelOpen(false);
      handleUpdateBlockScreen(false);
    } catch (error) {
      handleUpdateBlockScreen(false);
      if (error.response)
        NotificationService.addToQueue(2, error.response.data.message);
    }
  };
  const handleAdd = async (newData, temporary = false) => {
    let list = [...userDataVersement];
    if (temporary) list = [...tempVersementData];
    let sendData = newData;
    delete sendData.id;
    delete sendData.default;
    try {
      handleUpdateBlockScreen(true);
      if (!temporary) {
        const response = await post(requestEndpoint.addHoteVersement, newData);
        if (list.length === 0) {
          handleUpdateUser({ payment_method_id: newData.payment_method_id });
        }
        NotificationService.addToQueue(
          0,
          t(
            "modals.personalInfo.paiementMethod.modals.notification.methodAddDone"
          )
        );
        list.push(response.data);
      } else {
        sendData.temporary = true;
        sendData.infos.tempVersDate = new Date().toISOString();
        sendData.id = Math.floor(Math.random() * 1000000);
        let temporaryVersement = JSON.parse(
          sessionStorage.getItem("temporaryVersement")
        );
        if (temporaryVersement === null) {
          temporaryVersement = [];
        }
        temporaryVersement.push(sendData);
        sessionStorage.setItem(
          "temporaryVersement",
          JSON.stringify(temporaryVersement)
        );
        list.push({
          ...sendData,
        });
        NotificationService.addToQueue(
          0,
          t(
            "modals.personalInfo.paiementMethod.modals.notification.temporaryMethodAddDone"
          )
        );
      }
      if (!temporary) setDataVersement(list);
      else setTempVersementData(list);
      if (setEditPanelOpen) setEditPanelOpen(false);
      handleUpdateBlockScreen(false);
    } catch (error) {
      handleUpdateBlockScreen(false);
      if (error.response)
        NotificationService.addToQueue(2, error.response.data.message);
    }
  };
  return (
    <div
      style={{
        width: 300,
      }}
      className="modif-versement-modal flcolm"
    >
      {typeEdit && !disabled && (
        <div
          style={{
            marginTop: "8px",
            paddingBottom: "8px",
            borderBottom: "1px solid #E5E5E5",
          }}
          className="action-btns flrow btw maxwidth addgap16"
        >
          <button
            onClick={() => handleSetDefault(data.temporary)}
            style={
              data.default || data.temporary
                ? {
                    opacity: 0.2,
                    cursor: "not-allowed",
                    background: "var(--gray-300)",
                    ...stylesBtn,
                  }
                : stylesBtn
            }
            className="btn opt-btn"
          >
            {t("modals.personalInfo.paiementMethod.modals.button.goDefault")}
          </button>
          <button
            onClick={() => handleDelete(data.temporary)}
            style={stylesBtn}
            className="btn cc-btn"
          >
            {t("modals.personalInfo.paiementMethod.modals.button.delete")}
          </button>
        </div>
      )}

      <div
        style={{
          marginTop: typeEdit ? 20 : 0,
        }}
        className="flcolm addgap8 maxwidth"
      >
        <ModifContactPanel
          t={t}
          data={data}
          handleModify={handleModify}
          handleAdd={handleAdd}
          typeEdit={typeEdit}
          disabled={disabled}
          inReservationPanel={inReservationPanel}
          userDataVersement={userDataVersement}
        />
      </div>
      {!disabled && <div style={{ height: 55 }}></div>}
    </div>
  );
}

const stylesBtn = {
  borderRadius: "8px",
  padding: "6px",
  fontSize: "14px",
};

const ModifContactPanel = ({
  t,
  data,
  handleModify,
  handleAdd,
  typeEdit,
  disabled,
  inReservationPanel,
  userDataVersement,
}) => {
  const versementMethodTypeData =
    useStoreAllStaticData.getState().versementMethod;
  const [fullName, setFullName] = useState({
    defaultValue: typeEdit ? data.infos.fullname?.toUpperCase() ?? "" : "",
    value: typeEdit ? data.infos.fullname?.toUpperCase() ?? "" : "",
  });
  const [versementTypeId, setVersementTypeId] = useState({
    defaultValue: typeEdit
      ? data.payment_method_id ?? versementMethodTypeData[0].id
      : versementMethodTypeData[0].id,
    value: typeEdit
      ? data.payment_method_id ?? versementMethodTypeData[0].id
      : versementMethodTypeData[0].id,
  });
  const [bankname, setBankname] = useState({
    defaultValue: typeEdit ? data.infos.bankname ?? "" : "",
    value: typeEdit ? data.infos.bankname ?? "" : "",
  });
  const [contact, setContact] = useState({
    defaultValue: typeEdit
      ? data.infos.contact?.replace(/[^0-9]/g, "") ?? ""
      : "",
    value: typeEdit ? data.infos.contact?.replace(/[^0-9]/g, "") ?? "" : "",
  });
  const [rib, setRib] = useState({
    defaultValue: typeEdit ? data.infos.rib ?? "" : "",
    value: typeEdit ? data.infos.rib ?? "" : "",
  });
  const [indicatif, setIndicatif] = useState({
    defaultValue: typeEdit ? data.infos.indicatif?.replace("+", "") ?? "" : "",
    value: typeEdit ? data.infos.indicatif?.replace("+", "") ?? "" : "",
  });

  const [isEdit, setIsEdit] = useState(false);

  const handleCheckIfDataChanged = () => {
    if (typeEdit === false) {
      if (fullName.value.trim().length < 3) {
        return false;
      }
      if (
        versementTypeId.value === 7 ||
        findVersementMethod(versementTypeId.value)
          ?.label?.toString()
          .toLowerCase() === "Compte Bancaire".toLowerCase()
      ) {
        if (bankname.value.trim().length < 3) {
          return false;
        }
        if (rib.value.length < 14) {
          return false;
        }
      } else {
        if (contact.value.length < 8) {
          return false;
        }
        if (indicatif.value.length < 1) {
          return false;
        }
      }
      return true;
    } else {
      if (
        fullName.value !== fullName.defaultValue ||
        versementTypeId.value !== versementTypeId.defaultValue ||
        bankname.value !== bankname.defaultValue ||
        contact.value !== contact.defaultValue ||
        rib.value !== rib.defaultValue ||
        indicatif.value !== indicatif.defaultValue
      ) {
        return true;
      }
      return false;
    }
  };
  useEffect(() => {
    setIsEdit(handleCheckIfDataChanged());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullName, versementTypeId, contact, bankname, rib, indicatif]);

  const [countries, setCountries] = useState([]);
  const [currentFilterCountries, setCurrentFilterCountries] = useState([]);
  const [fetchFinish, setFetchFinish] = useState(false);
  const [versementTemporaires, setVersementTemporaires] = useState(
    data?.temporary ?? false
  );
  const getCountriesInfo = async () => {
    try {
      fetchCountryInfo()
        .then((res) => {
          if (res.error) return;
          setCountries(res.countries);
          setCurrentFilterCountries(res.countries);
          setFetchFinish(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCountriesInfo();
  }, []);

  const handleSave = () => {
    if (!handleCheckIfDataChanged()) return;
    let newData = {
      id: data ? data?.id : 0,
      payment_method_id: versementTypeId.value,
      infos:
        versementTypeId.value === 7 ||
        findVersementMethod(versementTypeId.value)
          .label.toString()
          .toLowerCase() === "Compte Bancaire".toLowerCase()
          ? {
              fullname: fullName.value.trim(),
              bankname: bankname.value.trim(),
              rib: rib.value,
            }
          : {
              fullname: fullName.value.trim(),
              contact: contact.value,
              indicatif: indicatif.value,
            },
      default: data ? data.default ?? false : false,
    };
    // console.log(newData, "newData");
    if (typeEdit) handleModify(newData, versementTemporaires);
    else handleAdd(newData, versementTemporaires);
  };

  const dataTempoDisabled = data?.temporary === true && !disabled;
  const dataTempo = data?.temporary ?? false;
  const [canShowBtn, setCanShowBtn] = useState(false);
  useEffect(() => {
    if (!(inReservationPanel || dataTempoDisabled)) return;
    if (
      !(
        (typeEdit && data.temporary) ||
        (!typeEdit && !data && inReservationPanel)
      )
    )
      return;
    setCanShowBtn(true);
  }, [versementTemporaires]);
  return (
    <>
      <InputDiv
        t={t}
        label={t("modals.personalInfo.paiementMethod.modals.champs.fullname")}
        value={fullName}
        setValue={setFullName}
        disabled={disabled}
      />
      <VersementMethodSelect
        t={t}
        value={versementTypeId.value}
        setValue={setVersementTypeId}
        versementMethodTypeData={versementMethodTypeData}
        disabled={disabled}
      />
      {versementTypeId.value === 7 ||
      findVersementMethod(versementTypeId.value)
        ?.label?.toString()
        .toLowerCase() === "Compte Bancaire".toLowerCase()
        ? versementTypeId != null && (
            <ModifRibPanel
              t={t}
              data={data}
              bankname={bankname}
              setBankname={setBankname}
              rib={rib}
              setRib={setRib}
              disabled={disabled}
            />
          )
        : versementTypeId != null && (
            <TelephoneInput
              t={t}
              countries={countries}
              currentFilterCountries={currentFilterCountries}
              setCurrentFilterCountries={setCurrentFilterCountries}
              fetchFinish={fetchFinish}
              contact={contact}
              setContact={setContact}
              indicatif={indicatif}
              setIndicatif={setIndicatif}
              disabled={disabled}
            />
          )}
      {canShowBtn && (
        <>
          <button
            onClick={() => {
              if (userDataVersement.length === 0) {
                NotificationService.addToQueue(
                  1,
                  t(
                    "modals.personalInfo.paiementMethod.modals.notification.blockTemporaryAddError"
                  )
                );
                return;
              }
              dataTempo === false
                ? setVersementTemporaires(!versementTemporaires)
                : NotificationService.addToQueue(
                    1,
                    t(
                      "modals.personalInfo.paiementMethod.modals.notification.cannotModifyTemporaryStatus"
                    )
                  );
            }}
            className={`btn flrow temporary-versement-btn addgap8 ${
              versementTemporaires ? "active" : ""
            }`}
          >
            <span
              className={`case ${versementTemporaires ? "active" : ""}`}
            ></span>
            <span>
              {t("modals.personalInfo.paiementMethod.modals.txts.temporary")}
            </span>
          </button>
          {dataTempo === false && (
            <span
              style={{
                textAlign: "center",
                transition: "0.15s",
                color: versementTemporaires ? "#000" : "#fff",
                height: versementTemporaires ? 40 : 0,
                userSelect: "none",
                overflow: "hidden",
                fontSize: "12px",
                fontWeight: "500",
              }}
              className="flash-me"
            >
              {t("modals.personalInfo.paiementMethod.modals.txts.temporary")}
            </span>
          )}
        </>
      )}

      {!disabled && (
        <ModifButton
          t={t}
          isEdit={isEdit}
          handleSave={handleSave}
          typeEdit={typeEdit}
        />
      )}
    </>
  );
};
const ModifRibPanel = ({
  t,
  data,
  bankname,
  setBankname,
  rib,
  setRib,
  disabled,
}) => {
  return (
    <>
      <InputDiv
        t={t}
        label={t("modals.personalInfo.paiementMethod.modals.txts.bankName")}
        value={bankname}
        setValue={setBankname}
        disabled={disabled}
      />
      <InputDiv
        t={t}
        label={t("modals.personalInfo.paiementMethod.modals.txts.rib")}
        value={rib}
        setValue={setRib}
        isRib={true}
        disabled={disabled}
      />
    </>
  );
};
const InputDiv = ({ t, label, value, setValue, isRib, disabled }) => {
  const inputRef = useRef(null);
  const handleChangeInput = (e) => {
    let newValue = e.target.value;
    if (disabled) {
      newValue = value.value;
    }

    if (isRib) {
      newValue = newValue.replace(/[^0-9]/g, "");
      if (newValue.length > 5) {
        newValue = newValue.slice(0, 5) + "-" + newValue.slice(5);
      }
    }
    newValue = newValue.toUpperCase();
    e.target.value = newValue;
    if (isRib) setValue({ ...value, value: newValue.replace(/[^0-9]/g, "") });
    else setValue({ ...value, value: newValue });
  };
  useEffect(() => {
    if (inputRef.current) {
      if (isRib) {
        let newValue = value.value;
        newValue = newValue.replace(/[^0-9]/g, "");
        if (newValue.length > 5) {
          newValue = newValue.slice(0, 5) + "-" + newValue.slice(5);
        }
        if (newValue.length > 14) {
          newValue =
            newValue.slice(0, newValue.length - 2) +
            "-" +
            newValue.slice(newValue.length - 2);
        }
        inputRef.current.value = newValue;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);
  const handleOnBlur = (e) => {
    //si le rib est supérieur à 14 on ajoute un tiret avant les 2 derniers chiffres
    if (isRib) {
      let newValue = e.target.value;
      newValue = newValue.replace(/[^0-9]/g, "");
      if (newValue.length > 5) {
        newValue = newValue.slice(0, 5) + "-" + newValue.slice(5);
      }
      if (newValue.length > 14) {
        newValue =
          newValue.slice(0, newValue.length - 2) +
          "-" +
          newValue.slice(newValue.length - 2);
      }

      e.target.value = newValue;
      setValue({ ...value, value: newValue.replace(/[^0-9]/g, "") });
    }
  };
  return (
    <div className="flcolm addgap2 input-div-versement">
      <span>{label}</span>
      <input
        disabled={disabled}
        autoComplete="off"
        autoCorrect="off"
        ref={inputRef}
        defaultValue={value.value}
        onChange={handleChangeInput}
        onBlur={handleOnBlur}
        type="text"
        className="input-field-versement input"
      />
    </div>
  );
};

const VersementMethodSelect = ({
  t,
  value,
  setValue,
  versementMethodTypeData,
  disabled,
}) => {
  const selectRef = useRef(null);
  const btnOpenRef = useRef(null);
  const [currentValue, setCurrentValue] = useState(null);
  useEffect(() => {
    const versement = findVersementMethod(value ?? 1);
    setCurrentValue(versement);
  }, []);
  const [openSelect, setOpenSelect] = useState(false);
  const handleSelectValue = (value) => {
    if (disabled) {
      setCurrentValue(findVersementMethod(value.value ?? 1));
      return;
    }
    setOpenSelect(false);
    setCurrentValue(value);
    setValue((prev) => ({ ...prev, value: parseInt(value.id) }));
  };
  const handleClickOutside = (event) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target) &&
      btnOpenRef.current &&
      !btnOpenRef.current.contains(event.target)
    ) {
      setOpenSelect(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="flcolm addgap2 input-div-versement rlve">
      {openSelect && (
        <div ref={selectRef} className="select-option-versement flcolm">
          {versementMethodTypeData.map((item) => (
            <button
              onClick={() => handleSelectValue(item)}
              key={item.id + "opt-ver"}
              className="btn opt-option-vers"
            >
              <img
                src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PAIEMENT}${item.icon}`}
                alt="paiement-method"
              />
              <span
                style={{
                  fontSize: 13,
                  fontWeight: 600,
                }}
              >
                {item.label}
              </span>
            </button>
          ))}
        </div>
      )}
      <span>
        {t("modals.personalInfo.paiementMethod.modals.txts.versementMethod")}
      </span>
      <div className="maxwidth rlve">
        <button
          ref={btnOpenRef}
          disabled={disabled}
          style={
            disabled
              ? {
                  cursor: "not-allowed",
                  color: "var(--gray-500)",
                  background: "none",
                  backgroundImage: "none",
                }
              : {}
          }
          onClick={() => setOpenSelect(!openSelect)}
          onChange={(e) => {}}
          className="btn input btn-open-versement"
        >
          <img
            src={`${process.env.REACT_APP_API_URL}${
              process.env.REACT_APP_LINK_PAIEMENT
            }${
              currentValue
                ? currentValue.icon
                : findVersementMethod(value ?? 1)?.icon
            }`}
            alt="paiement-method"
          />
          <span>
            {currentValue
              ? currentValue.label
              : findVersementMethod(value ?? 1)?.label}
          </span>
        </button>
      </div>
    </div>
  );
};

const TelephoneInput = ({
  t,
  countries,
  currentFilterCountries,
  setCurrentFilterCountries,
  fetchFinish,
  contact,
  setContact,
  indicatif,
  setIndicatif,
  disabled,
}) => {
  const indicatifRef = useRef(null);
  const contactRef = useRef(null);
  const selectIndicatifRef = useRef(null);
  useEffect(() => {
    if (contactRef.current) {
      let newValue = contact.value;
      newValue = newValue.replace(/[^0-9]/g, "");
      if (newValue.length > 2) {
        newValue = newValue.slice(0, 2) + " " + newValue.slice(2);
      }
      if (newValue.length > 6) {
        newValue = newValue.slice(0, 6) + " " + newValue.slice(6);
      }
      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10) + " " + newValue.slice(10);
      }
      contactRef.current.value = newValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRef]);

  useEffect(() => {
    if (indicatifRef.current) {
      let newValue = indicatif.value;
      newValue = newValue.replace(/[^0-9]/g, "");
      indicatifRef.current.value = newValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatifRef]);

  const handleChangeContact = (e) => {
    let newValue = e.target.value;
    if (disabled) {
      newValue = contact.value;
    }
    //on retire tout les caractères non numériques
    newValue = newValue.replace(/[^0-9]/g, "");
    //on fait un algo pour ajouter des espaces comme celui des iphones ex : 07 079 093 19XXX
    if (newValue.length > 2) {
      newValue = newValue.slice(0, 2) + " " + newValue.slice(2);
    }
    if (newValue.length > 6) {
      newValue = newValue.slice(0, 6) + " " + newValue.slice(6);
    }
    if (newValue.length > 10) {
      newValue = newValue.slice(0, 10) + " " + newValue.slice(10);
    }
    e.target.value = newValue;
    newValue = newValue.replace(/[^0-9]/g, "");
    setContact({ ...contact, value: newValue });
  };
  const handleFilterIndicatif = (indicatif) => {
    let value = indicatif;
    let currentFilterCountries = [];
    if (value.length > 0) {
      currentFilterCountries = countries.filter((country) => {
        return country.dial_code.toLowerCase().includes(value.toLowerCase());
      });
    } else {
      currentFilterCountries = countries;
    }
    setCurrentFilterCountries(currentFilterCountries);
  };
  const handleIndicatifChange = (e) => {
    let newValue = e.target.value;
    if (disabled) {
      newValue = indicatif.value;
    }
    newValue = newValue.replace(/[^0-9]/g, "");
    e.target.value = newValue;
    handleFilterIndicatif(newValue);
    setIndicatif({ ...indicatif, value: newValue });
  };
  const handleClickOutside = (event) => {
    if (
      selectIndicatifRef.current &&
      !selectIndicatifRef.current.contains(event.target)
    ) {
      setIndicatifFocus(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [indicatifFocus, setIndicatifFocus] = useState(false);
  return (
    <div className="flcolm addgap2 input-div-versement">
      <span>
        {t("modals.personalInfo.paiementMethod.modals.champs.telephone")}
      </span>
      <div className="flrow addgap8 input-field-versement">
        <div
          ref={selectIndicatifRef}
          className={`flrow addgap8 liner input-field-indicatif ${
            indicatif.value.length > 0 ? "contain" : ""
          } rlve`}
        >
          {indicatifFocus &&
          fetchFinish &&
          !disabled &&
          currentFilterCountries.length > 0 ? (
            <div className="flcolm filter-indicatif addgap8">
              {currentFilterCountries.map((country, index) => (
                <button
                  key={index + country.name}
                  onClick={() => {
                    setIndicatif({
                      ...indicatif,
                      value: country.dial_code.replace("+", ""),
                    });
                    indicatifRef.current.value = country.dial_code.replace(
                      "+",
                      ""
                    );
                    setIndicatifFocus(false);
                  }}
                  className="flrow liner btn indicatif-btn addgap8"
                >
                  <img src={country.flag} alt="flag" />
                  <span className="dial-code-countrie">
                    {country.dial_code}
                  </span>
                  <span className="countrie-name">{country.name}</span>
                </button>
              ))}
            </div>
          ) : (
            <></>
          )}
          <input
            //adapted la taille de l'input en fonction du nombre de caractères
            style={{
              transition: "width 0.15s",
              paddingLeft: "20px",
              width:
                indicatif.value.length === 0
                  ? "65px"
                  : indicatif.value.length === 1
                  ? "43px"
                  : indicatif.value.length === 2
                  ? "52px"
                  : indicatif.value.length === 3
                  ? "64px"
                  : "74px",
            }}
            disabled={disabled}
            ref={indicatifRef}
            defaultValue={indicatif.defaultValue}
            placeholder="000"
            maxLength={4}
            onFocus={() => {
              setIndicatifFocus(true);
              handleFilterIndicatif(indicatif.value);
            }}
            onChange={handleIndicatifChange}
            type="tel"
            className="input indicatif-inp"
          />
        </div>
        <input
          disabled={disabled}
          ref={contactRef}
          defaultValue={contact.defaultValue}
          onChange={handleChangeContact}
          type="text"
          className="input"
          placeholder={t(
            "modals.personalInfo.paiementMethod.modals.champs.tel"
          )}
        />
      </div>
    </div>
  );
};

const ModifButton = ({ t, isEdit, handleSave, typeEdit }) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "16px",
        width: "100%",
        left: "0",
        backgroundColor: "var(--shade-white)",
      }}
    >
      <button
        disabled={!isEdit}
        onClick={handleSave}
        style={{
          width: "calc(100% - 48px)",
          left: "0",
          borderRadius: "8px",
          margin: "0 auto",
          backgroundColor: typeEdit ? "var(--gray-200)" : "var(--primary-500)",
        }}
        className="btn"
      >
        <span
          style={
            isEdit
              ? {
                  color: typeEdit ? "var(--shade-black)" : "var(--shade-white)",
                  fontSize: "14px",
                  fontWeight: "600",
                }
              : {
                  color: "var(--shade-white)",
                  fontSize: "14px",
                  fontWeight: "600",
                }
          }
        >
          {typeEdit
            ? t("modals.personalInfo.paiementMethod.modals.button.save")
            : t("modals.personalInfo.paiementMethod.modals.button.add")}
        </span>
      </button>
    </div>
  );
};

export default ModifVersement;
