import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from "../../globalTraduction";
import { Check, Home, Star } from "../../images/icons/JsxIcons";
import "../../styles/components/selectResidenceModal.css";
export default function SelectResidenceModal({
  className = "",
  residenceList,
  handleUpdateCurrentResidenceSelected,
  currentResidenceSelected,
  showSelectAll = true,
  justShow = false,
  closeModal = null,
  handleUpdateForModifMenu,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = i18n.language;
  const handleSelectResidence = (residence) => {
    if (handleUpdateForModifMenu) {
      // handleUpdateForModifMenu(residence.id);
      // closeModal();
      handleUpdateForModifMenu(residence);
      closeModal();

      return;
    }
    handleUpdateCurrentResidenceSelected(residence);
    closeModal();
  };
  const handleSelectAllResidence = () => {
    handleUpdateCurrentResidenceSelected(null);
    closeModal();
  };

  return (
    <div className={`modal-select-resi ${className} | flcolm addgap24`}>
      {showSelectAll && (
        <button
          onClick={handleSelectAllResidence}
          className="select-all btn-select-resi | btn"
        >
          <div className="left-side | flrow liner">
            <span>
              <Home color="white" />
            </span>
            <label>{t("addResidence.step11.allResidencesTxt")}</label>
          </div>
          {currentResidenceSelected == null && (
            <Check color="var(--shade-black)" />
          )}
        </button>
      )}
      {residenceList.map((residence) => {
        let stars = residence?.stars ?? residence?.residence?.stars ?? null;
        if (stars != null) {
          stars = parseFloat(stars);
          const decimalPlaces = (stars.toString().split(".")[1] || "").length;
          if (decimalPlaces > 2) {
            stars = parseFloat(
              stars.toFixed(2).replace(".", language == "fr" ? "," : ".")
            );
          } else if (decimalPlaces === 1) {
            stars = parseFloat(
              stars.toFixed(1).replace(".", language == "fr" ? "," : ".")
            );
          } else {
            stars = parseFloat(stars.toFixed(0));
          }
        }
        let residenceName =
          residence?.name ?? residence?.residence?.name ?? null;
        if (residenceName != null) {
          residenceName =
            residenceName.charAt(0).toUpperCase() +
            residenceName.slice(1).toLowerCase();
        }
        // console.log(residence);
        return (
          <button
            onClick={() => !justShow && handleSelectResidence(residence)}
            key={residence.id + "-residence-select"}
            className="select-all btn-select-resi | btn"
          >
            <div className="left-side | flrow liner">
              <img
                src={
                  residence?.medias
                    ? `${process.env.REACT_APP_API_URL}${
                        process.env.REACT_APP_LINK_RESIDENCE_MEDIA
                      }${residence?.medias[0]?.filename ?? "0.jpg"}`
                    : residence.thumb
                }
                alt="resi-img"
              />
              <div className="flcolm">
                <label>{residenceName}</label>
                <div className="location-stars | flrow liner addgap8">
                  <div className="stars | flrow liner">
                    <Star color="var(--shade-black)" />
                    <span>{stars ?? "N/A"}</span>
                  </div>
                  <div className="verti-sepa"></div>
                  <span>
                    {residence.address ?? residence?.residence?.address}
                  </span>
                </div>
              </div>
            </div>
            {currentResidenceSelected == residence && (
              <Check color="var(--shade-black)" />
            )}
          </button>
        );
      })}
    </div>
  );
}
