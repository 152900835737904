const cancellationTranslation = {
  fr: {
    about: "A PROPROS",
    about2: "A PROPROS",
    about3: "A PROPOS",
    accecibility: "ACCESSIBILITE",
    hourRules: "HEURE D'ARRIVEE / DEPART",
    hourRules2: "HEURE D’ARRIVEE / DEPART",
  },
  en: {
    about: "ABOUT",
    about2: "ABOUT",
    about3: "ABOUT",
    accecibility: "ACCESSIBILITY",
    hourRules: "ARRIVAL / DEPARTURE TIME",
    hourRules2: "ARRIVAL / DEPARTURE TIME",
  },
};

export const handleReturnGoodDescriptionTranslation = (
  language,
  frCurrentLabel
) => {
  //chercher en fr si la valeur est la même que frCurrentLabel
  let searchKey = "";
  Object.keys(cancellationTranslation.fr).forEach((key) => {
    if (
      cancellationTranslation.fr[key]
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase() ===
      frCurrentLabel
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    ) {
      searchKey = key;
    }
  });
  Object.keys(cancellationTranslation.en).forEach((key) => {
    if (
      cancellationTranslation.en[key]
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase() ===
      frCurrentLabel
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    ) {
      searchKey = key;
    }
  });
  return cancellationTranslation[language][searchKey];
};
