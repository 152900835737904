import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
import "../../styles/components/addResidenceNextFooter.css";

export default function AddResidenceNextFooter({
  step = 15,
  handleGoBackStep,
  handleGoNextStep,
  handleGoOpenForAdd,
  blockStatus = false,
  handleGoToHostMode,
}) {
  const { t } = useTranslation();
  const checkForGoNext = () => {
    if (blockStatus == false) handleGoNextStep();
  };

  useEffect(() => {
    useStoreShareFunction
      .getState()
      .handleUpdateNextPage(() => handleGoNextStep());
    useStoreShareFunction
      .getState()
      .handleUpdateGoOpenForAdd(() => handleGoOpenForAdd(true));
  }, []);
  return (
    <div className="add-residence-next-footer">
      <div className="contains-next-footer | max-container flrow liner btw">
        <div className="left-part-add-resi espace"></div>
        <div className="right-part-add-resi btns-part | flcolm">
          <div className="bars-load | flrow">
            <div className="bar | rlve">
              <div
                style={{
                  width: Math.min(Math.max((step * 100) / 5, 0), 100) + "%",
                }}
                className="contain"
              ></div>
            </div>
            <div className="bar | rlve">
              <div
                style={{
                  width:
                    Math.min(Math.max(((step - 6) * 100) / 5, 0), 100) + "%",
                }}
                className="contain"
              ></div>
            </div>
            <div className="bar | rlve">
              <div
                style={{
                  width:
                    Math.min(Math.max(((step - 11) * 100) / 6, 0), 100) + "%",
                }}
                className="contain"
              ></div>
            </div>
          </div>
          <div className="contains-btns | flrow liner btw maxwidth">
            {step == 0 ? (
              <>
                <div style={{ flex: 1 }}></div>
                <button
                  onClick={() => handleGoNextStep()}
                  className="next-btn block-right | btn pry-btn"
                >
                  <span>
                    {t("addResidence.addResidenceFooter.letsGoTxtAdd")}
                  </span>
                </button>
              </>
            ) : (
              <>
                {step == 17 ? (
                  <>
                    <button
                      onClick={() => handleGoBackStep()}
                      className="back-btn | btn"
                    >
                      <span>
                        {t("addResidence.addResidenceFooter.retourTxtAdd")}
                      </span>
                    </button>
                    <button
                      onClick={() => handleGoOpenForAdd(true)}
                      disabled={blockStatus}
                      className="next-btn | btn pry-btn"
                    >
                      <span>
                        {t("addResidence.addResidenceFooter.finishAddTxt")}
                      </span>
                    </button>
                  </>
                ) : (
                  <>
                    {step == 18 ? (
                      <>
                        <button
                          style={{ marginLeft: "auto" }}
                          onClick={handleGoToHostMode}
                          className="next-btn | btn pry-btn"
                        >
                          {t(
                            "addResidence.addResidenceFooter.continueToHostMode"
                          )}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => handleGoBackStep()}
                          className="back-btn | btn"
                        >
                          <span>
                            {t("addResidence.addResidenceFooter.retourTxtAdd")}
                          </span>
                        </button>
                        <button
                          onClick={checkForGoNext}
                          disabled={blockStatus}
                          className="next-btn | btn pry-btn"
                        >
                          {step == 5 || step == 11 ? (
                            <span>
                              {t(
                                "addResidence.addResidenceFooter.continuerTxtAdd"
                              )}
                            </span>
                          ) : step == 0 || step == 6 || step == 12 ? (
                            <span>
                              {t(
                                "addResidence.addResidenceFooter.letsGoTxtAdd"
                              )}
                            </span>
                          ) : step == 17 ? (
                            <span>
                              {t(
                                "addResidence.addResidenceFooter.finishAddTxt"
                              )}
                            </span>
                          ) : step == 18 ? (
                            <span>
                              {t("addResidence.addResidenceFooter.perfectAdd")}
                            </span>
                          ) : (
                            <span>
                              {t("addResidence.addResidenceFooter.nextTxtAdd")}
                            </span>
                          )}
                        </button>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
