import React from "react";
import { useTranslation } from "react-i18next";
import { handleReturnGoodDescriptionTranslation } from "../../description";
import "../../styles/components/descriptionModal.css";
export default function DescriptionModal({ handleOutModal, data, language }) {
  const { t } = useTranslation();
  const description =
    language == "fr"
      ? data.description
      : data.descriptionEn || data.description;
  return (
    <div className="description-modal | flcolm">
      <div className="title-modal flcolm addgap8">
        <label>{t("residencePage.description.modals.title")}</label>
      </div>
      <div style={{ rowGap: 6 }} className="contents | flcolm">
        {description?.map((item, index) => {
          const capitalizedTitle =
            handleReturnGoodDescriptionTranslation(language, item.title) ||
            item.title;
          const capitalizedText =
            item.text.charAt(0).toUpperCase() + item.text.slice(1);
          return (
            <div className="maxwidth flcolm" key={index}>
              <h6>
                {capitalizedTitle.charAt(0).toUpperCase() +
                  capitalizedTitle.slice(1)}
              </h6>
              <p>{capitalizedText}</p>
            </div>
          );
        })}
        {/* <p>{language == "fr-FR" ? data.description : data.descriptionEn}</p> */}
      </div>
    </div>
  );
}
