import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import illusStep1 from "../../images/illustrations/illus-step1.png";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
import "../../styles/components/addResidencePart/step1Annonce.css";
const currentStep = 0;
export default function StepOneAnnonce({ step, handleBlockBtn }) {
  const { t } = useTranslation();
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(false);
  }, [step]);

  //detecter si on appuie sur la touche entrer pour passer à l'étape suivante sauf a l'étape 11 && la step est la currentstep actuel
  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [step]);
  return (
    <section
      className={`step-1-annonce stp1 ${currentStep == step ? "visible" : ""} 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <span className="step">{t("addResidence.step1.title")}</span>
            <label>{t("addResidence.step1.label")}</label>
            <p>{t("addResidence.step1.description")}</p>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <img src={illusStep1} alt="illustration" />
        </div>
      </div>
    </section>
  );
}
