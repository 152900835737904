import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MobileCalendarIcon,
  MobileReservationIcon,
  MobileResiIcon,
  MobileStatsIcon,
} from "../../images/icons/JsxIcons";
import "../../styles/components/headerHotePage.css";
export default function HeaderHotePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentMenuCheck = parseInt(searchParams.get("menu")) || 1;
  const [currentMenu, setCurrentMenu] = useState(currentMenuCheck);
  useEffect(() => {
    const checkCurrentMenu = [1, 2, 3, 4].includes(currentMenuCheck);
    navigate(`/hote?menu=${checkCurrentMenu ? currentMenu : 1}`, {
      replace: true,
      state: { menu: currentMenu },
    });
  }, []);

  const handleUpdateCurrentMenu = (menu) => {
    setCurrentMenu(menu);
    navigate(`/hote?menu=${menu}`, { state: { menu } });
  };
  return (
    <div className="header-hote-select flrow liner">
      <Link
        className={`btn-hote-select ${
          currentMenuCheck == 1 ? "active" : ""
        } | btn`}
        to="/hote?menu=1"
        // onClick={() => handleUpdateCurrentMenu(1)}
      >
        <MobileResiIcon
          stroke={
            currentMenuCheck == 1 ? "var(--shade-white)" : "var(--shade-black)"
          }
        />
        <span>{t("header.hote.mesResiMenuTxt")}</span>
      </Link>
      <Link
        className={`btn-hote-select ${
          currentMenuCheck == 2 ? "active" : ""
        } | btn`}
        // onClick={() => handleUpdateCurrentMenu(2)}
        to="/hote?menu=2"
      >
        <MobileCalendarIcon
          stroke={
            currentMenuCheck == 2 ? "var(--shade-white)" : "var(--shade-black)"
          }
        />
        <span>{t("header.hote.calendarMenuTxt")}</span>
      </Link>
      <Link
        className={`btn-hote-select ${
          currentMenuCheck == 3 ? "active" : ""
        } | btn`}
        // onClick={() => handleUpdateCurrentMenu(3)}
        to="/hote?menu=3"
      >
        <MobileReservationIcon
          stroke={
            currentMenuCheck == 3 ? "var(--shade-white)" : "var(--shade-black)"
          }
        />
        <span>{t("header.hote.reservationMenuTxt")}</span>
      </Link>
      <Link
        className={`btn-hote-select ${
          currentMenuCheck == 4 ? "active" : ""
        } | btn`}
        // onClick={() => handleUpdateCurrentMenu(4)}
        to="/hote?menu=4"
      >
        <MobileStatsIcon
          stroke={
            currentMenuCheck == 4 ? "var(--shade-white)" : "var(--shade-black)"
          }
        />
        <span>{t("header.hote.statistiquesMenuTxt")}</span>
      </Link>
    </div>
  );
}
