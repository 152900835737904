import moment from "moment";
import React, { useEffect, useState } from "react";
import i18n from "../../globalTraduction";
import chevronLeft from "../../images/icons/chevron-left.svg";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import {
  checkDateJust,
  getJustDateFormat,
  handlePreviewPrice,
} from "../../services/utils";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import { useStoreAllStaticData } from "../../store/dataForAll";
import { UserPreferences } from "../../store/UserPreferences";
import Tooltip from "../molecules/Tooltip";
import TooltipRecap from "./TooltipReservation";

export default function RecapReservation({
  t,
  data,
  setOpenQuitModal,
  reservationCard,
}) {
  const sign = useStoreCurrencies((state) => state.sign);
  const showDevise = UserPreferences((state) => state.showDevise);
  const language = i18n.language;
  const [priceCalculResult, setPriceCalculResult] = useState(<></>);
  const [totalPriceCalcul, setTotalPriceCalcul] = useState(0);
  const [totalPrice, setTotalPrice] = useState(<></>);
  const [fraisPrice, setFraisPrice] = useState(0);

  const [totalPricing, setTotalPricing] = useState({
    normalPrice: 0,
    discountPrice: 0,
    discountAmount: 0,
    discountPercent: 0,
    subtotal: 0,
    fee: 0,
    total: 0,
  });

  const [reductionApply, setReductionApply] = useState(false);
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [loadingPreview, setLoadingPreview] = useState(false);
  useEffect(() => {
    let totalPrice = 0;
    let priceBase = 0;
    let daysCount = 0;
    let fraisPricing = 0;

    const date1 = moment(data.startDate).startOf("day");
    const date2 = moment(data.endDate).endOf("day");
    daysCount = date2.diff(date1, "days");
    let { subTotalPriceN = 0, fraisPriceN = 0, totalPriceResidenceN = 0 } = {};
    const reservationPreviewPrice =
      useStoreAllStaticData.getState().reservationPreviewPrice;
    if (checkDateJust(data.startDate) && checkDateJust(data.endDate)) {
      setLoadingPreview(true);
      handlePreviewPrice(
        data.startDate,
        data.endDate,
        data.nbrPersonne,
        data.residenceId,
        false,
        reservationPreviewPrice
      ).then((data) => {
        subTotalPriceN = data.subTotalPrice;
        fraisPriceN = data.fraisPrice;
        totalPriceResidenceN = data.totalPriceResidence;
        setSubtotalPrice(data.normalSubtotal);
        setFraisPrice(data.normalFee);
        setTotalPrice(
          <p className="info">{`${handleFormatToLocalString(
            data.state === "UP" ? data.subtotal : data.normalSubtotal,
            language,
            sign
          )} ${showDevise}`}</p>
        );
        setTotalPricing(data);
        // setDaySave(daysDifference);
        setPriceCalculResult(
          <p className="info">
            {t("reservationPaiement.modals.recap.subtotal")}
          </p>
        );
        if (data?.discountAmount > 0) {
          setReductionApply(true);
        }
        setLoadingPreview(false);
      });
      return;
    }
    setReductionApply(false);
    if (daysCount <= 0) daysCount = 1;
    priceBase = data.residencePrice || data.residence.price;
    priceBase = data.total ? Math.round(data.subtotal / daysCount) : priceBase;
    totalPrice = priceBase * daysCount;

    setPriceCalculResult(
      <p className="info">{`${handleFormatToLocalString(
        priceBase ?? 0,
        language,
        sign
      )} ${showDevise} x ${daysCount} ${
        daysCount > 1
          ? t("reservationPaiement.modals.recap.nightPlural")
          : t("reservationPaiement.modals.recap.night")
      }`}</p>
    );
    let totalPriceWithNight = 0;
    let totalPriceResidence = 0;

    totalPriceResidence = Math.round(daysCount * priceBase);
    totalPriceWithNight = totalPriceResidence;
    fraisPricing = Math.round((totalPriceResidence * 7) / 100);
    if (fraisPricing.toString().includes(".")) {
      fraisPricing = fraisPricing.toFixed(2);
    }
    totalPriceResidence = totalPriceResidence + fraisPricing;
    if (totalPriceResidence.toString().includes(".")) {
      totalPriceResidence = totalPriceResidence.toFixed(2);
    }
    setFraisPrice(fraisPricing);
    setTotalPricing(totalPriceResidence);
    setTotalPriceCalcul(totalPrice);
    setTotalPrice(
      <p className="info">{`${handleFormatToLocalString(
        totalPriceWithNight,
        language,
        sign
      )} ${showDevise}`}</p>
    );
  }, []);

  return (
    <div className="recap | flcolm">
      <div className="title flrow addgap16">
        <button
          onClick={() => setOpenQuitModal(true)}
          className="btn-close | btn"
        >
          <img src={chevronLeft} alt="close-btn" />
        </button>
        {reservationCard ? (
          <label>{t("reservationPaiement.modals.recap.goToPayment")}</label>
        ) : (
          <label>
            {t("reservationPaiement.modals.recap.askReservationTitleTxt")}
          </label>
        )}
      </div>

      <label>
        {t("reservationPaiement.modals.recap.reservationDetailsTxt")}
      </label>
      <div className="date-one | flcolm addgap8">
        <label>{t("reservationPaiement.modals.recap.date")}</label>
        <span>
          {getJustDateFormat(data.startDate, "D MMMM YYYY")} -{" "}
          {getJustDateFormat(data.endDate, "D MMMM YYYY")}
        </span>
      </div>
      <div className="date-one | flcolm addgap8">
        <label>{t("reservationPaiement.modals.recap.personNumber")}</label>
        <span>
          {data.nbrPersonne}{" "}
          {data.nbrPersonne > 1
            ? t("reservationPaiement.modals.recap.personPlural")
            : t("reservationPaiement.modals.recap.person")}
        </span>
      </div>

      <div className="recap-paiement flcolm addgap24">
        <div className="price-detail | flcolm addgap8">
          <label>{t("reservationPaiement.modals.recap.detailsPricetxt")}</label>
          <div className="price-recap | flrow btw liner">
            {priceCalculResult}
            <div
              style={{
                columnGap: 6,
                display: "flex",
              }}
              className="flrow"
            >
              {totalPrice}
              <TooltipRecap
                data={totalPricing.recap}
                state={totalPricing.state}
              />
            </div>
          </div>
        </div>
        <div className="price-frais | flcolm addgap8">
          <label>{t("reservationPaiement.modals.recap.fraisTxt")}</label>
          <div className="price-recap | flrow btw liner">
            <p className="info hv-tooltip">
              {`${t("reservationPaiement.modals.recap.fee")}`}
              <Tooltip minWidth={200}>
                {t("reservationPaiement.modals.recap.tooltip")}
              </Tooltip>
            </p>

            <p className="info red-txt">{`${handleFormatToLocalString(
              totalPricing.state === "UP"
                ? totalPricing.fee
                : totalPricing.normalFee,
              language,
              sign
            )} ${showDevise}`}</p>
          </div>
        </div>
        {reductionApply && (
          <div className="total-price">
            <div className="price-recap | flrow btw liner">
              <div className="flrow liner addgap8">
                <p>{t("reservationPaiement.modals.recap.discount")} :</p>
                <span className="discount-txt">
                  -{totalPricing?.discountPercent || 0}%
                </span>
              </div>
              <span className="amount-txt">
                -
                {`${handleFormatToLocalString(
                  totalPricing.discountAmount,
                  language,
                  sign
                )} ${showDevise}`}
              </span>
            </div>
          </div>
        )}
        <div className="total-price">
          <div className="price-recap | flrow btw liner">
            <p className="big-gras">
              {t("reservationPaiement.modals.recap.total")}
            </p>
            <div style={{ columnGap: 6 }} className="flrow liner">
              {reductionApply && (
                <span
                  style={{ fontSize: 16 }}
                  className="flrow liner txt-normal"
                >
                  {handleFormatToLocalString(
                    totalPricing?.normalPrice || 0,
                    language,
                    sign
                  )}{" "}
                  {showDevise}
                </span>
              )}
              <p className="total-show">{`${handleFormatToLocalString(
                totalPricing.total,
                language,
                sign
              )} ${showDevise}`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
