import React, { useEffect } from "react";
import MentionLegalesContentEn from "../components/biggest/cgu/MentionLegalesContentEn";
import MentionLegalesContentFr from "../components/biggest/cgu/MentionLegalesContentFr";
import Footer from "../components/biggest/Footer";
import i18n from "../globalTraduction";
import { smoothScrollUp } from "../services/utils";
import { useStorePageManager } from "../store/PageManage";
export default function MentionLegalesPage() {
  const handleUpdateStorePage = useStorePageManager(
    (state) => state.handleUpdateStorePage
  );
  useEffect(() => {
    handleUpdateStorePage(false);
  }, []);
  useEffect(() => {
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);
  const language = i18n.language;
  return (
    <>
      {/* <Header /> */}
      <main>
        {language === "fr" ? (
          <MentionLegalesContentFr />
        ) : (
          <MentionLegalesContentEn />
        )}
        <Footer />
      </main>
    </>
  );
}
