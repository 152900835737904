import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentScrollY } from "../../services/useCurrentScrollY";
import "../../styles/components/favorisContent.css";
import "../../styles/components/notificationContent.css";
import CardResidence from "../cells/CardResidence";
import CardResidenceSkeleton from "../cells/CardResidenceSkeleton";

export default function FavorisContent({ favoris = null, loading, error }) {
  const [currentFavs, setCurrentFavs] = useState([]);
  const { checkingPosY } = useCurrentScrollY(80);
  useEffect(() => {
    if (!favoris) return;
    if (favoris.length == 0) return;
    let residenceFavs = [];
    favoris.map((fav) => {
      if (fav.residence) residenceFavs.push(fav.residence);
    });
    // console.log(residenceFavs);
    setCurrentFavs(residenceFavs);
  }, [favoris]);

  const updateFavorisList = (favoId) => {
    let newTabFav = [];
    currentFavs.map((fav) => {
      if (fav.id != favoId) newTabFav.push(fav);
    });
    setCurrentFavs(newTabFav);
  };
  const { t } = useTranslation();
  return (
    <>
      <section className="favoris-section | flcolm max-container rlve">
        <div className="content | flcolm">
          {checkingPosY && (
            <div className="title second | max-container">
              <h2>{t("favorisPage.title")}</h2>
            </div>
          )}
          <div className="title">
            <label>{t("favorisPage.title")}</label>
          </div>
          <div className="second-info | flcolm">
            <p style={{ opacity: loading ? 1 : 0, height: loading ? 28 : 0 }}>
              {t("favorisPage.loadFavorisTxt")}
            </p>
            {loading == false && currentFavs.length == 0 && (
              <>
                <p>{t("favorisPage.createFirstFavorisTxt")}</p>
                <span>{t("favorisPage.seconPFavorisTxt")}</span>
              </>
            )}
          </div>

          <div className="favoris-list | flrow">
            {loading == true &&
              Array.from({ length: 8 }).map((_, index) => {
                return (
                  <React.Fragment key={"skeleton" + index}>
                    <CardResidenceSkeleton />
                  </React.Fragment>
                );
              })}
            {loading == false &&
              currentFavs.length > 0 &&
              currentFavs.map((residence) => (
                <React.Fragment key={"resi" + residence.id}>
                  <CardResidence
                    residence={residence}
                    updateFavorisList={updateFavorisList}
                    favorisPage={true}
                  />
                </React.Fragment>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
