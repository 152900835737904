import moment from "moment";
import React, { useEffect, useState } from "react";
import { Star } from "../../images/icons/JsxIcons";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import { UserPreferences } from "../../store/UserPreferences";

import i18n from "../../globalTraduction";
import mastercard from "../../images/icons/paiement/mastercard.png";
import moovmoney from "../../images/icons/paiement/moovmoney.png";
import mtnmomo from "../../images/icons/paiement/mtnmomo.png";
import om from "../../images/icons/paiement/om.png";
import paypal from "../../images/icons/paiement/paypal.png";
import visa from "../../images/icons/paiement/visa.png";
import wave from "../../images/icons/paiement/wave.png";
import useCurrentHeight from "../../services/useCurrentHeight";
import { checkDateJust, handlePreviewPrice } from "../../services/utils";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import { useStoreAllStaticData } from "../../store/dataForAll";
import Tooltip from "../molecules/Tooltip";
import TooltipRecap from "./TooltipReservation";

export default function SideArticleReservation({
  headerShow,
  t,
  data,
  reservationCard,
}) {
  const imgPaiement = [
    <img src={visa} alt="visa-icon" />,
    <img src={mastercard} alt="mastercard-icon" />,
    <img src={paypal} alt="paypal-icon" />,
    <img src={wave} alt="wave-icon" />,
    <img src={om} alt="om-icon" />,
    <img src={mtnmomo} alt="mtnmomo-icon" />,
    <img src={moovmoney} alt="moovmoney-icon" />,
  ];

  const sign = useStoreCurrencies((state) => state.sign);
  const showDevise = UserPreferences((state) => state.showDevise);
  const language = i18n.language;
  const [priceCalculResult, setPriceCalculResult] = useState(<></>);

  const [totalPriceCalcul, setTotalPriceCalcul] = useState(0);
  const [totalPrice, setTotalPrice] = useState(<></>);
  const [fraisPrice, setFraisPrice] = useState(0);

  const [totalPricing, setTotalPricing] = useState({
    normalPrice: 0,
    discountPrice: 0,
    discountAmount: 0,
    discountPercent: 0,
    subtotal: 0,
    fee: 0,
    total: 0,
  });

  const [reductionApply, setReductionApply] = useState(false);
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [loadingPreview, setLoadingPreview] = useState(false);

  useEffect(() => {
    let totalPrice = 0;
    let priceBase = 0;
    let daysCount = 0;
    let fraisPricing = 0;

    const date1 = moment(data.startDate).startOf("day");
    const date2 = moment(data.endDate).endOf("day");
    daysCount = date2.diff(date1, "days");
    let { subTotalPriceN = 0, fraisPriceN = 0, totalPriceResidenceN = 0 } = {};
    const reservationPreviewPrice =
      useStoreAllStaticData.getState().reservationPreviewPrice;
    if (checkDateJust(data.startDate) && checkDateJust(data.endDate)) {
      setLoadingPreview(true);
      handlePreviewPrice(
        data.startDate,
        data.endDate,
        data.nbrPersonne,
        data.residenceId,
        false,
        reservationPreviewPrice
      ).then((data) => {
        subTotalPriceN = data.subTotalPrice;
        fraisPriceN = data.fraisPrice;
        totalPriceResidenceN = data.totalPriceResidence;
        setSubtotalPrice(data.normalSubtotal);
        setFraisPrice(data.normalFee);
        setTotalPrice(
          <p className="info">{`${handleFormatToLocalString(
            data.state === "UP" ? data.subtotal : data.normalSubtotal,
            language,
            sign
          )} ${showDevise}`}</p>
        );
        setTotalPricing(data);
        // setDaySave(daysDifference);
        setPriceCalculResult(
          <p className="info">
            {t("reservationPaiement.modals.side.subtotal")}
          </p>
        );
        if (data?.discountAmount > 0) {
          setReductionApply(true);
        }
        setLoadingPreview(false);
      });
      return;
    }
    setReductionApply(false);

    if (daysCount <= 0) daysCount = 1;

    priceBase = data.residencePrice;
    priceBase = data.total ? Math.round(data.subtotal / daysCount) : priceBase;
    totalPrice = priceBase * daysCount;
    setPriceCalculResult(
      <p className="info">{`${handleFormatToLocalString(
        priceBase,
        language,
        sign
      )} ${showDevise} x ${daysCount} ${
        daysCount > 1
          ? t("reservationPaiement.modals.side.nightPlural")
          : t("reservationPaiement.modals.side.night")
      }`}</p>
    );
    let totalPriceWithNight = 0;
    let totalPriceResidence = 0;
    totalPriceResidence = Math.round(daysCount * priceBase);
    totalPriceWithNight = totalPriceResidence;
    fraisPricing = Math.round((totalPriceResidence * 7) / 100);
    if (fraisPricing.toString().includes(".")) {
      fraisPricing = fraisPricing.toFixed(2);
    }
    totalPriceResidence = totalPriceResidence + fraisPricing;
    if (totalPriceResidence.toString().includes(".")) {
      totalPriceResidence = totalPriceResidence.toFixed(2);
    }
    setFraisPrice(fraisPricing);
    setTotalPricing(totalPriceResidence);
    setTotalPriceCalcul(totalPrice);
    setTotalPrice(
      <p className="info">{`${handleFormatToLocalString(
        totalPriceWithNight,
        language,
        sign
      )} ${showDevise}`}</p>
    );
  }, []);

  const { windowHeight } = useCurrentHeight();
  return (
    <article
      style={{ top: headerShow && windowHeight > 550 ? 170 : 105 }}
      className="calcul-recap-residence | flcolm"
    >
      <div className="residence-part | flrow liner">
        <img
          style={{ objectFit: "cover" }}
          src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${data.residenceThumb}`}
          alt="residence-thumb"
        />
        <div className="right-part | flcolm ">
          <label>{data.residenceName}</label>
          <div className="stars-part | flrow liner">
            <div className="rating-part | flrow liner">
              <Star />
              <span>{data.residenceStars}</span>
            </div>
            <span>{data.residenceLocation}</span>
          </div>
        </div>
      </div>
      <div className="price-detail | flcolm">
        <label>{t("reservationPaiement.modals.side.detailsPricetxt")}</label>
        <div className="price-recap | flrow btw liner">
          {priceCalculResult}
          <div
            style={{
              columnGap: 6,
              display: "flex",
              alignItems: "center",
            }}
            className="flrow"
          >
            {totalPrice}
            <TooltipRecap
              data={totalPricing.recap}
              state={totalPricing.state}
            />
          </div>
        </div>
      </div>
      <div className="price-frais | flcolm">
        <label>{t("reservationPaiement.modals.side.feeTxt")}</label>
        <div className="price-recap | flrow btw liner">
          <p className="info hv-tooltip">
            {/* {`${feeOfService} ${currentTypePaiement.paiementFee.value}%`} */}
            {t("reservationPaiement.modals.side.feeOfServiceTxt")}
            <Tooltip minWidth={200}>
              {t("reservationPaiement.modals.side.feeInfoTxt")}
            </Tooltip>
          </p>
          <p className="info red-txt">{`${handleFormatToLocalString(
            totalPricing.state === "UP"
              ? totalPricing.fee
              : totalPricing.normalFee,
            language,
            sign
          )} ${showDevise}`}</p>
        </div>
      </div>
      {reductionApply && (
        <div className="total-price">
          <div className="price-recap | flrow btw liner">
            <div className="flrow liner addgap8">
              <p>{t("reservationPaiement.modals.side.discountTxt")} :</p>
              <span className="discount-txt">
                -{totalPricing?.discountPercent || 0}%
              </span>
            </div>
            <span className="amount-txt">
              -
              {`${handleFormatToLocalString(
                totalPricing.discountAmount,
                language,
                sign
              )} ${showDevise}`}
            </span>
          </div>
        </div>
      )}
      <div className="total-price">
        <div className="price-recap | flrow btw liner">
          <p>{t("reservationPaiement.modals.side.totalTxt")}</p>
          <div style={{ columnGap: 6 }} className="flrow liner">
            {reductionApply && (
              <span className="flrow liner txt-normal">
                {handleFormatToLocalString(
                  totalPricing?.normalPrice || 0,
                  language,
                  sign
                )}{" "}
                {showDevise}
              </span>
            )}
            <p>{`${handleFormatToLocalString(
              totalPricing.total,
              language,
              sign
            )} ${showDevise}`}</p>
          </div>
        </div>
      </div>
    </article>
  );
}
