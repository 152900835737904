import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../../data/requestEndpoint";
import { Marker } from "../../../images/icons/JsxIcons";
import { NotificationService } from "../../../services/Notification.service";
import { patch } from "../../../services/useFetchAction";
import { useStorePageManager } from "../../../store/PageManage";
export default function ChangeHabitation({
  handleUpdateBlockScreen,
  handleOutModal,
  userData,
  handleUpdateUser,
}) {
  const { t } = useTranslation();
  const [currentHabitation, setCurrentHabitation] = useState("");

  const [stateHabitation, setStateHabitation] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const handleUpdateHabitation = (e) => {
    let newHabitation = e.target.value;
    newHabitation = newHabitation.replace(/\b\w/g, (match) =>
      match.toUpperCase()
    );
    setCurrentHabitation(newHabitation);
    e.target.value = newHabitation;
  };
  useEffect(() => {
    if (currentHabitation.length >= 1) {
      if (currentHabitation.length >= 4) {
        setStateHabitation({ ...stateHabitation, error: false, good: true });
      } else {
        setStateHabitation({ ...stateHabitation, error: true, good: false });
      }
    } else {
      setStateHabitation({ ...stateHabitation, error: false, good: false });
    }
  }, [currentHabitation]);

  const handleGoNewPass = async () => {
    if (stateHabitation.good) {
      handleUpdateBlockScreen(true);
      // handleUpdateUser
      let data = {
        // password: newPassword,
        location: currentHabitation,
      };
      await patch(requestEndpoint.updateProfilData, data)
        .then((res) => {
          // console.log(res);
          handleUpdateUser({ location: currentHabitation });
          NotificationService.addToQueue(
            0,
            t("modals.personalInfo.habitation.notification.changeLocationDone")
          );
        })
        .catch((err) => {
          console.log(err);
          NotificationService.addToQueue(
            2,
            t("modals.personalInfo.habitation.notification.changeLocationError")
          );
        })
        .finally(() => {
          useStorePageManager.getState().handleUpdateBodyOverflow(true);
          handleUpdateBlockScreen(false);
          handleOutModal();
        });
    }
  };
  return (
    <>
      <label>{t("modals.personalInfo.habitation.description")}</label>
      <div className="input-field | flcolm">
        <div className="input | rlve">
          <Marker
            stroke="var(--gray-400)"
            color={stateHabitation.focus ? "var(--gray-600)" : "none"}
          />
          <input
            onBlur={() =>
              setStateHabitation({ ...stateHabitation, focus: false })
            }
            onFocus={() =>
              setStateHabitation({ ...stateHabitation, focus: true })
            }
            onChange={handleUpdateHabitation}
            placeholder={t("modals.personalInfo.habitation.champs.habitation")}
            type="text"
          />
          {stateHabitation.focus == false && stateHabitation.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>{t("modals.personalInfo.habitation.champs.error")}</span>
            </div>
          )}
        </div>
      </div>
      <button
        onClick={handleGoNewPass}
        className="next-btn | btn flex"
        disabled={!stateHabitation.good}
      >
        {t("modals.personalInfo.habitation.button")}
      </button>
    </>
  );
}
