import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleReturnGoodDescriptionTranslation } from "../../description";
import i18n from "../../globalTraduction";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import { UserPreferences } from "../../store/UserPreferences";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import DescriptionModal from "./DescriptionModal";
export default function DescriptionResidence({ residence = null }) {
  const [isOpen, toggle] = useState(false);
  const [isOpenSpecific, toggleSpecific] = useState(false);
  const { t } = useTranslation();
  const [currentReduction, setCurrentReduction] = useState(null);
  const [canShowReduction, setCanShowReduction] = useState(false);
  const [specificReduction, setSpecificReduction] = useState([]);
  const language = i18n.language;
  const showDevise = UserPreferences((state) => state.showDevise);
  const sign = useStoreCurrencies((state) => state.sign);
  // let description =
  //   language == "fr-FR"
  //     ? residence?.description ?? ""
  //     : residence?.descriptionEn ?? "";
  const [calculForFirstDescription, setCalculForFirstDescription] = useState(0);
  const [totalDescriptionLength, setTotalDescriptionLength] = useState(0);
  const description =
    language == "fr"
      ? residence?.description
      : residence?.descriptionEn || residence?.description;
  useEffect(() => {
    if (!residence) return;
    let totalLength = 0;
    description?.forEach((item, index) => {
      totalLength += item.text.length;
      if (index == 0) setCalculForFirstDescription(totalLength);
    });

    setTotalDescriptionLength(totalLength);
    // fetchDiscountData();
  }, [residence]);

  //afficher la date en forme local DDDD DD MMMM YYYY avec moment, mettre DD MMMM en strong et la premiere lettre de DDDD en majuscule
  const handleFormatDate = (date) => {
    let newDate = moment(date).format("dddd DD MMMM YYYY");
    let newDateArray = newDate.split(" ");
    let newDateArray2 = [];
    newDateArray.forEach((item, index) => {
      if (index == 0) {
        let newString = item.charAt(0).toUpperCase() + item.slice(1);
        newDateArray2.push(newString + " ");
      } else if (index == 1 || index == 2) {
        newDateArray2.push(
          <>
            {" "}
            <strong key={index}>{item}</strong>
          </>
        );
      } else {
        newDateArray2.push(<> {item}</>);
      }
    });
    return newDateArray2;
  };

  return (
    <>
      <ContainerPersoModal
        isOpen={isOpen}
        toggle={toggle}
        baseTop={false}
        title={t("residencePage.description.modals.title")}
      >
        <DescriptionModal data={residence} language={language} />
      </ContainerPersoModal>
      <ContainerPersoModal
        isOpen={isOpenSpecific}
        toggle={toggleSpecific}
        baseTop={false}
        title={t("residencePage.description.modals.titleSpecific")}
      >
        <div className="description-modal | flcolm">
          <div className="title-modal flcolm addgap8">
            <label>
              {t("residencePage.description.txts.specificPeriodTxt")}
            </label>
          </div>
          <div style={{ rowGap: 6 }} className="contents | flcolm">
            {specificReduction?.map((item, index) => {
              return (
                <div className="maxwidth flcolm" key={index}>
                  <p>
                    • {handleFormatDate(item.fromDate)}{" "}
                    {t("residencePage.description.txts.au")}{" "}
                    {handleFormatDate(item.toDate)},{" "}
                    <strong>
                      {handleFormatToLocalString(item.price, language, sign)}{" "}
                      {showDevise}
                    </strong>{" "}
                    {t("residencePage.description.txts.nighted")}
                  </p>
                </div>
              );
            })}
            {/* <p>{language == "fr-FR" ? data.description : data.descriptionEn}</p> */}
          </div>
        </div>
      </ContainerPersoModal>

      <div className="description-residence | flcolm">
        {residence ? (
          <>
            {description?.slice(0, 2).map((item, index) => {
              const capitalizedTitle =
                handleReturnGoodDescriptionTranslation(language, item.title) ||
                item.title;
              let capitalizedText =
                item.text.charAt(0).toUpperCase() + item.text.slice(1);
              return (
                <div className="maxwidth flcolm" key={index}>
                  <h6>
                    {capitalizedTitle.charAt(0).toUpperCase() +
                      capitalizedTitle.slice(1)}
                  </h6>
                  <p>{capitalizedText}</p>
                </div>
              );
            })}
            {(calculForFirstDescription > 270 ||
              totalDescriptionLength > 270 ||
              description?.length > 2) && (
              <button onClick={() => toggle(true)} className="btn">
                <span>{t("residencePage.description.txts.showMoreTxt")}</span>
              </button>
            )}
          </>
        ) : (
          <>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "78px", height: "13px" }}
              className="skeleton span-skeleton"
            ></span>
          </>
        )}
      </div>
    </>
  );
}
