import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleReturnGoodCancellationTranslation } from "../../cancellations";
import i18n from "../../globalTraduction";
import ContainerPersoModal from "../biggest/ContainerPersoModal";

export default function CancelConditionResidence({ residence = null }) {
  const { t } = useTranslation();
  const language = i18n.language;
  //fr ou en
  const [newCondition, setNewConditionState] = useState([]);

  useEffect(() => {
    if (!residence) return;
    const tableauResultat = Object.entries(residence?.refundGrid).map(
      ([label, valeur]) => {
        const googleTranslation =
          handleReturnGoodCancellationTranslation(language, label) || label;
        return {
          label:
            googleTranslation.charAt(0).toUpperCase() +
            googleTranslation.slice(1),
          valeur,
        };
      }
    );
    setNewConditionState(tableauResultat);
  }, [residence]);
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <ContainerPersoModal
        isOpen={openModal}
        toggle={setOpenModal}
        title={t("residencePage.cancelCondition.title")}
        baseTop={true}
      >
        <div className="cancel-condition-residence | flcolm">
          <ul className="list-condition | flcolm addgap24">
            {newCondition.map((condition) => (
              <li className="flcolm liner addgap8">
                <p style={{ fontSize: 15 }}>
                  {t("residencePage.cancelCondition.label") +
                    " " +
                    condition.label}
                </p>
                <span style={{ fontSize: 14 }}>
                  {condition.valeur == 0
                    ? t("residencePage.cancelCondition.notRemboursement")
                    : `${t(
                        "residencePage.cancelCondition.amountToGiveTxt"
                      )}${" "}${condition.valeur}% ${t(
                        "residencePage.cancelCondition.totalMontantTxt"
                      )}`}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </ContainerPersoModal>

      <div
        id="cancel-condition-residence"
        className="cancel-condition-residence | flcolm"
      >
        {residence ? (
          <>
            <label>{t("residencePage.cancelCondition.title")}</label>
            {/* <p>{residence.cancelCondition}</p> */}
            <ul className="list-condition | flcolm addgap24">
              {newCondition.slice(0, 3).map((condition) => (
                <li className="flcolm liner addgap8">
                  <p>
                    {t("residencePage.cancelCondition.label") +
                      " " +
                      condition.label}
                    :{" "}
                  </p>
                  <span>
                    {condition.valeur == 0
                      ? t("residencePage.cancelCondition.notRemboursement")
                      : `${t(
                          "residencePage.cancelCondition.amountToGiveTxt"
                        )}${" "}${condition.valeur}% ${t(
                          "residencePage.cancelCondition.totalMontantTxt"
                        )}`}
                  </span>
                </li>
              ))}
            </ul>
            <button
              onClick={() => setOpenModal(true)}
              className="btn btn-show-all"
            >
              <span>{t("residencePage.cancelCondition.showAllTxt")}</span>
            </button>
          </>
        ) : (
          <div style={{ rowGap: 8 }} className="flcolm max-width">
            <span
              style={{ width: "22%", height: "28px", marginBottom: 20 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "100%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
          </div>
        )}
      </div>
    </>
  );
}
