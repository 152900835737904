import { Autocomplete } from "@react-google-maps/api";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryParam } from "use-query-params";
import { requestEndpoint } from "../../data/requestEndpoint";
import { SearchLg } from "../../images/icons/JsxIcons";
import locationPosition from "../../images/icons/marker-pin-01.svg";
import {
  get,
  useGetDelayRequest,
  useGetStoreWithDelay,
} from "../../services/useFetchAction";
import { getLatLngFromAddress } from "../../services/utils";
import "../../styles/components/destinationInput.css";
export default function DestinationInput({
  destination,
  setDestination,
  setCurrentLat,
  setCurrentLng,
  setViewPort,
  viewPort,
}) {
  const { t } = useTranslation();
  const refInputDestination = useRef();
  const [focus, setFocus] = useState(false);
  const [timeoutId, setTimeoutId] = useState();
  const [lastSearch, setLastSearch] = useState([]);
  const [loadingLocationLongLat, setLoadingLocation] = useState(false);
  //SUGGESTIONS DATA MANAGE
  const [centerMap] = useQueryParam("centerMap");
  useEffect(() => {
    if (centerMap) {
      refInputDestination.current.value = "";
      setDestination("");
    }
  }, [centerMap]);
  const [dataLocationShow, setDataLocationShow] = useState([]);

  const fetchMyLngLat = async (place_id) => {
    const response = await get(
      requestEndpoint.reverseGeo + `?search=${place_id}`
    );
    if (response?.data?.result?.geometry?.location)
      return response.data.result.geometry.location;
    return null;
  };
  const handleTakeMineLngLat = async (dataLocation) => {
    let newDatas = [];
    await Promise.all(
      dataLocation.data.predictions.slice(0, 4).map(async (search) => {
        const longlat = await fetchMyLngLat(search.place_id);
        newDatas.push({
          name: search.description,
          longlat: longlat,
        });
      })
    );
    setLoadingLocation(false);
    setDataLocationShow(newDatas);
  };
  const handleResetLngLat = () => {
    setCurrentLat(null);
    setCurrentLng(null);
    setViewPort(null);
  };
  const handleFocus = () => {
    setFocus(true);
    // handleResetLngLat();
  };
  const [dataLocation, loadinglocation, errorLocation, fetchLocation] =
    useGetDelayRequest({
      delay: 200,
    });
  useEffect(() => {
    // if (loadinglocation) {
    //   setDataLocationShow([]);
    //   return;
    // }
    // if (!dataLocation) {
    //   setLoadingLocation(false);
    //   return;
    // }
    // if (dataLocation.data.predictions) {
    //   handleTakeMineLngLat(dataLocation);
    // } else {
    //   setLoadingLocation(false);
    // }
  }, [loadinglocation, dataLocation]);

  const [oldValue, setOldValue] = useState("");
  const handleBlur = () => {
    setFocus(false);
    let value = refInputDestination.current.value;
    setDestination(value);
    if (oldValue == value) return;
    getLatLngFromAddress(value)
      .then(({ lat, lng, viewport }) => {
        if (!lat || !lng) return;
        setOldValue(value);
        setCurrentLat(lat);
        setCurrentLng(lng);
        setViewPort(viewport);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des coordonnées:", error);
      });
  };
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);
  const handleSelectChange = (val) => {
    setDestination(val.name);
    refInputDestination.current.value = val.name;
    setCurrentLat(val.longlat.lat);
    setCurrentLng(val.longlat.lng);
    setViewPort(val.viewport);
  };

  useEffect(() => {
    const lastSearches = handleGetLastSearches();
    setLastSearch(lastSearches.reverse());
  }, []);

  const handleGetLastSearches = () => {
    const cookieData = Cookies.get("myArrayRecentSearch");
    const myArray = cookieData ? JSON.parse(cookieData) : [];
    const lastTwoElements = myArray.slice(-2);
    return lastTwoElements;
  };

  const { loading, data, error, fetchData } = useGetStoreWithDelay(
    requestEndpoint.getSuggestions + "?search=" + destination,
    null,
    null,
    0,
    400
  );
  const [suggestionsDatas, setSuggestionsDatas] = useState([]);

  useEffect(() => {
    // if (!destination) return;
    // if (destination.length < 3) return;
    // // fetchData();
    // // setTimeout(() => {
    // //   fetchData();
    // // }, 50);
    // const newPRoute =
    //   requestEndpoint.getLocationResidence + "?location=" + destination;
    // setLoadingLocation(true);
    // fetchLocation(null, newPRoute);
  }, [destination]);

  useEffect(() => {
    if (loading) {
      setSuggestionsDatas([]);
      return;
    }
    if (!data) return;
    if (data.length == 0) return;
    let dataLoadSuggestions = [];
    data.map((item) => {
      dataLoadSuggestions.push({
        name: item,
      });
    });
    setSuggestionsDatas(dataLoadSuggestions);
  }, [data, loading]);
  return (
    <div className="destination-input-m | flcolm ">
      <label>{t("store.panel.mobile.champs.destination.label")}</label>
      <div className="input-contain | rlve">
        <SearchLg
          fill={focus ? "var(--primary-50)" : "none"}
          color={focus ? "var(--primary-200)" : "var(--gray-400)"}
        />
        <Autocomplete
          options={{
            types: ["establishment"],
            fields: ["address_components", "name"],
            componentRestrictions: {
              country: "ci",
            },
          }}
        >
          <input
            ref={refInputDestination}
            // value={destination}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => {
              setSuggestionsDatas([]);
              handleResetLngLat();
              setOldValue(null);
              setDestination(e.target.value);
            }}
            placeholder={t("store.panel.mobile.champs.destination.placeholder")}
            type="text"
            className="input"
          />
        </Autocomplete>
        {/* <Searchbar
          className="searchbar-destination"
          thumbLeft={locationPosition}
          loading={loadinglocation || loadingLocationLongLat}
          data={dataLocationShow.slice(0, 3)}
          handleFunctionLocation={handleSelectChange}
          open={focus && destination.length >= 3}
        /> */}
      </div>

      <div
        style={{
          height:
            destination.length > 0 && oldValue != destination && focus
              ? 190
              : 0,
        }}
        className="transition-height"
      ></div>
      {lastSearch.length > 0 && (
        <div className="lasted-search | flcolm">
          <label className="lasted-search-title">
            {t("store.panel.mobile.champs.destination.lastSearch")}
          </label>
          <div className="lasted-search-contain | flcolm">
            {lastSearch.map((search, index) => {
              return (
                <button
                  key={index + search.name}
                  onClick={() => handleSelectChange(search)}
                  className="lasted-search-btn | btn"
                >
                  <img src={locationPosition} alt="markeur" />
                  <span>{search.name}</span>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
