import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Home, Room } from "../../images/icons/JsxIcons";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
import "../../styles/components/addResidencePart/step3.css";
const currentStep = 2;
export default function Step3Accessibility({
  step,
  handleBlockBtn,
  accessibilityResi,
  setTypeAccessibilityResi,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(accessibilityResi == 0);
  }, [step, accessibilityResi]);
  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && accessibilityResi != 0) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [step, accessibilityResi]);
  return (
    <section
      className={`step-1-annonce step-3 ${currentStep == step ? "visible" : ""} 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>{t("addResidence.step3.label")}</label>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="contains-input | flcolm jcenter liner">
            <div className="filter-selected-panel | flcolm">
              <div className="accessibility-type | flcolm">
                <button
                  onClick={() => setTypeAccessibilityResi(1)}
                  className={`${
                    accessibilityResi == 1 && "selected-type | "
                  } btn flrow liner addgap16`}
                >
                  <div className="info-btn | flcolm">
                    <label>{t("addResidence.step3.resiEntiere")}</label>
                    <span>
                      {t("addResidence.step3.resiEntiereDescription")}
                    </span>
                  </div>
                  <Home
                    color={accessibilityResi == 1 ? "#1E1A21" : "#1E1A21"}
                  />
                </button>
                <button
                  onClick={() => setTypeAccessibilityResi(2)}
                  className={`${
                    accessibilityResi == 2 && "selected-type | "
                  } btn flrow liner`}
                >
                  <div className="info-btn | flcolm">
                    <label>{t("addResidence.step3.uneChambre")}</label>
                    <span>{t("addResidence.step3.uneChambreDescription")}</span>
                  </div>
                  <Room
                    color={accessibilityResi == 2 ? "#1E1A21" : "#1E1A21"}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
