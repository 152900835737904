import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../../data/requestEndpoint";
import { EyeSlashed, Pass } from "../../../images/icons/JsxIcons";
import { NotificationService } from "../../../services/Notification.service";
import { put } from "../../../services/useFetchAction";
import { checkIfPassIsGood } from "../../../services/utils";
import { useStorePageManager } from "../../../store/PageManage";
export default function ChangePassword({
  handleUpdateBlockScreen,
  handleOutModal,
}) {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [secPassword, setSecPassword] = useState("");
  const [stateNewPassword, setStateNewPassword] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [stateSecPassword, setStateSecPassword] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [seeNewPassword, setSeeNewPassword] = useState(false);
  const [seeSecPassword, setSeeSecPassword] = useState(false);

  useEffect(() => {
    if (secPassword.length >= 8) {
      if (checkIfPassIsGood(secPassword) && secPassword != newPassword) {
        setStateSecPassword({ ...stateSecPassword, error: false, good: true });
      } else
        setStateSecPassword({ ...stateSecPassword, error: true, good: false });
    } else {
      setStateSecPassword({ ...stateSecPassword, error: false, good: false });
    }
    if (newPassword.length >= 8) {
      setStateNewPassword({ ...stateNewPassword, error: false, good: true });
    } else {
      setStateNewPassword({ ...stateNewPassword, error: false, good: true });
    }
  }, [secPassword, newPassword]);

  const handleGoNewPass = async () => {
    if (checkIfPassIsGood(secPassword)) {
      handleUpdateBlockScreen(true);
      let data = {
        oldPassword: newPassword,
        newPassword: secPassword,
      };
      await put(requestEndpoint.updatePassword, data)
        .then((res) => {
          // console.log(res);
          NotificationService.addToQueue(
            0,
            t("modals.personalInfo.notification.motPasseSwitchDone")
          );
          handleOutModal();
        })
        .catch((err) => {
          console.log(err);
          NotificationService.addToQueue(
            1,
            t("modals.personalInfo.notification.passwordError")
          );
          NotificationService.addToQueue(
            2,
            t("modals.personalInfo.notification.motPasseSwitchError")
          );
        })
        .finally(() => {
          useStorePageManager.getState().handleUpdateBodyOverflow(true);
          handleUpdateBlockScreen(false);
        });
    }
  };
  return (
    <>
      <label>{t("modals.personalInfo.password.description")}</label>
      <div className="input-field | flcolm">
        <div className="input password | rlve">
          <Pass color={stateNewPassword.focus ? "var(--gray-600)" : "none"} />
          <input
            onBlur={() =>
              setStateNewPassword({ ...stateNewPassword, focus: false })
            }
            onFocus={() =>
              setStateNewPassword({ ...stateNewPassword, focus: true })
            }
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder={t("modals.personalInfo.password.champs.password")}
            type={seeNewPassword ? "text" : "password"}
            name="password"
          />
          <button
            onClick={() => setSeeNewPassword(!seeNewPassword)}
            className="see-pass | btn"
          >
            <EyeSlashed
              stroke={seeNewPassword ? "var(--gray-300)" : "#9CA3AF"}
            />
          </button>
          {stateNewPassword.focus == false && stateNewPassword.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>
                {t("modals.personalInfo.password.error.issuePassword")}
              </span>
            </div>
          )}
        </div>
        <div className="input password | rlve">
          <Pass color={stateSecPassword.focus ? "var(--gray-600)" : "none"} />
          <input
            onBlur={() =>
              setStateSecPassword({ ...stateSecPassword, focus: false })
            }
            onFocus={() =>
              setStateSecPassword({ ...stateSecPassword, focus: true })
            }
            onChange={(e) => setSecPassword(e.target.value)}
            placeholder={t("modals.personalInfo.password.champs.newPassword")}
            type={seeSecPassword ? "text" : "password"}
            name="password"
          />
          <button
            onClick={() => setSeeSecPassword(!seeSecPassword)}
            className="see-pass | btn"
          >
            <EyeSlashed
              stroke={seeSecPassword ? "var(--gray-300)" : "#9CA3AF"}
            />
          </button>
          {stateSecPassword.focus == false && stateSecPassword.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>
                {t("modals.personalInfo.password.error.samePassword")}
              </span>
            </div>
          )}
        </div>
      </div>
      <button
        onClick={handleGoNewPass}
        className="next-btn | btn flex"
        disabled={!(stateSecPassword.good && stateNewPassword.good)}
      >
        {t("modals.personalInfo.password.button")}
      </button>
    </>
  );
}
