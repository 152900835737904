import React, { useEffect, useRef, useState } from "react";
import "../../styles/components/inputHeader.css";
// import "../../styles/components/inputHeaderSecond.css";
import en from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import i18n from "../../globalTraduction";
import { NotificationService } from "../../services/Notification.service";
import { getJustDateFormat } from "../../services/utils";

export const InputDatePicker = ({
  title = "",
  placeholder = "",
  myPlaceHolder = null,
  value,
  typeInput = "text",
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  baseFlex = 1,
  type = "start",
  nbrMonth = 2,
  setHider,
  format = "D MMMM Y",
  className = "",
  searchStoreParams,
  confirmSearchParameters,
  handleGoSearch,
  baseTouched = 0,
  secondCall = "endDatePicker",
  myId = null,
  direct = false,
  noLabel = false,
  excludeDatesValues = [],
}) => {
  const { t } = useTranslation();
  const language = i18n.language;
  const [showValue] = useState("");
  const [showValueDate, setCurrentValueDate] = useState("");
  const [currentBlur, setCurrentBlur] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const refInput = useRef(null);
  const [touched, setTouched] = useState(baseTouched);
  const [directCheck, setDirectCheck] = useState(false);
  useEffect(() => {
    setDirectCheck(direct);
  }, [direct]);
  const [excludeDates, setExcludeDates] = useState(excludeDatesValues);

  const checkIfDateIsExcluded = (date, excludeFound) => {
    //convert to iso string to compare
    let arrayCheck = excludeFound ?? excludeDates;
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (date <= yesterday) {
      return false;
    }
    date = date.toISOString();

    //verifier si la date est superieure a la date d'hier

    let result = false;
    arrayCheck.forEach((element) => {
      if (element.toISOString() === date) {
        result = true;
      }
    });
    return result;
  };

  useEffect(() => {
    // console.log("isOpened " + type, isOpened);
  }, [isOpened]);

  // useEffect(() => {
  //   if (type === "start") {
  //     console.log("startDate", startDate);
  //   }
  //   if (type === "end") {
  //     console.log("endDate", endDate);
  //   }
  // }, [startDate, endDate]);
  const [firstUpdated, setFirstUpdated] = useState(false);
  useEffect(() => {
    if (firstUpdated) return;
    if (!refInput) return;
    if (!value) return;
    setCurrentValueDate(value);
    // refInput.current.value = value;
    setFirstUpdated(true);
    if (type === "end") {
      if (startDate && endDate)
        if (endDate < startDate) {
          // console.log("why");
          const newEndDate = new Date(startDate);
          newEndDate.setDate(newEndDate.getDate() + 1);
          setEndDate(newEndDate);
        }
    }
  }, [refInput, value]);

  const handleClickOnInput = (clickOn) => {
    setCurrentBlur(true);
    if (setHider) {
      setTimeout(() => {
        setHider(true);
      }, 10);
    }
    if (touched < 1) {
      setTouched(touched + 1);
    }
    clickOn();
  };

  useEffect(() => {
    if (type == "start") {
      if (endDate) {
        if (startDate > endDate) {
          setEndDate(null);
        }
      }
    }
    if (direct) {
      // console.log("direct", direct);
      if (type === "start") {
        // if (startDate < new Date()) {
        //   setStartDate(new Date());
        // }
      }
      if (type === "end") {
        if (startDate && endDate)
          if (endDate < startDate) {
            // console.log("why");
            const newEndDate = new Date(startDate);
            newEndDate.setDate(newEndDate.getDate() + 1);
            setEndDate(newEndDate);
          }
      }
    }
  }, [showValueDate, startDate, endDate]);

  const handleClick = (event) => {
    if (!event.target.classList.contains(`date-${title}`) && currentBlur) {
      setCurrentBlur(false);
      if (type === "end") {
        if (startDate && endDate)
          if (endDate <= startDate) {
            const newEndDate = new Date(startDate);
            newEndDate.setDate(newEndDate.getDate() + 1);
            if (checkIfDateIsExcluded(newEndDate, excludeDatesValues)) {
              setEndDate(null);
            } else setEndDate(newEndDate);
          }
      }

      if (setHider) {
        setHider(false);
      }
    }
  };

  useEffect(() => {
    const handleBodyClick = (e) => handleClick(e);
    if (currentBlur) {
      document.body.addEventListener("click", handleBodyClick);
    } else {
      document.body.removeEventListener("click", handleBodyClick);
    }
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [currentBlur]);
  useEffect(() => {
    if (value !== showValueDate) {
      // console.log("value", value);
      setCurrentValueDate(value);
    }
  }, []);

  const [firstUpdatedPlaceHolder, setFirstUpdatedPlaceHoder] = useState(false);
  const [posFirstPlaceholder, setPosFirstPlaceholder] = useState(null);
  const checkGetOldValue = () => {
    let result = "";
    // console.log("searchStoreParams", searchStoreParams);
    if (firstUpdatedPlaceHolder) return null;
    if (searchStoreParams) {
      if (searchStoreParams.arriveeDate && searchStoreParams.departDate) {
        if (type === "start") {
          setStartDate(searchStoreParams.arriveeDate);
          const dateObject = moment(
            searchStoreParams.arriveeDate,
            "DD/MM/YYYY"
          ).toDate();
          result = getJustDateFormat(dateObject, format, myPlaceHolder);
        } else {
          setEndDate(searchStoreParams.departDate);
          const dateObject = moment(
            searchStoreParams.departDate,
            "DD/MM/YYYY"
          ).toDate();
          result = getJustDateFormat(dateObject, format, myPlaceHolder);
        }
        setFirstUpdatedPlaceHoder(true);
      } else {
        result = placeholder;
      }
    } else {
      result = placeholder;
    }
    if (confirmSearchParameters) {
      if (
        confirmSearchParameters.startDate &&
        confirmSearchParameters.endDate
      ) {
        if (type === "start") {
          setStartDate(confirmSearchParameters.startDate);
          const dateObject = moment(
            confirmSearchParameters.startDate,
            "DD/MM/YYYY"
          ).toDate();
          result = getJustDateFormat(dateObject, format, myPlaceHolder);
        } else {
          setEndDate(confirmSearchParameters.endDate);
          const dateObject = moment(
            confirmSearchParameters.endDate,
            "DD/MM/YYYY"
          ).toDate();
          result = getJustDateFormat(dateObject, format, myPlaceHolder);
        }
        setFirstUpdatedPlaceHoder(true);
      } else {
        result = placeholder;
      }
    } else {
      result = placeholder;
    }
    setPosFirstPlaceholder(result);
  };

  useEffect(() => {
    if (!startDate || !endDate) return;
    if (!handleGoSearch) return;
    handleGoSearch();
  }, [startDate, endDate]);

  useEffect(() => {
    checkGetOldValue();
  }, [searchStoreParams, confirmSearchParameters]);
  const InputDatePicker = ({ value, onClick }) => {
    // console.log("value", value);
    // console.log(type);

    const dateObject = moment(value, "DD/MM/YYYY").toDate();
    if (startDate && excludeDates && type == "end") {
      let currentDate = new Date(startDate);
      let stopDate = new Date(dateObject ?? startDate);
      let newDateRange = [];
      while (currentDate <= stopDate) {
        newDateRange.push(new Date(currentDate.getTime()));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      let find = false;
      newDateRange.forEach((value) => {
        if (find == false)
          if (checkIfDateIsExcluded(value, excludeDatesValues)) {
            find = true;
            NotificationService.addToQueue(
              1,
              t("header.store.notification.dateError")
            );
            NotificationService.addToQueue(
              1,
              t("header.store.notification.otherDateError")
            );
            setStartDate(null);
            setEndDate(null);
          }
      });
    }
    return (
      <button
        id={
          myId ? myId : type === "start" ? "startDatePicker" : "endDatePicker"
        }
        style={{
          opacity: !currentBlur && showValue && typeInput ? 0 : 1,
          fontWeight: !noLabel ? 400 : 600,
          textAlign: !noLabel ? "left" : "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "100%",
          maxWidth: "90px",
        }}
        className={`custom-date-input date-${title}`}
        onClick={() => {
          setIsOpened(true);
          handleClickOnInput(onClick);
        }}
      >
        {touched >= 1 || directCheck
          ? getJustDateFormat(dateObject, format, myPlaceHolder)
          : posFirstPlaceholder}
      </button>
    );
  };
  const handleOnChanged = (functionCall, date) => {
    //si la date == date de start date alors que on est en date on fait +1
    functionCall(date);
  };

  const [firstUpdatedExcludeDates, setFirstUpdatedExcludeDates] =
    useState(false);
  useEffect(() => {
    if (firstUpdatedExcludeDates) return;
    if (!excludeDatesValues || excludeDatesValues.length == 0) return;
    setExcludeDates(excludeDatesValues);
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);
    if (checkIfDateIsExcluded(newStartDate, excludeDatesValues)) {
      setStartDate(null);
      setEndDate(null);
    }
    if (checkIfDateIsExcluded(newEndDate, excludeDatesValues)) {
      setStartDate(null);
      setEndDate(null);
    }
    setFirstUpdatedExcludeDates(true);
  }, [excludeDatesValues]);
  return (
    <div
      style={{
        flex: baseFlex,
        transition: "all 0.15s ease",
        background: currentBlur ? "var(--shade-white)" : "none",
      }}
      className={`hd-inp hd-input-second hd-input-second-date ${className} | flcolm liner addgap8 `}
    >
      {!noLabel && <label className="lbl-inp-second">{title}</label>}
      {type === "start" ? (
        <DatePicker
          minDate={new Date()}
          selected={startDate}
          // onChange={(date) => handleOnChanged(setStartDate, date)}
          onChange={(date) => {
            handleOnChanged(setStartDate, date);
            setIsOpened(false);
            const endDatePicker = document.getElementById(secondCall);
            if (endDatePicker) {
              endDatePicker.click();
            }
          }}
          onClickOutside={() => setIsOpened(false)}
          onBlur={() => setIsOpened(false)}
          // onSelect={() => (handleGoSearch ? handleGoSearch() : "")}
          // locale=""
          dateFormat="dd/MM/yyyy"
          selectsStart
          startDate={startDate}
          endDate={endDate}
          monthsShown={nbrMonth}
          customInput={<InputDatePicker />}
          excludeDates={[...excludeDates]}
          monthClassName={(date) => "month-date-picker"}
          dayClassName={(date) => {
            let returned = isOpened
              ? `date-picker-opened-start day-date-picker ${language}`
              : `day-date-picker ${language}`;
            //si la date est dans les dates exclues on ajoute la classe excludedate
            if (checkIfDateIsExcluded(date, excludeDatesValues)) {
              returned += ` exclude-date ${language}`;
            }
            return returned;
          }}
          placeholderText="Date"
          locale={language == "fr" ? fr : en}
        />
      ) : (
        <DatePicker
          minDate={startDate ? startDate : new Date()}
          selected={endDate}
          onChange={(date) => {
            setIsOpened(false);
            handleOnChanged(setEndDate, date);
          }}
          openToDate={new Date(startDate ?? new Date())}
          onClickOutside={() => setIsOpened(false)}
          onBlur={() => setIsOpened(false)}
          // onSelect={() => (handleGoSearch ? handleGoSearch() : "")}
          dateFormat="dd/MM/yyyy"
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          monthsShown={nbrMonth}
          excludeDates={[startDate, ...excludeDates]}
          customInput={<InputDatePicker />}
          monthClassName={(date) => "month-date-picker"}
          dayClassName={(date) => {
            let returned = isOpened
              ? `date-picker-opened-end day-date-picker ${language}${
                  startDate
                    ? [new Date(startDate).toISOString()].includes(
                        date.toISOString()
                      )
                      ? " excludedate-start "
                      : ""
                    : ""
                }`
              : `day-date-picker ${language}`;
            if (checkIfDateIsExcluded(date, excludeDatesValues)) {
              returned += " exclude-date";
            }
            return returned;
          }}
          placeholderText={t("header.store.champs.date.datePlaceholder")}
          locale={language == "fr" ? fr : en}
        />
      )}
      <span
        className={
          !currentBlur && showValue && typeInput
            ? "show-me spanner-show"
            : "spanner-show"
        }
      >
        {showValue}
      </span>
    </div>
  );
};
