/* eslint-disable no-fallthrough */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../data/requestEndpoint";
import { NotificationService } from "../../services/Notification.service";
import { post, put, useGetDelayRequest } from "../../services/useFetchAction";
import { smoothScrollUp } from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";
import "../../styles/components/reservationContent.css";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import ReservationModal from "../cells/ReservationModal";
import ListReservation from "./ListReservation";
export default function ReservationContent() {
  const { t } = useTranslation();
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const [timeoutId, setTimeoutId] = useState();
  const [timeoutId2, setTimeoutId2] = useState();

  const [dataAllReservations, loading, error, fetchData] = useGetDelayRequest({
    route: requestEndpoint.getReservationUser,
    loadingTime: 0,
    delay: 0,
  });
  const [dataRefund, loadingRefund, errorRefund, fetchDataRefund] =
    useGetDelayRequest({
      route: requestEndpoint.getRefund,
      loadingTime: 0,
      delay: 1000,
    });
  useEffect(() => {
    fetchData();
  }, []);

  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    if (loading) return;
    if (!dataAllReservations) return;
    let newData = [];
    dataAllReservations.data.map((residence) => {
      newData.push(residence);
    });
    setCurrentData(newData);
    // console.log(newData);
    // setCurrentData(dataAllReservations);
  }, [dataAllReservations, loading]);

  const [currentMenu, setCurrentMenu] = useState(0);
  const [openModalReservation, setOpenModalReservation] = useState(false);
  const [cancelRemboursementOption, setCancelRemboursement] = useState(false);
  const [justOpen, setJustOpen] = useState(false);
  const [remboursementPanelOpen, setRemboursementPanelOpen] = useState(false);
  const [refundedPanelOpen, setRefundedPanelOpen] = useState(false);
  const [withRemboursement, setRemboursementPanel] = useState(false);
  const [selectedCancelReservation, setSelectedCancelReservation] = useState(0);
  const [openValideCancelReservation, setOpenValideCancelReservation] =
    useState(false);
  const [reservationInfo, setReservationInfo] = useState({});

  const handleOpenReservationPanel = (
    reservation,
    justO = false,
    remboursement = false,
    cancelRemboursement = false,
    refunded = false
  ) => {
    // console.log(justO, remboursement, cancelRemboursement, refunded);
    setJustOpen(justO);
    setRemboursementPanel(remboursement);
    setCancelRemboursement(cancelRemboursement);
    setRefundedPanelOpen(refunded);
    setReservationInfo(reservation);
    setOpenModalReservation(true);
    setSelectedCancelReservation(0);
  };

  //REMBOURSEMENT LA RESERVATION POUR DE BON
  const handleGoRemboursement = async () => {
    handleUpdateBlockScreen(true);
    let cancelReason = t(
      "profileReservation.remboursement.remboursementAskTxt",
      { lng: "fr" }
    );
    let cancelReasonEn = t(
      "profileReservation.remboursement.remboursementAskTxt",
      { lng: "en" }
    );

    try {
      const remboursement = await post(requestEndpoint.cancelBooking, {
        bookingId: reservationInfo.id,
        reason: cancelReason,
        reasonEn: cancelReasonEn,
      });
      let timeout = setTimeout(() => {
        NotificationService.addToQueue(
          0,
          t("profileReservation.notification.confirmRemboursementTxtDone")
        );
        NotificationService.addToQueue(
          0,
          t("profileReservation.notification.confirmRemboursementTxtDone2")
        );
        setRemboursementPanelOpen(false);
        setOpenModalReservation(false);
        handleUpdateBlockScreen(false);
        let newReservations = [];
        dataAllReservations.data.map((res) => {
          if (res.id == reservationInfo.id) {
            res.cancelledAt = new Date();
            res.refundAskedAt = new Date();
          }
          newReservations.push(res);
        });
        setCurrentData(newReservations);
        fetchDataRefund();
      }, 300);
      setTimeoutId(timeout);
    } catch (error) {
      NotificationService.addToQueue(
        2,
        t("profileReservation.notification.confirmRemboursementTxtError")
      );
      handleUpdateBlockScreen(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDataRefund();
  }, []);
  const [refundList, setRefundList] = useState([]);
  useEffect(() => {
    if (loadingRefund) return;
    if (!dataRefund) return;
    if (dataRefund.data.length == 0) return;
    let newRefundList = [];
    dataRefund.data.map((refund) => {
      newRefundList.push(refund);
    });
    let newCurrentData = [];
    setRefundList(newRefundList);
    if (currentData.length == 0) return;
    currentData.forEach((element) => {
      let newElement = { ...element };
      dataRefund.data.map((refund) => {
        if (refund.bookingId == element.id && !newElement.refundFee) {
          newElement = { ...newElement, refundFee: refund.refundedAmount };
        }
      });
      newCurrentData.push(newElement);
    });
    // console.log(newCurrentData);
    setCurrentData(newCurrentData);
  }, [dataRefund, loadingRefund]);
  //CANCEL REMBOURSEMENT REQUEST
  const handleCancelRemboursementRequest = async () => {
    handleUpdateBlockScreen(true);
    let findIDRefund = 0;
    refundList.map((refund) => {
      if (refund.bookingId == reservationInfo.id) {
        findIDRefund = refund.id;
      }
    });
    // console.log(requestEndpoint.cancelRefund + findIDRefund);
    try {
      const remboursement = await put(
        requestEndpoint.cancelRefund + findIDRefund
      );
      let timeout = setTimeout(() => {
        NotificationService.addToQueue(
          0,
          t("profileReservation.notification.refundCanceled")
        );
        setRemboursementPanelOpen(false);
        setOpenModalReservation(false);
        handleUpdateBlockScreen(false);
        let newReservations = [];
        dataAllReservations.data.map((res) => {
          if (res.id == reservationInfo.id) {
            res.cancelledAt = new Date();
            res.refundAskedAt = null;
          }
          newReservations.push(res);
        });
        // console.log(newReservations);
        setCurrentData(newReservations);
      }, 300);
      setTimeoutId(timeout);
    } catch (error) {
      NotificationService.addToQueue(
        2,
        t("profileReservation.notification.refundCanceledError")
      );
      handleUpdateBlockScreen(false);
      console.log(error);
    }
  };
  //CANCEL LA RESERVATION POUR DE BON
  const handleGoCancelReservation = async () => {
    handleUpdateBlockScreen(true);
    let cancelReason = "";
    let cancelReasonEn = "";
    switch (selectedCancelReservation) {
      case 0:
        cancelReason = t("profileReservation.remboursement.noRefundAsk", {
          lng: "fr",
        });
        cancelReasonEn = t("profileReservation.remboursement.noRefundAsk", {
          lng: "en",
        });
        break;
      case 1:
        cancelReason = t(
          "profileReservation.remboursement.iChooseOtherResiTxt",
          { lng: "fr" }
        );
        cancelReasonEn = t(
          "profileReservation.remboursement.iChooseOtherResiTxt",
          { lng: "en" }
        );
        break;
      case 2:
        cancelReason = t("profileReservation.remboursement.iChangedMyMindTxt", {
          lng: "fr",
        });
        cancelReasonEn = t(
          "profileReservation.remboursement.iChangedMyMindTxt",
          { lng: "en" }
        );
        break;
      default:
        break;
    }
    try {
      const cancelReservation = await post(requestEndpoint.cancelBooking, {
        bookingId: reservationInfo.id,
        reason: cancelReason,
        reasonEn: cancelReasonEn,
      });
      let timeout = setTimeout(() => {
        NotificationService.addToQueue(
          0,
          t("profileReservation.notification.confirmCancelReservationTxt")
        );
        setOpenValideCancelReservation(false);
        setOpenModalReservation(false);
        handleUpdateBlockScreen(false);
        let newReservations = [];
        dataAllReservations.data.map((res) => {
          if (res.id == reservationInfo.id) {
            res.cancelledAt = new Date();
            res.status = "cancelled";
            res.cancelReason = cancelReason;
            res.cancelReasonEn = cancelReasonEn;
          }
          newReservations.push(res);
        });
        setCurrentData(newReservations);
      }, 300);
      setTimeoutId(timeout);
    } catch (error) {
      handleUpdateBlockScreen(false);
      NotificationService.addToQueue(
        2,
        t("profileReservation.notification.errorCancelReservationTxt")
      );
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const refBtnList = useRef();
  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartX(e.pageX - refBtnList.current.offsetLeft);
    setScrollLeft(refBtnList.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - refBtnList.current.offsetLeft;
    const walk = (x - startX) * 1;
    refBtnList.current.scrollLeft = scrollLeft - walk;
  };
  useEffect(() => {
    const handleScroll = () => {
      const div = refBtnList.current;

      // console.log(div.scrollLeft + div.clientWidth + 50 >= div.scrollWidth);
    };

    const div = refBtnList.current;

    if (div) {
      div.addEventListener("scroll", handleScroll);

      return () => {
        div.removeEventListener("scroll", handleScroll);
      };
    }
  }, [refBtnList]);
  useEffect(() => {
    clearTimeout(timeoutId2);
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 200);
    setTimeoutId2(newTimeout);
    return () => {
      clearTimeout(timeoutId2);
    };
  }, [currentMenu]);

  let valueToShow = <></>;
  const hoteMode = false;
  if (reservationInfo)
    switch (reservationInfo.status) {
      case "waiting":
        if (hoteMode) {
          valueToShow = (
            <span className="top-corner-info">
              {t("profileReservation.topSection.waitingTxt")}
            </span>
          );
        } else {
          valueToShow = (
            <span className="top-corner-info">
              {t("profileReservation.topSection.waitingHoteTxt")}
            </span>
          );
        }
        break;
      case "accepted" || "payment_processing":
        if (reservationInfo.status == "payment_processing") {
          valueToShow = (
            <span className="top-corner-info">
              {t("profileReservation.topSection.paymentProcessingTxt")}
            </span>
          );
        } else
          valueToShow = (
            <span className="top-corner-info">
              {t("profileReservation.topSection.waitingPaiementTxt")}
            </span>
          );
        break;
      case "planified" || "progressing":
        if (hoteMode) {
          valueToShow = (
            <span className="top-corner-info">
              {t("profileReservation.topSection.paidConfirm")}
            </span>
          );
        } else {
          if (reservationInfo.refundAskedAt) {
            valueToShow = (
              <span className="top-corner-info">
                {t("profileReservation.topSection.closePanel")}
              </span>
            );
          } else {
            valueToShow = (
              <span className="top-corner-info">
                {t("profileReservation.topSection.paiementAcceptTxt")}
              </span>
            );
          }
        }
        if (reservationInfo.refundedAt) {
          valueToShow = (
            <span className="top-corner-info">
              {t("profileReservation.topSection.closePanel")}
            </span>
          );
        }
        break;
      case "cancelled":
        valueToShow = (
          <span className="top-corner-info">
            {t("profileReservation.topSection.cancelByClientTxt")}
          </span>
        );
        break;
      case "rejected":
        if (hoteMode) {
          valueToShow = (
            <span className="top-corner-info">
              {t("profileReservation.infos.refuseBooking")}
            </span>
          );
        } else {
          valueToShow = (
            <span className="top-corner-info">
              {t("profileReservation.infos.paiementCancelByHote")}
            </span>
          );
        }
        break;
      case "done":
        valueToShow = (
          <span className="top-corner-info">
            {t("profileReservation.infos.reservationDoneRsvTxt")}
          </span>
        );
      default:
        break;
    }
  if (reservationInfo.status == "payment_processing") {
    valueToShow = (
      <span className="top-corner-info">
        {t("profileReservation.topSection.paymentProcessingTxt")}
      </span>
    );
  }
  if (reservationInfo.refundedAt) {
    valueToShow = (
      <span className="top-corner-info">
        {t("profileReservation.topSection.closePanel")}
      </span>
    );
  }
  const [showRemboursement, setShowRemboursement] = useState(false);
  const [showInProgress, setShowInProgress] = useState(false);

  return (
    <section className="reservation-list | max-container flcolm">
      <div className="btns-action">
        <div
          ref={refBtnList}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          className="btns-actions-line | max-container flrow maxwidth liner"
        >
          <button
            onClick={() => setCurrentMenu(0)}
            className={`${currentMenu == 0 ? "current-btn" : ""} | btn`}
          >
            <span>{t("profileReservation.topAction.btnAll")}</span>
          </button>
          <button
            onClick={() => setCurrentMenu(1)}
            className={`${currentMenu == 1 ? "current-btn" : ""} | btn`}
          >
            <span>{t("profileReservation.topAction.btnWaiting")}</span>
          </button>
          <button
            onClick={() => setCurrentMenu(2)}
            className={`${currentMenu == 2 ? "current-btn" : ""} | btn`}
          >
            <span>{t("profileReservation.topAction.btnAcceptByHote")}</span>
          </button>
          <button
            onClick={() => setCurrentMenu(3)}
            className={`${currentMenu == 3 ? "current-btn" : ""} | btn`}
          >
            <span>{t("profileReservation.topAction.btnAskValidate")}</span>
          </button>
          {showInProgress && (
            <button
              onClick={() => setCurrentMenu(9)}
              className={`${currentMenu == 9 ? "current-btn" : ""} | btn`}
            >
              <span>{t("profileReservation.topAction.inProgressTxt")}</span>
            </button>
          )}
          <button
            onClick={() => setCurrentMenu(4)}
            className={`${currentMenu == 4 ? "current-btn" : ""} | btn`}
          >
            <span>{t("profileReservation.topAction.btnCancelByYou")}</span>
          </button>
          <button
            onClick={() => setCurrentMenu(5)}
            className={`${currentMenu == 5 ? "current-btn" : ""} | btn`}
          >
            <span>{t("profileReservation.topAction.refused")}</span>
          </button>
          <button
            onClick={() => setCurrentMenu(6)}
            className={`${currentMenu == 6 ? "current-btn" : ""} | btn`}
          >
            <span>{t("profileReservation.topAction.waitingRefund")}</span>
          </button>
          {showRemboursement && (
            <>
              <button
                onClick={() => setCurrentMenu(7)}
                className={`${currentMenu == 7 ? "current-btn" : ""} | btn`}
              >
                <span>{t("profileReservation.topAction.refundConfirmed")}</span>
              </button>
            </>
          )}
        </div>
      </div>
      <div className="contains-all rlve">
        <ListReservation
          handleOpenReservationPanel={handleOpenReservationPanel}
          skeletonNumber={4}
          showNodata={t("profileReservation.noData.noDataAllReservation")}
          currentSelected={currentMenu}
          mineSelect={0}
          loading={loading}
          data={currentData}
        />
        <ListReservation
          handleOpenReservationPanel={handleOpenReservationPanel}
          skeletonNumber={3}
          showNodata={t("profileReservation.noData.noDataWaitingReservation")}
          currentSelected={currentMenu}
          mineSelect={1}
          loading={loading}
          data={currentData}
        />
        <ListReservation
          handleOpenReservationPanel={handleOpenReservationPanel}
          showNodata={t("profileReservation.noData.noDataAcceptedReservation")}
          currentSelected={currentMenu}
          mineSelect={2}
          loading={loading}
          data={currentData}
        />
        <ListReservation
          handleOpenReservationPanel={handleOpenReservationPanel}
          showNodata={t("profileReservation.noData.noDataAcceptedReservation")}
          currentSelected={currentMenu}
          mineSelect={3}
          loading={loading}
          data={currentData}
        />
        <ListReservation
          handleOpenReservationPanel={handleOpenReservationPanel}
          showNodata={t("profileReservation.noData.noDataInProgress")}
          currentSelected={currentMenu}
          mineSelect={9}
          loading={loading}
          data={currentData}
          setShowRemboursement={setShowInProgress}
        />
        <ListReservation
          handleOpenReservationPanel={handleOpenReservationPanel}
          showNodata={t("noDataCancelByYouReservation")}
          currentSelected={currentMenu}
          mineSelect={5}
          loading={loading}
          data={currentData}
          smile={true}
        />
        <ListReservation
          handleOpenReservationPanel={handleOpenReservationPanel}
          showNodata={t("profileReservation.noData.noDataCancelReservation")}
          currentSelected={currentMenu}
          mineSelect={4}
          loading={loading}
          data={currentData}
          smile={true}
        />
        <ListReservation
          handleOpenReservationPanel={handleOpenReservationPanel}
          showNodata={t("profileReservation.noData.noDataWaitingRefund")}
          currentSelected={currentMenu}
          mineSelect={6}
          loading={loading}
          data={currentData}
        />
        <ListReservation
          handleOpenReservationPanel={handleOpenReservationPanel}
          showNodata={t("profileReservation.noData.noDataWaitingRefund")}
          currentSelected={currentMenu}
          mineSelect={7}
          loading={loading}
          data={currentData}
          setShowRemboursement={setShowRemboursement}
        />
      </div>
      <ContainerPersoModal
        isOpen={openModalReservation}
        toggle={setOpenModalReservation}
        title={
          refundedPanelOpen
            ? t("modals.booking.titles.confirmRefund")
            : cancelRemboursementOption
            ? t("modals.booking.titles.cancelRefund")
            : justOpen && !withRemboursement
            ? t("modals.booking.titles.details")
            : withRemboursement
            ? t("modals.booking.titles.remboursementAskTxt")
            : t("modals.booking.titles.cancelReservationTxt")
        }
        baseTop={true}
        fullBlock={true}
        className="cancel-modal modal-details"
      >
        {refundedPanelOpen ? (
          <>
            <ReservationModal reservationInfo={reservationInfo} t={t}>
              <button
                onClick={() => setOpenModalReservation(false)}
                className="see-info-btn | btn info-btn"
              >
                <span>{t("modals.booking.button.closePanel")}</span>
              </button>
            </ReservationModal>
          </>
        ) : cancelRemboursementOption ? (
          <>
            <ReservationModal reservationInfo={reservationInfo} t={t}>
              <button
                onClick={() => handleCancelRemboursementRequest()}
                className="see-info-btn | btn cch-btn"
              >
                <span>{t("modals.booking.button.cancelRefund")}</span>
              </button>
            </ReservationModal>
          </>
        ) : (
          <>
            {reservationInfo.status == "waiting" ||
            reservationInfo.status == "accepted" ? (
              <ReservationModal reservationInfo={reservationInfo} t={t}>
                {!justOpen ? (
                  <button
                    disabled={openValideCancelReservation}
                    onClick={() => setOpenValideCancelReservation(true)}
                    className="cancel-btn | btn cc-btn"
                  >
                    <span>{t("modals.booking.button.cancelReservation")}</span>
                  </button>
                ) : (
                  <button
                    onClick={() => setOpenModalReservation(false)}
                    className="see-info-btn | btn info-btn"
                  >
                    <span>{t("modals.booking.button.closePanel")}</span>
                  </button>
                )}
              </ReservationModal>
            ) : (
              <ReservationModal
                withRemboursement={withRemboursement}
                reservationInfo={reservationInfo}
                t={t}
              >
                {withRemboursement ? (
                  <button
                    disabled={remboursementPanelOpen}
                    onClick={() => setRemboursementPanelOpen(true)}
                    className="cch-btn btn"
                  >
                    {t("modals.booking.button.askRemboursement")}
                  </button>
                ) : (
                  <button className="see-info-btn | btn info-btn">
                    {valueToShow}
                  </button>
                )}
              </ReservationModal>
            )}
          </>
        )}
        {}
      </ContainerPersoModal>
      {/* MODAL CONFIRM REMBOURSEMENT PAIEMENT */}
      <ContainerPersoModal
        isOpen={remboursementPanelOpen}
        toggle={setRemboursementPanelOpen}
        title={t("modals.booking.titles.confirmRemboursementTxt")}
        baseTop={true}
        className="modal-details cancel-confirm"
      >
        <div className="confirm-panel second-conf flcolm">
          <p style={{ marginBottom: 8 }} className="p-just -c">
            {t("modals.booking.txts.doAskRemboursementTxt")}
          </p>
          <div className="btns | flcolm addgap8">
            <button
              style={{ borderRadius: 8 }}
              onClick={() => handleGoRemboursement()}
              className="cancel-btn | btn cc-btn"
            >
              {t("modals.booking.button.confirmTxt")}
            </button>
          </div>
        </div>
      </ContainerPersoModal>

      {/* MODAL CONFIRM ANNULATION PAIEMENT */}
      <ContainerPersoModal
        isOpen={openValideCancelReservation}
        toggle={setOpenValideCancelReservation}
        title={t("modals.booking.titles.confirmCancelReservation")}
        baseTop={true}
        className="modal-details cancel-confirm"
      >
        <div className="confirm-panel second-conf flcolm">
          <br />
          <div className="selectors | flcolm addgap8">
            <button
              onClick={() => setSelectedCancelReservation(0)}
              className="select-btn | btn btw rlve"
            >
              <span>{t("modals.booking.txts.noMotif")}</span>
              <div className="selector-circle rlve">
                {selectedCancelReservation == 0 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
            <button
              onClick={() => setSelectedCancelReservation(1)}
              className="select-btn | btn btw rlve"
            >
              <span>{t("modals.booking.txts.ichooseOtherResiTxt")}</span>
              <div className="selector-circle rlve">
                {selectedCancelReservation == 1 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
            <button
              onClick={() => setSelectedCancelReservation(2)}
              className="select-btn | btn btw rlve"
            >
              <span>{t("modals.booking.txts.iChangedMyMindTxt")}</span>
              <div className="selector-circle rlve">
                {selectedCancelReservation == 2 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
          </div>
          <button
            style={{ marginTop: 16 }}
            onClick={handleGoCancelReservation}
            className="cancel-btn | btn cc-btn"
          >
            {t("modals.booking.button.cancelReservation")}
          </button>
        </div>
      </ContainerPersoModal>
    </section>
  );
}
