import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { XClose } from "../images/icons/JsxIcons";
import icon404 from "../images/illustrations/no-results.png";
import tcLogo from "../images/logos/logo-tc.svg";
import { useStorePageManager } from "../store/PageManage";
import "../styles/pages_styles/notFoundPage.css";
export default function NotFoundPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    useStorePageManager.getState().handleUpdateDesactiveHeader(true);
    return () => {
      useStorePageManager.getState().handleUpdateDesactiveHeader(false);
    };
  }, []);
  const handleNavigateToHome = () => {
    navigate("/", { replace: true });
  };
  return (
    <>
      {/* <Header /> */}
      <main>
        <section className="not-found-section | max-container flex jcenter liner">
          <div className="background"></div>
          <div className="card-not-found | flcolm jcenter liner rlve">
            <button onClick={handleNavigateToHome} className="tc-logo btn">
              <img src={tcLogo} alt="tc-logo" />
            </button>
            <button onClick={handleNavigateToHome} className="close-quit | btn">
              <XClose color="var(--shade-white)" strokeWidth={3} />
            </button>
            <img className="icon-404" src={icon404} alt="icon-404" />
            <p>{t("notFound.txt")}</p>
            <button onClick={handleNavigateToHome} className="btn pry-btn">
              <span>{t("notFound.btnTxt")}</span>
            </button>
          </div>
        </section>
      </main>
    </>
  );
}
