/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../../data/requestEndpoint";
import i18n from "../../../globalTraduction";
import { User } from "../../../images/icons/JsxIcons";
import cameraIcon from "../../../images/icons/camera-01.svg";
import pencilIcon from "../../../images/icons/edit-02.svg";
import backgroundImageProfil from "../../../images/illustrations/profil-background.jpeg";
import { NotificationService } from "../../../services/Notification.service";
import { patch } from "../../../services/useFetchAction";
import { useStorePageManager } from "../../../store/PageManage";
import { useStoreUserManager } from "../../../store/UserManage";
import ContainerPersoModal from "../../biggest/ContainerPersoModal";

export default function AddProfilPhotoPart({
  handleUpdateUser,
  userData,
  maxSizeInBytes,
  handleUpdateBlockScreen,
}) {
  const { t } = useTranslation();
  const language = i18n.language;
  const [currentFile, setCurrentFile] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalChangeName, setOpenModalChangeName] = useState(false);

  const handleSendPhoto = async () => {
    handleUpdateBlockScreen(true);
    let data = new FormData();
    data.append("file", currentFile[0]);
    await patch(requestEndpoint.updateProfilPicture, data, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        useStoreUserManager
          .getState()
          .handleUpdateUser({ profilImgUrl: res.data.avatar });
        NotificationService.addToQueue(
          0,
          t("profile.notification.uploadPhotoConfirm")
        );
      })
      .catch((err) => {
        NotificationService.addToQueue(
          2,
          t("profile.notification.errorMajPhoto")
        );
      })
      .finally(() => {
        handleUpdateBlockScreen(false);
        useStorePageManager.getState().handleUpdateBodyOverflow(true);
        setOpenModal(false);
      });
  };
  const handleChangementFichier = async (e) => {
    const fichierSelectionne = e.target.files[0];
    let newFile = [];

    // Vérifier si un fichier a été sélectionné
    if (fichierSelectionne) {
      if (
        ["image/png", "image/jpeg", "image/heic"].includes(
          fichierSelectionne.type
        )
      ) {
        if (fichierSelectionne.size <= maxSizeInBytes) {
          // const compressedFile = await compressImage(fichierSelectionne);
          const compressedFile = fichierSelectionne;
          newFile.push(compressedFile);
        } else {
          NotificationService.addToQueue(
            2,
            `${t("profile.notification.filep1")} ${fichierSelectionne.name} ${t(
              "profile.notification.filep2"
            )}`
          );
        }
      } else {
        NotificationService.addToQueue(
          2,
          t("profile.notification.profilePhotoTypeNotAccepted")
        );
      }
    }
    if (newFile.length == 0) return;
    setCurrentFile(newFile);
    setOpenModal(true);
  };
  const [name, setName] = useState("");
  useEffect(() => {
    //si le nom est superieur a 25 caracteres on coupe un prenom et on le limite a la premiere lettre avec un point
    if (!userData.firstname) return;
    if (!userData.lastname) return;
    let firstname = userData.firstname;
    let lastname = userData.lastname;
    if (firstname.length > 25) {
      let restName = "";
      let dataArray = firstname.split(" ");
      for (let index = 0; index < dataArray.length; index++) {
        restName += index == 0 ? dataArray[index] : ` ${dataArray[index]}`;
        if (restName.length > 22) {
          //on coupe le dernier mot en gardant la premiere lettre et on ajoute un point
          let restNameArray = restName.split(" ");
          let returnedName = "";
          restNameArray.forEach((el, index) => {
            if (index == restNameArray.length - 1) {
              returnedName += ` ${el[0]}.`;
            } else if (index == 0 || index < restNameArray.length - 1) {
              returnedName += ` ${el}`;
            }
          });
          firstname = returnedName.trim();
          break;
        }
      }
    }

    if (lastname.length > 22) {
      let restName = "";
      let dataArray = lastname.split(" ");
      for (let index = 0; index < dataArray.length; index++) {
        restName += index == 0 ? dataArray[index] : ` ${dataArray[index]}`;
        if (restName.length > 25) {
          //on coupe le dernier mot en gardant la premiere lettre et on ajoute un point
          let restNameArray = restName.split(" ");
          let returnedName = "";
          restNameArray.forEach((el, index) => {
            if (index == restNameArray.length - 1) {
              returnedName += ` ${el[0]}.`;
            } else if (index == 0 || index < restNameArray.length - 1) {
              returnedName += ` ${el}`;
            }
          });
          lastname = returnedName.trim();
          break;
        }
      }
    }
    setName(`${lastname} ${firstname}`);
  }, [userData]);

  let status = userData.profil;
  if (language === "en") {
    const normalizedStatus = status
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (
      normalizedStatus.toLocaleLowerCase() === "client/hote".toLocaleLowerCase()
    ) {
      status = "Client/host";
    }
  }
  return (
    <>
      <div className="profil-photo | flcolm rlve">
        <img src={backgroundImageProfil} alt="backgroundImageProfil" />
        <div className="photo-panel | flcolm liner jcenter">
          <div className="img-show rlve">
            {userData.profilImgUrl ? (
              <img
                src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PROFIL_PICTURE}${userData.profilImgUrl}`}
                alt="profil-icon"
              />
            ) : (
              <span className="not-photo">{t("profile.photo.noPhoto")}</span>
            )}

            <div
              style={{ pointerEvents: "all", userSelect: "auto" }}
              className="flrow liner rlve"
            >
              <label
                style={{
                  pointerEvents: "all",
                  userSelect: "auto",
                  cursor: "pointer",
                  zIndex: 4,
                }}
                htmlFor="inputFile"
                className="customFileInput"
              >
                <img src={cameraIcon} alt="cameraIcon" />
                <span style={{ marginLeft: 4 }}>
                  {t("profile.photo.changeTxt")}
                </span>
                <input
                  type="file"
                  id="inputFile"
                  onChange={handleChangementFichier}
                  style={{
                    cursor: "pointer",
                    zIndex: 4,
                    pointerEvents: "all",
                    userSelect: "auto",
                    position: "absolute",
                    opacity: 0,
                    transform: "scale(1.5)",
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  accept="image/png, image/jpeg, image/heic"
                />
              </label>
            </div>
            {/* <img /> */}
          </div>
          <div className="name-status | flcolm liner">
            <div
              style={{
                flexWrap: "wrap",
                columnGap: 4,
                width: "100%",
              }}
              className="flrow liner"
            >
              <div style={{ gap: 2 }} className="flex flcolm liner jcenter">
                <span className="rlve" style={{ whiteSpace: "nowrap" }}>
                  {name}
                </span>
                <button
                  style={{ padding: 8 }}
                  onClick={() => setOpenModalChangeName(true)}
                  className="btn edit-name-btn"
                >
                  <img src={pencilIcon} alt="pencil-icon" />
                </button>
              </div>
            </div>
            <p
              style={{
                width: "100%",
                textAlign: "center",
                whiteSpace: "normal",
                display: "inline-block",
              }}
            ></p>
            <div className="status">
              <span>{status}</span>
            </div>
          </div>
        </div>
      </div>
      <ContainerPersoModal
        bottomChildren={true}
        baseTop={true}
        title={t("modals.photo.title")}
        isOpen={openModal}
        toggle={setOpenModal}
        className="piece-send-modal"
      >
        <div className="modal-piece profil-pic | flcolm jcenter liner">
          {currentFile.length > 0 && (
            <img
              src={URL.createObjectURL(currentFile[0])}
              className="preview-photo"
            />
          )}

          <span>{t("modals.photo.confirmPhotoChange")}</span>
          <button onClick={handleSendPhoto} className="btn pry-btn">
            {t("modals.photo.confirmTxt")}
          </button>
        </div>
      </ContainerPersoModal>
      {openModalChangeName && (
        <ProfileNameUpdate
          t={t}
          openModalChangeName={openModalChangeName}
          setOpenModalChangeName={setOpenModalChangeName}
          userData={userData}
        />
      )}
    </>
  );
}

const ProfileNameUpdate = ({
  t,
  openModalChangeName,
  setOpenModalChangeName,
  userData,
}) => {
  const userIsHotelier = userData.profil
    ? userData.profil.toLocaleLowerCase() == "Client/hote".toLocaleLowerCase()
    : false;
  const handleUpdateUser = useStoreUserManager(
    (state) => state.handleUpdateUser
  );
  const [dataInscription, setDataInscription] = useState({
    naming: "",
    firstName: "",
  });

  const [stateNaming, setStateNaming] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [stateFirstName, setFirstName] = useState({
    focus: false,
    error: false,
    good: false,
  });
  useEffect(() => {
    if (dataInscription.firstName.length > 0) {
      if (dataInscription.firstName.length > 2) {
        setFirstName({ ...stateFirstName, error: false, good: true });
      } else {
        setFirstName({ ...stateFirstName, error: true, good: false });
      }
    } else {
      setFirstName({ ...stateFirstName, error: false, good: false });
    }
  }, [dataInscription]);
  useEffect(() => {
    if (dataInscription.naming.length > 0) {
      if (dataInscription.naming.length > 2) {
        setStateNaming({ ...stateNaming, error: false, good: true });
      } else {
        setStateNaming({ ...stateNaming, error: true, good: false });
      }
    } else {
      setStateNaming({ ...stateNaming, error: false, good: false });
    }
  }, [dataInscription]);

  const handleGoUpdateName = async () => {
    if (!stateFirstName.good || !stateNaming.good) return;
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    let data = {
      firstname: dataInscription.firstName,
      lastname: dataInscription.naming,
      resetResidence: true,
    };
    await patch(requestEndpoint.updateProfilData, data)
      .then((res) => {
        handleUpdateUser({
          firstname: dataInscription.firstName,
          lastname: dataInscription.naming,
        });
        NotificationService.addToQueue(0, "Modification effectuée");
        setOpenModalChangeName(false);
      })
      .catch((err) => {
        //si il y a un message d'erreur on l'affiche
        if (err.response && err.response.data.message) {
          NotificationService.addToQueue(2, err.response.data.message);
        } else NotificationService.addToQueue(2, "Une erreur est survenue");
      })
      .finally(() => {
        useStorePageManager.getState().handleUpdateBlockScreen(false);
        useStorePageManager.getState().handleUpdateBodyOverflow(true);
      });
  };

  return (
    <ContainerPersoModal
      title={t("modals.nameUpdate.title")}
      isOpen={openModalChangeName}
      toggle={setOpenModalChangeName}
      baseTop={true}
      fitContent={true}
      className="modal-change-name contains inscription-part"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleGoUpdateName();
        }}
        className="chooseName | flcolm"
      >
        {userIsHotelier && (
          <span
            className="just-t"
            style={{
              width: "100%",
              fontSize: 16,
              fontWeight: 500,
              fontFamily: "Mori Gothic",
              margin: "10px 0",
              marginTop: 0,
              textAlign: "center",
              lineHeight: "28px",
              color: "var(--gray-700)",
            }}
          >
            {t("modals.nameUpdate.infoTxt")}
          </span>
        )}
        <div className="input-select-perso input-field | flcolm addgap8">
          <div className="input | rlve">
            <User color={stateNaming.focus ? "var(--gray-600)" : "none"} />
            <input
              autoComplete="off"
              autoCorrect="off"
              onBlur={() => setStateNaming({ ...stateNaming, focus: false })}
              onFocus={() => setStateNaming({ ...stateNaming, focus: true })}
              onChange={(e) =>
                setDataInscription({
                  ...dataInscription,
                  naming: e.target.value,
                })
              }
              placeholder={t("modals.nameUpdate.champs.lastname")}
              type="text"
              name="lastname"
            />
            {stateNaming.focus == false &&
              dataInscription.naming.length > 0 &&
              stateNaming.error && (
                <div className="detect-issue-input">
                  <div className="chevron"></div>
                  <span>{t("modals.nameUpdate.error.issueNameType")}</span>
                </div>
              )}
          </div>
          <div className="input | rlve">
            <User color={stateFirstName.focus ? "var(--gray-600)" : "none"} />
            <input
              autoComplete="off"
              autoCorrect="off"
              onBlur={() => setFirstName({ ...stateFirstName, focus: false })}
              onFocus={() => setFirstName({ ...stateFirstName, focus: true })}
              onChange={(e) =>
                setDataInscription({
                  ...dataInscription,
                  firstName: e.target.value,
                })
              }
              placeholder={t("modals.nameUpdate.champs.firstname")}
              type="text"
              name="firstname"
            />
            {stateFirstName.focus == false &&
              dataInscription.firstName.length > 0 &&
              stateNaming.error && (
                <div className="detect-issue-input">
                  <div className="chevron"></div>
                  <span>{t("modals.nameUpdate.error.issueNameType")}</span>
                </div>
              )}
          </div>
        </div>
        <button
          type="button"
          disabled={!stateFirstName.good || !stateNaming.good}
          onClick={() => handleGoUpdateName()}
          style={{
            marginTop: 12,
            padding: 12,
            borderRadius: 8,
          }}
          className="confirm-btn | btn opt-btn"
        >
          <span style={{ fontWeight: 600 }}>
            {t("modals.nameUpdate.confirmTxt")}
          </span>
        </button>
      </form>
    </ContainerPersoModal>
  );
};
