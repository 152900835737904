import React from "react";
import { useCurrentScrollY } from "../../../services/useCurrentScrollY";
import "../../../styles/components/politiqueContent.css";
export default function ConditionsContentFr() {
  const { checkingPosY } = useCurrentScrollY(100);
  return (
    <section
      style={{ paddingBottom: 30 }}
      className="policy-section | flcolm max-container rlve"
    >
      <div className="content | flcolm">
        {checkingPosY && (
          <div className="title second | max-container">
            <label>Conditions de services</label>
          </div>
        )}

        <h1
          style={{
            fontWeight: "bold",
            marginBottom: "10px",
            textTransform: "uppercase",
          }}
        >
          CONDITIONS DE SERVICES
        </h1>
        <p>
          Les présentes Conditions de service pour les utilisateurs de
          Trouvechap en Côte d'Ivoire (les « Conditions ») sont un contrat
          juridique contraignant entre vous et Trouvechap qui régit votre droit
          d'utilisation des sites web, applications et autres offres de
          Trouvechap (collectivement, la « Plate-forme Trouvechap »). Lorsqu'ils
          sont utilisés dans ces conditions, les termes « Trouvechap », « nous
          », « nos » ou « notre » désignent l'entité Trouvechap avec laquelle
          vous concluez un contrat.
        </p>
        <p>
          La Plateforme Trouvechap offre un outil en ligne permettant aux
          utilisateurs (« Membres ») de publier, de proposer, de rechercher et
          de réserver des services.
        </p>
        <p>
          Nous appliquons d'autres Conditions et Politiques complémentaires à
          ces Conditions, comme notre Politique de confidentialité, qui décrit
          notre collecte et utilisation des données personnelles, et nos
          Conditions de paiement, qui régissent tous les services de paiement
          fournis aux membres par les entités de paiement Trouvechap
          (collectivement, « Paiements Trouvechap »).
        </p>
        <p>
          Si vous êtes Hôte, vous devez comprendre et respecter l'ensemble des
          lois, règlements, réglementations et contrats avec des tiers qui
          s'appliquent à vos Services Hôtes.
        </p>
        <p>
          Vous devez créer un compte pour accéder à de nombreuses
          fonctionnalités de la Plate-forme Trouvechap et les utiliser. Vous
          devez également veiller à ce que les informations de votre compte
          demeurent exactes. En tant que fournisseur de la Plate-forme
          Trouvechap, Trouvechap ne possède, ne contrôle, ne propose et ne gère
          ni les annonces, ni les Services Hôtes ni aucun service touristique.
          Trouvechap n'est pas partie aux contrats conclus directement entre les
          Hôtes et les Résidents, et Trouvechap n'est pas un agent immobilier,
          agence de voyage, ni assureur, pas non plus organisateur ou détaillant
          de voyages organisés. Trouvechap n'agit pas en qualité de mandataire
          d'un quelconque Membre.
        </p>
        <h2>a. Modalités de Contrat avec Trouvechap</h2>
        <p>
          Trouvechap peut conclure des accords contractuels avec des
          propriétaires d'hébergements souhaitant externaliser la gestion de
          leurs résidences. Ces contrats spécifiques sont élaborés pour répondre
          aux besoins particuliers des propriétaires, permettant ainsi une
          gestion personnalisée des propriétés. Dans le cas où des propriétaires
          souhaitent que Trouvechap gère leurs résidences en jouant le rôle
          d'hôtes, Trouvechap respectera les responsabilités inhérentes à ce
          rôle. Cette approche garantit une gestion fidèle aux attentes des
          propriétaires. Les Hôtes peuvent également être appelés "Hébergeurs".
        </p>
        <h2>b. Définitions</h2>
        <p>
          <h3>1. Hôte : </h3>Les Hôtes sur Trouvechap sont des individus ou
          entités proposant des hébergements et des services sur la plateforme.
          Ils peuvent être désignés de différentes manières, notamment :
        </p>
        <ul>
          <li>
            <h3>Propriétaires</h3>Ce sont les personnes physiques ou morales
            possédant les logements proposés sur Trouvechap.
          </li>
          <li>
            <h3>Gérants</h3>Certains Hôtes peuvent déléguer la gestion complète
            de leurs résidences à des gérants, chargés de superviser tous les
            aspects du logement selon les demandes des propriétaires.
          </li>
          <li>
            <h3>Démarcheurs</h3>Il s'agit de ceux qui trouvent des clients
            potentiels pour les logements, jouant un rôle clé dans la promotion
            des hébergements.
          </li>
          <li>
            <h3>Co-hôtes</h3>Référence à des collaborateurs ou partenaires
            impliqués dans la gestion ou la promotion des hébergements, en
            collaboration avec les Hôtes principaux.
          </li>
          <li>
            <h3>Hébergeurs</h3>Les Hôtes peuvent également être appelés
            "Hébergeurs", soulignant leur rôle dans la mise à disposition
            d'hébergements.
          </li>
        </ul>
        <p>
          <h3>2. Résidents, Clients ou Voyageurs : </h3>Les utilisateurs de
          Trouvechap qui recherchent, réservent ou utilisent des services
          d'hébergement. Ils peuvent être appelés indifféremment résidents,
          clients ou voyageurs.
        </p>
        <p>
          <h3>3. Types de Logements : </h3>Trouvechap propose différents types
          de logements, dont :
        </p>
        <ul>
          <li>
            <h3>Résidence Meublée Entière : </h3>Une habitation complète
            comprenant des espaces communs tels que salon, piscine, cuisine,
            offerte en location.
          </li>
          <li>
            <h3>Chambres : </h3>Des chambres individuelles au sein d'une
            résidence, proposées à la location.
          </li>
        </ul>
        <p>
          <h3>4. Types de Gestion : </h3>Les Hôtes peuvent adopter différents
          styles de gestion en fonction de leurs préférences, engendrant
          diverses responsabilités. Ces types de gestion incluent :
        </p>
        <ul>
          <li>
            <h3>Gestion Propriétaire : </h3>Les propriétaires gèrent directement
            leurs résidences et services.
          </li>
          <li>
            <h3>Gestion par un Gérant : </h3>Les gérants sont responsables de
            toutes les opérations liées au logement, agissant au nom des
            propriétaires.
          </li>
          <li>
            <h3>Démarchage : </h3>Les démarcheurs se concentrent sur la
            recherche de clients pour les hébergements, sans impliquer une
            gestion opérationnelle directe.
          </li>
        </ul>
        <h1>Conditions relatives aux voyageurs</h1>
        <h2>a. Notre Mission</h2>
        <p>
          Chez trouvechap, notre mission est de révolutionner le marché africain
          de l'hébergement en fournissant une plateforme pratique et sécurisée
          pour la réservation de propriétés résidentielles, en proposant des
          options de paiement mobiles locales en plus de méthodes de paiement
          traditionnelles et en assurant un service de qualité.
        </p>
        <h2>b. Recherche et Réservation sur TROUVECHAP</h2>
        <p>
          <h3>1. Recherche : </h3>Comment trouver des résidences ? Vous pouvez
          rechercher des services d'hébergement en utilisant des critères tels
          que le type de logement, la destination, les dates et le nombre
          d'invités, le type d’activités sociales, etc…
        </p>
        <p>
          Comment se font les propositions de résultats de recherches ? Les
          résultats de la recherche sont basés sur leur pertinence par rapport à
          vos filtres de sélection de recherche, les notes, la popularité, et
          plus encore.
        </p>
        <p>
          <h3>2. Réservation : </h3>Lorsque vous réservez, vous acceptez de
          payer tous les frais, y compris le prix, les frais de service de
          TROUVECHAP, les frais de transferts d’argent etc. Une fois confirmée,
          une "Réservation" est formée entre vous et l'hôte, avec des conditions
          spécifiées. En plus de ces Termes, vous devrez respecter et assumer la
          responsabilité de toutes les conditions liées à la réservation. Cela
          inclut, mais ne se limite pas, aux conditions d'annulation et à toutes
          les autres règles, normes, politiques ou exigences spécifiées dans
          l'Annonce ou lors du processus de paiement associés à la réservation.
          Il vous incombe de prendre connaissance et de comprendre ces règles,
          normes, politiques et exigences avant de procéder à la réservation.
        </p>
        <p>
          <h3>3. Réservations d'Hébergement : </h3>Une réservation d'Hébergement
          confère une licence restreinte pour accéder, occuper et utiliser le
          logement. L'Hôte conserve le droit de réintégrer le logement pendant
          votre séjour, dans la mesure où cela est raisonnablement nécessaire,
          autorisé par votre accord avec l'Hôte et conforme à la législation en
          vigueur. En cas de dépassement de l'heure de départ, l'Hôte est en
          droit de vous faire partir conformément à la loi, et peut imposer des
          sanctions raisonnables pour une prolongation indue du séjour. Il est
          impératif de se conformer à l'ensemble des politiques d'utilisation
          affichées sur l'annonce des logements de l'hôte.
        </p>
        <h2>
          c. Annulations, Problèmes de Voyage, Remboursements et Modifications
          de Réservation
        </h2>
        <p>
          <h3>1. Annulations et Problèmes de Voyage : </h3>Les remboursements
          dépendent de la politique d'annulation applicables à cette
          réservation. En cas d'événements imprévus, la Politique des
          Circonstances Atténuantes peut s'appliquer. En cas d'annulation de la
          part de l'Hôte ou si vous faites face à un problème lié à votre séjour
          (tel que défini dans notre Politique de remplacement d'hébergement et
          de remboursement), vous pourriez avoir droit à une assistance pour une
          nouvelle réservation ou à un remboursement partiel ou intégral
          conformément à la Politique de remplacement d'hébergement et de
          remboursement.
        </p>
        <h2>d. Modifications de Réservation</h2>
        <p>
          Les invités et les hôtes sont responsables des modifications de
          réservation et des coûts associés.
        </p>
        <h2>e. Vos Responsabilités</h2>
        <p>
          Vous êtes responsable de vos actions et des personnes que vous
          invitez, notamment en laissant l'hébergement dans l'état initial.
        </p>
        <h2>f. Assumption des Risques</h2>
        <p>
          Vous assumez les risques liés à l'utilisation de TROUVECHAP, y compris
          les risques inhérents aux activités proposées par les hôtes.
        </p>
        <p>
          Vous assumez la responsabilité de vos actions et omissions, ainsi que
          de celles de toute personne que vous invitez à vous accompagner (ou à
          qui vous accordez un accès) en ce qui concerne un Hébergement. Cela
          implique, par exemple :
        </p>
        <p>
          La responsabilité de maintenir un Hébergement (et les biens personnels
          associés) dans l'état initial à votre arrivée,
        </p>
        <p>
          L'obligation de régler tous les montants liés aux demandes
          d'indemnisation nécessaires pour couvrir les dommages causés par vous,
          vos compagnons de voyage, ou vos animaux de compagnie à un
          Hébergement, et Le respect de l'intégrité, du traitement respectueux
          envers autrui, et de la conformité aux lois en vigueur en tout temps.
          Si vous effectuez une réservation pour un Voyageur supplémentaire qui
          est mineur, ou si vous amenez un mineur dans un Service d'Hôte, vous
          devez avoir l'autorisation légale d'agir au nom du mineur, et vous
          êtes seul responsable de la surveillance de ce mineur.
        </p>
        <p>
          Il est important de noter que Trouvechap n'est pas tenu responsable si
          le voyageur ne prend pas ses responsabilités. La responsabilité
          incombe au voyageur, et tout manquement de sa part ne peut être imputé
          à Trouvechap.
        </p>
        <h1>Conditions relatives aux hôtes</h1>
        <h2>a. Héberger sur TROUVECHAP</h2>
        <p>
          <h3>Fonctionnalités de TROUVECHAP pour l’hôte</h3>
          En tant qu'hôte, TROUVECHAP vous offre la possibilité de partager
          votre logement avec la communauté des voyageurs et de gagner de
          l'argent. La création d'une Annonce est simplifiée au maximum. Vous
          avez le contrôle total sur la manière dont vous accueillez les
          Voyageurs, avec la liberté de définir votre propre prix, vos
          disponibilités et les règles spécifiques pour chaque Annonce.
        </p>
        <p>
          <h3>Contrat avec les voyageurs</h3>
          En acceptant une réservation, vous entrez en contrat direct avec le
          voyageur, responsable de fournir le service conformément à votre
          annonce. Lorsque vous approuvez une demande de réservation ou recevez
          une confirmation de réservation sur la plateforme Trouvechap, vous
          établissez un contrat direct avec le Voyageur. Cela implique que vous
          :
        </p>
        <ul>
          <li>
            êtes responsables de fournir le Service d'Hôte conformément aux
            conditions et au tarif spécifiés dans votre Annonce.
          </li>
          <li>
            êtes responsable de maintenir l'intégrité des annonces que vous
            publiez:
          </li>
        </ul>
        <p>En garantissant l'exactitude de l'emplacement de votre annonce. </p>
        <p>
          En associant des photos à vos annonces qui sont véridiques et
          représentent fidèlement la réalité de l'hébergement proposé.
        </p>
        <p>
          vous engagez également à régler les frais applicables tels que les
          frais de transfert d'argent et les frais de service Trouvechap pour
          chaque réservation. Les montants dus seront déduits de votre paiement,
          sauf accord contraire convenu entre vous et nous. Toutes les
          conditions ou modalités que vous incluez dans un accord supplémentaire
          avec les Voyageurs doivent être clairement divulguées dans la
          description de votre Annonce.
        </p>
        <h2>Relation Indépendante avec Trouvechap</h2>
        <p>
          <h3>1. Signification</h3> En tant qu'hôte sur Trouvechap, vous
          bénéficiez d'une indépendance significative dans la gestion de vos
          hébergements et services. Voici les principaux éléments qui
          caractérisent cette relation indépendante :
        </p>
        <p>
          <h3>1. Signification</h3> En tant qu'hôte sur Trouvechap, vous
          bénéficiez d'une indépendance significative dans la gestion de vos
          hébergements et services. Voici les principaux éléments qui
          caractérisent cette relation indépendante :
        </p>
        <p>
          Votre relation avec Trouvechap est définie comme celle d'une entité ou
          d'un individu indépendant. Vous n'êtes ni un employé, ni un agent, ni
          un co-entrepreneur, ni un partenaire de Trouvechap.
        </p>
        <p>
          Vous assumez la responsabilité de vos actions et omissions en ce qui
          concerne un Hébergement. Cela implique, par exemple :
        </p>
        <p>
          <h3>2. Contrôle sur les Services d'Hôte</h3>Vous conservez un contrôle
          complet sur la prestation de vos services d'hôte. Cela inclut la
          décision de fournir des services, les conditions auxquelles ils sont
          offerts, ainsi que les prix fixés.
        </p>
        <p>
          <h3>3. CResponsabilités envers les Autorisations Légales</h3>Il
          incombe aux hôtes de garantir que leurs résidences sont en conformité
          avec les autorisations légales en vigueur. Trouvechap ne peut être
          tenu responsable des conséquences liées à des infractions éventuelles.
        </p>
        <p>
          <h3>4. Responsabilités des Hôtes et des Voyageurs</h3>Les hôtes sont
          tenus de respecter leurs responsabilités et de veiller à ce que les
          voyageurs respectent également les leurs. Trouvechap n'est pas
          responsable des problèmes découlant des actions des voyageurs, et ces
          problèmes ne doivent pas être imputés à Trouvechap.
        </p>
        <p>
          Modalités de Contrat avec Trouvechap : Trouvechap peut conclure des
          accords contractuels avec des propriétaires d'hébergements souhaitant
          externaliser la gestion de leurs résidences. Ces contrats spécifiques
          sont élaborés pour répondre aux besoins particuliers des
          propriétaires, permettant ainsi une gestion personnalisée des
          propriétés. Dans le cas où des propriétaires souhaitent que Trouvechap
          gère leurs résidences en jouant le rôle d'hôtes, Trouvechap respectera
          les responsabilités inhérentes à ce rôle. Cette approche garantit une
          gestion fidèle aux attentes des propriétaires.
        </p>
        <p>
          Ces principes visent à établir une collaboration transparente tout en
          préservant l'indépendance des hôtes sur la plateforme Trouvechap.
        </p>
        <h2>e. Gestion de Votre Annonce</h2>
        <p>
          <h3>1. Création et Gestion de Votre Annonce</h3>Vous avez le contrôle
          sur votre annonce, y compris le prix, la disponibilité et les règles.
        </p>
        <p>
          La plateforme Trouvechap met à votre disposition des outils facilitant
          la configuration et la gestion aisée d'une Annonce. Votre Annonce doit
          comporter des informations exhaustives et précises sur votre Service
          d'Hôte, notamment votre tarif, d'éventuels frais supplémentaires ainsi
          que toutes les règles ou exigences applicables à vos Voyageurs ou à
          votre Annonce. Vous êtes responsable de vos actions et omissions,
          ainsi que de la mise à jour régulière et de l'exactitude des
          informations de votre annonce (y compris les dates de disponibilité
          sur votre calendrier) et de son contenu (tel que les photos) en tout
          temps. Vous pouvez gérer une seule Annonce par Hébergement, mais
          plusieurs Annonces sont autorisées pour une même résidence entière
          s'il propose plusieurs Hébergements.{" "}
        </p>
        <h2>f. Connaître Vos Obligations Légales</h2>
        <p>
          Vous devez comprendre et respecter les lois, règles et contrats
          applicables.
        </p>
        <p>
          Il est de votre responsabilité de comprendre et de respecter toutes
          les lois, règlements, réglementations et accords avec des tiers qui
          peuvent s'appliquer à votre Annonce ou à vos Services d'Hôte. Par
          exemple, certaines dispositions des contrats de bail ou des règlements
          de copropriété peuvent restreindre ou interdire la sous-location, les
          locations à court terme et les séjours prolongés. Des plans
          d'urbanisme locaux ou d'autres réglementations similaires peuvent
          limiter la location à court terme de propriétés résidentielles dans
          certaines villes. Certaines communautés locales, y compris des
          municipalités, peuvent exiger que les Hôtes s'enregistrent, obtiennent
          des permis ou des licences avant de fournir certains Services d'Hôte
          tels que des locations à court terme, des séjours prolongés, la
          préparation de nourriture, la vente d'alcool, des visites guidées ou
          l'utilisation de véhicules. Dans certains endroits, les Services
          d'Hôte que vous envisagez de proposer peuvent être interdits. Des
          juridictions locales peuvent exiger l'enregistrement des Voyageurs
          séjournant dans votre Hébergement. Certaines réglementations créent
          des droits de location pour les Voyageurs et imposent des obligations
          supplémentaires aux Hôtes. Par exemple, certaines lois régissent la
          location, le contrôle des loyers et l'expulsion pour les séjours
          prolongés. Il est essentiel de vérifier les règles nationales et
          locales applicables aux Services d'Hôte que vous envisagez d'offrir.
          Les informations fournies sur les exigences légales sont uniquement à
          titre informatif, et vous devez vérifier indépendamment vos
          obligations. Vous êtes également responsable du traitement et de
          l'utilisation des données personnelles des Voyageurs et d'autres
          individus conformément aux lois sur la protection de la vie privée et
          aux présentes Conditions, y compris nos Règles de confidentialité
          applicables aux Hôtes. En cas de doute sur l'application de la
          législation locale, il est recommandé de consulter un conseiller
          juridique tel qu'un avocat.
        </p>
        <h2>g. Classement dans les résultats de recherche</h2>
        <p>
          Le positionnement des Annonces dans les résultats de recherche sur la
          plateforme TROUVECHAP est influencé par divers facteurs, notamment :
        </p>
        <ul>
          <li>
            Paramètres de recherche des Voyageurs : Tels que le nombre de
            Voyageurs, la date et la durée du voyage, la fourchette de prix, les
            commodités, les activités sociales, type de logement, la destination
          </li>
          <li>
            Caractéristiques de l'Annonce : Comme le prix, la disponibilité du
            calendrier, le nombre et la qualité des images, les avis, le type de
            Service d'Hôte, le statut de l'Hôte, l'ancienneté de l'Annonce, la
            note moyenne auprès des Voyageurs.
          </li>
          <li>
            Expérience de réservation du Voyageur : Incluant l'historique des
            annulations et du recours à l'assistance utilisateur de l'Hôte,
            ainsi que la facilité de la réservation.
          </li>
          <li>
            Exigences de l'Hôte : Telles que le nombre de nuits minimum ou
            maximum, l'heure limite de réservation, types d’activités sociales
            autorisées, politiques d’intérieur, conditions d’annulation.
          </li>
          <li>
            Préférences du Voyageur : Comme les voyages précédents, les Annonces
            enregistrées, le lieu à partir duquel le Voyageur effectue sa
            recherche.
          </li>
          <p>
            Les résultats de recherche peuvent varier entre notre application
            mobile et notre site web. TROUVECHAP offre la possibilité aux Hôtes
            de promouvoir leurs Annonces dans les résultats de recherche
            moyennant des frais supplémentaires. Pour plus d'informations sur
            les critères de classement des Annonces, ainsi que sur la façon dont
            nous identifions le contenu promu, veuillez consulter notre
            Marketing et optimisation des recherches.
          </p>
        </ul>
        <h2>h. Vos obligations</h2>
        <p>
          Vous êtes tenu(e) responsable de vos propres actions et omissions,
          ainsi que de celles des personnes que vous autorisez à participer à la
          prestation de vos Services d'hôte. Cela inclut la responsabilité des
          tarifs que vous fixez, des règles que vous établissez, et des
          exigences spécifiées dans votre annonce. Vous devez clairement
          détailler tous les frais et éléments facturés dans la description de
          votre annonce. Il est important de noter que vous ne pouvez pas
          demander de frais supplémentaires en dehors de la plateforme
          TROUVECHAP, à divulguer leurs coordonnées, ou à entreprendre d'autres
          actions contraires à notre Politique de TROUVECHAP sur l'utilisation
          hors plateforme. Respecter ces directives contribue à maintenir
          l'intégrité de notre communauté et à garantir des expériences
          positives pour tous les utilisateurs.
        </p>
        <h2>i. Accueil de Voyageurs en tant qu'équipe ou organisation</h2>
        <p>
          Si vous collaborez en tant que membre d'une équipe, d'une entreprise
          ou d'une autre organisation pour offrir des Services d'hôte, l'entité
          dans son ensemble ainsi que chaque individu impliqué dans la
          prestation des Services d'hôte sont considérés comme des Hôtes et sont
          donc soumis aux présentes Conditions. Lorsque vous acceptez des
          conditions ou concluez des contrats, vous attestez et garantissez que
          vous avez l'autorisation nécessaire pour conclure des contrats au nom
          de votre équipe, entreprise ou toute autre organisation, et que chaque
          entité que vous utilisez est en conformité avec les lois du lieu où
          elle est établie. Si vous exercez des responsabilités additionnelles,
          vous certifiez et garantissez que vous êtes autorisé(e) à exercer ces
          responsabilités. Si vous sollicitez TROUVECHAP pour transférer une
          partie de votre paiement à d'autres Hôtes ou pour effectuer des
          paiements à un tiers, vous devez détenir l'autorisation nécessaire
          pour le faire. Vous assumez la responsabilité des montants des
          paiements et de l'exactitude des informations que vous fournissez.
          Cette approche garantit la transparence et l'intégrité des
          transactions au sein de notre communauté.
        </p>
        <h2>
          j. Annulations, Problèmes de Voyage et Modifications de Réservation{" "}
        </h2>
        <p>
          <h3>1. Annulations et Problèmes de Voyage</h3> Les remboursements
          dépendent de la politique d'annulation. Les hôtes ne doivent pas
          annuler sans raison valide.{" "}
        </p>
        <p>
          En général, si un Voyageur décide d'annuler une réservation, le
          montant qui vous est versé dépend des conditions d'annulation
          spécifiques associées à cette réservation. En tant qu'Hôte, il est
          impératif de ne pas annuler la réservation d'un Voyageur sans motif
          valable, conformément à notre politique d'annulation. Annuler une
          réservation sans raison valable peut entraîner des frais d'annulation
          et d'autres conséquences. Dans les cas où :
        </p>
        <p>
          Un Voyageur fait face à des problèmes de voyage (selon la Politique de
          remplacement d'hébergement et de remboursement),
        </p>
        <p>
          Un événement de force majeure survient, ou Une réservation est annulée
          conformément aux Conditions, le montant qui vous est versé est ajusté
          en fonction du remboursement que nous accordons au Voyageur, ainsi que
          de tous les frais raisonnables que nous engageons en raison de
          l'annulation. Si un Voyageur reçoit un remboursement après que vous
          ayez déjà été payé, ou si le montant du remboursement et des frais
          engagés par TROUVECHAP excède votre paiement, TROUVECHAP peut
          récupérer cette somme auprès de vous, notamment en déduisant le
          remboursement de vos futurs versements. Vous consentez à ce que la
          Politique de remplacement d'hébergement et de remboursement, la
          Politique sur les cas de force majeure de TROUVECHAP, ainsi que ces
          Conditions, prévalent sur vos propres conditions d'annulation, dans
          les situations où elles autorisent l'annulation d'une réservation ou
          l'émission de remboursements aux Voyageurs. Dans le cas où TROUVECHAP
          envisage de rembourser un Voyageur conformément à l'une de ces
          Politiques, tout paiement pour cette réservation peut être retenu
          jusqu'à ce qu'une décision de remboursement soit prise. Consultez
          chaque politique pour des détails sur ce qui est couvert et sur le
          montant de votre versement dans différentes situations. Vous avez la
          possibilité de contester une décision de TROUVECHAP en contactant
          notre service d'assistance utilisateur.
        </p>
        <p>
          <h3>2. Modifications de Réservation</h3>Hôtes et invités sont
          responsables des modifications de réservation et des coûts associés.
        </p>
        <h1>4. Conditions générales d’utilisation</h1>
        <h2>a. Politique de Commentaires de Trouvechap</h2>
        <h3>1. Introduction</h3>
        <p>
          Chez Trouvechap, la confiance entre les hôtes et les résidents est
          essentielle pour assurer un séjour exceptionnel. Dans cette optique,
          notre Politique de Commentaires vise à garantir l'authenticité, la
          fiabilité et l'utilité des retours au sein de notre communauté. Ces
          commentaires jouent un rôle crucial dans la prise de décision des
          résidents et des hôtes, tout en offrant des perspectives honnêtes pour
          favoriser l'amélioration continue.
        </p>
        <h3>2. Impartialité des Commentaires</h3>
        <p>
          Nous insistons sur l'impartialité des commentaires au sein de la
          communauté Trouvechap. Ainsi, aucun membre de notre communauté ne doit
          contraindre, intimider, extorquer, menacer, inciter ou manipuler
          autrui dans le but d'influencer un commentaire. Les échanges de
          compensations, tels que des avantages en échange d'avis positifs, ou
          les menaces de représailles pour des commentaires négatifs, sont
          strictement interdits.
        </p>
        <p>
          Les commentaires doivent être fournis dans le cadre d'une expérience
          réelle et ne peuvent être échangés contre des avantages matériels. Ils
          doivent refléter la réalité du séjour ou de l'expérience vécue et ne
          peuvent être utilisés de manière trompeuse. Tout recours à des
          commentaires biaisés ou inexacts dans le but de nuire à la concurrence
          est également proscrit.{" "}
        </p>
        <h3>3. Pertinence des Commentaires</h3>
        <p>
          Les commentaires doivent contenir des informations pertinentes sur
          l'expérience de l'auteur avec l'hôte, le résident, le séjour. Tout
          commentaire dépassant le cadre de la réalité du séjour peut être sujet
          à suppression. De plus, les commentaires doivent respecter la
          Politique de Trouvechap en matière de contenus, excluant tout contenu
          explicite, discriminatoire, nuisible, frauduleux, illégal ou en
          violation de nos principes communautaires.
        </p>
        <h3>4. Signalement des Commentaires</h3>
        <p>
          Tout membre de la communauté Trouvechap est encouragé à signaler tout
          commentaire en violation de cette politique. Nous prenons les
          signalements au sérieux et pouvons procéder à la suppression du
          commentaire ainsi que prendre des mesures supplémentaires, y compris
          la restriction, la suspension ou la suppression du compte Trouvechap
          associé, en cas de violation manifeste.
        </p>
        <p>
          Cette politique peut être appliquée différemment selon les lois
          locales en vigueur, et Trouvechap se réserve le droit de faire évoluer
          cette politique pour mieux servir sa communauté.
        </p>
        <h2>b. Politique de Contenu de Trouvechap</h2>
        <h3>1. Introduction</h3>
        <p>
          La publication de contenu sur Trouvechap engage chaque membre à
          respecter des règles fondamentales. Le terme "contenu" englobe les
          écrits, photographies, les images : (affiches ou tableaux accrochés à
          un mur par exemple), enregistrements audio, vidéos, et autres types de
          contenus, qu'il s'agisse des titres et descriptions d'annonces, pages
          de profil, commentaires publics et privés, et messages envoyés à
          Trouvechap, aux hôtes ou aux résidents.
        </p>
        <h3>2. Retrait de Contenu Non-Conforme</h3>
        <p>
          Trouvechap se réserve le droit de retirer tout contenu allant à
          l'encontre de cette politique, des Conditions de service, des valeurs
          de la communauté, ou de la Politique en matière de commentaires. Cette
          action peut être effectuée en totalité ou en partie, à notre seule
          discrétion. En cas de violations répétées ou graves, des mesures plus
          strictes, telles que la restriction, la suspension ou la suppression
          du compte Trouvechap associé, peuvent être appliquées.
        </p>
        <h3>3. Contenus Non Autorisés sur Trouvechap</h3>
        <p>
          Certains contenus ne sont pas autorisés sur Trouvechap, notamment tout
          contenu créé uniquement à des fins publicitaires ou commerciales, les
          prises de contact indésirables ou tout contenu nuisible ou illégal. La
          politique s'applique également aux titres d'annonces, aux pages
          d'annonce ou de profil, aux contenus des messages, et aux
          commentaires.
        </p>
        <p>
          Tout contenu créé uniquement à des fins publicitaires ou tout type de
          contenu commercial, y compris les logos, les liens ou les noms de
          sociétés
        </p>
        <p>
          Les contenus et prises de contact indésirables, ou tout contenu
          diffusé de façon répétée ou pouvant déranger
        </p>
        <p>
          Tout contenu qui approuve ou encourage les activités illégales ou
          nuisibles, ou qui est à caractère sexuel, violent, choquant, menaçant
          ou diffamatoire
        </p>
        <p>
          Tout contenu discriminatoire (consultez notre Politique de
          non-discrimination pour plus d'informations)
        </p>
        <p>
          Tout contenu s'avérant être une tentative d'usurpation d'identité, de
          compte ou de nom d'entreprise, y compris une tentative de se faire
          passer pour un représentant de Trouvechap
        </p>
        <p>
          Tout contenu illégal allant à l'encontre des droits d'un tiers ou
          d'une entité, y compris le droit de propriété intellectuelle et le
          droit au respect de la vie privée.
        </p>
        <p>
          Tout contenu présentant des informations personnelles ou
          confidentielles d'une autre personne, y compris les contenus
          suffisamment explicites pour identifier l'adresse d'un logement.
        </p>
        <h3>4. Infractions Spécifiques</h3>
        <p>
          Nous détaillons des infractions spécifiques liées à certains types de
          contenus :
        </p>
        <ul>
          <li>
            <h3>Titres d'annonces : </h3> exigence de pertinence des
            informations et interdiction des symboles ou émoticônes
            inappropriés.
          </li>
          <li>
            <h3>Pages d'annonce ou de profil : </h3> obligation d'exactitude et
            d'honnêteté des informations fournies, interdiction de contenu
            frauduleux ou trompeur.
          </li>
          <li>
            <h3>Messages : </h3> nécessité de messages respectueux, excluant
            tout contenu explicite, discriminatoire, nuisible, frauduleux,
            illégal ou contraire aux principes communautaires.
          </li>
          <li>
            <h3>Commentaires : </h3> respect de la Politique de Trouvechap en
            matière de commentaires, excluant tout contenu explicite,
            discriminatoire, nuisible, frauduleux, illégal ou contraire aux
            principes communautaires.
          </li>
        </ul>
        <h3>5. Contenu lié au Covid-19</h3>
        <p>
          Dans le contexte de la pandémie de Covid-19, nous avons une politique
          spécifique pour lutter contre la propagation d'informations erronées.
          Les conseils sont détaillés dans la Politique de Trouvechap en matière
          de contenus et coronavirus.
        </p>
        <h3>6. Contenus Sexuels et Inappropriés </h3>
        <p>
          Les contenus à caractère sexuel, explicite, ou inapproprié n'ont pas
          leur place sur Trouvechap. Cela inclut, sans s'y limiter, des images
          ou textes à caractère pornographique, des commentaires obscènes ou des
          propositions sexuelles non sollicitées. Les membres sont encouragés à
          signaler de tels contenus pour des mesures immédiates.
        </p>
        <h3>7. Commentaires Non Pertinents</h3>
        <p>
          Les commentaires qui ne sont pas pertinents pour une annonce
          d'hébergement, tels que des discussions personnelles, des spams ou des
          contenus sans rapport avec la réservation ou le séjour, sont également
          considérés comme non conformes à notre politique. Ces commentaires
          peuvent être retirés, et des sanctions peuvent être appliquées en cas
          de récidive. Les commentaires jugés non pertinents enfreignent
          également la Politique de Trouvechap en matière de commentaires.
        </p>
        <h3>8. Signalement des Contenus</h3>
        <p>
          Les membres sont invités à signaler tout contenu enfreignant cette
          politique, soit directement sur l'application, soit en contactant
          Trouvechap. Les signalements peuvent entraîner le retrait du contenu
          en question, avec des mesures supplémentaires en cas de violations
          graves ou répétées.
        </p>
        <h2>c. Frais de Service de la Plateforme Trouvechap</h2>
        <h3>1. Frais de Service pour les Voyageurs Trouvechap</h3>
        <p>
          Trouvechap applique des frais de service de 7 % du sous-total de la
          réservation pour chaque transaction effectuée sur notre plateforme.
          Ces frais incluent les coûts associés aux transactions financières,
          notamment les frais de paiement en ligne et de transfert d'argent.
        </p>
        <p>
          Pour les transactions effectuées en Côte d'Ivoire, les frais de
          CinetPay sont intégrés dans ces 7 % et varient en fonction du moyen de
          paiement utilisé :
        </p>
        <ul>
          <li>Moov Money Côte d'Ivoire : Minimum 3,5 %</li>
          <li>MTN Money Côte d'Ivoire : Minimum 3,5 %</li>
          <li>Orange Money Côte d'Ivoire : Minimum 3,5 %</li>
          <li>Visa - Mastercard : 3,5%</li>
        </ul>
        <p>
          Le surplus nécessaire pour atteindre le total de 7 % représente les
          frais de services de Trouvechap. Il est important de noter que les
          frais de CinetPay peuvent varier en fonction des pays dans lesquels
          ils opèrent. Pour des détails spécifiques sur les tarifs de CinetPay,
          veuillez consulter leur page de tarification{" "}
          <a href="https://cinetpay.com/pricing">ici</a>.
        </p>
        <p>
          De plus, les commissions de WAVE s'appliquent également aux voyageurs.
          Les commissions de WAVE s’élèvent à 1 % TTC du montant global relatif
          aux décaissements effectués par le Client (API Payout). Vous pouvez
          consulter les détails sur les tarifs de WAVE{" "}
          <a href="https://www.wave.com/fr/">ici</a>.
        </p>
        <h2>2. Frais de Service pour les Hôtes Trouvechap</h2>
        <p>
          Trouvechap applique des frais de service de 10 % sur le montant total
          de la réservation pour les hôtes. Ces frais englobent divers coûts
          liés aux services fournis par Trouvechap, y compris les frais de
          traitement des transactions.
        </p>
        <p>
          Pour les transactions effectuées en Côte d'Ivoire, les frais de
          CinetPay, qui dépendent du moyen de paiement, sont également inclus
          dans les 10 % :
        </p>
        <ul>
          <li>Moov Money Côte d'Ivoire : Minimum 3,5 %</li>
          <li>MTN Money Côte d'Ivoire : Minimum 3,5 %</li>
          <li>Orange Money Côte d'Ivoire : Minimum 3,5 %</li>
          <li>Visa - Mastercard : Jusqu'à 4%</li>
        </ul>
        <p>
          Le montant restant après déduction des frais CinetPay constitue les
          frais de services de Trouvechap auprès des hôtes. Il est important de
          noter que les frais de Visa et MasterCard peuvent varier et atteindre
          jusqu'à 4 %, ajoutés aux frais de service de Trouvechap. Pour des
          détails spécifiques sur les tarifs de CinetPay, veuillez consulter
          leur page de tarification{" "}
          <a href="https://cinetpay.com/pricing">ici</a>.
        </p>
        <p>
          De plus, les commissions de WAVE s'appliquent également aux voyageurs.
          Les commissions de WAVE s’élèvent à 1 % TTC du montant global relatif
          aux décaissements effectués par le Client (API Payout). Vous pouvez
          consulter les détails sur les tarifs de WAVE{" "}
          <a href="https://www.wave.com/fr/">ici</a>.
        </p>
        <h2>d. Règles de Base pour les Hôtes</h2>
        <p>
          Afin d'assurer des séjours confortables et sûrs aux résidents, les
          hôtes doivent respecter nos règles dans quatre domaines clés :
        </p>
        <h3>1. Engagement vis-à-vis des Réservations</h3>
        <p>
          Les hôtes doivent honorer les réservations acceptées et offrir une
          expérience d'arrivée fiable.
        </p>
        <ul>
          <li>
            <h3>Annulation : </h3> les hôtes ne doivent pas annuler les
            réservations confirmées, sauf pour des raisons valables
            indépendantes de leur volonté. Même dans ce cas, les hôtes doivent
            faire de leur mieux pour annuler une réservation dans les meilleurs
            délais et contacter Trouvechap s'ils ont besoin d'aide.
          </li>
          <li>
            <h3>Arrivée : </h3> les hôtes doivent donner à leurs résidents la
            possibilité d'accéder facilement au logement à leur arrivée, en
            fournissant des instructions claires, des codes d'accès mis à jour,
            etc.
          </li>
        </ul>
        <h3>2. Communication Rapide</h3>
        <p>
          Les hôtes doivent se montrer disponibles pour répondre aux demandes
          d'information des résidents ou réagir aux imprévus susceptibles de
          survenir avant et au cours d'un séjour.
        </p>
        <ul>
          <li>
            <h3>Délai de Réponse : </h3> Les hôtes doivent répondre rapidement
            aux demandes des voyageurs, idéalement dans l'heure qui suit la
            demande. Cela garantit une communication efficace pour résoudre
            rapidement toute préoccupation ou question soulevée par le voyageur.
          </li>
        </ul>
        <h3>3. Exactitude de l'Annonce</h3>
        <p>
          L'annonce doit décrire avec exactitude le logement, reflétant les
          caractéristiques et équipements disponibles de l'arrivée au départ.
        </p>
        <p>
          Détails de la Réservation : les hôtes ne peuvent modifier les éléments
          d'une réservation acceptée sans le consentement préalable du résident.
        </p>
        <p>
          Emplacement : les informations relatives à l'emplacement sur l'annonce
          doivent être précises, y compris les niveaux de nuisance sonore
          possibles.
        </p>
        <h3>4. Propreté du Logement</h3>
        <p>
          Tous les logements doivent être propres et exempts de risques pour la
          santé avant l'arrivée des résidents.
        </p>
        <p>
          Santé et Sécurité : les logements ne doivent présenter aucun risque
          pour la santé.
        </p>
        <p>
          Propreté : les hôtes doivent proposer des logements d'une propreté
          élevée.
        </p>
        <p>
          Roulement des Résidents : les hôtes doivent nettoyer le logement entre
          chaque séjour.
        </p>
        <p>
          Si un voyageur constate un manquement aux règles de base, il est
          encouragé à contacter Trouvechap en premier lieu. Le voyageur peut
          illustrer le problème avec des photos sur le support de messagerie ou
          sur le whatsapp de Trouvechap et contacter Trouvechap si une
          résolution directe avec l'hôte est difficile à obtenir. Laisser un
          commentaire honnête peut également contribuer à améliorer l'expérience
          pour les futurs voyageurs.
        </p>
        <h3>5. Manquement répété</h3>
        <p>
          En cas de manquements répétés ou graves aux règles de base, Trouvechap
          peut prendre des mesures, allant d'avertissements à la suspension ou
          suppression du compte hôte, en fonction de la nature du manquement.
          Les hôtes ont la possibilité de faire appel des décisions prises par
          Trouvechap.
        </p>
        <p>
          Nous nous engageons à faire respecter les règles de base. Lorsqu'un
          manquement aux règles de base est signalé, Trouvechap tente de
          contacter l'hôte pour comprendre ce qui s'est passé.
        </p>
        <p>
          Pour cela, nous pouvons être amenés à informer les hôtes de cette
          politique et à leur adresser des avertissements. Lorsque des
          manquements répétés ou graves des règles de base sont signalés, les
          hôtes ou leurs annonces peuvent être suspendus ou supprimés de la
          plateforme.
        </p>
        <p>
          Selon la nature du manquement, Trouvechap peut également prendre
          d'autres mesures, telles que l'annulation d'une réservation à venir ou
          en cours, le remboursement d'un résident à partir du paiement dû à
          l'hôte et/ou l'obligation pour les hôtes de fournir une preuve qu'ils
          ont résolu les problèmes avant de pouvoir recommencer à accueillir des
          résidents.
        </p>
        <p>
          En outre, un hôte qui annule une réservation confirmée ou qui est
          reconnu responsable d'une annulation peut faire l'objet d'autres
          sanctions en vertu de notre Politique en cas d'annulation par l'hôte.
          Trouvechap peut ne pas appliquer de frais d'annulation et, dans
          certains cas, éviter d'autres mesures si l'hôte annule en raison
          d'autres raisons valables indépendantes de sa volonté.
        </p>
        <h2>e. Politique de Résiliation, Suspension et Autres Mesures</h2>
        <h3>1. Application des Règles de Base</h3>
        <p>
          La présente section énonce les règles de base régissant la
          résiliation, la suspension et d'autres mesures prises par Trouvechap
          en cas de manquement à ses politiques. Ces mesures sont essentielles
          pour assurer le respect des normes et la sécurité au sein de la
          communauté Trouvechap.
        </p>
        <h3>2. Contact avec l'hôte en cas de manquement</h3>
        <p>
          En cas de violation des Conditions d'Utilisation de Trouvechap ou
          d'autres politiques pertinentes, Trouvechap peut contacter l'hôte pour
          discuter du manquement allégué. Ce contact peut prendre la forme d'une
          notification par e-mail, d'une alerte dans le tableau de bord de
          l'hôte ou d'une communication directe par d'autres moyens appropriés.
        </p>
        <h3>3. Avertissements et sanctions possibles</h3>
        <p>
          Après avoir identifié un manquement, Trouvechap peut émettre un
          avertissement à l'hôte concerné. Les avertissements servent à informer
          les utilisateurs de leur non-conformité et à offrir l'opportunité de
          remédier à la situation.
        </p>
        <p>
          En cas de manquement grave ou répété, Trouvechap se réserve le droit
          d'appliquer des sanctions. Ces sanctions peuvent inclure la suspension
          temporaire de l'hôte, la désactivation de certaines fonctionnalités,
          voire la résiliation définitive du compte. Les décisions prises
          dépendront de la gravité du manquement, de son impact sur la
          communauté et de la réactivité de l'hôte face aux avertissements
          précédents.
        </p>
        <p>Exemple de manquement :</p>
        <p>
          Selon la nature du manquement, Trouvechap peut également prendre
          d'autres mesures, telles que l'annulation d'une réservation à venir ou
          en cours, le remboursement d'un résident à partir du paiement dû à
          l'hôte et/ou l'obligation pour les hôtes de fournir une preuve qu'ils
          ont résolu les problèmes avant de pouvoir recommencer à accueillir des
          résidents.
        </p>
        <p>
          En outre, un hôte qui annule une réservation confirmée ou qui est
          reconnu responsable d'une annulation peut faire l'objet d'autres
          sanctions en vertu de notre Politique en cas d'annulation par l'hôte.
          Trouvechap peut ne pas appliquer de frais d'annulation et, dans
          certains cas, éviter d'autres mesures si l'hôte annule en raison
          d'autres raisons valables indépendantes de sa volonté.
        </p>
        <h3>4. Faire Appel des Décisions</h3>
        <h3>4.1 Procédure d'appel</h3>
        <p>
          Les hôtes ont le droit de faire appel des décisions prises par
          Trouvechap en cas de résiliation, suspension ou autres mesures
          disciplinaires. Pour faire appel, l'hôte doit soumettre une demande
          écrite à l'équipe de support de Trouvechap dans les quatorze (14)
          jours suivant la réception de la notification de la décision
          contestée.
        </p>
        <h3>4.2 Considérations lors de l'examen des appels</h3>
        <p>
          L'équipe de support examinera chaque appel de manière objective.
          L'hôte a l'opportunité de présenter des éléments de preuve ou des
          explications supplémentaires étayant sa position. Trouvechap s'engage
          à garantir un processus d'appel équitable et à prendre en compte tous
          les éléments pertinents avant de prendre une décision finale.
        </p>
        <p>
          La décision résultant de l'appel est définitive et obligatoire. Les
          sanctions décidées à l'issue de l'appel seront appliquées conformément
          aux politiques de Trouvechap.
        </p>
        <p>
          Cette politique vise à maintenir un environnement sûr, transparent et
          conforme aux normes éthiques au sein de la communauté Trouvechap. Les
          hôtes sont encouragés à respecter scrupuleusement les règles énoncées
          pour garantir une expérience positive pour tous les membres de la
          plateforme.
        </p>
        <h2>f. Politique de Modification des Présentes Conditions</h2>
        <h3>1. Introduction</h3>
        <p>
          La plateforme Trouvechap se réserve le droit d'ajuster périodiquement
          les présentes conditions pour refléter les évolutions législatives,
          les nouvelles fonctionnalités, ou tout autre changement considéré
          comme nécessaire. Nous vous encourageons à consulter régulièrement ces
          conditions pour rester informé des mises à jour.
        </p>
        <h3>2. Processus de Modification</h3>
        <p>
          Lorsque des ajustements sont apportés aux présentes modalités, nous
          mettons à jour les conditions révisées sur la plateforme Trouvechap
          tout en actualisant la date de la « dernière mise à jour » située en
          haut de la page des conditions.
        </p>
        <h3>3. Notification des Modifications</h3>
        <p>
          Toute modification significative vous sera communiquée par courrier
          électronique au moins trente (30) jours avant leur entrée en vigueur.
          Nous nous engageons à vous informer de manière claire et
          compréhensible sur la nature des changements apportés.
        </p>
        <h3>4. Acceptation des Conditions Révisées</h3>
        <p>
          Dans le cas où des modifications substantielles sont proposées, vous
          serez explicitement invité à accepter les conditions révisées. Ce
          message d'avis vous informera également de votre droit de refuser ces
          changements, du délai imparti pour le faire, et de votre possibilité
          de résilier le contrat à tout moment avant la date d'entrée en vigueur
          des modifications, conformément aux dispositions énoncées dans les
          présentes conditions.
        </p>
        <h3>5. Refus des Modifications</h3>
        <p>
          Vous avez le droit de refuser les modifications proposées. Si vous
          choisissez de refuser, cela peut entraîner des limitations sur
          l'utilisation de certaines fonctionnalités ou, dans certains cas, la
          résiliation du contrat.
        </p>
        <h3>6. Résiliation du Contrat</h3>
        <p>
          Pour les modifications (i) qui ne sont pas matérielles et n'affectent
          pas les dispositions essentielles, telles que celles définissant la
          nature et la portée des services fournis par Trouvechap, ou (ii) qui
          sont exigées par la loi, une décision de justice légalement
          contraignante, ou un ordre obligatoire émanant d'une autorité
          compétente, votre utilisation continue de la plateforme Trouvechap
          après la date d'entrée en vigueur des modifications sera interprétée
          comme une acceptation des conditions révisées. Nous nous efforçons de
          garantir la transparence et la clarté dans toutes les communications
          relatives aux modifications des conditions d'utilisation.
        </p>
        <h2>g. Politique Relative aux Cas de Force Majeure</h2>
        <h3>1. Informations Générales</h3>
        <p>
          La présente Politique Relative aux Cas de Force Majeure définit les
          procédures à suivre lorsque des événements imprévus et indépendants de
          la volonté des utilisateurs de Trouvechap surviennent, rendant
          impossible ou illégal le respect des engagements pris dans le cadre
          d'une réservation. Cette politique s'applique aux réservations
          d'hébergements sur la plateforme Trouvechap.
        </p>
        <h3>2. Gestion des Annulations</h3>
        <p>
          Lorsque cette politique autorise l'annulation, elle prévaut sur la
          politique d'annulation standard de la réservation. Les résidents
          concernés par un événement couvert par cette politique peuvent annuler
          leur réservation et recevoir, selon les circonstances, un
          remboursement monétaire, un crédit voyage ou une autre contrepartie.
          Les hôtes touchés par un événement couvert peuvent annuler sans subir
          de conséquences négatives, bien que leurs calendriers puissent être
          bloqués aux dates de la réservation annulée.
        </p>
        <h3>3. Événements Couverts</h3>
        <h3>
          3.1. Modifications des Exigences du Gouvernement en Matière de Voyages
        </h3>
        <p>
          Cette catégorie couvre les changements inattendus dans les obligations
          de visa ou de passeport imposées par un organisme gouvernemental,
          empêchant la présence physique sur le lieu de la réservation.
        </p>
        <h3>3.2. Situations d'Urgence et Épidémies</h3>
        <p>
          Les situations d'urgence locales ou nationales déclarées par les
          autorités, les épidémies, les pandémies et les urgences de santé
          publique sont incluses dans cette catégorie.
        </p>
        <h3>3.3. Restrictions Gouvernementales en Matière de Voyages</h3>
        <p>
          Les restrictions de voyage imposées par une autorité gouvernementale,
          empêchant de se rendre, de séjourner ou de revenir du lieu de séjour,
          sont couvertes par cette politique.
        </p>
        <h3>3.4. Opérations Militaires et Autres Conflits</h3>
        <p>
          Les actes de guerre, les conflits, les invasions, les guerres civiles,
          le terrorisme, les explosions, les bombardements, les rébellions, les
          émeutes, les insurrections, les troubles civils et les désordres
          civils sont inclus dans cette catégorie.
        </p>
        <h3>3.5. Catastrophes Naturelles</h3>
        <p>
          Les catastrophes naturelles, les cas de force majeure, les pannes de
          grande ampleur ayant une incidence sur des services essentiels, les
          éruptions volcaniques, les tsunamis et autres phénomènes
          météorologiques graves et anormaux entrent dans cette catégorie.
          Cependant, les conditions météorologiques ou naturelles suffisamment
          fréquentes pour être prévisibles à l'endroit concerné ne sont pas
          couvertes.
        </p>
        <h3>3.6. Événements Non Couverts</h3>
        <p>
          Tout événement non inclus dans les catégories susmentionnées n'est pas
          couvert par cette politique. Des exemples de situations non couvertes
          comprennent, mais sans s'y limiter, les maladies, les pathologies ou
          les blessures, les obligations légales telles que la participation à
          un jury, la comparution devant les tribunaux ou les obligations
          militaires, les avis aux résidents ou autres conseils gouvernementaux
          qui n'entraînent pas d'interdiction de voyager, ainsi que les
          annulations ou reprogrammations d'événements pour lesquels une
          réservation a été faite.
        </p>
        <h3>3.7. Étapes Suivantes</h3>
        <p>
          Si un événement relevant de cette politique se produit, les
          utilisateurs concernés doivent suivre les instructions d'annulation
          fournies par Trouvechap. Dans le cas où Trouvechap n'aurait pas émis
          de notification ou publié d'informations sur l'événement, les
          utilisateurs sont invités à contacter Trouvechap pour annuler leur
          réservation. Dans tous les cas, les utilisateurs doivent être prêts à
          fournir des documents justifiant l'impact de l'événement sur leur
          réservation.
        </p>
        <h3>3.8. Contact pour des Questions</h3>
        <p>
          Pour toute question relative à cette politique, les utilisateurs sont
          encouragés à contacter Trouvechap, qui se fera un plaisir de fournir
          des informations supplémentaires et de l'aide en cas de besoin.
        </p>
        <h2>h. Politique d'Exclusion de Responsabilité</h2>
        <h3>1. Garanties Limitées</h3>
        <p>
          Trouvechap ne garantit pas l'existence, la conduite, la performance,
          la sécurité, la qualité, la légalité ou l'adéquation de tout résident
          ou hôte utilisant la plateforme. Les termes tels que "vérifié" ou
          similaires indiquent uniquement qu'un résident ou Trouvechap a fait
          l'objet d'une procédure de vérification ou d'identification
          appropriée, sans impliquer aucune garantie supplémentaire.
        </p>
        <h3>2. Interruptions de Service</h3>
        <p>
          Trouvechap n'est pas responsable des pannes ou coupures d'Internet,
          des infrastructures de télécommunications ou de tout autre événement
          indépendant de son contrôle, pouvant entraîner des interruptions de
          l'accessibilité à la plateforme Trouvechap.
        </p>
        <h3>3. Exclusions de Responsabilité</h3>
        <p>
          Dans le cadre de la fourniture de ses services, Trouvechap se réserve
          le droit de limiter temporairement l'accessibilité de la plateforme ou
          de certaines de ses fonctionnalités. Cette limitation peut intervenir
          pour des raisons de capacités limitées, pour préserver la sécurité ou
          l'intégrité des serveurs de Trouvechap, ou pour effectuer des
          opérations de maintenance visant à assurer le bon fonctionnement ou à
          améliorer la plateforme.
        </p>
        <h3>4. Politique sur la Source des Fonds et l'Utilisation Licite</h3>
        <p>
          Chez Trouvechap, nous valorisons la transparence et la responsabilité
          dans toutes les transactions effectuées sur notre plateforme. Il est
          essentiel de souligner que Trouvechap n'est pas une institution
          financière, et par conséquent, nous ne vérifions pas directement la
          source des fonds des utilisateurs.
        </p>
        <h3>4.1. Responsabilité de l'Utilisateur</h3>
        <p>
          Les utilisateurs de Trouvechap, qu'il s'agisse de voyageurs ou
          d'hôtes, sont tenus de garantir que les fonds utilisés dans le cadre
          de leurs transactions sont légitimes et proviennent de sources licites
          conformément aux lois en vigueur.
        </p>
        <h3>4.2. Politiques d'Utilisation</h3>
        <p>
          Les utilisateurs doivent adhérer aux politiques d'utilisation de
          Trouvechap, lesquelles stipulent clairement que les fonds utilisés sur
          la plateforme doivent être légitimes et conformes aux réglementations
          financières applicables.
        </p>
        <h3>4.3. Transparence et Communication</h3>
        <p>
          Nous encourageons une communication transparente entre les
          utilisateurs. Il est de la responsabilité de chacun de s'assurer que
          les fonds utilisés sont légitimes. Les voyageurs et les hôtes sont
          invités à être honnêtes et transparents quant à l'origine de leurs
          fonds.
        </p>
        <h3>4.4. Collaboration avec des Partenaires Financiers</h3>
        <p>
          Bien que Trouvechap ne soit pas une banque, nous explorons des
          collaborations avec des partenaires financiers et des services de
          paiement tiers pour renforcer la sécurité des transactions et garantir
          la légitimité des fonds.
        </p>
        <h3>4.5. Support Juridique </h3>
        <p>
          Nous recommandons à tous les utilisateurs de consulter un
          professionnel du droit pour s'assurer que leurs activités sur
          Trouvechap sont conformes aux lois locales et internationales.
        </p>
        <p>
          <h3>
            Responsabilité Clarifiée : Trouvechap n'est pas tenu pour
            responsable si la Politique sur la Source des Fonds et l'Utilisation
            Licite n'est pas respectée par les voyageurs. Aucune responsabilité
            ne peut être imputée à Trouvechap dans de tels cas.
          </h3>
        </p>
        <p>
          La responsabilité de Trouvechap, de ses représentants légaux,
          administrateurs, employés ou autres agents est limitée par les
          dispositions légales applicables. Trouvechap peut être tenu
          responsable en cas de faute intentionnelle ou grave de sa part, de ses
          représentants légaux, administrateurs ou mandataires. En cas de
          manquements à des obligations contractuelles, la responsabilité est
          limitée aux dommages prévisibles typiques.
        </p>
        <p>
          Toute exclusion ou limitation de responsabilité s'applique également à
          la responsabilité personnelle des représentants légaux,
          administrateurs, employés et autres agents de Trouvechap.
        </p>
        <p>
          Cette politique d'exclusion de responsabilité vise à établir des
          garanties limitées, à définir les interruptions de service pouvant
          survenir et à préciser les exclusions de responsabilité liées à la
          prestation des services de Trouvechap.
        </p>
        <h2>i. Politique de Responsabilité</h2>
        <h3>1. Responsabilité en Cas de Faute</h3>
        <p>
          Trouvechap pourrait être tenu responsable conformément aux
          dispositions légales en vigueur en cas de faute intentionnelle et
          grave commise par Trouvechap, ainsi que par ses représentants légaux,
          administrateurs, ou mandataires.
        </p>
        <p>
          En situation de manquements aux obligations contractuelles perpétrés
          par Trouvechap, ses représentants légaux, administrateurs, ou
          mandataires, la responsabilité est limitée aux dommages prévisibles
          typiques susceptibles de survenir. Les obligations contractuelles se
          rapportent aux engagements de Trouvechap nécessaires à l'exécution
          correcte du contrat.
        </p>
        <p>
          Toute responsabilité supplémentaire de Trouvechap est exclue, à moins
          que les dispositions légales applicables ne stipulent le contraire.
        </p>
        <h3>2. Limitation de Responsabilité</h3>
        <p>
          Dans la mesure permise par la loi applicable, toute responsabilité de
          Trouvechap, de ses représentants légaux, administrateurs, employés et
          autres agents est limitée dans les cas où la responsabilité ne peut
          être exclue. Cette limitation s'applique également à la responsabilité
          personnelle des représentants légaux, administrateurs, employés et
          autres agents de Trouvechap.
        </p>
        <p>
          La présente politique vise à définir la responsabilité de Trouvechap
          en cas de faute, en accordant une attention particulière aux
          situations où la responsabilité peut être engagée et en établissant
          une limite quant à l'étendue de cette responsabilité.
        </p>
        <h2>j. Politique d'Indemnisation</h2>
        <h3>1. Champ d'Application</h3>
        <p>
          Dans la mesure maximale permise par la loi applicable, vous acceptez
          de libérer, défendre (au choix de Trouvechap), indemniser et tenir
          Trouvechap à l'abri de l'ensemble des réclamations, responsabilités,
          dommages, pertes et dépenses découlant de ou en relation avec :
        </p>
        <h3>2. Obligation d'Indemnisation</h3>
        <p>
          Votre violation de ces Conditions, Politiques ou Normes, votre
          utilisation inappropriée de la plateforme Trouvechap, votre
          interaction avec tout Membre, séjour dans un logement, mais sans s'y
          limiter, tout préjudice, perte ou dommage (compensatoire, direct,
          accessoire, immatériel ou autre) de toute sorte survenant en relation
          avec ou à la suite d'une Annonce, d'un séjour, d'une participation ou
          d'une utilisation de ce type, votre manquement, ou notre manquement à
          vos instructions, à déclarer, collecter ou remettre avec précision les
          taxes, ou votre violation de toute loi ou de tout règlement ou droit
          de tiers tels que les droits de propriété intellectuelle ou de
          confidentialité, entraînera votre obligation d'indemnisation. Cette
          obligation d'indemnisation s'applique uniquement dans la mesure où les
          réclamations, responsabilités, dommages, pertes et dépenses ont été
          effectivement causés par votre manquement fautif à une obligation
          contractuelle.
        </p>
        <p>
          La présente obligation d'indemnisation s'applique uniquement dans la
          mesure où les réclamations, responsabilités, dommages, pertes et
          dépenses ont été effectivement causés par votre manquement fautif à
          une obligation contractuelle.
        </p>
        <h3>3. Droit applicable et juridiction compétente</h3>
        <p>
          Les présentes Conditions sont régies et interprétées conformément au
          droit de la Côte d'Ivoire. Si vous agissez en tant que consommateur et
          que la législation impérative de protection des consommateurs de votre
          pays de résidence comporte des dispositions plus avantageuses pour
          vous, ces dispositions s'appliquent indépendamment du choix de la loi
          applicable en Côte d'Ivoire. En tant que consommateur, vous pouvez
          intenter toute action judiciaire relative aux présentes Conditions
          devant le tribunal compétent de votre lieu de résidence ou le tribunal
          compétent de la Côte d'Ivoire. Si Trouvechap souhaite faire valoir ses
          droits à votre encontre en tant que consommateur, cela ne pourra être
          fait que devant les tribunaux de la juridiction dont vous dépendez. Si
          vous agissez en tant que professionnel, vous consentez à vous
          soumettre à la compétence exclusive des tribunaux de la Côte d'Ivoire.
        </p>
        <h2>k. Divers</h2>
        <h3>1. Absence de renonciation </h3>
        <p>
          Le manquement de Trouvechap à faire appliquer tout droit ou toute
          disposition des présentes Conditions ne constituera pas une
          renonciation audit droit ou à ladite disposition sauf si cela est
          expressément reconnu et convenu par écrit. Sauf stipulation expresse
          contraire des présentes Conditions, l'exercice par l'une ou l'autre
          des parties d'un quelconque recours à sa disposition en vertu des
          présentes Conditions sera sans préjudice des autres recours dont elle
          dispose en vertu des présentes Conditions ou de la loi.
        </p>
        <h3>2. Cession </h3>
        <p>
          Vous n'êtes pas autorisé à céder, transférer ou déléguer ce contrat ou
          vos droits et obligations en vertu des présentes Conditions sans le
          consentement écrit préalable de Trouvechap. Trouvechap peut, à sa
          seule discrétion et sans restriction, céder, transférer ou déléguer le
          présent contrat ainsi que tout droit et obligation en vertu des
          présentes moyennant un préavis de 30 jours. Vous conservez le droit de
          résilier les Conditions à tout moment.
        </p>
        <h3>3. Préavis</h3>
        <p>
          À moins d'indication contraire, toutes les notifications ou autres
          communications autorisées ou requises par les présentes, destinées aux
          Membres, seront effectuées électroniquement et fournies par Trouvechap
          par e-mail, par notification sur la plateforme Trouvechap, par service
          de messagerie (y compris par SMS) ou tout autre moyen de contact que
          nous vous fournissons. Si une notification concerne une réservation
          dans la région de l'Afrique de l'Ouest, vous consentez et reconnaissez
          que ces notifications par voie électronique, plutôt qu'une déclaration
          écrite, satisfont aux obligations de Trouvechap conformément à la
          législation applicable.
        </p>
        <h3>4. Services de tiers</h3>
        <p>
          La plateforme Trouvechap peut inclure des liens vers des sites web,
          des applications, des services ou des ressources de tiers
          (collectivement appelés « Services de tiers ») soumis à des conditions
          et des pratiques de confidentialité distinctes. Trouvechap n'assume
          aucune responsabilité pour quelque aspect que ce soit de ces Services
          de tiers, et les liens vers ces Services de tiers ne constituent pas
          une approbation de leur part.
        </p>
        <h3>5. Conditions générales des API et partenaires</h3>
        <ul>
          <li>
            <h3>Exchange Rate API.</h3> L'utilisation de l'API Exchange Rate est
            soumise aux{" "}
            <a href="https://www.ideracorp.com/Legal/Terms-of-Use">
              Conditions d'utilisation d'IDERA Corporation
            </a>
            .
          </li>
          <li>
            <h3>Cinetpay API.</h3> L'utilisation de l'API Cinetpay est soumise
            aux{" "}
            <a href="https://cinetpay.com/legal/cgv">
              Conditions Générales de Vente de Cinetpay
            </a>
            .
          </li>
          <li>
            <h3>JustTranslate.</h3> Les conditions d'utilisation de
            JustTranslate ne sont pas disponibles
          </li>
          <li>
            <h3>Paypal Business.</h3> L'utilisation de Paypal est soumise aux
            <a href="https://www.paypal.com/fr/webapps/mpp/ua/legalhub-full?locale.x=fr_FR">
              Conditions Générales d'Utilisation de Paypal
            </a>
            .
          </li>
          <li>
            <h3>Wave Business API.</h3> L'utilisation de l'API Wave Business est
            soumise aux{" "}
            <a href="https://www.wave.com/fr/terms_and_conditions/">
              Conditions Générales de Wave
            </a>
            .
          </li>
          <li>
            <h3>Djamo.</h3> L'utilisation de Djamo est soumise aux{" "}
            <a href="https://www.djamo.com/ci/conditions-generales-et-tarifaires/">
              Conditions Générales et Tarifaires de Djamo
            </a>
            .
          </li>
          <li>
            <h3>Google Maps API.</h3>L'utilisation de l'API Google Maps est
            soumise aux{" "}
            <a href="https://cloud.google.com/maps-platform/terms">
              Conditions Générales de Google
            </a>
            .
          </li>
          <li>
            <h3>InfoBip.</h3>L'utilisation de InfoBip est soumise aux{" "}
            <a href="https://www.infobip.com/policies/service-terms-conditions">
              Conditions Générales de InfoBip
            </a>
            .
          </li>
          <li>
            <h3>Facebook API.</h3>L'utilisation de l'API Facebook est soumise
            aux{" "}
            <a href="https://www.facebook.com/legal/terms">
              Conditions Générales de Facebook
            </a>
            .
          </li>
        </ul>
        <p>
          Veuillez consulter les conditions générales d'utilisation spécifiques
          à chaque service pour comprendre comment elles peuvent affecter votre
          utilisation de Trouvechap.
        </p>
        <p>
          En utilisant la plateforme Trouvechap via notre site web ou en
          téléchargeant notre application à partir de Google Play Store, ou
          d'autres plateformes, vous acceptez également les conditions
          d'utilisation de nos partenaires et fournisseurs y compris d’API.
          Veuillez consulter les conditions générales d'utilisation spécifiques
          à chaque service pour comprendre comment elles peuvent affecter votre
          utilisation de Trouvechap.
        </p>
        <h3>6. Contenu de la plateforme Trouvechap</h3>
        <p>
          Le Contenu fourni par la plateforme Trouvechap peut être protégé par
          des droits d'auteur, des marques déposées et/ou d'autres lois de la
          Côte d'Ivoire et d'autres pays. Vous reconnaissez que tous les droits
          de propriété intellectuelle relatifs à ce contenu sont la propriété
          exclusive de Trouvechap et/ou de ses concédants de licence. Vous
          acceptez de ne pas supprimer, altérer ou dissimuler tout droit
          d'auteur, marque déposée, marque de service ou autre notification de
          droits de propriété. Vous n'êtes pas autorisé à utiliser, copier,
          adapter, modifier, créer des œuvres dérivées, distribuer, concéder une
          licence, vendre, transférer, afficher publiquement, exécuter
          publiquement, transmettre, diffuser ou exploiter de quelque manière
          que ce soit tout Contenu accessible par la plateforme Trouvechap, sauf
          si vous êtes le propriétaire légal de ce Contenu ou si cela est
          expressément autorisé par les présentes Conditions. Sous réserve de
          votre respect de ces Conditions, Trouvechap vous accorde une licence
          limitée, non exclusive, non cessible, révocable et ne pouvant faire
          l'objet d'une sous-licence pour (i) télécharger et utiliser
          l'application sur votre ou vos appareils personnels, et (ii) accéder
          au Contenu mis à votre disposition sur ou via la plateforme
          Trouvechap, uniquement à des fins personnelles et non commerciales.
        </p>
        <h3>7. Force majeure</h3>
        <p>
          Trouvechap décline toute responsabilité en cas de retard ou de défaut
          d'exécution résultant de circonstances échappant à son contrôle
          raisonnable, notamment, sans s'y limiter, en cas de guerre, de
          terrorisme, d'émeutes, d'embargos, d'actions des autorités civiles ou
          militaires, d'incendies, d'inondations, d'accidents, d'épidémies ou de
          maladies, de grèves, ou de pénuries de moyens de transport, de
          carburant, d'énergie, de main-d'œuvre ou de matériaux.
        </p>
        <h3>8. E-mails et SMS</h3>
        <p>
          Vous serez destinataire de nos communications via l'adresse e-mail ou
          d'autres coordonnées que vous avez fournies lors de la création de
          votre compte Trouvechap. Votre participation à des programmes
          impliquant l'envoi d'e-mails n'aura pas d'impact sur la fréquence de
          ces communications, mais il est à noter que vous pourriez recevoir des
          e-mails supplémentaires spécifiques aux programmes auxquels vous vous
          êtes abonnés. Des e-mails promotionnels pourraient également vous être
          envoyés, sans frais associés, bien que des tarifs de données de tiers
          puissent s'appliquer. Vous avez la possibilité de gérer les e-mails
          promotionnels que vous recevez en ajustant les préférences de
          notification dans les paramètres de votre compte. Veuillez noter que
          la désactivation de certains paramètres de communication ou l'absence
          de compte Trouvechap pourrait vous priver de certaines promotions.
          L'acceptation de recevoir des SMS de notre part vous soumettra à nos
          Conditions relatives aux SMS.
        </p>
        <h3>9. Nous contacter</h3>
        <p>
          Pour toute question concernant les présentes Conditions, envoyez-nous
          un e-mail à l'adresse suivante :{" "}
          <a href="mailto:contact@trouvechap.com">contact@trouvechap.com</a>
        </p>
        <h3>10. Accès aux données</h3>
        <p>
          Les Utilisateurs professionnels ont le droit d'accéder aux données
          personnelles et aux autres informations présentes dans leur compte
          Trouvechap et leur tableau de bord Hôte. Ces données sont fournies par
          l'Utilisateur professionnel, ses Voyageurs, ou générées par
          l'utilisation de la plateforme Trouvechap. Elles sont essentielles à
          la prestation des Services d'hôte et permettent d'obtenir des
          informations agrégées sur les recherches, les réservations, ainsi que
          les performances des Annonces. Pour connaître en détail les catégories
          de données personnelles et d'autres types de données collectées, leur
          utilisation, traitement, divulgation, conservation, et pour exercer
          vos droits en matière de protection des données, veuillez consulter
          notre <a href="/politique">Politique de confidentialité</a> .
        </p>
      </div>
    </section>
  );
}
