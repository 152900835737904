import React, { useEffect } from "react";
import Footer from "../components/biggest/Footer";
import NotificationContent from "../components/biggest/NotificationContent";
import { requestEndpoint } from "../data/requestEndpoint";
import { useGetDelayRequest } from "../services/useFetchAction";
import { smoothScrollUp } from "../services/utils";
import { useStorePageManager } from "../store/PageManage";
export default function NotificationPage() {
  const handleUpdateStorePage = useStorePageManager(
    (state) => state.handleUpdateStorePage
  );
  useEffect(() => {
    handleUpdateStorePage(false);
  }, []);

  const [dataNotifs, loadingNotif, errorNotif, fetchDataNotif] =
    useGetDelayRequest({
      route: requestEndpoint.notifications,
      delay: 100,
      log: false,
    });
  useEffect(() => {
    fetchDataNotif();
  }, []);
  useEffect(() => {
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);
  return (
    <>
      {/* <Header /> */}
      <main>
        <NotificationContent
          notifications={dataNotifs}
          loading={loadingNotif}
          error={errorNotif}
        />
        <Footer />
      </main>
    </>
  );
}
