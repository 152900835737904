import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FavorisContent from "../components/biggest/FavorisContent";
import Footer from "../components/biggest/Footer";
import { requestEndpoint } from "../data/requestEndpoint";
import { NotificationService } from "../services/Notification.service";
import { useGetData } from "../services/useFetchAction";
import { smoothScrollUp } from "../services/utils";
import { useStorePageManager } from "../store/PageManage";
import { useStoreUserManager } from "../store/UserManage";
export default function FavorisPage() {
  // CheckingConnectedUser();
  const userData = useStoreUserManager((state) => state.user);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (!userData) return;
    if (userData.connected == false) {
      navigate("/");
      NotificationService.addToQueue(
        1,
        t("favorisPage.notification.youNeedConnectTxt")
      );
    }
  }, [userData]);

  const handleUpdateStorePage = useStorePageManager(
    (state) => state.handleUpdateStorePage
  );
  const handleUpdateFavorisUser = useStoreUserManager(
    (state) => state.handleUpdateFavorisUser
  );
  useEffect(() => {
    handleUpdateStorePage(false);
  }, []);

  const [data, loading, error] = useGetData({
    route: requestEndpoint.favoris,
    testing: false,
    loadingTime: 0,
    log: false,
  });
  useEffect(() => {
    if (loading) return;
    // console.log(data);
    if (!data) return;
    let newFavoris = [];
    // console.log(data);
    data.map((fav) => {
      if (fav.residence) newFavoris.push(fav.residence.id);
    });
    handleUpdateFavorisUser(newFavoris);
  }, [data, loading]);
  useEffect(() => {
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);
  return (
    <>
      {/* <Header /> */}
      <main>
        <FavorisContent favoris={data} loading={loading} error={error} />
        <Footer />
      </main>
    </>
  );
}
