import { Autocomplete, GoogleMap, Marker } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchLg } from "../../images/icons/JsxIcons";
import homeIcon from "../../images/icons/home-icon.png";
import { NotificationService } from "../../services/Notification.service";
import { useGetDelayRequest } from "../../services/useFetchAction";
import "../../styles/components/addResidencePart/step4.css";

import axios from "axios";
import { requestEndpoint } from "../../data/requestEndpoint";
import { getLatLngFromAddress } from "../../services/utils";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
const currentStep = 4;
export default function Step4Location({
  step,
  handleBlockBtn,
  longLat,
  setLongLat,
  location,
  setLocation,
  setMarkerPosition,
  markerPosition,
  currentCountrySelected,
  currentCity,
  currentWeighborhood,
}) {
  const { t } = useTranslation();
  const mapRef = useRef(null);
  const [isFocused, setIsfocused] = useState(false);
  const abortController = new AbortController();
  const cancelToken = useRef(null);
  const { isLoaded } = true;
  const [map, setMap] = useState(/** @type google.maps.GoogleMap */ (null));

  // console.log(markerPosition);
  const [dataLocation, loadinglocation, error, fetchLocation] =
    useGetDelayRequest({
      delay: 700,
    });
  const [dataLocationShow, setDataLocationShow] = useState([]);
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(!markerPosition);
  }, [step, location, markerPosition]);

  const handleChangeLocation = (e) => {
    setLocation(e.target.value);
    let value = e.target.value;
    value = value.replace(/[^\w\s]/gi, "");
    setDataLocationShow([]);
    if (value.trim().length <= 3) return;
    const newPRoute =
      requestEndpoint.getLocationResidence + "?location=" + value;
    // fetchLocation(null, newPRoute);
  };
  //for input
  const [oldValue, setOldValue] = useState("");

  const handleSearchPlace = (valueSend = null, putMarker = true) => {
    let newValue = valueSend ?? refInput.current.value;
    if (valueSend) {
      setLocation(valueSend);
      if (refInput.current) refInput.current.value = newValue;
    }
    let value = newValue;
    value = value.trim();
    if (value !== "" && value !== oldValue) {
      setOldValue(value);
      getLatLngFromAddress(value)
        .then(({ lat, lng }) => {
          if (!lat || !lng) return;
          const latLng = { lat, lng };
          setUserLocation(latLng);

          // setLongLat(latLng);
          if (putMarker) setMarkerPosition(latLng);
          else setLongLat(latLng);
          if (map) {
            map.panTo(latLng);
          }
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des coordonnées:",
            error
          );
        });
    }
  };
  useEffect(() => {
    useStoreShareFunction
      .getState()
      .handleUpdateSearchAndPanToPoint(handleSearchPlace);
  }, [handleSearchPlace]);
  const [directApply, setDirectApply] = useState(false);
  useEffect(() => {
    if (loadinglocation) return;
    if (!dataLocation) return;
    if (dataLocation.data.predictions) {
      let newDatas = [];
      dataLocation.data.predictions.map((search) => {
        newDatas.push({
          name: search.description,
          longlat: null,
        });
      });
      if (directApply) {
        if (newDatas.length > 0) handleSelectChange(newDatas[0]);
      } else setDataLocationShow(newDatas);
    }
  }, [loadinglocation, dataLocation]);

  const mapOptions = {
    mapTypeId: "terrain",
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    disableDefaultUI: true,
  };
  const [localisationProcessing, setLocationProcessing] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const getUserLocation = () => {
    //demande la permission d'accéder à la position de l'utilisateur

    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        getGeolocation();
      } else if (result.state === "prompt") {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ lat: latitude, lng: longitude });
            setMarkerPosition({ lat: latitude, lng: longitude });
            // setLongLat({ lat: latitude, lng: longitude });
            if (map) {
              map.panTo({ lat: latitude, lng: longitude });
            }
          },
          (error) => {
            console.error("Erreur de géolocalisation :", error.message);
          }
        );
      } else if (result.state === "denied") {
        NotificationService.addToQueue(
          1,
          t("addResidence.step4location.notifcation.locationError")
        );
        NotificationService.addToQueue(
          1,
          t("addResidence.step4location.notifcation.locationError2")
        );
      }
      result.onchange = () => {
        // console.log(result.state);
      };
    });
  };
  const getGeolocation = () => {
    setLocationProcessing(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
        setMarkerPosition({ lat: latitude, lng: longitude });
        setLongLat({ lat: latitude, lng: longitude });
        if (map) {
          map.panTo({ lat: latitude, lng: longitude });
        }
        setLocationProcessing(false);
        NotificationService.addToQueue(
          0,
          t("addResidence.step4location.notifcation.locationDone")
        );
        handleActiveGoodLocate(latitude + "," + longitude);
      },
      (error) => {
        console.error("Erreur de géolocalisation :", error.message);
        setLocationProcessing(false);
      }
    );
  };
  useEffect(() => {
    return undefined;
  }, [step, map]);

  const refInput = useRef();
  const handleSelectChange = (value) => {
    if (currentStep < step) return;
    setIsfocused(false);
    refInput.current.value = value.name;
    setLocation(value.name);
    setShowSearch(false);
    if (value.longlat == null) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${value.name}&key=${process.env.REACT_APP_GOOGLE_KEY}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.results[0]) {
            if (!directApply)
              setMarkerPosition(res.data.results[0].geometry.location);
            setLongLat(res.data.results[0].geometry.location);
            if (map) {
              map.panTo(res.data.results[0].geometry.location);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLongLat(value.longlat);
      if (map) map.panTo(value.longlat);
      setMarkerPosition(value.longlat);
    }
    // mapRef.current.panTo(value.longlat);
  };
  const [showSearch, setShowSearch] = useState(false);

  const handlePutMarker = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = axios.CancelToken.source();

    setMarkerPosition({ lat, lng });
    setInfoWindowOpen(true);
    handleActiveGoodLocate(lat + "," + lng);
  };

  const handleActiveGoodLocate = (latLng) => {
    const url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      latLng +
      "&result_type=route&key=" +
      process.env.REACT_APP_GOOGLE_KEY;

    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.results[0]) {
          setLocation(
            res.results[0].formatted_address.replace("Route sans nom, ", "")
          );
          refInput.current.value = res.results[0].formatted_address.replace(
            "Route sans nom, ",
            ""
          );
          if (map)
            map.panTo({
              lat: res.results[0].geometry.location.lat,
              lng: res.results[0].geometry.location.lng,
            });
        }
      });
  };

  useEffect(() => {
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel(
          "Requête annulée car le composant est démonté."
        );
      }
    };
  }, []);

  const handleMarkerDragEnd = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setMarkerPosition({ lat, lng });
    handleActiveGoodLocate(lat + "," + lng);
    // setLongLat({ lat, lng });
  };

  const selectRefLocation = useRef(null);
  const selectRefLocationBar = useRef(null);

  const handleClickOutside = (event) => {
    if (
      selectRefLocation.current &&
      selectRefLocationBar.current &&
      (!selectRefLocation.current.contains(event.target) ||
        !selectRefLocationBar.current.contains(event.target))
    ) {
      setShowSearch(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [waitingToAddMarker, setWaitingToAddMarker] = useState(false);
  useEffect(() => {
    // console.log(window);
    let timer = setTimeout(() => {
      if (window.google) {
        // console.log("google loaded");
        setWaitingToAddMarker(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [window]);
  const [openModalGoogleAddress, setOpenModalGoogleAddress] = useState(false);
  const [valueGoogleAddress, setValueGoogleAddress] = useState("");
  const [typeGoogleAddress, setTypeGoogleAddress] = useState(0);
  const [canConfirmGoogleAddress, setCanConfirmGoogleAddress] = useState(false);

  const checkIsValueGoogleAddress = () => {
    let isGood = false;
    if (valueGoogleAddress.trim().length > 0) {
      //on verifie si l'address contient bien une , et une longitude et latitude, on verifie aussi que la longitude et latitude sont bien des nombres/float on retire les () et les espaces avant et apres
      let value = valueGoogleAddress
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .split(",");
      if (value.length == 2) {
        if (!isNaN(value[0]) && !isNaN(value[1])) {
          setTypeGoogleAddress(1);
          return true;
        }
      }

      //on verifie si l'addresse est du type 9X6H+2GV Abidjan ou y ressemble
      let value2 = valueGoogleAddress.split("q=");
      if (value2.length >= 2) {
        if (valueGoogleAddress.length > 5) {
          setTypeGoogleAddress(2);
          return true;
        }
      }
    }
    setTypeGoogleAddress(0);
    return isGood;
  };

  useEffect(() => {
    checkIsValueGoogleAddress();
  }, [valueGoogleAddress]);

  const handleConfirmGoogleAddress = async () => {
    if (!checkIsValueGoogleAddress()) return;
    let latitude;
    let longitude;
    if (typeGoogleAddress == 1) {
      let value = valueGoogleAddress
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .split(",");
      latitude = value[0];
      longitude = value[1];
    } else if (typeGoogleAddress == 2) {
      let value = valueGoogleAddress.split("q=");
      value = value[value.length - 1].split(",");
      latitude = value[0];
      longitude = value[1];
    }
    //convertir en float longitude et latitude
    latitude = parseFloat(latitude);
    longitude = parseFloat(longitude);
    setUserLocation({ lat: latitude, lng: longitude });
    setMarkerPosition({ lat: latitude, lng: longitude });
    setLongLat({ lat: latitude, lng: longitude });
    handleActiveGoodLocate(latitude + "," + longitude);
    if (map) {
      map.panTo({ lat: latitude, lng: longitude });
    }
    setOpenModalGoogleAddress(false);
  };

  const checkIfBlock = () => {
    if (currentStep == step) {
      if (markerPosition) {
        return false;
      }
      return true;
    }
    return false;
  };
  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && !checkIfBlock()) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [checkIfBlock]);

  if (!waitingToAddMarker) return <></>;
  return (
    <>
      <section
        className={`step-1-annonce step-4 ${
          currentStep == step ? "visible" : ""
        } 
      ${currentStep < step ? "go-top" : ""} ${
          currentStep > step ? "go-bottom" : ""
        } add-resi add-resi-input input-resi | relve`}
      >
        <div className="contains |  max-container flrow liner">
          <div className="left-part-add-resi | flcolm jcenter">
            <div className="content | flcolm jcenter">
              <label>{t("addResidence.step4location.label")}</label>
              <span>{t("addResidence.step4location.description")}</span>
              <div className="flcolm maxwidth addgap8">
                {localisationProcessing ? (
                  <button>
                    <span
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {t("addResidence.step4location.locationLoading")}
                    </span>
                  </button>
                ) : (
                  <button onClick={getUserLocation} className="btn opt-btn">
                    <span
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {t("addResidence.step4location.locationBtnTxt")}
                    </span>
                  </button>
                )}
                <button
                  onClick={() => setOpenModalGoogleAddress(true)}
                  className="btn pry-btn"
                >
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {t("addResidence.step4location.useGoogleMapTxt")}
                  </span>
                </button>

                <div ref={selectRefLocation} className="input-location | rlve">
                  <SearchLg
                    color={
                      location.length > 0
                        ? "var(--shade-black)"
                        : "var(--gray-400)"
                    }
                  />

                  <Autocomplete
                    options={
                      currentCountrySelected
                        ? {
                            types: ["establishment"],
                            fields: ["address_components", "name"],
                            componentRestrictions: {
                              country:
                                currentCountrySelected?.code?.toLowerCase(),
                            },
                          }
                        : {
                            types: ["establishment"],
                            fields: ["address_components", "name"],
                          }
                    }
                  >
                    <input
                      ref={refInput}
                      placeholder={t(
                        "addResidence.step4location.champs.location"
                      )}
                      onChange={handleChangeLocation}
                      name="location"
                      type="text"
                      autoComplete="on"
                      autoCorrect="none"
                      onFocus={() => setShowSearch(true)}
                      onBlur={() => {
                        handleSearchPlace();
                        setIsfocused(false);
                      }}
                    />
                  </Autocomplete>
                  {/* <Searchbar
                thumbLeft={locationPosition}
                loading={loadinglocation}
                open={true}
                data={dataLocationShow}
                className="searchbar-location"
                handleFunctionLocation={handleSelectChange}
                refMine={selectRefLocationBar}
              /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="right-part-add-resi | flcolm jcenter liner">
            <div className="contains-input | flcolm jcenter liner">
              <GoogleMap
                mapContainerClassName="map-google"
                center={longLat}
                options={mapOptions}
                onClick={handlePutMarker}
                zoom={16}
                onLoad={(map) => setMap(map)}
              >
                {markerPosition && (
                  <Marker
                    position={markerPosition}
                    title={t("posResidenceTxt")}
                    animation={window.google.maps.Animation.DROP}
                    onClick={() => setInfoWindowOpen(!infoWindowOpen)}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                    options={{
                      icon: {
                        url: homeIcon,
                        scaledSize: new window.google.maps.Size(100, 100),
                        anchor: new window.google.maps.Point(50, 50),
                      },
                    }}
                  ></Marker>
                )}
              </GoogleMap>
            </div>
          </div>
        </div>
      </section>
      <ContainerPersoModal
        toggle={setOpenModalGoogleAddress}
        isOpen={openModalGoogleAddress}
        title={t("addResidence.step4location.googleMapTxt")}
        baseTop={true}
        fullBlock={true}
        className="modal-google-address"
      >
        <div
          style={{
            width: 300,
          }}
          className="flcolm addgap8"
        >
          <div className="flcolm">
            <span
              style={{
                fontSize: 14,
                lineHeight: "20px",
              }}
              className="p-just -l"
            >
              {t("addResidence.step4location.copyTxt")}
            </span>
            <span
              style={{
                fontSize: 14,
                lineHeight: "20px",
              }}
              className="p-just -l"
            >
              {t("addResidence.step4location.exampleTxt")}
            </span>
            <span
              style={{
                fontSize: 14,
                lineHeight: "20px",
              }}
              className="p-just -l"
            >
              {t("addResidence.step4location.exampleTxt2")}
            </span>
          </div>
          <input
            defaultValue={valueGoogleAddress}
            onChange={(e) => setValueGoogleAddress(e.target.value)}
            style={{
              borderRadius: 8,
              padding: "12px 8px",
              border: "1px solid var(--gray-400)",
            }}
            type="text"
            name="google-address"
          />
          <button
            style={{
              borderRadius: 8,
            }}
            onClick={handleConfirmGoogleAddress}
            disabled={typeGoogleAddress == 0}
            className="btn pry-btn"
          >
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {t("addResidence.step4location.confirmTxt")}
            </span>
          </button>
        </div>
      </ContainerPersoModal>
    </>
  );
}
