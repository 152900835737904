import React, { useEffect, useState } from "react";
import "../../styles/components/profilContent.css";

import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../data/requestEndpoint";
import { Check, XClose } from "../../images/icons/JsxIcons";
import { NotificationService } from "../../services/Notification.service";
import { post } from "../../services/useFetchAction";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreUserManager } from "../../store/UserManage";
import AddPiecePart from "../cells/profilPart/AddPiecePart";
import AddProfilPhotoPart from "../cells/profilPart/AddProfilPhotoPart";
import PersonalInfo from "../cells/profilPart/PersonalInfo";
import TokenCheck from "../cells/profilPart/TokenCheck";
import TokenCheckMail from "../cells/profilPart/TokenCheckMail";
const maxSizeInBytes = 8 * 1024 * 1024;

export default function ProfilContent() {
  const userData = useStoreUserManager((state) => state.user);
  const handleUpdateUser = useStoreUserManager(
    (state) => state.handleUpdateUser
  );
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const [saveTokenWaiting, setSaveTokenWaiting] = useState(false);
  const [indicatifNumber, setIndicatifNumber] = useState("");
  const [telNumber, setTelNumberWaiting] = useState("");
  const [saveTokenWaitingMail, setSaveTokenWaitingMail] = useState(false);
  const [emailNew, setMailNumberWaiting] = useState("");
  // console.log(userData);
  const { t } = useTranslation();
  // console.log(userData)
  const checkIfAlreadySend = () => {
    let tokenSendWait = Cookies.get("tokenSendWait");
    if (!tokenSendWait) return false;
    return true;
  };
  const [tokenResend, setTokenResend] = useState(checkIfAlreadySend);
  const [timerSave, setTimerSave] = useState(null);
  useEffect(() => {
    if (!tokenResend) return;
    let timeoutS = setTimeout(() => {
      setTokenResend(false);
    }, 180000);
    setTimerSave(timeoutS);
    return () => {
      clearTimeout(timeoutS);
    };
  }, [tokenResend]);

  const handleSendNewEmailToken = async () => {
    if (tokenResend) return;
    setTokenResend(true);
    try {
      let response = await post(requestEndpoint.resendEmailConfirmation);
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 1000);
      Cookies.set("tokenSendWait", true, {
        expires: expirationDate,
      });
      NotificationService.addToQueue(
        0,
        t("profile.notification.tokenSendWait")
      );
      NotificationService.addToQueue(
        1,
        t("profile.notification.tokenSendWaitTxt")
      );
    } catch (error) {
      NotificationService.addToQueue(
        1,
        t("profile.notification.errorSendToken")
      );
      setTokenResend(false);
      clearTimeout(timerSave);
    }
  };
  return (
    <section className="profile-page | flrow rlve max-container liner">
      <AddProfilPhotoPart
        handleUpdateBlockScreen={handleUpdateBlockScreen}
        handleUpdateUser={handleUpdateUser}
        userData={userData}
        maxSizeInBytes={maxSizeInBytes}
      />
      <PersonalInfo
        handleUpdateBlockScreen={handleUpdateBlockScreen}
        handleUpdateUser={handleUpdateUser}
        userData={userData}
        setSaveTokenWaiting={setSaveTokenWaiting}
        setTelNumberWaiting={setTelNumberWaiting}
        setIndicatifNumber={setIndicatifNumber}
        indicatifNumber={indicatifNumber}
        telNumber={telNumber}
        setSaveTokenWaitingMail={setSaveTokenWaitingMail}
        setMailNumberWaiting={setMailNumberWaiting}
        emailNew={emailNew}
      />
      <TokenCheck
        saveTokenWaiting={saveTokenWaiting}
        setSaveTokenWaiting={setSaveTokenWaiting}
        telNumber={telNumber}
        setTelNumberWaiting={setTelNumberWaiting}
        setIndicatifNumber={setIndicatifNumber}
        indicatifNumber={indicatifNumber}
      />
      <TokenCheckMail
        saveTokenWaiting={saveTokenWaitingMail}
        setSaveTokenWaiting={setSaveTokenWaitingMail}
        telNumber={emailNew}
        setTelNumberWaiting={setMailNumberWaiting}
      />
      <AddPiecePart
        handleUpdateBlockScreen={handleUpdateBlockScreen}
        handleUpdateUser={handleUpdateUser}
        userData={userData}
        maxSizeInBytes={maxSizeInBytes}
      />

      <div className="verification | flcolm">
        <label>{t("profile.checkedTxt")}</label>
        <div
          className={`${
            userData.emailVerified ? "is-ok" : "not"
          } | flrow liner `}
        >
          <XClose color={"var(--destructive-500)"} />
          <Check color={"var(--shade-black)"} />
          <span>{t("profile.champs.email")}</span>
          {!userData.emailVerified && (
            <button
              disabled={tokenResend}
              style={{
                borderRadius: 8,
                padding: 10,
              }}
              className="resend-token btn opt-btn"
              onClick={handleSendNewEmailToken}
            >
              <span
                style={{
                  fontSize: 14,
                  lineHeight: "16px",
                  color: "var(--shade-black)",
                }}
              >
                {t("profile.action.resendToken")}
              </span>
            </button>
          )}
        </div>
        <div
          className={`${
            userData.contactChecked ? "is-ok" : "not"
          } | flrow liner `}
        >
          <XClose color={"var(--destructive-500)"} />
          <Check color={"var(--shade-black)"} />
          <span>{t("profile.champs.number")}</span>
        </div>
        <div
          className={`${
            userData.mailChecked || userData.identityDoc ? "is-ok" : "not"
          } | flrow liner `}
        >
          <XClose color={"var(--destructive-500)"} />
          <Check color={"var(--shade-black)"} />
          <span>{t("profile.champs.id")}</span>
        </div>
      </div>
    </section>
  );
}
