import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import { EyeSlashed, Mail, Pass } from "../../images/icons/JsxIcons";
import fbIcon from "../../images/icons/social/fb.png";
import googleIcon from "../../images/icons/social/google.png";
import { NotificationService } from "../../services/Notification.service";
import { encryptData } from "../../services/encryption.service";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreUserManager } from "../../store/UserManage";

export const ConnectPanelConnexionPart = ({
  currentState = 1,
  panelState,
  setPanelState,
  dataInscription,
  setDataInscription,
  launchGoogleLogin,
  facebookLoginVoid,
  refInputConnexionEmail,
  refInputConnexionMdp,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleUpdateConnexionPanelState = useStorePageManager(
    (state) => state.handleUpdateConnexionPanelState
  );
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );

  const handleUpdateUser = useStoreUserManager(
    (state) => state.handleUpdateUser
  );

  const [stateMail, setStateMail] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [stateMdp, setStateMdp] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [seePassword, setSeePassword] = useState(false);

  useEffect(() => {
    if (dataInscription.addressMail.length > 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(dataInscription.addressMail)) {
        setStateMail({ ...stateMail, error: false, good: true });
      } else {
        setStateMail({ ...stateMail, error: true, good: false });
      }
    } else {
      setStateMail({ ...stateMail, error: false, good: false });
    }
  }, [dataInscription.addressMail]);

  useEffect(() => {
    if (dataInscription.mdp.length > 0) {
      if (dataInscription.mdp.length >= 8) {
        setStateMdp({ ...stateMdp, error: false, good: true });
      } else {
        setStateMdp({ ...stateMdp, error: true, good: false });
      }
    } else {
      setStateMdp({ ...stateMdp, error: false, good: false });
    }
  }, [dataInscription.mdp]);

  const handleGoToInscriptionPanel = () => {
    refInputConnexionEmail.current.value = "";
    refInputConnexionMdp.current.value = "";
    setDataInscription({
      ...dataInscription,
      addressMail: "",
      mdp: "",
    });
    setPanelState({
      ...panelState,
      inscriptionBegin: 1,
      connexion: 2,
    });
  };
  const handleGoToForgetPanel = () => {
    setPanelState({
      ...panelState,
      connexion: 0,
      passwordForgotten: 1,
    });
  };
  const handleGoConnexion = async () => {
    if (stateMail.good && stateMdp.good) {
      handleUpdateBlockScreen(true);
      axios
        .post(process.env.REACT_APP_API_URL + "/users/login", {
          email: dataInscription.addressMail,
          password: dataInscription.mdp,
        })
        .then(async (res) => {
          if (
            res.data.profile.toString().toLowerCase() !=
            "client".toString().toLowerCase()
          ) {
            NotificationService.addToQueue(
              1,
              t("modals.connexionPanel.notification.blockAccount")
            );
            return;
          }
          if (!res.data.enableClient) {
            NotificationService.addToQueue(
              1,
              t("modals.connexionPanel.notification.blockClient")
            );
            return;
          }
          if (!res.data.payment_method_id && res.data.enableHost) {
            NotificationService.addToQueue(
              1,
              t("modals.connexionPanel.notification.paymentMethod")
            );
          }

          NotificationService.addToQueue(
            0,
            t("modals.connexionPanel.notification.connexionDoneTxt")
          );
          let dataToken = {
            access_token: res.data.access_token,
            refresh_token: res.data.refresh_token,
          };
          let encryptToken = await encryptData(dataToken);
          localStorage.setItem("token", encryptToken);

          let dataUserSave = {
            token: res.data.token,
            email: res.data.email,
          };
          let dataEncrypted = await encryptData(dataUserSave);
          useStoreUserManager.getState().handleUpdateUser({ pieceChecked: 1 });
          handleUpdateUser({
            id: res.data.id,
            lastname: res.data.lastname,
            firstname: res.data.firstname,
            location: res.data.location,
            connected: true,
            profil: res.data.profile + `${res.data.enableHost ? "/hote" : ""}`,
            mail: res.data.email,
            contact: res.data.contact,
            emailVerified: res.data.mailActivatedAt && true,
            identityDoc: res.data.identityDoc ?? null,
            pieceChecked: res.data.identityDoc ? 1 : 0,
            profilImgUrl: res.data.avatar ?? null,
            contactChecked: res.data.contactActivatedAt && true,
            isSocial: res.data.facebookEmail
              ? true
              : res.data.googleEmail
              ? true
              : false,
            typeSocial: res.data.facebookEmail
              ? "facebook"
              : res.data.googleEmail
              ? "google"
              : "",
            enableHost: res.data.enableHost,
            payment_method_id: res.data.payment_method_id ?? 0,
          });

          localStorage.setItem("user", dataEncrypted);
          let whereDirectGo = "";
          whereDirectGo = useStorePageManager.getState().nextLoadingNave;
          if (whereDirectGo != "") {
            useStorePageManager.getState().handleUpdateNextLoadingNave("");
            navigate(whereDirectGo);
          }
          handleUpdateConnexionPanelState(false);
        })
        .catch((err) => {
          console.log(err);
          NotificationService.addToQueue(
            2,
            t("modals.connexionPanel.notification.errorConnexionTxt")
          );
        })
        .finally(() => {
          handleUpdateBlockScreen(false);
        });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleGoConnexion();
      }}
      className={`contains inscription-part ${
        currentState == 0
          ? "go-left-side"
          : currentState == 1
          ? "come-center"
          : "go-right-side"
      } | flcolm`}
    >
      <label>{t("modals.connexionPanel.connexion.title")}</label>
      <div className="input-field | flcolm">
        <div className="input | rlve">
          <Mail color={stateMail.focus ? "var(--gray-600)" : "none"} />
          <input
            ref={refInputConnexionEmail}
            onBlur={() => setStateMail({ ...stateMail, focus: false })}
            onFocus={() => setStateMail({ ...stateMail, focus: true })}
            onChange={(e) =>
              setDataInscription({
                ...dataInscription,
                addressMail: e.target.value,
              })
            }
            autoCorrect="false"
            autoCapitalize="false"
            autoComplete="false"
            placeholder={t("modals.connexionPanel.connexion.champs.mail")}
            type="email"
          />
        </div>
        <div className="input password | rlve">
          <Pass color={stateMdp.focus ? "var(--gray-600)" : "none"} />
          <input
            ref={refInputConnexionMdp}
            onBlur={() => setStateMdp({ ...stateMdp, focus: false })}
            onFocus={() => setStateMdp({ ...stateMdp, focus: true })}
            onChange={(e) =>
              setDataInscription({
                ...dataInscription,
                mdp: e.target.value,
              })
            }
            autoCorrect="false"
            autoCapitalize="false"
            autoComplete="false"
            placeholder={t("modals.connexionPanel.connexion.champs.password")}
            type={seePassword ? "text" : "password"}
            name="password"
          />
          <button
            type="button"
            onClick={() => setSeePassword(!seePassword)}
            className="see-pass | btn"
          >
            <EyeSlashed stroke={seePassword ? "var(--gray-300)" : "#9CA3AF"} />
          </button>
        </div>
      </div>
      <span className="forget-pass" onClick={handleGoToForgetPanel}>
        {t("modals.connexionPanel.connexion.forgetPassword")}
      </span>
      <button
        type="submit"
        // onClick={handleGoConnexion}
        className="next-btn | btn flex"
        disabled={!(stateMail.good && stateMdp.good)}
      >
        {t("modals.connexionPanel.connexion.btn")}
      </button>
      <div className="separator | flrow allcenter">
        <div className="line"></div>
        <span>{t("modals.connexionPanel.connexion.or")}</span>
        <div className="line"></div>
      </div>
      <div className="social-connect | flcolm addgap8">
        <button
          type="button"
          onClick={() => {
            // NotificationService.addToQueue(
            //   1,
            //   "Fonctionnalité bientôt disponible"
            // );
            // return;
            useStorePageManager.getState().handleUpdateBlockScreen(true);

            launchGoogleLogin();
          }}
          className="google-btn | btn flrow center"
        >
          <img src={googleIcon} alt="google-logo" />
          <span>{t("modals.connexionPanel.connexion.social.google")}</span>
        </button>
        <LoginSocialFacebook
          className="fb-login | maxwidth"
          appId={process.env.REACT_APP_CONNEXION_FACEBOOK}
          onLoginStart={(_response) => {
            useStorePageManager.getState().handleUpdateBlockScreen(true);
          }}
          onResolve={(response) => {
            useStorePageManager.getState().handleUpdateBlockScreen(true);
            facebookLoginVoid(response);
          }}
          onReject={(_response) => {
            useStorePageManager.getState().handleUpdateBlockScreen(false);
            NotificationService.addToQueue(
              2,
              t("modals.connexionPanel.notification.social.facebookLoginError")
            );
          }}
          onError={(_response) => {
            useStorePageManager.getState().handleUpdateBlockScreen(false);
            NotificationService.addToQueue(
              2,
              t("modals.connexionPanel.notification.social.facebookLoginError")
            );
          }}
        >
          <button type="button" className="fb-btn | btn flrow center">
            <img src={fbIcon} alt="fb-logo" />
            <span>{t("modals.connexionPanel.connexion.social.facebook")}</span>
          </button>
        </LoginSocialFacebook>
        <p className="other-choice">
          {t("modals.connexionPanel.connexion.notHaveAccount")}{" "}
          <span onClick={handleGoToInscriptionPanel}>
            {t("modals.connexionPanel.connexion.inscription")}
          </span>
        </p>
      </div>
    </form>
  );
};
