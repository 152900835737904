import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../../data/requestEndpoint";
import { NotificationService } from "../../../services/Notification.service";
import { get, post } from "../../../services/useFetchAction";
import { useStorePageManager } from "../../../store/PageManage";
import { useStoreUserManager } from "../../../store/UserManage";

export default function TokenCheck({
  saveTokenWaiting,
  telNumber,
  setSaveTokenWaiting,
  setTelNumberWaiting,
}) {
  const { t } = useTranslation();
  const [code, setCode] = useState(["", "", "", ""]);
  const [isFocused, setIsFocused] = useState([false, false, false, false]);
  const inputRefs = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];
  const userData = useStoreUserManager((state) => state.user);

  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;

    setCode(newCode);

    if (value !== "" && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    if (index == 3) {
      let isFull = true;
      newCode.map((item) => {
        if (item == "") isFull = false;
      });
      if (isFull) {
        checkCode(newCode.join(""));
      }
    }
  };
  const checkCode = async (code) => {
    useStorePageManager.getState().handleUpdateBodyOverflow(false);
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    let recupCode = code;

    let data = {
      token: saveTokenWaiting,
      code: recupCode,
      type: "contact",
    };
    await post(requestEndpoint.verifyOtp, data)
      .then((res) => {
        setCode(["", "", "", ""]);

        useStoreUserManager
          .getState()
          .handleUpdateUser({ contact: telNumber, contactChecked: true });
        NotificationService.addToQueue(
          0,
          t("modals.personalInfo.tokenTelCheck.notification.success")
        );
        NotificationService.addToQueue(
          0,
          `${t("modals.tokenTelCheck.notification.newNumber")} ${telNumber}`
        );
      })
      .catch((err) => {
        console.log(err);
        NotificationService.addToQueue(
          2,
          t("modals.tokenTelCheck.notification.errorCode")
        );
      })
      .finally(() => {
        useStorePageManager.getState().handleUpdateBodyOverflow(true);
        useStorePageManager.getState().handleUpdateBlockScreen(false);
      });
    // console.log("new habitation update");
  };
  const handleKeyDown = (index, event) => {
    // Passe automatiquement au champ précédent si la touche Backspace est pressée sur un champ vide
    if (event.key === "Backspace" && code[index] === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  // Gère le focus sur le champ en fonction de l'index
  const handleFocus = (index) => {
    const newIsFocused = [...isFocused];
    newIsFocused[index] = true;
    setIsFocused(newIsFocused);
  };

  // Gère le blur sur le champ en fonction de l'index
  const handleBlur = (index) => {
    const newIsFocused = [...isFocused];
    newIsFocused[index] = false;
    setIsFocused(newIsFocused);
  };

  const handleActiveMyCurrentTel = async () => {
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    let data = {
      // password: newPassword,
      contact: userData.contact,
      contactActivatedAt: null,
    };
    await get(requestEndpoint.confirContact, data)
      .then((res) => {
        console.log(res);
        // NotificationService.addToQueue(0, t("changeTelDone"));
        NotificationService.addToQueue(
          1,
          t("modals.tokenTelCheck.notification.confirmCode")
        );
        useStoreUserManager
          .getState()
          .handleUpdateUser({ contactChecked: false });
        setSaveTokenWaiting(res.data.updateContactToken);
        setTelNumberWaiting(userData.contact);
      })
      .catch((err) => {
        console.log(err);
        NotificationService.addToQueue(
          2,
          t("modals.tokenTelCheck.notification.errorSendCode")
        );
      })
      .finally(() => {
        useStorePageManager.getState().handleUpdateBodyOverflow(true);
        useStorePageManager.getState().handleUpdateBlockScreen(false);
      });
  };
  useEffect(() => {
    if (telNumber != "" && !userData.contactChecked) {
      let element = document.getElementById("token-contact");
      if (!element) return;
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [telNumber, userData]);
  if (userData.contactChecked) return null;
  if (!userData.contact && !telNumber) return null;
  return (
    <div
      id="token-contact"
      className={`info-personnelle flcolm ${isFocused ? "focused" : ""}`}
    >
      <label>{t("modals.tokenTelCheck.title")}</label>
      <div className="separator"></div>
      {!userData.contactChecked &&
      telNumber == "" &&
      userData.contact?.length >= 8 ? (
        <>
          <p className="info-sec -l">{t("modals.tokenTelCheck.description")}</p>
          <button
            onClick={handleActiveMyCurrentTel}
            style={{
              borderRadius: 8,
            }}
            className="btn opt-btn"
          >
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {t("modals.tokenTelCheck.button.sendCode")}
            </span>
          </button>
        </>
      ) : (
        <>
          <p className="info-sec -l">
            {t("modals.tokenTelCheck.secondDescription")}
          </p>
          <div className="flrow liner contains-digit">
            {code.map((digit, index) => (
              <input
                key={index}
                className={`code-input ${isFocused ? "no-placeholder" : ""}`}
                type="tel"
                maxLength="1"
                value={digit}
                placeholder={isFocused[index] ? "" : "-"}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onFocus={() => handleFocus(index)}
                onBlur={() => handleBlur(index)}
                ref={inputRefs[index]}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
