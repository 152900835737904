export function handleFormatNumber(nombre, devise, language = "fr") {
  const nombreString = nombre.toString();
  const parties = nombreString.split(".");
  const partieEntiereFormatee = parties[0].replace(
    /\B(?=(\d{3})+(?!\d))/g,
    language === "fr" ? "." : ","
  );
  const nombreFormate =
    partieEntiereFormatee + (parties[1] ? `.${parties[1]}` : "");
  const nombreAvecFCFA = `${nombreFormate} ${devise}`;

  return nombreAvecFCFA;
}

export function justRetireLetter(input) {
  let inputValueCheck = 0;
  //faire un regex pour garder que les chiffres et les points et les virgules
  // console.log("input", input);
  let realInput = input.toString().replace(/[^0-9]/g, "");
  if (isNaN(realInput)) {
    inputValueCheck = parseFloat(realInput);
  } else {
    inputValueCheck = realInput;
  }
  inputValueCheck = Math.round(inputValueCheck);

  const inputValue = inputValueCheck.toString().trim();
  const cleanedValue = inputValue.replace(/[^0-9]/g, "");
  const numericValue = parseFloat(cleanedValue);
  return numericValue;
}

export function handleFormatToLocalString(input, language = "fr-FR", sign) {
  let languageToUse = language;
  if (input === undefined || input === null) {
    return "";
  }
  if (language === "fr") {
    languageToUse = "fr-FR";
  }
  if (language === "en") {
    languageToUse = "en-US";
  }
  const effectiveSign = sign === "$" ? sign : false;
  const numericValue = justRetireLetter(input);
  if (!isNaN(numericValue)) {
    let formattedValue = numericValue.toLocaleString(languageToUse);
    formattedValue = formattedValue.replace(" ", " ");
    return `${effectiveSign ? effectiveSign + " " : ""}${formattedValue}`;
  } else {
    return "";
  }
}
