import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Footer from "../components/biggest/Footer";
import ProfilContent from "../components/biggest/ProfilContent";
import { NotificationService } from "../services/Notification.service";
import { smoothScrollUp } from "../services/utils";
import { useStorePageManager } from "../store/PageManage";
import { useStoreUserManager } from "../store/UserManage";
import "../styles/pages_styles/profilPage.css";

export default function ProfilePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleUpdateStorePage = useStorePageManager(
    (state) => state.handleUpdateStorePage
  );
  const handleUpdateDesactiveHeader = useStorePageManager(
    (state) => state.handleUpdateDesactiveHeader
  );
  const userData = useStoreUserManager((state) => state.user);
  useEffect(() => {
    handleUpdateStorePage(false);
    handleUpdateDesactiveHeader(false);
  }, []);

  useEffect(() => {
    if (!userData) return;
    if (userData.connected == false) {
      navigate("/");
      NotificationService.addToQueue(
        1,
        t("profile.notification.youNeedConnectTxt")
      );
    }
  }, [userData]);
  useEffect(() => {
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);
  return (
    <>
      {/* <Header /> */}
      <main>
        <ProfilContent />
        <Footer />
      </main>
    </>
  );
}
