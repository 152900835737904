import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../data/requestEndpoint";
import { ChevronDownSimple, MoreBtn, Trash } from "../../images/icons/JsxIcons";
import { NotificationService } from "../../services/Notification.service";
import { useGetDelayRequest } from "../../services/useFetchAction";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
import { useStoreUserManager } from "../../store/UserManage";
import "../../styles/components/addResidencePart/step11.css";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import SelectResidenceModal from "../cells/SelectResidenceModal";
const currentStep = 11;
export default function Step11Description({
  step,
  handleBlockBtn,
  descriptionSections,
  setDescriptionSections,
  baseDescription,
  setBaseDescription,
}) {
  const { t } = useTranslation();
  const maxLenghtTitle = 70;
  const maxLenghtDescription = 1000;
  const [modalSelectDescription, setModalSelectDescription] = useState(false);
  const handleCopyForThisResidence = (residence) => {
    let newBaseDescription = [];
    residence.description.slice(0, 3).map((item, index) => {
      newBaseDescription.push({
        id: "",
        title: item.title,
        lengthTitle: item.title.length,
        description: item.text,
        lengthDescription: item.text.length,
      });
    });
    setBaseDescription(newBaseDescription);
    let newTableDescription = [];
    residence.description.slice(3, 11).map((item, index) => {
      newTableDescription.push({
        id: "",
        title: item.title,
        lengthTitle: item.title.length,
        description: item.text,
        lengthDescription: item.text.length,
      });
    });
    setDescriptionSections(newTableDescription);
    setModalSelectDescription(false);
  };
  useEffect(() => {
    let cantGo = true;
    for (let index = 0; index < baseDescription.length; index++) {
      const element = baseDescription[index];
      if (
        element.title.trim().length > 0 &&
        element.description.trim().length > 0
      ) {
        cantGo = false;
      } else {
        cantGo = true;
        if (currentStep == step) handleBlockBtn(cantGo);
        return;
        break;
      }
    }
    for (let index = 0; index < descriptionSections.length; index++) {
      const element = descriptionSections[index];
      if (
        element.title.trim().length > 0 &&
        element.description.trim().length > 0
      ) {
        cantGo = false;
      } else {
        cantGo = true;
        if (currentStep == step) handleBlockBtn(cantGo);
        return;
        break;
      }
    }
    if (telDetected) cantGo = true;
    if (currentStep == step) handleBlockBtn(cantGo);
  }, [step, baseDescription, descriptionSections]);
  const handleAddSection = () => {
    const newSection = {
      id: "",
      title: "",
      lengthTitle: 0,
      description: "",
      lengthDescription: 0,
    };
    setDescriptionSections((prev) => [...prev, newSection]);
  };
  const handleDeleteSection = (index) => {
    // if (descriptionSections.length == 1) return;
    const newSections = descriptionSections.filter((section, i) => i != index);
    setDescriptionSections(newSections);
  };
  const moveUpSection = (index) => {
    if (index == 0) return;
    const newSections = [...descriptionSections];
    const currentSection = newSections[index];
    newSections[index] = newSections[index - 1];
    newSections[index - 1] = currentSection;
    setDescriptionSections(newSections);
  };
  const moveDownSection = (index) => {
    if (index == descriptionSections.length - 1) return;
    const newSections = [...descriptionSections];
    const currentSection = newSections[index];
    newSections[index] = newSections[index + 1];
    newSections[index + 1] = currentSection;
    setDescriptionSections(newSections);
  };

  const handleInputTitleSection = (e, index) => {
    const newSections = [...descriptionSections];
    let value = e.target.value;
    if (value.length > maxLenghtTitle) {
      NotificationService.addToQueue(
        1,
        t("addResidence.step11.notification.errorTitle")
      );
      e.target.value = value.slice(0, maxLenghtTitle);
      return;
    }

    value = value.slice(0, maxLenghtTitle);
    newSections[index].title = value;
    newSections[index].lengthTitle = value.length;
    setDescriptionSections(newSections);
  };
  const [telDetected, setTelDetected] = useState(false);
  const handleInputDescriptionSection = (e, index) => {
    const newSections = [...descriptionSections];
    let value = e.target.value;

    //essayer de detecter les numéros de téléphone avec plusieurs regex
    //et les remplacer par des étoiles

    let regexTel = /(\+?\d{1,3}[- ]?)?\d{10}/g;
    let regexTel2 = /(\+?\d{1,3}[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}/g;
    let regexTel3 = /(\+?\d{1,3}[- ]?)?\d{3}[- ]?\d{4}/g;
    if (
      value.match(regexTel) ||
      value.match(regexTel2) ||
      value.match(regexTel3)
    ) {
      value = value.replace(regexTel, "**********");
      setTelDetected(true);
    } else {
      setTelDetected(false);
    }
    if (value.length > maxLenghtDescription) {
      NotificationService.addToQueue(
        1,
        t("addResidence.step11.notification.errorDescription")
      );
      e.target.value = value.slice(0, maxLenghtDescription);
      return;
    }
    value = value.slice(0, maxLenghtDescription);
    newSections[index].description = value;
    newSections[index].lengthDescription = value.length;
    setDescriptionSections(newSections);
  };
  const handleInputDescriptionSectionBase = (e, index) => {
    const newSections = [...baseDescription];
    let value = e.target.value;

    let regexTel = /(\+?\d{1,3}[- ]?)?\d{10}/g;
    let regexTel2 = /(\+?\d{1,3}[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}/g;
    let regexTel3 = /(\+?\d{1,3}[- ]?)?\d{3}[- ]?\d{4}/g;

    if (
      value.match(regexTel) ||
      value.match(regexTel2) ||
      value.match(regexTel3)
    ) {
      value = value.replace(regexTel, "**********");
      NotificationService.addToQueue(
        1,
        t("addResidence.step11.notification.noPhone")
      );
      NotificationService.addToQueue(
        1,
        t("addResidence.step11.notification.phoneReplace")
      );
      setTelDetected(true);
    } else {
      setTelDetected(false);
    }
    if (value.length > maxLenghtDescription) {
      NotificationService.addToQueue(
        1,
        t("addResidence.step11.notification.errorDescription")
      );
      e.target.value = value.slice(0, maxLenghtDescription);
      return;
    }
    value = value.slice(0, maxLenghtDescription);
    newSections[index].description = value;
    newSections[index].lengthDescription = value.length;
    setBaseDescription(newSections);
  };

  const userData = useStoreUserManager((state) => state.userData);
  const [residenceUser, setResidenceUser] = useState([]);

  const [
    residenceData,
    loadingResidenceData,
    errorResidenceData,
    fetchResidenceData,
  ] = useGetDelayRequest({
    route: requestEndpoint.getHoteResidence,
    loadingTime: 0,
  });

  useEffect(() => {
    fetchResidenceData();
  }, []);

  useEffect(() => {
    if (loadingResidenceData) return;
    if (residenceData) {
      //garder les résidences qui ont isConfirmed = true
      let newResidenceData = [];
      residenceData.data.map((residence) => {
        if (
          residence.isConfirmed &&
          !residence.deletedAt &&
          residence?.medias?.length > 0
        ) {
          newResidenceData.push(residence);
        }
      });
      setResidenceUser(newResidenceData);
    }
  }, [residenceData, loadingResidenceData]);

  const handleCloseModal = () => {
    setModalSelectDescription(false);
  };

  const checkIfBlock = () => {
    let cantGo = true;
    for (let index = 0; index < baseDescription.length; index++) {
      const element = baseDescription[index];
      if (
        element.title.trim().length > 0 &&
        element.description.trim().length > 0
      ) {
        cantGo = false;
      } else {
        cantGo = true;
        return cantGo;
        break;
      }
    }
    for (let index = 0; index < descriptionSections.length; index++) {
      const element = descriptionSections[index];
      if (
        element.title.trim().length > 0 &&
        element.description.trim().length > 0
      ) {
        cantGo = false;
      } else {
        cantGo = true;
        return cantGo;
        break;
      }
    }
    return cantGo;
  };

  const handleKeyDown = (e) => {
    return null;
    if (e.key == "Enter" && currentStep == step && !checkIfBlock()) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [checkIfBlock, step, baseDescription, descriptionSections]);

  return (
    <>
      <ContainerPersoModal
        isOpen={modalSelectDescription}
        toggle={setModalSelectDescription}
        baseTop={true}
        className="select-resi-modal-container"
        title={t("addResidence.step11.modals.selectAnnonceTxt")}
      >
        <SelectResidenceModal
          showSelectAll={false}
          residenceList={residenceUser}
          closeModal={handleCloseModal}
          handleUpdateCurrentResidenceSelected={handleCopyForThisResidence}
          currentResidenceSelected={() => {}}
        />
      </ContainerPersoModal>
      <section
        className={`step-1-annonce step-11 ${
          currentStep == step ? "visible" : ""
        } 
      ${currentStep < step ? "go-top" : ""} ${
          currentStep > step ? "go-bottom" : ""
        } add-resi add-resi-input input-resi | relve`}
      >
        <div className="contains |  max-container flrow liner">
          <div className="left-part-add-resi | flcolm jcenter">
            <div className="content | flcolm jcenter">
              <label>{t("addResidence.step11.label")}</label>
              <p>{t("addResidence.step11.description")}</p>
              {residenceUser.length > 0 && (
                <button
                  style={{
                    borderRadius: 8,
                  }}
                  onClick={() => setModalSelectDescription(true)}
                  className="btn opt-btn"
                >
                  {t("addResidence.step11.copyDescription")}
                </button>
              )}
            </div>
          </div>
          <div className="right-part-add-resi | flcolm">
            <div className="contains-input | flcolm addgap16">
              {baseDescription.map((section, index) => (
                <div
                  style={{
                    paddingBottom: 0,
                  }}
                  key={index}
                  className="contains-input-section flcolm"
                >
                  <div className="flrow liner btw">
                    <div
                      style={{
                        columnGap: 2,
                        backgroundColor: "var(--shade-white)",
                      }}
                      className="flrow liner"
                    >
                      <label
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: "var(--shade-black)",
                          marginRight: 10,
                        }}
                      >
                        {section.title}
                      </label>
                    </div>
                    {section.lengthDescription <= 0 && (
                      <p
                        style={{
                          fontSize: 12,
                          color: "var(--destructive-500)",
                          fontWeight: 600,
                        }}
                      >
                        {t("addResidence.step11.required")}
                      </p>
                    )}
                  </div>
                  <div className="input-select-contains flcolm">
                    <textarea
                      value={section.description}
                      style={{
                        marginTop: 10,
                        height: 70,
                        borderRadius: 8,
                      }}
                      maxLength={1000}
                      onChange={(e) =>
                        handleInputDescriptionSectionBase(e, index)
                      }
                      placeholder={t(
                        "addResidence.step11.placeHolderDescription"
                      )}
                      autoComplete="none"
                      name="Description"
                    ></textarea>
                    <span>
                      {section.lengthDescription}/1000{" "}
                      {t("addResidence.step11.caracter")}
                    </span>
                  </div>
                </div>
              ))}
              {descriptionSections.map((section, index) => (
                <div key={index} className="contains-input-section flcolm">
                  <div className="flrow liner btw">
                    <div
                      style={{
                        columnGap: 2,
                        backgroundColor: "var(--shade-white)",
                      }}
                      className="flrow liner"
                    >
                      <label
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: "var(--shade-black)",
                          marginRight: 10,
                        }}
                      >
                        {t("addResidence.step11.section.title")} {index + 4}
                      </label>
                      <button
                        onClick={() => index > 0 && moveUpSection(index)}
                        style={{
                          borderRadius: 0,
                          width: 25,
                          padding: 5,
                          opacity: index == 0 ? 0.2 : 1,
                          cursor: index == 0 ? "not-allowed" : "pointer",
                          backgroundColor: "var(--shade-white)",
                          // visibility: index == 0 ? "hidden" : "visible",
                          transform: "rotate(180deg)",
                        }}
                        className="up-btn btn"
                      >
                        <ChevronDownSimple stroke="var(--shade-black)" />
                      </button>

                      <button
                        onClick={() =>
                          index < descriptionSections.length - 1 &&
                          moveDownSection(index)
                        }
                        style={{
                          borderRadius: "50%",
                          padding: 5,
                          width: 25,
                          // display:
                          //   index < descriptionSections.length - 1
                          //     ? "flex"
                          //     : "none",
                          opacity:
                            index < descriptionSections.length - 1 ? 1 : 0.2,
                          cursor:
                            index < descriptionSections.length - 1
                              ? "pointer"
                              : "not-allowed",
                        }}
                        className="down-btn btn"
                      >
                        <ChevronDownSimple stroke="var(--shade-black)" />
                      </button>
                    </div>
                    <button
                      onClick={() =>
                        //  index > 0 &&
                        handleDeleteSection(index)
                      }
                      style={{
                        padding: 8,
                        width: 35,
                        borderRadius: "50%",
                        // opacity: index == 0 ? 0.2 : 1,
                        // cursor: index == 0 ? "not-allowed" : "pointer",
                      }}
                      className="sup-btn btn"
                    >
                      <Trash color="var(--destructive-500)" />
                    </button>
                  </div>
                  <div className="input-select-contains flcolm">
                    <input
                      value={section.title}
                      maxLength={70}
                      onChange={(e) => handleInputTitleSection(e, index)}
                      placeholder="Titre"
                    />
                    <span>
                      {section.lengthTitle}/70{" "}
                      {t("addResidence.step11.caracter")}
                    </span>
                  </div>
                  <div className="input-select-contains flcolm">
                    <textarea
                      style={{
                        borderRadius: 8,
                      }}
                      value={section.description}
                      maxLength={1000}
                      onChange={(e) => handleInputDescriptionSection(e, index)}
                      placeholder={t(
                        "addResidence.step11.placeHolderDescription"
                      )}
                      autoComplete="none"
                      name="Description"
                    ></textarea>
                    <span>
                      {section.lengthDescription}/1000{" "}
                      {t("addResidence.step11.caracter")}
                    </span>
                  </div>
                </div>
              ))}
              {descriptionSections.length < 7 && (
                <button
                  onClick={() => handleAddSection()}
                  className="add-section-btn btn"
                >
                  <span>{t("addResidence.step11.addSection")}</span>
                  <button className="flex liner">
                    <MoreBtn />
                  </button>
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
