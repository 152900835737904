import React from "react";
import "../../styles/components/selectFiltersOptionCard.css";
export default function SelectFiltersOptionCard({
  language,
  mine,
  setFilters,
}) {
  const handleUpdateMyFilter = () => {
    setFilters(mine);
  };
  return (
    <button
      onClick={handleUpdateMyFilter}
      className={`filter-btn | btn opt-btn ${
        !mine.selected && "not-active-opt"
      } flrow`}
    >
      <img src={mine.secondThumb} alt={"icon-" + mine.title} />
      <span>{language == "fr" ? mine.title : mine.titleEng}</span>
    </button>
  );
}
