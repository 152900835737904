import React from "react";
import { useCurrentScrollY } from "../../../services/useCurrentScrollY";
import "../../../styles/components/politiqueContent.css";
export default function MentionLegalesContentEn() {
  const { checkingPosY } = useCurrentScrollY(100);
  return (
    <section
      style={{ paddingBottom: 30 }}
      className="policy-section | flcolm max-container rlve"
    >
      <div className="content | flcolm">
        {checkingPosY && (
          <div className="title second | max-container">
            <label>Legal Notice</label>
          </div>
        )}
        <div className="title | flcolm"></div>

        <h1 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          LEGAL NOTICE
        </h1>
        <h3>Website Publisher:</h3>
        <p>
          <a
            href="https://impactplus-digital.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Impact Plus Digital
          </a>
        </p>
        <h3>Head Office:</h3>
        <p>Abidjan</p>
        <p>Commune of Cocody</p>
        <p>
          Angré 8th Tranche (RCI) opposite pharmacy 8th tranche - Street L259
        </p>
        <p>Le Continental Building 3rd floor door B2</p>
        <p>Ivory Coast</p>
        <h3>Intra-community VAT number:</h3>
        <p>FISCAL DECLARATION OF EXISTENCE (DFE): 2305086 Z</p>
        <h3>Registration number:</h3>
        <p>UNIQUE IDENTIFICATION NUMBER (IDU): CI-2023-0064051 B</p>
        <p>
          TRADE AND PERSONAL PROPERTY CREDIT REGISTER (RCCM):
          CI-ABJ-03-2023-B12-06222
        </p>
        <h3>Share capital:</h3>
        <p>2,350,000 FCFA</p>
        <h3>General Assembly:</h3>
        <p>Neuville BOUO BELLA, Kevin KOFFI</p>
        <p>Contact us:</p>
        <h3>Email address:</h3>
        <p>
          <a href="mailto:contact@trouvechap.com">contact@trouvechap.com</a>
        </p>
        <h3>Trouvechap Support:</h3>
        <p>
          Trouvechap acts as a technical intermediary and does not intervene in
          the choice of content uploaded by its users. However, Trouvechap
          commits to:
        </p>
        <p>verify the authenticity of residence images posted by hosts.</p>
        <p>
          Ensure compliance with the type of content to be posted on the
          platform.
        </p>
        <p>
          More information in our <a href="/conditions">Terms of Service</a>
        </p>
        <h3>Technical Provider (website host):</h3>
        <p>
          Trouvechap uses Hostinger cloud services to host its website. The head
          office is located at 61 Lordou Vironos Street, 6023 Larnaca, Cyprus,
          reachable by the following means:
        </p>
        <a href="https://www.hostinger.fr/contact">
          https://www.hostinger.fr/contact
        </a>
        <p>
          <h3>
            Contracting Party for Payment Services for Users Residing in ECOWAS:
          </h3>
        </p>
        <p>Abidjan</p>
        <p>Commune of Cocody</p>
        <p>
          Angré 8th Tranche (RCI) opposite pharmacy 8th tranche - Street L259
        </p>
        <p>Le Continental Building 3rd floor door B2</p>
        <p>Ivory Coast</p>
        <h3>Company number:</h3>
        <p>UNIQUE IDENTIFICATION NUMBER (IDU): CI-2023-0064051 B</p>
        <p>
          TRADE AND PERSONAL PROPERTY CREDIT REGISTER (RCCM):
          CI-ABJ-03-2023-B12-06222
        </p>
        <h3>General Assembly:</h3>
        <p>Neuville BOUO BELLA, Kevin KOFFI</p>
        <p>
          Trouvechap is governed by the provisions of the revised OHADA Uniform
          Act of January 30, 2014 relating to Commercial Companies and Economic
          Interest Groups (EIG) Law, as well as by all other applicable legal or
          regulatory provisions and its present statutes for:
        </p>
        <ul>
          <li>Digital connection between property owners and tenants</li>
          <li>Management of furnished or unfurnished residences</li>
          <li>Website creation and management</li>
          <li>Advertising and promotion of goods of all kinds</li>
          <li>And miscellaneous;</li>
        </ul>
      </div>
    </section>
  );
}
