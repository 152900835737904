import { useTranslation } from "react-i18next";
import { VectorHowResidenceLeft } from "../../images/icons/JsxDesign";
import { BankNote, Home, SearchLg } from "../../images/icons/JsxIcons";
import "../../styles/components/howWorkSection.css";

export default function HowWork() {
  const { t } = useTranslation();
  return (
    <section className="paiement second-how | flcolm liner max-container rlve">
      <div className="contains | flcolm liner">
        <div className="title | flcolm liner addgap8">
          <h2>{t("home.howWork.title")}</h2>
          <div>
            <p>{t("home.howWork.description")}</p>
          </div>
        </div>
        <div className="contents-how | flrow btw">
          <div className="card-how | flcolm">
            <span className="icon | flex liner">
              <SearchLg color="#A273FF" />
            </span>
            <div className="content | flcolm addgap16">
              <h3>{t("home.howWork.cards.one.title")}</h3>
              <p>{t("home.howWork.cards.one.description")}</p>
            </div>
          </div>
          <div className="card-how | flcolm">
            <span className="icon | flex liner">
              <BankNote color="#A273FF" />
            </span>
            <div className="content | flcolm addgap16">
              <h3>{t("home.howWork.cards.two.title")}</h3>
              <p>{t("home.howWork.cards.two.description")}</p>
            </div>
          </div>
          <div className="card-how | flcolm">
            <span className="icon | flex liner">
              <Home color="#A273FF" />
            </span>
            <div className="content | flcolm addgap16">
              <h3>{t("home.howWork.cards.three.title")}</h3>
              <p>{t("home.howWork.cards.three.description")}</p>
            </div>
          </div>
        </div>
      </div>
      <VectorHowResidenceLeft className="vector-left-how desktop-vector | design" />
    </section>
  );
}
