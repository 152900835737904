const cancellationTranslation = {
  fr: {
    first: "Entre 1 mois et 3 mois avant le jour J",
    second: "Entre 1 semaine et 1 mois avant le jour J",
    third: "Entre 48h et 1 semaine avant le jour J",
    fourth: "Moins de 48h avant le jour J",
    fifth: "Plus de 3 mois avant le jour J",
    //traduction hote
    firstHote: "Annulation moins de 48 heures avant le début du séjour",
    secondHote: "Annulation entre 48h et 1 semaine avant le début du séjour",
    thirdHote: "Annulation entre 1 semaine et 1 mois avant le début du séjour",
    fourthHote: "Annulation entre 1 mois et 3 mois avant le début du séjour",
    fifthHote: "Annulation plus de 3 mois avant le début du séjour",
  },
  en: {
    first: "Between 1 month and 3 months before the day of arrival",
    second: "Between 1 week and 1 month before the day of arrival",
    third: "Between 48 hours and 1 week before the day of arrival",
    fourth: "Less than 48 hours before the day of arrival",
    fifth: "More than 3 months before the day of arrival",
    //traduction hote
    firstHote: "Cancellation less than 48 hours before the start of the stay",
    secondHote:
      "Cancellation between 48 hours and 1 week before the start of the stay",
    thirdHote:
      "Cancellation between 1 week and 1 month before the start of the stay",
    fourthHote:
      "Cancellation between 1 month and 3 months before the start of the stay",
    fifthHote: "Cancellation more than 3 months before the start of the stay",
  },
};

export const handleReturnGoodCancellationTranslation = (
  language,
  frCurrentLabel
) => {
  //chercher en fr si la valeur est la même que frCurrentLabel
  let searchKey = "";
  Object.keys(cancellationTranslation.fr).forEach((key) => {
    if (
      cancellationTranslation.fr[key].toLowerCase() ===
      frCurrentLabel.toLowerCase()
    ) {
      searchKey = key;
    }
  });
  return cancellationTranslation[language][searchKey];
};
