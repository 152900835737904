/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useSortable } from "@dnd-kit/sortable";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Trash } from "../../images/icons/JsxIcons";
import dotHori from "../../images/icons/dots-horizontal.svg";
import pick from "../../images/icons/image-04.svg";
export default function PhotosStep9({
  id,
  photo,
  activeId,
  index,
  handlePutCover,
  handleDeleteThisPick,
  setHideDeleteBtn,
}) {
  const { t } = useTranslation();
  const sortable = useSortable({
    id,
  });
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = sortable;
  const [openModal, setOpenModal] = useState(false);
  const [randomColor] = useState(
    "#" + Math.floor(Math.random() * 16777215).toString(16)
  );
  const refModal = useRef(null);
  const refBtnModal = useRef(null);
  useEffect(() => {
    if (activeId) {
      setOpenModal(false);
    }
  }, [activeId]);
  const handleClickOutside = (e) => {
    if (
      refModal.current &&
      !refModal.current.contains(e.target) &&
      refBtnModal.current &&
      !refBtnModal.current.contains(e.target)
    ) {
      if (setHideDeleteBtn) {
        setHideDeleteBtn(false);
      }
      setOpenModal(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const cancelMyDrag = () => {};
  return (
    <motion.div
      layoutId={id}
      transition={{
        type: "spring",
        duration: activeId ? 0 : 0.6,
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        ...styles.cardStyles,
        backgroundColor: "var(--primary-50)",
        cursor: isDragging ? "grabbing" : "grab",
        transform: transform
          ? `translate(${transform.x}px, ${transform.y}px)`
          : "none",
        transition,
        border: isDragging ? "2px dashed var(--primary-500)" : "none",
        backgroundImage: isDragging ? "" : `url(${photo.url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 10,
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
      }}
      className="one-img | rlve"
    >
      {index === 0 && (
        <div className="am-the-thumb">
          <span>{t("addResidence.step9.photoOnCover")}</span>
        </div>
      )}
      <button
        onTouchStart={cancelMyDrag}
        ref={refBtnModal}
        style={{
          msTouchSelect: "none",
          touchAction: "auto",
        }}
        onClick={() => {
          setOpenModal(!openModal);
          if (setHideDeleteBtn) setHideDeleteBtn(!openModal);
        }}
        className="btn-open-modal | btn"
      >
        <img src={dotHori} alt="dot-horizontal" />
      </button>
      {openModal && (
        <div ref={refModal} className="select-options | flcolm jcenter liner">
          <button
            disabled={index === 0}
            onClick={() => {
              if (setHideDeleteBtn) setHideDeleteBtn(false);
              setOpenModal(false);
              handlePutCover(index);
            }}
            className="btn-cover-thumb | btn"
          >
            <img src={pick} alt="photo" />
            <span>{t("addResidence.step9.photoOnCover")}</span>
          </button>
          <button
            onClick={() => {
              if (setHideDeleteBtn) setHideDeleteBtn(false);
              setOpenModal(false);
              handleDeleteThisPick(index);
            }}
            className="btn-delete-photo | btn flrow liner"
          >
            <Trash color="var(--shade-white)" />
            <span>{t("addResidence.step9.deletePhoto")}</span>
          </button>
        </div>
      )}
    </motion.div>
  );
}
const styles = {
  cardStyles: {
    userSelect: "none",
    pointerEvent: "none",
    touchAction: "manipulation",
  },
};
