import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EyeSlashed, Pass } from "../../images/icons/JsxIcons";
import { NotificationService } from "../../services/Notification.service";
import { post } from "../../services/useFetchAction";
import { checkIfPassIsGood } from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";

export const NewPassPanelConnexion = ({
  currentState = 1,
  panelState,
  setPanelState,
  dataInscription,
  setDataInscription,
  requestId,
  token,
}) => {
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState("");
  const [secPassword, setSecPassword] = useState("");
  const [stateNewPassword, setStateNewPassword] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [stateSecPassword, setStateSecPassword] = useState({
    focus: false,
    error: false,
    good: false,
  });

  const [seeNewPassword, setSeeNewPassword] = useState(false);
  const [seeSecPassword, setSeeSecPassword] = useState(false);
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );

  useEffect(() => {
    if (newPassword.length > 0) {
      if (checkIfPassIsGood(newPassword)) {
        setStateNewPassword({ ...stateNewPassword, error: false, good: true });
      } else {
        setStateNewPassword({ ...stateNewPassword, error: true, good: false });
      }
    } else {
      setStateNewPassword({ ...stateNewPassword, error: false, good: false });
    }
    if (secPassword.length > 0) {
      if (newPassword === secPassword) {
        setStateSecPassword({ ...stateSecPassword, error: false, good: true });
      } else {
        setStateSecPassword({ ...stateSecPassword, error: true, good: false });
      }
    } else {
      setStateSecPassword({ ...stateSecPassword, error: false, good: false });
    }
  }, [secPassword, newPassword]);

  const handleGoNewPass = () => {
    if (checkIfPassIsGood(newPassword)) {
      handleUpdateBlockScreen(true);
      post("/users/password_recovery_update", {
        requestId: requestId,
        token: token,
        password: newPassword,
      })
        .then((res) => {
          NotificationService.addToQueue(
            0,
            t("modals.connexionPanel.notification.passChanged")
          );
          setPanelState({
            ...panelState,
            newPassword: 2,
            connexion: 1,
          });
        })
        .finally(() => {
          handleUpdateBlockScreen(false);
        });
    }
  };

  return (
    <div
      className={`contains inscription-part ${
        currentState == 0
          ? "go-left-side"
          : currentState == 1
          ? "come-center"
          : "go-right-side"
      } | flcolm`}
    >
      <label>{t("modals.connexionPanel.newPassword.title")}</label>
      <div className="separator label-separator">
        <div className="line"></div>
      </div>

      <p className="label-second">
        {t("modals.connexionPanel.newPassword.description")}
      </p>
      <div className="input-field | flcolm">
        <div className="input password | rlve">
          <Pass color={stateNewPassword.focus ? "var(--gray-600)" : "none"} />
          <input
            onBlur={() =>
              setStateNewPassword({ ...stateNewPassword, focus: false })
            }
            onFocus={() =>
              setStateNewPassword({ ...stateNewPassword, focus: true })
            }
            autoCorrect="false"
            autoCapitalize="false"
            autoComplete="false"
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder={t("modals.connexionPanel.newPassword.champs.password")}
            type={seeNewPassword ? "text" : "password"}
            name="password"
          />
          <button
            onClick={() => setSeeNewPassword(!seeNewPassword)}
            className="see-pass | btn"
          >
            <EyeSlashed
              stroke={seeNewPassword ? "var(--gray-300)" : "#9CA3AF"}
            />
          </button>
          {stateNewPassword.focus == false && stateNewPassword.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>
                {t("modals.connexionPanel.newPassword.issuePassword")}
              </span>
            </div>
          )}
        </div>
        <div className="input password | rlve">
          <Pass color={stateSecPassword.focus ? "var(--gray-600)" : "none"} />
          <input
            onBlur={() =>
              setStateSecPassword({ ...stateSecPassword, focus: false })
            }
            onFocus={() =>
              setStateSecPassword({ ...stateSecPassword, focus: true })
            }
            onChange={(e) => setSecPassword(e.target.value)}
            placeholder={t(
              "modals.connexionPanel.newPassword.champs.confirmPassword"
            )}
            autoCorrect="false"
            autoCapitalize="false"
            autoComplete="false"
            type={seeSecPassword ? "text" : "password"}
            name="password"
          />
          <button
            onClick={() => setSeeSecPassword(!seeSecPassword)}
            className="see-pass | btn"
          >
            <EyeSlashed
              stroke={seeSecPassword ? "var(--gray-300)" : "#9CA3AF"}
            />
          </button>
          {stateSecPassword.focus == false && stateSecPassword.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>
                {t("modals.connexionPanel.newPassword.issueConfirmPassword")}
              </span>
            </div>
          )}
        </div>
      </div>
      <button
        onClick={handleGoNewPass}
        className="next-btn | btn flex"
        disabled={!(stateNewPassword.good && stateSecPassword.good)}
      >
        {t("modals.connexionPanel.newPassword.btn")}
      </button>
    </div>
  );
};
