import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../globalTraduction";
import check from "../../images/icons/check.svg";
export default function ApercuResidence({ residence = null }) {
  const { t } = useTranslation();
  const language = i18n.language;
  return (
    <div className="apercu-residence | flcolm max-width">
      {residence ? (
        <>
          <label>{t("residencePage.apercu.title")}</label>
          <div className="contains-apercu | flrow max-width">
            <div className="apercu-part | flrow addgap16">
              <img src={check} alt="check-icon" />
              <div className="flcolm addgap8">
                <h4>{t("residencePage.apercu.interiorRulesTxt")}</h4>
                {residence.rules.length > 0 ? (
                  <ul className="list-option">
                    {residence.rules.map((ruleResidence) => (
                      <li className="rlve">
                        {language == "fr"
                          ? ruleResidence.rule.title
                          : ruleResidence.rule.titleEn}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>{t("residencePage.apercu.nothingToShow")}</p>
                )}
              </div>
            </div>
            <div className="apercu-part | flrow addgap16">
              <img src={check} alt="check-icon" />
              <div className="flcolm addgap8">
                <h4>{t("residencePage.apercu.socialActivitiesTxt")}</h4>
                {residence.activities.length > 0 ? (
                  <ul className="list-option">
                    {residence.activities.map((activitiesResidence) => (
                      <li className="rlve">
                        {language == "fr"
                          ? activitiesResidence.activity.name
                          : activitiesResidence.activity.nameEn}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>{t("residencePage.apercu.nothingToShow2")}</p>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <span
            style={{ width: "22%", height: "28px", marginBottom: 8 }}
            className="skeleton span-skeleton"
          ></span>
          <div className="contains-apercu | flrow max-widht">
            <div className="apercu-part | flrow addgap16">
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                }}
                className="skeleton span-skeleton"
              ></span>
              <div className="flcolm addgap8">
                <span
                  style={{ width: "40%", height: "18px" }}
                  className="skeleton span-skeleton"
                ></span>
                <span
                  style={{ width: "70%", height: "14px" }}
                  className="skeleton span-skeleton"
                ></span>
              </div>
            </div>
            <div className="apercu-part | flrow addgap16">
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                }}
                className="skeleton span-skeleton"
              ></span>
              <div className="flcolm addgap8">
                <span
                  style={{ width: "40%", height: "18px" }}
                  className="skeleton span-skeleton"
                ></span>
                <span
                  style={{ width: "70%", height: "14px" }}
                  className="skeleton span-skeleton"
                ></span>
                <span
                  style={{ width: "90%", height: "14px" }}
                  className="skeleton span-skeleton"
                ></span>
              </div>
            </div>
            <div className="apercu-part | flrow addgap16">
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                }}
                className="skeleton span-skeleton"
              ></span>
              <div className="flcolm addgap8">
                <span
                  style={{ width: "35%", height: "18px" }}
                  className="skeleton span-skeleton"
                ></span>

                <ul className="list-option flcolm">
                  {Array.from({ length: 2 }).map((_, index) => (
                    <span
                      style={{
                        width: "20%",
                        height: "14px",
                        marginBottom: 8,
                      }}
                      className="skeleton span-skeleton"
                    ></span>
                  ))}
                </ul>
              </div>
            </div>
            <div className="apercu-part | flrow addgap16">
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                }}
                className="skeleton span-skeleton"
              ></span>
              <div className="flcolm addgap8">
                <span
                  style={{ width: "30%", height: "18px" }}
                  className="skeleton span-skeleton"
                ></span>
                <span
                  style={{ width: "20%", height: "14px" }}
                  className="skeleton span-skeleton"
                ></span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
