import { GoogleMap } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Home, Warning } from "../../images/icons/JsxIcons";
import "../../styles/components/logementResidence.css";

export default function LogementResidence({ residence = null }) {
  const { t } = useTranslation();
  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const defaultCenter = {
    lat: parseFloat(residence?.lat),
    lng: parseFloat(residence?.lng),
  };
  const mapOptions = {
    mapTypeId: "terrain",
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    disableDefaultUI: true,
  };

  const [waitingToAddMarker, setWaitingToAddMarker] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => {
      if (window.google) {
        setWaitingToAddMarker(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [window.google]);
  if (!waitingToAddMarker)
    return (
      <div className="logement-residence | flcolm">
        <>
          <span
            className="skeleton span-skeleton"
            style={{ width: "30%", height: 35, marginBottom: 14 }}
          ></span>
          <div className="skeleton"></div>
        </>
      </div>
    );
  return (
    <div className="logement-residence | flcolm">
      {residence ? (
        <>
          <label>{t("residencePage.logement.title")}</label>
          <div className="map-show rlve">
            {/* <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_USER}
              loadingElement=""
            > */}
            <GoogleMap
              mapContainerStyle={mapStyles}
              center={defaultCenter}
              zoom={15}
              options={mapOptions}
            ></GoogleMap>
            {/* </LoadScript> */}
            <div className="home-place | center-absolute flex liner jcenter">
              <div className=""></div>
              <div className="home | flex center jcenter">
                <Home color="#fff" strokeWidth={2} />
              </div>
            </div>
          </div>
          <div className="info-reglement-warning flrow liner addgap16">
            <div className="flex liner">
              <Warning />
            </div>
            <p>{t("residencePage.logement.infoLocationLater")}</p>
          </div>
        </>
      ) : (
        <>
          <span
            className="skeleton span-skeleton"
            style={{ width: "30%", height: 35, marginBottom: 14 }}
          ></span>
          <div className="skeleton"></div>
        </>
      )}
    </div>
  );
}
