import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../../data/requestEndpoint";
import { NotificationService } from "../../../services/Notification.service";
import { post } from "../../../services/useFetchAction";
import { useStorePageManager } from "../../../store/PageManage";
import { useStoreUserManager } from "../../../store/UserManage";

export default function TokenCheckMail({
  saveTokenWaiting,
  telNumber,
  setSaveTokenWaiting,
  setTelNumberWaiting,
}) {
  const { t } = useTranslation();
  const [code, setCode] = useState(["", "", "", ""]);
  const [isFocused, setIsFocused] = useState([false, false, false, false]);
  const inputRefs = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];
  const userData = useStoreUserManager((state) => state.user);

  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;

    setCode(newCode);

    // Passe automatiquement au champ suivant si un chiffre est entré
    if (value !== "" && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    //verifier que tous les champs sont remplis
    if (index == 3) {
      let isFull = true;
      newCode.map((item) => {
        if (item == "") isFull = false;
      });
      if (isFull) {
        checkCode(newCode.join(""));
      }
    }
  };
  const checkCode = async (code) => {
    useStorePageManager.getState().handleUpdateBodyOverflow(false);
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    let recupCode = code;

    let data = {
      token: saveTokenWaiting,
      code: recupCode,
      type: "email",
    };
    await post(requestEndpoint.verifyOtp, data)
      .then((res) => {
        setCode(["", "", "", ""]);

        useStoreUserManager
          .getState()
          .handleUpdateUser({ mail: telNumber, emailVerified: true });
        NotificationService.addToQueue(
          0,
          t("modals.tokenMailCheck.notification.success")
        );
      })
      .catch((err) => {
        console.log(err);
        NotificationService.addToQueue(
          2,
          t("modals.tokenMailCheck.notification.errorCode")
        );
      })
      .finally(() => {
        useStorePageManager.getState().handleUpdateBodyOverflow(true);
        useStorePageManager.getState().handleUpdateBlockScreen(false);
      });
    // console.log("new habitation update");
  };
  const handleKeyDown = (index, event) => {
    // Passe automatiquement au champ précédent si la touche Backspace est pressée sur un champ vide
    if (event.key === "Backspace" && code[index] === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  // Gère le focus sur le champ en fonction de l'index
  const handleFocus = (index) => {
    const newIsFocused = [...isFocused];
    newIsFocused[index] = true;
    setIsFocused(newIsFocused);
  };

  // Gère le blur sur le champ en fonction de l'index
  const handleBlur = (index) => {
    const newIsFocused = [...isFocused];
    newIsFocused[index] = false;
    setIsFocused(newIsFocused);
  };

  if (userData.emailVerified) return null;
  if (telNumber.length <= 2) return null;
  return (
    <div className={`info-personnelle flcolm ${isFocused ? "focused" : ""}`}>
      <label>{t("modals.tokenMailCheck.title")}</label>
      <div className="separator"></div>
      <p className="info-sec -l">
        {t("modals.tokenMailCheck.secondDescription")}
      </p>
      <div className="flrow liner contains-digit">
        {code.map((digit, index) => (
          <input
            key={index}
            className={`code-input ${isFocused ? "no-placeholder" : ""}`}
            type="tel"
            maxLength="1"
            value={digit}
            placeholder={isFocused[index] ? "" : "-"}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onFocus={() => handleFocus(index)}
            onBlur={() => handleBlur(index)}
            ref={inputRefs[index]}
          />
        ))}
      </div>
    </div>
  );
}
