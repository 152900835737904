import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestEndpoint } from "../../data/requestEndpoint";
import { NotificationService } from "../../services/Notification.service";
import { decryptData, encryptData } from "../../services/encryption.service";
import { post } from "../../services/useFetchAction";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import { UserPreferences } from "../../store/UserPreferences";
import "../../styles/components/reservationRequestContent.css";
import BottomCancelConditionsReservation from "../cells/BottomCancelConditionsReservation";
import BuyWithReservation from "../cells/BuyWithReservation";
import RecapReservation from "../cells/RecapReservation";
import SideArticleReservation from "../cells/SideArticleReservation";
import ContainerPersoModal from "./ContainerPersoModal";

export default function ReservationRequestContent({
  t,
  data,
  loading,
  error,
  setOpenQuitModal,
  openQuitModal,
  headerShow = false,
  reservationCard,
}) {
  const navigate = useNavigate();
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const devise = UserPreferences((state) => state.devise);
  const showDevise = UserPreferences((state) => state.showDevise);
  const reservationParams = useStoreManager((state) => state.reservationParams);
  const navigateToBack = () => {
    handleUpdateBodyOverflow(true);
    navigate(-1);
  };
  const handleUpdateReservationParameters = useStoreManager(
    (state) => state.handleUpdateReservationParameters
  );
  const [canAskReservation, setCanAskReservation] = useState(false);
  const [badRenseignement, setBadRenseignement] = useState(null);

  //////////
  const [currentCountrySelected, setCurentCountrySelected] = useState();
  const [stateName, setStateName] = useState({
    focus: false,
    error: false,
    good: false,
    data: "",
    save: null,
  });
  const [stateTel, setStateTel] = useState({
    focus: false,
    error: false,
    good: false,
    data: "",
    save: null,
  });
  const [stateMail, setStateMail] = useState({
    focus: false,
    error: false,
    good: false,
    data: "",
    save: null,
  });
  const [stateAdress, setStateAdress] = useState({
    focus: false,
    error: false,
    good: false,
    data: "",
    save: null,
  });
  const [stateVille, setStateVille] = useState({
    focus: false,
    error: false,
    good: false,
    data: "",
    save: null,
  });
  const [stateZip, setStateZip] = useState({
    focus: false,
    error: false,
    good: false,
    data: "",
    save: null,
  });
  const [stateProvince, setStateProvince] = useState({
    focus: false,
    error: false,
    good: false,
    data: "",
    save: null,
  });
  const [currentProvince, setCurentProvince] = useState();
  const handleUpdateDesactiveHeader = useStorePageManager(
    (state) => state.handleUpdateDesactiveHeader
  );
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const [currentSelectedPaiement, setCurrentSelectedPaiement] = useState(null);
  const handleSaveInfo = () => {
    handleUpdateBlockScreen(true);
    let data = {
      name: stateName.data,
      tel: stateTel.data,
      mail: stateMail.data,
      adress: stateAdress.data,
      ville: stateVille.data,
      zip: stateZip.data,
      province: stateProvince.data,
      country: currentCountrySelected,
      province: currentProvince,
      currentPaiement: currentSelectedPaiement,
    };
    let encryptedData = encryptData(data);
    localStorage.setItem("reservationInfo", encryptedData);
    handleJustReserveBilling();
  };
  const handleJustReserveBilling = async (deleteStorage = false) => {
    if (!canAskReservation) return;
    if (deleteStorage) localStorage.removeItem("reservationInfo");
    handleUpdateBlockScreen(true);
    try {
      //dateFormat = 2024-10-10T10:53:28+0000
      //mettre la date au format 2024-10-10 et l heure au debut de la journee avec moment
      let dateStart = data.startDate.toISOString();
      let dateEnd = data.endDate.toISOString();

      // console.log(dateStart, dateEnd);

      let dataSend = {
        adults: data.nbrPersonne,
        fromDate: dateStart,
        toDate: dateEnd,
        residence: data.residenceId,
        // payment_method_id: currentSelectedPaiement.id,
        paymentData: {
          contact: stateTel.data,
          zip_code: stateZip.data,
          countryISO2: currentCountrySelected.code.toUpperCase(),
          provinceISO: currentProvince
            ? currentProvince.iso.toUpperCase()
            : null,
          city: stateVille.data,
          address: stateAdress.data,
          email: stateMail.data,
          firstname: stateName.data.split(" ")[0],
          lastname: stateName.data.replace(
            stateName.data.split(" ")[0] + " ",
            ""
          ),
        },
      };
      // console.log(dataSend);
      const booking = await post(requestEndpoint.booking, dataSend);
      // console.log(booking);
      setTimeout(() => {
        handleUpdateDesactiveHeader(true);
        handleUpdateBlockScreen(false);
        handleUpdateBodyOverflow(true);
        NotificationService.addToQueue(
          0,
          t("reservationPaiement.notification.bookingDone")
        );
        handleUpdateReservationParameters({
          ...reservationParams,
          residenceId: null,
          residence: null,
        });
        navigate("/profilereservation", { replace: true });
      }, 300);
    } catch (error) {
      handleUpdateBlockScreen(false);
      handleUpdateBodyOverflow(true);
      NotificationService.addToQueue(
        2,
        t("reservationPaiement.notification.cantForThisPeriod")
      );
      //verifier si error contient un message si oui l'afficher avec une notification
      //sinon afficher un message par defaut
      if (error.response) {
        if (error.response.data.message)
          NotificationService.addToQueue(1, error.response.data.message);
      } else {
        NotificationService.addToQueue(
          1,
          t("reservationPaiement.notification.periodAlreadyBooked")
        );
      }
    }
  };
  //////////

  useEffect(() => {
    //verifier si les infos sont deja enregistrer dans le localstorage et les mettre dans les states
    let reservationInfo = localStorage.getItem("reservationInfo");
    if (reservationInfo) {
      handleTakeSaveData(reservationInfo);
    } else {
      if (reservationCard) {
        // console.log("reservationCard", reservationCard.paymentData);
        setStateName({
          ...stateName,

          save:
            reservationCard.paymentData.firstname +
            " " +
            reservationCard.paymentData.lastname,
          data:
            reservationCard.paymentData.firstname +
            " " +
            reservationCard.paymentData.lastname,
          good: true,
        });
        setStateTel({
          ...stateTel,
          save: reservationCard.paymentData.contact,
          data: reservationCard.paymentData.contact,
          good: true,
        });
        setStateVille({
          ...stateVille,
          save: reservationCard.paymentData.city,
          data: reservationCard.paymentData.city,
          good: true,
        });
        setStateMail({
          ...stateMail,
          save: reservationCard.paymentData.email,
          data: reservationCard.paymentData.email,
          good: true,
        });
        setStateAdress({
          ...stateAdress,
          save: reservationCard.paymentData.address,
          data: reservationCard.paymentData.address,
          good: true,
        });
        setStateZip({
          ...stateZip,
          save: reservationCard.paymentData.zip_code,
          data: reservationCard.paymentData.zip_code,
          good: true,
        });
        if (reservationCard.paymentData.provinceISO)
          setStateProvince({
            ...stateProvince,
            save: reservationCard.paymentData.provinceISO,
            data: reservationCard.paymentData.provinceISO,
            good: true,
          });
      }
    }
  }, []);

  const handleTakeSaveData = async (reservationInfo) => {
    let decryptedData = await decryptData(reservationInfo, true);
    if (!decryptedData) return;
    setTimeout(() => {
      setStateName({
        ...stateName,
        save: decryptedData.name,
        data: decryptedData.name,
        good: true,
      });
      setStateTel({
        ...stateTel,
        save: decryptedData.tel,
        data: decryptedData.tel,
        good: true,
      });
      setStateMail({
        ...stateMail,
        save: decryptedData.mail,
        data: decryptedData.mail,
        good: true,
      });
      setStateAdress({
        ...stateAdress,
        save: decryptedData.adress,
        data: decryptedData.adress,
        good: true,
      });
      setStateVille({
        ...stateVille,
        save: decryptedData.ville,
        data: decryptedData.ville,
        good: true,
      });
      setStateZip({
        ...stateZip,
        save: decryptedData.zip,
        data: decryptedData.zip,
        good: true,
      });
      setStateProvince({
        ...stateProvince,
        save: decryptedData.province,
        data: decryptedData.province,
        good: true,
      });
      setCurentCountrySelected(decryptedData.country);
      setCurentProvince(decryptedData.province);
      setCurrentSelectedPaiement(decryptedData.currentPaiement);
    }, 500);
  };
  return (
    <>
      <section className="full-panel | flcolm">
        <RecapReservation
          t={t}
          data={data}
          setOpenQuitModal={setOpenQuitModal}
          reservationCard={reservationCard}
        />
        <BuyWithReservation
          t={t}
          setCanAskReservation={setCanAskReservation}
          currentCountrySelected={currentCountrySelected}
          setCurentCountrySelected={setCurentCountrySelected}
          setBadRenseignement={setBadRenseignement}
          stateName={stateName}
          setStateName={setStateName}
          stateTel={stateTel}
          setStateTel={setStateTel}
          stateMail={stateMail}
          setStateMail={setStateMail}
          stateAdress={stateAdress}
          setStateAdress={setStateAdress}
          stateVille={stateVille}
          setStateVille={setStateVille}
          stateZip={stateZip}
          setStateZip={setStateZip}
          stateProvince={stateProvince}
          setStateProvince={setStateProvince}
          currentProvinceSelected={currentProvince}
          setCurentProvince={setCurentProvince}
          reservationCard={reservationCard}
          setCurrentSelectedPaiement={setCurrentSelectedPaiement}
          currentSelectedPaiement={currentSelectedPaiement}
        />
        <BottomCancelConditionsReservation
          t={t}
          data={data}
          canAskReservation={canAskReservation}
          badRenseignement={badRenseignement}
          handleJustReserveBilling={() => handleJustReserveBilling(true)}
          handleSaveInfo={handleSaveInfo}
          reservationCard={reservationCard}
          dataPaiement={{
            name: stateName.data,
            tel: stateTel.data,
            mail: stateMail.data,
            adress: stateAdress.data,
            ville: stateVille.data,
            zip: stateZip.data,
            province: stateProvince.data,
            country: currentCountrySelected,
            province: currentProvince,
            currentPaiement: currentSelectedPaiement,
          }}
          setStateName={setStateName}
          setStateTel={setStateTel}
          setStateMail={setStateMail}
          setStateAdress={setStateAdress}
          setStateVille={setStateVille}
          setStateZip={setStateZip}
        />
        <SideArticleReservation
          reservationCard={reservationCard}
          headerShow={headerShow}
          t={t}
          data={data}
          showDevise={showDevise}
        />
      </section>
      <ContainerPersoModal
        baseTop={true}
        isOpen={openQuitModal}
        toggle={setOpenQuitModal}
        title={t("reservationPaiement.modals.quit.title")}
        className="modal-quit"
      >
        <div
          style={{ paddingTop: 20, paddingBottom: 10 }}
          className="quit-check | flcolm addgap16"
        >
          <p className="p-just -c">
            {t("reservationPaiement.modals.quit.nopTxtAvertissement")}
          </p>
          <button onClick={() => navigateToBack()} className="btn cc-btn">
            {t("reservationPaiement.modals.quit.btnTxt")}
          </button>
        </div>
      </ContainerPersoModal>
    </>
  );
}
