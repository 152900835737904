import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../globalTraduction";
import {
  handleFormatNumber,
  handleFormatToLocalString,
} from "../../services/usePersoFormat";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import { UserPreferences } from "../../store/UserPreferences";
import "../../styles/components/rangeSelectFilterOption.css";
import MultiRangeSlider from "./MultiRangeSlider";
import "./multiRangeSlider.css";
export default function RangeSelectfilterOption({
  minP = 1000,
  maxP = 200000,
  storeParameters,
  updateStoreParams,
  setPriceRangeStateParent,
  myMinP = 1000,
  mymaxP = 200000,
  priceRangeState,
  setPriceRangeState,
  handleUpdateRange,
}) {
  const { t } = useTranslation();
  const devise = UserPreferences((state) => state.devise);
  const showDevise = UserPreferences((state) => state.showDevise);
  const language = i18n.language;
  const sign = useStoreCurrencies((state) => state.sign);
  const refStartInp = useRef(null);
  const refEndInp = useRef(null);

  useEffect(() => {
    if (!refStartInp || !refEndInp) return;
    refStartInp.current.value = handleFormatNumber(
      priceRangeState[0],
      showDevise,
      language
    );
    refEndInp.current.value = handleFormatNumber(
      priceRangeState[1],
      showDevise,
      language
    );
  }, [priceRangeState, refStartInp, refEndInp]);

  const handleOnFocus = (e) => {
    if (e.target.value.trim() != "") {
      const inputValue = handleFormatToLocalString(
        e.target.value,
        language,
        sign
      );
      e.target.value = inputValue.replace(` ${showDevise}`, "");
    }
  };

  const handleOnChange = (e) => {
    e.target.value = handleFormatToLocalString(e.target.value, language, sign);
  };

  const handleOnBlur = (e, type) => {
    const inputValue =
      e.target.value.trim() == "" ? "0" : e.target.value.toString();
    const cleanedValue = inputValue.replace(/[^0-9]/g, "");
    let numericValue = parseFloat(cleanedValue);
    if (type == "start") {
      if (numericValue > priceRangeState[1] && numericValue < maxP) {
        setPriceRangeState((prev) => {
          handleUpdateRange([prev[1], numericValue]);
          return [prev[1], numericValue];
        });
      } else if (numericValue > priceRangeState[1] && numericValue >= maxP) {
        setPriceRangeState((prev) => {
          handleUpdateRange([prev[1], maxP]);
          return [prev[1], maxP];
        });
      } else if (numericValue < minP) {
        setPriceRangeState((prev) => {
          handleUpdateRange([minP, prev[1]]);
          return [minP, prev[1]];
        });
      } else {
        setPriceRangeState((prev) => {
          handleUpdateRange([numericValue, prev[1]]);
          return [numericValue, prev[1]];
        });
      }
    } else {
      if (numericValue < priceRangeState[0] && numericValue > minP) {
        setPriceRangeState((prev) => {
          handleUpdateRange([numericValue, prev[0]]);
          return [numericValue, prev[0]];
        });
      } else if (numericValue < priceRangeState[0] && numericValue <= minP) {
        setPriceRangeState((prev) => {
          handleUpdateRange([minP, prev[0]]);
          return [minP, prev[0]];
        });
      } else if (numericValue > maxP) {
        setPriceRangeState((prev) => {
          handleUpdateRange([prev[0], maxP]);
          return [prev[0], maxP];
        });
      } else {
        setPriceRangeState((prev) => {
          handleUpdateRange([prev[0], numericValue]);
          return [prev[0], numericValue];
        });
      }
    }
    //
  };

  // NEW RANGE SLIIDER

  return (
    <div className="range-select flcolm">
      <MultiRangeSlider
        min={minP}
        max={maxP}
        handleReset={() => setPriceRangeState([myMinP, mymaxP])}
        onChange={({ min, max }) => setPriceRangeState([min, max])}
        baseV={priceRangeState}
      />
      <div className="input-value | flrow btw liner">
        <div className="input-v rlve">
          <input
            ref={refStartInp}
            className="rs-input-number"
            type="text"
            placeholder={t("store.filters.range.min")}
            // disabled={true}
            onFocus={handleOnFocus}
            onChange={handleOnChange}
            onBlur={(e) => handleOnBlur(e, "start")}
          />
          <span className="rs-night">{t("store.filters.range.night")}</span>
        </div>

        <span></span>
        <div className="input-v rlve">
          <input
            ref={refEndInp}
            className="rs-input-number"
            type="text"
            // disabled={true}
            placeholder={t("store.filters.range.max")}
            onFocus={handleOnFocus}
            onChange={handleOnChange}
            onBlur={(e) => handleOnBlur(e, "end")}
          />
          <span className="rs-night">{t("store.filters.range.night")}</span>
        </div>
      </div>
    </div>
  );
}
