/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrayParam,
  NumberParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import {
  BuildingTypeIcon,
  ChaletTypeIcon,
  ChevronDownSimple,
  Home,
  HomeTypeIcon,
  HoteChambersIcon,
} from "../../images/icons/JsxIcons";
import caseIcon from "../../images/icons/stop.svg";
import crossCloseIcon from "../../images/icons/x-close.svg";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import "../../styles/components/filtersExtendPanel.css";
import AddLessDiv from "../molecules/AddLessDiv";
import RangeSelectfilterOption from "../molecules/RangeSelectfilterOption";
export default function FiltersExtendPanel({
  setPriceRangeStateCurrent,
  priceRangeStateCurrent,
  language,
  filtersExtendPanel,
  setFiltersExtendPanel,
  filtersComodities,
  filtersSocialsActivities,
  setFiltersSocialsActivities,
  handleUpdateFilterComodities,
  handleUpdateStoreParameters,
  handleUpdateSocialActivities,
  storeParameters,
  priceRange,
  setFiltersComodities,
}) {
  const { t } = useTranslation();
  const [query] = useQueryParams({
    roomsId: withDefault(ArrayParam, []),
    roomsNum: withDefault(ArrayParam, []),
    minprice: withDefault(NumberParam, null),
    maxprice: withDefault(NumberParam, null),
  });
  const findMyFirstValueInUrl = (id) => {
    let value = 0;
    if (query.roomsId.length == 0) return value;
    try {
      query.roomsId.map((room, index) => {
        const IdRoom = parseInt(room);
        if (IdRoom == id) {
          const valueToGive = parseInt(query.roomsNum[index]);
          value = valueToGive < 0 ? 0 : valueToGive;
        }
      });
      return value;
    } catch (error) {
      return value;
    }
  };
  const [numberBedRoom, setNumberBedRoom] = useState(() =>
    findMyFirstValueInUrl(1)
  );
  const [numberSalon, setNumberSalons] = useState(() =>
    findMyFirstValueInUrl(2)
  );
  const [numberBathrooms, setNumberBathrooms] = useState(() =>
    findMyFirstValueInUrl(5)
  );
  const [priceRangeState, setPriceRangeState] = useState([null, null]);
  const [minMaxPrice, setMinMaxPrice] = [2000, 200000];
  const [currentOpenActivitiesPanel, setCurrentOpenActivitiesPanel] = useState([
    {
      id: 1,
      open: false,
    },
    {
      id: 2,
      open: false,
    },
    {
      id: 3,
      open: false,
    },
    {
      id: 4,
      open: false,
    },
  ]);
  useEffect(() => {
    const createRoomEntry = (roomId, quantity) => ({ roomId, quantity });
    const newDataRooms = [
      numberBedRoom !== 0 && createRoomEntry(1, numberBedRoom),
      numberSalon !== 0 && createRoomEntry(2, numberSalon),
      numberBathrooms !== 0 && createRoomEntry(5, numberBathrooms),
    ].filter(Boolean);

    handleUpdateStoreParameters(
      {
        ...storeParameters,
        roomIds: newDataRooms.length > 0 ? newDataRooms : null,
      },
      "FiltersExtendPanel 1"
    );
  }, [numberBedRoom, numberBathrooms, numberSalon]);

  const findMyRoomAndGetQuantity = (id) => {
    let quantity = 0;
    // console.log("storeParameters.roomIds", storeParameters.roomIds);
    if (storeParameters.roomIds == null) return quantity;
    storeParameters.roomIds.map((room) => {
      if (room.roomId == id) {
        quantity = room.quantity;
      }
    });
    return quantity;
  };

  useEffect(() => {
    // console.log("priceRange", priceRange);
    if (priceRange[0] == null || priceRange[1] == null) return;
    setPriceRangeState([priceRange[0], priceRange[1]]);
  }, [priceRange]);

  const handleUpdateResiType = (val) => {
    let newTabResi = storeParameters.typeIds;
    let stateVal = storeParameters.typeIds.includes(val);
    if (stateVal == false) {
      newTabResi.push(val);
    } else {
      newTabResi = newTabResi.filter((id) => {
        return id !== val;
      });
    }
    newTabResi.sort();
    handleUpdateStoreParameters(
      {
        ...storeParameters,
        typeIds: newTabResi,
      },
      "FiltersExtendPanel 2"
    );
  };
  const handleUpdateResiOccupation = (val) => {
    let occupation = storeParameters.occupation;
    if (occupation == val) {
      occupation = null;
    } else {
      occupation = val;
    }
    handleUpdateStoreParameters(
      {
        ...storeParameters,
        occupation: occupation,
      },
      "FiltersExtendPanel 3"
    );
    useStoreManager.getState().handleUpdateSearchStoreParameters();
  };

  const refreshRange = useStoreManager((state) => state.refreshRange);
  const handleResetAllFilters = async () => {
    setNumberBedRoom(0);
    setNumberBathrooms(0);
    setNumberSalons(0);
    await setPriceRangeStateCurrent([priceRange[0], priceRange[1]]);
    await setFiltersSocialsActivities((prev) => {
      const newFilters = prev.map((filter) => {
        return { ...filter, selected: false };
      });
      return newFilters;
    });
    await setFiltersComodities((prev) => {
      const newFilters = prev.map((filter) => {
        return { ...filter, selected: false };
      });
      return newFilters;
    });
    let rangeReset = [
      priceRangeState[0] ?? minMaxPrice[0],
      priceRangeState[1] ?? minMaxPrice[1],
    ];
    await setPriceRangeStateCurrent(rangeReset);
    if (refreshRange) refreshRange();
    if (updateRange) updateRange(rangeReset);
    handleUpdateStoreParameters(
      {
        ...storeParameters,
        minPrice: priceRangeState[0] ?? minMaxPrice[0],
        maxPrice: priceRangeState[1] ?? minMaxPrice[1],
        assetId: [],
        commodites: [],
        typeIds: [],
        activitiesIds: [],
        occupation: null,
        roomIds: [],
      },
      "FiltersExtendPanel 4"
    );
  };
  const refreshStore = useStoreManager((state) => state.refreshStore);
  const updateRange = useStoreManager((state) => state.updateRange);

  const handleGoSearch = async () => {
    await handleUpdateStoreParameters(
      {
        ...storeParameters,
        minPrice: priceRangeStateCurrent[0],
        maxPrice: priceRangeStateCurrent[1],
        page: 1,
      },
      "FiltersExtendPanel 5"
    );
    goSearch();
  };
  const goSearch = () => {
    useStoreManager.getState().handleUpdateSearchStoreParameters();
    useStorePageManager.getState().handleUpdateCurrentMapTouched(null);
    if (refreshStore) refreshStore();
    setFiltersExtendPanel(false);
  };

  const selectComoditiesToShow = (allIdsToShow) => {
    let newFilters = [];
    let newFiltersComodities = filtersComodities.slice();
    newFiltersComodities.map((filter) => {
      if (allIdsToShow.includes(filter.id)) {
        newFilters.push(filter);
      }
    });
    return newFilters;
  };

  const selectActivitiesToShow = (allIdsToShow) => {
    let newFilters = [];
    let newFiltersActivities = filtersSocialsActivities.slice();
    newFiltersActivities.map((filter) => {
      if (allIdsToShow.includes(filter.id)) {
        newFilters.push(filter);
      }
    });
    return newFilters;
  };
  const handleToggleActivitiesPanel = (id) => {
    let newCurrentOpenActivitiesPanel = currentOpenActivitiesPanel.slice();
    newCurrentOpenActivitiesPanel.map((panel) => {
      if (panel.id == id) {
        panel.open = !panel.open;
      }
    });
    setCurrentOpenActivitiesPanel(newCurrentOpenActivitiesPanel);
  };
  const checkIfAmOpen = (id) => {
    let amOpen = false;
    currentOpenActivitiesPanel.map((panel) => {
      if (panel.id == id) {
        amOpen = panel.open;
      }
    });
    return amOpen;
  };

  useEffect(() => {
    if (!filtersComodities) return;
    // selectComoditiesToShow([1, 2, 3, 4, 8, 9, 11, 25]);
    // selectComoditiesNotToShow([1, 2, 5, 6]);
  }, [filtersComodities]);

  const handleUpdateRange = (range) => {
    setPriceRangeStateCurrent(range);
    handleUpdateStoreParameters(
      {
        ...storeParameters,
        minPrice: range[0],
        maxPrice: range[1],
      },
      "FiltersExtendPanel 6"
    );
    updateRange(range);
  };

  const [baseDataRoomIds, setBaseDataIds] = useState([]);
  useEffect(() => {
    //mise a jour de setFiltersSocialsActivities avec les activités venant de l'url
    let rooms = [];
    rooms.push(findMyRoomAndGetQuantity(1));
    rooms.push(findMyRoomAndGetQuantity(2));
    rooms.push(findMyRoomAndGetQuantity(5));
    // console.log("rooms", rooms);
    setBaseDataIds(rooms);
  }, []);

  return (
    filtersExtendPanel && (
      <div className="filters-panel">
        <div
          className="background-touch"
          onClick={() => {
            setFiltersExtendPanel(false);
          }}
        ></div>
        <div className="contains | rlve flcolm btw">
          <div className="title | flex jcenter">
            <button
              onClick={() => {
                setFiltersExtendPanel(false);
              }}
              className="close-btn | btn"
            >
              <img src={crossCloseIcon} alt="close" />
            </button>
            <label>{t("store.filters.title")}</label>
          </div>
          <div className="all-filters full-contains | flcolm">
            <div className="filter-selected-panel | flcolm">
              <label>{t("store.filters.range.title")}</label>
              <RangeSelectfilterOption
                minP={priceRangeState[0] ?? minMaxPrice[0]}
                maxP={priceRangeState[1] ?? minMaxPrice[1]}
                setPriceRangeStateParent={setPriceRangeState}
                storeParameters={storeParameters}
                updateStoreParams={handleUpdateStoreParameters}
                setPriceRangeState={setPriceRangeStateCurrent}
                priceRangeState={priceRangeStateCurrent}
                handleUpdateRange={handleUpdateRange}
              />
            </div>
            <div className="filter-selected-panel | flcolm">
              <label>{t("piecesNumber")}</label>
              <AddLessDiv
                classname="room-nbr"
                title={`${
                  numberBedRoom > 1
                    ? t("store.filters.room.bedroom.bedroomTxtPlural")
                    : t("store.filters.room.bedroom.bedroomTxt")
                }`}
                baseData={baseDataRoomIds[0] ?? 0}
                setData={setNumberBedRoom}
                data={numberBedRoom}
              />
              <AddLessDiv
                classname="salon-nbr"
                title={`${
                  numberSalon > 1
                    ? t("store.filters.room.salon.salonTxtPlural")
                    : t("store.filters.room.salon.salonTxt")
                }`}
                baseData={baseDataRoomIds[1] ?? 0}
                setData={setNumberSalons}
                data={numberSalon}
              />
              <AddLessDiv
                classname="bathroom-nbr"
                title={`${
                  numberBathrooms > 1
                    ? t("store.filters.room.bathroom.bathroomTxtPlural")
                    : t("store.filters.room.bathroom.bathroomTxt")
                }`}
                baseData={baseDataRoomIds[2] ?? 0}
                setData={setNumberBathrooms}
                data={numberBathrooms}
              />
            </div>
            <div className="filter-selected-panel | flcolm">
              <label>{t("store.filters.occupation.title")}</label>
              <div className="select-acces-resi flrow liner btw">
                <button
                  onClick={() => handleUpdateResiOccupation("Full")}
                  className={`one-select-resi btn flrow liner addgap16 ${
                    storeParameters.occupation == "Full" ? "selected" : ""
                  }`}
                >
                  <div className="left flcolm">
                    <label>{t("store.filters.occupation.full.title")}</label>
                    <p>{t("store.filters.occupation.full.description")}</p>
                  </div>
                  <Home color="var(--shade-black)" />
                </button>
                <button
                  onClick={() => handleUpdateResiOccupation("Partial")}
                  className={`one-select-resi btn flrow liner addgap16 ${
                    storeParameters.occupation == "Partial" ? "selected" : ""
                  }`}
                >
                  <div className="left flcolm">
                    <label>{t("store.filters.occupation.shared.title")}</label>
                    <p>{t("store.filters.occupation.shared.description")}</p>
                  </div>
                  <HoteChambersIcon stroke="var(--shade-black)" />
                </button>
              </div>
            </div>
            <div className="filter-selected-panel commodities-select | flcolm">
              <label>{t("store.filters.occupation.commodities.title")}</label>
              <div className="select-contains | flcolm addgap16 maxwidth">
                <strong>
                  {t("store.filters.occupation.commodities.equipments")}
                </strong>
                <div className="case-selectors | flrow">
                  {filtersComodities &&
                    selectComoditiesToShow([1, 3, 4, 8, 9, 11, 17, 25, 29]).map(
                      (filter) => (
                        <React.Fragment key={filter.id}>
                          <button
                            onClick={() => handleUpdateFilterComodities(filter)}
                            className="case-selector | btn flrow addgap8 rlve"
                          >
                            {filter.selected && (
                              <img
                                className="close-selected"
                                src={crossCloseIcon}
                                alt="cross-close"
                              />
                            )}
                            <img src={caseIcon} alt="case-selector" />
                            <span>
                              {language == "fr"
                                ? filter.title
                                : filter.titleEng}
                            </span>
                          </button>
                        </React.Fragment>
                      )
                    )}
                </div>
              </div>
              <div className="select-contains | flcolm addgap16 maxwidth">
                <strong>
                  {t("store.filters.occupation.commodities.security")}
                </strong>
                <div className="case-selectors | flrow">
                  {filtersComodities &&
                    selectComoditiesToShow([12, 13, 14, 15]).map((filter) => (
                      <React.Fragment key={filter.id}>
                        <button
                          onClick={() => handleUpdateFilterComodities(filter)}
                          className="case-selector | btn flrow addgap8 rlve"
                        >
                          {filter.selected && (
                            <img
                              className="close-selected"
                              src={crossCloseIcon}
                              alt="cross-close"
                            />
                          )}
                          <img src={caseIcon} alt="case-selector" />
                          <span>
                            {language == "fr" ? filter.title : filter.titleEng}
                          </span>
                        </button>
                      </React.Fragment>
                    ))}
                </div>
              </div>
              <div className="select-contains | flcolm addgap16 maxwidth">
                <strong>
                  {t("store.filters.occupation.commodities.leisure")}
                </strong>
                <div className="case-selectors | flrow">
                  {filtersComodities &&
                    selectComoditiesToShow([18, 19, 24]).map((filter) => (
                      <React.Fragment key={filter.id}>
                        <button
                          onClick={() => handleUpdateFilterComodities(filter)}
                          className="case-selector | btn flrow addgap8 rlve"
                        >
                          {filter.selected && (
                            <img
                              className="close-selected"
                              src={crossCloseIcon}
                              alt="cross-close"
                            />
                          )}
                          <img src={caseIcon} alt="case-selector" />
                          <span>
                            {language == "fr" ? filter.title : filter.titleEng}
                          </span>
                        </button>
                      </React.Fragment>
                    ))}
                </div>
              </div>
              <div className="select-contains | flcolm addgap16 maxwidth">
                <strong>
                  {t("store.filters.occupation.commodities.convenience")}
                </strong>
                <div className="case-selectors | flrow">
                  {filtersComodities &&
                    selectComoditiesToShow([21, 22, 23]).map((filter) => (
                      <React.Fragment key={filter.id}>
                        <button
                          onClick={() => handleUpdateFilterComodities(filter)}
                          className="case-selector | btn flrow addgap8 rlve"
                        >
                          {filter.selected && (
                            <img
                              className="close-selected"
                              src={crossCloseIcon}
                              alt="cross-close"
                            />
                          )}
                          <img src={caseIcon} alt="case-selector" />
                          <span>
                            {language == "fr" ? filter.title : filter.titleEng}
                          </span>
                        </button>
                      </React.Fragment>
                    ))}
                </div>
              </div>
              <div className="select-contains | flcolm addgap16 maxwidth">
                <strong>
                  {t("store.filters.occupation.commodities.other")}
                </strong>
                <div className="case-selectors | flrow">
                  {filtersComodities &&
                    selectComoditiesToShow([7, 40]).map((filter) => (
                      <React.Fragment key={filter.id}>
                        <button
                          onClick={() => handleUpdateFilterComodities(filter)}
                          className="case-selector | btn flrow addgap8 rlve"
                        >
                          {filter.selected && (
                            <img
                              className="close-selected"
                              src={crossCloseIcon}
                              alt="cross-close"
                            />
                          )}
                          <img src={caseIcon} alt="case-selector" />
                          <span>
                            {language == "fr" ? filter.title : filter.titleEng}
                          </span>
                        </button>
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>
            <div className="filter-selected-panel | flcolm">
              <label>{t("store.filters.social.title")}</label>
              <div
                style={{
                  rowGap: 0,
                }}
                className="case-selectors | flcolm"
              >
                <button
                  onClick={() => handleToggleActivitiesPanel(1)}
                  className={`btn open-select liner ${
                    checkIfAmOpen(1) ? "opened" : ""
                  }`}
                >
                  <span>
                    <strong>{t("store.filters.social.events")}</strong>
                  </span>
                  <ChevronDownSimple stroke="var(--shade-black)" />
                </button>
                <div
                  className={`flcolm opened-activitie maxwidth addgap16 ${
                    checkIfAmOpen(1) ? "opened" : ""
                  }`}
                >
                  {filtersSocialsActivities &&
                    selectActivitiesToShow([1, 2, 3]).map((filter) => (
                      <React.Fragment key={filter.id}>
                        <button
                          onClick={() => handleUpdateSocialActivities(filter)}
                          className="case-selector | btn flrow addgap8 rlve"
                        >
                          {filter.selected && (
                            <img
                              className="close-selected"
                              src={crossCloseIcon}
                              alt="cross-close"
                            />
                          )}
                          <img src={caseIcon} alt="case-selector" />
                          <span>
                            {language == "fr" ? filter.name : filter.nameEn}
                          </span>
                        </button>
                      </React.Fragment>
                    ))}
                </div>
                <button
                  onClick={() => handleToggleActivitiesPanel(2)}
                  className={`btn open-select liner ${
                    checkIfAmOpen(2) ? "opened" : ""
                  }`}
                >
                  <span>
                    <strong>{t("store.filters.social.nightEvents")}</strong>
                  </span>
                  <ChevronDownSimple stroke="var(--shade-black)" />
                </button>
                <div
                  className={`flcolm opened-activitie maxwidth addgap16 ${
                    checkIfAmOpen(2) ? "opened" : ""
                  }`}
                >
                  {filtersSocialsActivities &&
                    selectActivitiesToShow([4, 5]).map((filter) => (
                      <React.Fragment key={filter.id}>
                        <button
                          onClick={() => handleUpdateSocialActivities(filter)}
                          className="case-selector | btn flrow addgap8 rlve"
                        >
                          {filter.selected && (
                            <img
                              className="close-selected"
                              src={crossCloseIcon}
                              alt="cross-close"
                            />
                          )}
                          <img src={caseIcon} alt="case-selector" />
                          <span>
                            {language == "fr" ? filter.name : filter.nameEn}
                          </span>
                        </button>
                      </React.Fragment>
                    ))}
                </div>
                <button
                  onClick={() => handleToggleActivitiesPanel(3)}
                  className={`btn open-select liner ${
                    checkIfAmOpen(3) ? "opened" : ""
                  }`}
                >
                  <span>
                    <strong>{t("store.filters.activities.title")}</strong>
                  </span>
                  <ChevronDownSimple stroke="var(--shade-black)" />
                </button>
                <div
                  className={`flcolm opened-activitie maxwidth addgap16 ${
                    checkIfAmOpen(3) ? "opened" : ""
                  }`}
                >
                  {filtersSocialsActivities &&
                    selectActivitiesToShow([6, 7]).map((filter) => (
                      <React.Fragment key={filter.id}>
                        <button
                          onClick={() => handleUpdateSocialActivities(filter)}
                          className="case-selector | btn flrow addgap8 rlve"
                        >
                          {filter.selected && (
                            <img
                              className="close-selected"
                              src={crossCloseIcon}
                              alt="cross-close"
                            />
                          )}
                          <img src={caseIcon} alt="case-selector" />
                          <span>
                            {language == "fr" ? filter.name : filter.nameEn}
                          </span>
                        </button>
                      </React.Fragment>
                    ))}
                </div>
                <button
                  onClick={() => handleToggleActivitiesPanel(4)}
                  className={`btn open-select liner ${
                    checkIfAmOpen(4) ? "opened" : ""
                  }`}
                >
                  <span>
                    <strong>{t("store.filters.arts.title")}</strong>
                  </span>
                  <ChevronDownSimple stroke="var(--shade-black)" />
                </button>
                <div
                  className={`flcolm opened-activitie maxwidth addgap16 ${
                    checkIfAmOpen(4) ? "opened" : ""
                  }`}
                >
                  {filtersSocialsActivities &&
                    selectActivitiesToShow([11, 12, 13, 14]).map((filter) => (
                      <React.Fragment key={filter.id}>
                        <button
                          onClick={() => handleUpdateSocialActivities(filter)}
                          className="case-selector | btn flrow addgap8 rlve"
                        >
                          {filter.selected && (
                            <img
                              className="close-selected"
                              src={crossCloseIcon}
                              alt="cross-close"
                            />
                          )}
                          <img src={caseIcon} alt="case-selector" />
                          <span>
                            {language == "fr" ? filter.name : filter.nameEn}
                          </span>
                        </button>
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>
            <div className="filter-selected-panel | flcolm">
              <label>{t("store.filters.residence.title")}</label>
              <div className="residence-type | flrow addgap8">
                <button
                  onClick={() => {
                    handleUpdateResiType(1);
                  }}
                  className={`${
                    storeParameters.typeIds?.includes(1) && "selected-type | "
                  } btn flcolm`}
                >
                  <HomeTypeIcon
                    stroke={
                      storeParameters.typeIds?.includes(1)
                        ? "#1E1A21"
                        : "#1E1A21"
                    }
                  />
                  <span>{t("store.filters.residence.home")}</span>
                </button>
                <button
                  onClick={() => {
                    handleUpdateResiType(2);
                  }}
                  className={`${
                    storeParameters.typeIds?.includes(2) && "selected-type | "
                  } btn flcolm`}
                >
                  <BuildingTypeIcon
                    stroke={
                      storeParameters.typeIds?.includes(2)
                        ? "#1E1A21"
                        : "#1E1A21"
                    }
                  />
                  <span>{t("store.filters.residence.appart")}</span>
                </button>
                <button
                  onClick={() => {
                    handleUpdateResiType(3);
                  }}
                  className={`${
                    storeParameters.typeIds?.includes(3) && "selected-type | "
                  } btn flcolm`}
                >
                  <ChaletTypeIcon
                    stroke={
                      storeParameters.typeIds?.includes(3)
                        ? "#1E1A21"
                        : "#1E1A21"
                    }
                  />
                  <span>{t("store.filters.residence.chalet")}</span>
                </button>
              </div>
            </div>
          </div>
          <div className="reset-done | flrow center btw">
            <button onClick={handleResetAllFilters} className="reset-btn | btn">
              {t("store.filters.action.resetAll")}
            </button>
            <button onClick={handleGoSearch} className="done-btn | btn pry-btn">
              {t("store.filters.action.search")}
            </button>
          </div>
        </div>
      </div>
    )
  );
}
