import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../globalTraduction";
import copyIcon from "../../images/icons/copy-02.svg";
import { Star } from "../../images/icons/JsxIcons";
import MessageIcon from "../../images/icons/message-circle-01.svg";
import WhaIcon from "../../images/icons/whatsapp.svg";
import { NotificationService } from "../../services/Notification.service";
import "../../styles/components/shareModal.css";
export default function ShareModal({ handleOutModal, data }) {
  const { t } = useTranslation();
  const [linkCopied, setLinkCopied] = useState(false);
  const language = i18n.language;
  useEffect(() => {
    if (linkCopied == true)
      setTimeout(() => {
        setLinkCopied(false);
      }, 4000);
  }, [linkCopied, setLinkCopied]);

  const handleCopyLink = () => {
    const link = window.location.href;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        NotificationService.addToQueue(
          0,
          t("modals.share.notification.linkCopied")
        );
        setLinkCopied(true);
      })
      .catch((error) => {
        NotificationService.addToQueue(
          1,
          t("modals.share.notification.errorCopyLink")
        );
      });
  };
  const handleShareByMail = () => {
    const link = window.location.href;

    const subject = t("modals.share.txts.subject");
    const body = `${t("modals.share.txts.body")}${link}`;

    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const handleShareByWhatsapp = () => {
    const link = window.location.href;

    const text = `${t("modals.share.txts.textMessage")}${link}`;

    const whatsappLink = `https://wa.me/?text=${encodeURIComponent(text)}`;

    window.open(whatsappLink, "_blank");
  };

  const handleShareDirect = async () => {
    const link = window.location.href;

    if (navigator.share) {
      try {
        await navigator.share({
          title: t("modals.share.txts.title"),
          text: t("modals.share.txts.textMessage"),
          url: link,
        });
        NotificationService.addToQueue(
          0,
          t("modals.share.notification.linkSend")
        );
      } catch (error) {
        NotificationService.addToQueue(
          1,
          t("modals.share.notification.errorShareLink")
        );
      }
    } else {
      console.log(
        "L'API Web Share n'est pas prise en charge par ce navigateur."
      );
      NotificationService.addToQueue(
        1,
        t("modals.share.notification.errorShareLink")
      );
    }
  };

  return (
    <div className="share-modal | flcolm">
      <div className="contents | flcolm">
        <div className="residence-show | flrow addgap16 liner">
          <img
            src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${data.medias[0]?.filename}`}
            alt="residence-photo"
          />
          <div className="infos | flcolm">
            <label>{data.name}</label>
            <div className="second-info | flrow addgap8 liner">
              <div className="note | flrow liner">
                <Star color="#374151" />
                <span>
                  {data.stars
                    ? parseFloat(data.stars)
                        .toFixed(1)
                        .replace(".", language == "fr" ? "," : ".")
                    : "N/A"}
                </span>
              </div>
              <span>{data.address}</span>
            </div>
          </div>
        </div>
        <div className="btns-action | flrow addgap16">
          <button
            onClick={handleCopyLink}
            disabled={linkCopied}
            className="link-copy | btn rlve"
          >
            <img src={copyIcon} alt="copy-icon" />
            <span>{t("modals.share.action.copyLink")}</span>
          </button>
          <button onClick={handleShareByMail} className="mail-go | btn rlve">
            <img src={copyIcon} alt="mail-icon" />
            <span>{t("modals.share.action.mail")}</span>
          </button>
          <button
            onClick={handleShareByWhatsapp}
            className="mail-go | btn rlve"
          >
            <img src={WhaIcon} alt="wha-icon" />
            <span>{t("modals.share.action.whatsapp")}</span>
          </button>
          <button onClick={handleShareDirect} className="mail-go | btn rlve">
            <img src={MessageIcon} alt="message-icon" />
            <span>{t("modals.share.action.sendDirect")}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
