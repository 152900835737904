import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ContainerPersoModal from "../components/biggest/ContainerPersoModal";
import { requestEndpoint } from "../data/requestEndpoint";
import checkGoodIcon from "../images/temps/check.png";
import checkBadIcon from "../images/temps/error.png";
import { decryptData, encryptData } from "../services/encryption.service";
import { NotificationService } from "../services/Notification.service";
import { post } from "../services/useFetchAction";
import { useStorePageManager } from "../store/PageManage";
import { useStoreUserManager } from "../store/UserManage";
import "../styles/pages_styles/verifyEmail.css";
export default function VerifyEmail() {
  const { t } = useTranslation();
  //recuperer le parametre token present dans l'url ?token=...
  const token = new URLSearchParams(window.location.search).get("token");
  const [verifiyEnd, setVerifiyEnd] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState(false);
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const handleActiveAccount = async () => {
    await post(requestEndpoint.activeAccount, {
      activationToken: token,
    })
      .then((response) => {
        setModal(true);
        NotificationService.addToQueue(
          0,
          t("verifyEmail.notification.emailVerified")
        );
        useStoreUserManager
          .getState()
          .handleUpdateUser({ emailVerified: true });
      })
      .catch((error) => {
        console.log(error);
        setModalError(true);
      });
  };
  useEffect(() => {
    let timeout = setTimeout(() => {
      handleUpdateBlockScreen(true);
    }, 600);
    timeout = setTimeout(() => {
      fetchData();
      handleActiveAccount();
    }, 1000);

    return () => {
      clearTimeout(timeout);
      handleUpdateBlockScreen(false);
    };
  }, []);

  useEffect(() => {
    if (verifiyEnd && (modal || modalError)) {
      handleUpdateBlockScreen(false);
    }
  }, [verifiyEnd, modalError, modal]);
  const handleAutoConnect = async (dataEncrypted) => {
    const dataDecrypted = await decryptData(dataEncrypted);
    axios
      .post(process.env.REACT_APP_API_URL + "/users/autologin", {
        email: dataDecrypted.email,
        token: dataDecrypted.token,
      })
      .then(async (res) => {
        let dataUserSave = {
          token: res.data.token,
          email: res.data.email,
        };
        let dataEncrypted = await encryptData(dataUserSave);
        localStorage.setItem("user", dataEncrypted);
        if (res.data.mailActivatedAt) {
          NotificationService.addToQueue(
            0,
            t("verifyEmail.notification.accountAlreadyVerified")
          );
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setVerifiyEnd(true);
      });
  };
  const fetchData = async () => {
    const dataEncrypted = localStorage.getItem("user");
    if (dataEncrypted) {
      await handleAutoConnect(dataEncrypted);
    } else {
      setVerifiyEnd(true);
    }
  };
  const navigate = useNavigate();
  const handleGoToMenu = () => {
    navigate("/", { replace: true });
  };
  return (
    <>
      {/* <Header /> */}
      <main>
        <section className="verify-email-section | max-container flcolm">
          <h2 className="verify-email-label p-just">
            {t("verifyEmail.title")}
          </h2>
        </section>
        <ContainerPersoModal
          isOpen={modal && verifiyEnd}
          toggle={handleGoToMenu}
          baseTop={true}
          title={t("modals.verifyEmail.title")}
          className="modal-container-verify"
        >
          <div className="modal-check | flcolm">
            <div className="flrow addgap16 liner">
              <img src={checkGoodIcon} alt="good-icon" />
              <p className="p-just">{t("modals.verifyEmail.text1")}</p>
            </div>
          </div>
        </ContainerPersoModal>
        <ContainerPersoModal
          isOpen={modalError && verifiyEnd}
          toggle={handleGoToMenu}
          baseTop={true}
          title={t("modals.verifyEmail.title")}
          className="modal-container-verify"
        >
          <div className="modal-check | flcolm">
            <div className="flrow addgap16 liner">
              <img src={checkBadIcon} alt="bad-icon" />
              <p className="p-just">{t("modals.verifyEmail.errorTxt")}</p>
            </div>
          </div>
        </ContainerPersoModal>
      </main>
    </>
  );
}
