import React, { useEffect } from "react";
import chevronLeft from "../../images/icons/chevron-left.svg";
import logo_tc from "../../images/logos/logo-tc.svg";
import { useCurrentScrollYCheck } from "../../services/useCurrentScrollY";

export default function HeaderReservation({
  setHeaderShow,
  t,
  setOpenQuitModal,
  reservationCard,
}) {
  const [checkingPosY] = useCurrentScrollYCheck(35);
  const [checkingPosYHeader] = useCurrentScrollYCheck(75);
  useEffect(() => {
    setHeaderShow(checkingPosYHeader);
  }, [checkingPosYHeader]);
  return (
    <>
      <div
        className={`${checkingPosY ? " shadow-box-active" : ""} fixed-header`}
      >
        <div className="contains-fixed-header | max-container">
          <button onClick={() => setOpenQuitModal(true)} className="tc-btn">
            <img src={logo_tc} alt="logo-trouvechap" />
          </button>
        </div>
      </div>
      {checkingPosYHeader && (
        <div className={`shadow-box-active fixed-header second-title-show`}>
          <div className="contains-fixed-header | max-container">
            <div className="title flrow addgap16 liner">
              <button
                onClick={() => setOpenQuitModal(true)}
                className="btn-close | btn"
              >
                <img src={chevronLeft} alt="close-btn" />
              </button>
              {reservationCard ? (
                <label>{t("reservationPaiement.header.title")}</label>
              ) : (
                <label>{t("reservationPaiement.header.secondTitle")}</label>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
