import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EyeSlashed, Pass, User } from "../../images/icons/JsxIcons";
import { NotificationService } from "../../services/Notification.service";
import { post } from "../../services/useFetchAction";
import { checkIfPassIsGood } from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";

export const FinishPanelConnexionPart = ({
  currentState = 1,
  panelState,
  setPanelState,
  dataInscription,
  setDataInscription,
  refFirstName,
  refLastName,
  refPassword,
}) => {
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const { t } = useTranslation();

  const [stateNaming, setStateNaming] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [stateFirstName, setFirstName] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [stateMdp, setStateMdp] = useState({
    focus: false,
    error: false,
    good: false,
  });

  const [seePassword, setSeePassword] = useState(false);
  useEffect(() => {
    if (dataInscription.firstName.length > 0) {
      if (dataInscription.firstName.length > 2) {
        setFirstName({ ...stateFirstName, error: false, good: true });
      } else {
        setFirstName({ ...stateFirstName, error: true, good: false });
      }
    } else {
      setFirstName({ ...stateFirstName, error: false, good: false });
    }
  }, [dataInscription.firstName]);
  useEffect(() => {
    if (dataInscription.naming.length > 0) {
      if (dataInscription.naming.length > 2) {
        setStateNaming({ ...stateNaming, error: false, good: true });
      } else {
        setStateNaming({ ...stateNaming, error: true, good: false });
      }
    } else {
      setStateNaming({ ...stateNaming, error: false, good: false });
    }
  }, [dataInscription.naming]);

  //
  useEffect(() => {
    if (dataInscription.mdp.length > 0) {
      if (
        dataInscription.mdp.length >= 8 &&
        checkIfPassIsGood(dataInscription.mdp)
      ) {
        setStateMdp({ ...stateMdp, error: false, good: true });
      } else {
        setStateMdp({ ...stateMdp, error: true, good: false });
      }
    } else {
      setStateMdp({ ...stateMdp, error: false, good: false });
    }
    // console.log(dataInscription.mdp);
  }, [dataInscription.mdp]);
  const navigate = useNavigate();
  const handleGoToCondition = () => {
    navigate("/conditions");
    useStorePageManager.getState().handleUpdateConnexionPanelState(false);
  };
  const handleGoToPolitique = () => {
    navigate("/politique");
    useStorePageManager.getState().handleUpdateConnexionPanelState(false);
  };
  const handleGoInscription = async () => {
    if (stateNaming.good && stateMdp.good && stateFirstName.good) {
      let tel = dataInscription.indicatif + dataInscription.telephone;
      if (tel[0] != "+") tel = "+" + tel;
      let inscriptionData = {
        lastname: dataInscription.naming,
        firstname: dataInscription.firstName,
        indicatif: dataInscription.indicatif,
        email: dataInscription.addressMail,
        contact: tel,
        profile: "Client",
        password: dataInscription.mdp,
      };
      handleUpdateBlockScreen(true);
      post("/users/signup", inscriptionData)
        .then((res) => {
          NotificationService.addToQueue(
            0,
            t("modals.connexionPanel.notification.inscriptionDoneTxt")
          );
          setDataInscription({
            ...dataInscription,
            naming: "",
            firstName: "",
            addressMail: "",
            telephone: "",
            mdp: "",
            indicatif: "",
          });
          setPanelState({
            ...panelState,
            inscriptionFinish: 0,
            connexion: 1,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 409) {
            NotificationService.addToQueue(
              2,
              t("modals.connexionPanel.notification.userAlreadyExistTxt")
            );
            NotificationService.addToQueue(
              1,
              t("modals.connexionPanel.notification.userAlreadyExistTxt")
            );
            setPanelState({
              ...panelState,
              inscriptionFinish: 2,
              inscriptionBegin: 1,
            });
          } else {
            NotificationService.addToQueue(
              2,
              t("modals.connexionPanel.notification.errorRegistrationTxt")
            );
            NotificationService.addToQueue(
              1,
              t("modals.connexionPanel.notification.tryAgainTxt")
            );
          }
        })
        .finally(() => {
          handleUpdateBlockScreen(false);
        });
    }
  };

  return (
    <div
      className={`contains inscription-part ${
        currentState == 0
          ? "go-left-side"
          : currentState == 1
          ? "come-center"
          : "go-right-side"
      } | flcolm`}
    >
      <label>{t("modals.connexionPanel.finish.title")}</label>
      <div className="input-field | flcolm">
        <div className="input | rlve">
          <User color={stateNaming.focus ? "var(--gray-600)" : "none"} />
          <input
            ref={refLastName}
            onBlur={() => setStateNaming({ ...stateNaming, focus: false })}
            onFocus={() => setStateNaming({ ...stateNaming, focus: true })}
            onChange={(e) =>
              setDataInscription({
                ...dataInscription,
                naming: e.target.value,
              })
            }
            autoCorrect="false"
            autoCapitalize="false"
            autoComplete="false"
            placeholder={t("modals.connexionPanel.finish.champs.lastname")}
            type="text"
            name="lastname"
          />
          {stateNaming.focus == false && stateNaming.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>{t("issueNameType")}</span>
            </div>
          )}
        </div>
        <div className="input | rlve">
          <User color={stateFirstName.focus ? "var(--gray-600)" : "none"} />
          <input
            ref={refFirstName}
            onBlur={() => setFirstName({ ...stateFirstName, focus: false })}
            onFocus={() => setFirstName({ ...stateFirstName, focus: true })}
            onChange={(e) =>
              setDataInscription({
                ...dataInscription,
                firstName: e.target.value,
              })
            }
            autoCorrect="false"
            autoCapitalize="false"
            autoComplete="false"
            placeholder={t("modals.connexionPanel.finish.champs.firstname")}
            type="text"
            name="firstname"
          />
          {stateFirstName.focus == false && stateFirstName.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>{t("modals.connexionPanel.finish.issueNameType")}</span>
            </div>
          )}
        </div>
        <div ref={refPassword} className="input password | rlve">
          <Pass color={stateMdp.focus ? "var(--gray-600)" : "none"} />
          <input
            onBlur={() => setStateMdp({ ...stateMdp, focus: false })}
            onFocus={() => setStateMdp({ ...stateMdp, focus: true })}
            onChange={(e) =>
              setDataInscription({
                ...dataInscription,
                mdp: e.target.value,
              })
            }
            autoCorrect="false"
            autoCapitalize="false"
            autoComplete="false"
            placeholder={t("modals.connexionPanel.finish.champs.password")}
            type={seePassword ? "text" : "password"}
            name="password"
          />
          <button
            type="button"
            onClick={() => setSeePassword(!seePassword)}
            className="see-pass | btn"
          >
            <EyeSlashed stroke={seePassword ? "var(--gray-300)" : "#9CA3AF"} />
          </button>
          {stateMdp.focus == false && stateMdp.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>{t("modals.connexionPanel.finish.issuePassword")}</span>
            </div>
          )}
        </div>
      </div>
      <button
        onClick={handleGoInscription}
        className="next-btn | btn flex"
        disabled={!(stateNaming.good && stateFirstName.good && stateMdp.good)}
      >
        {t("modals.connexionPanel.finish.btn")}
      </button>
      <div className="social-connect | flcolm addgap8">
        <p className="other-choice">
          {t("modals.connexionPanel.finish.process.txt")}{" "}
          <span onClick={handleGoToCondition}>
            {t("modals.connexionPanel.finish.process.condition")}
          </span>{" "}
          {t("modals.connexionPanel.finish.process.and")}{" "}
          <span onClick={handleGoToPolitique}>
            {t("modals.connexionPanel.finish.process.policy")}
          </span>
        </p>
      </div>
    </div>
  );
};
