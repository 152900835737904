import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../../data/requestEndpoint";
import { Mail } from "../../../images/icons/JsxIcons";
import { NotificationService } from "../../../services/Notification.service";
import { patch } from "../../../services/useFetchAction";
import { useStorePageManager } from "../../../store/PageManage";
export default function ChangeMail({
  setSaveTokenWaitingMail,
  setMailNumberWaiting,
  handleUpdateBlockScreen,
  handleOutModal,
  userData,
  handleUpdateUser,
}) {
  const { t } = useTranslation();
  const [currentMail, setCurrentMail] = useState("");

  const [stateMail, setStateMail] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const handleUpdateMail = (e) => {
    let newMail = e.target.value;
    newMail = newMail.toString().toLowerCase();
    setCurrentMail(newMail);
    e.target.value = newMail;
  };
  useEffect(() => {
    if (currentMail.length >= 1) {
      const regexMail = new RegExp(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      );
      if (regexMail.test(currentMail) && currentMail != userData.mail) {
        setStateMail({ ...stateMail, error: false, good: true });
      } else {
        setStateMail({ ...stateMail, error: true, good: false });
      }
    } else {
      setStateMail({ ...stateMail, error: false, good: false });
    }
  }, [currentMail]);

  useEffect(() => {
    if (userData.isSocial) {
      NotificationService.addToQueue(
        1,
        t("modals.personalInfo.notification.mailChangeError1")
      );
      NotificationService.addToQueue(
        1,
        t("modals.personalInfo.notification.mailChangeError2")
      );
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      handleOutModal();
    }
  }, [userData]);

  const handleGoNewPass = async () => {
    if (stateMail.good) {
      handleUpdateBlockScreen(true);
      let data = {
        email: currentMail,
      };
      await patch(requestEndpoint.updateProfilData, data)
        .then((res) => {
          // console.log(res);
          handleUpdateUser({ emailVerified: false });
          // NotificationService.addToQueue(0, "Mail changé avec succès");
          NotificationService.addToQueue(
            1,
            t("modals.personalInfo.notification.confirmCodeMail")
          );
          setSaveTokenWaitingMail(res.data.updateMailToken);
          setMailNumberWaiting(currentMail);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 409)
            NotificationService.addToQueue(
              1,
              t("modals.personalInfo.notification.mailAlreadyUsed")
            );
          NotificationService.addToQueue(
            2,
            t("modals.personalInfo.notification.mailChangeError")
          );
        })
        .finally(() => {
          useStorePageManager.getState().handleUpdateBodyOverflow(true);
          handleUpdateBlockScreen(false);
          handleOutModal();
        });
    }
  };
  return (
    <>
      {/* <label>{t("putGooLocation")}</label> */}
      <div className="input-field | flcolm">
        <div className="input | rlve">
          <Mail
            stroke="var(--gray-400)"
            color={stateMail.focus ? "var(--gray-600)" : "none"}
          />
          <input
            onBlur={() => setStateMail({ ...stateMail, focus: false })}
            onFocus={() => setStateMail({ ...stateMail, focus: true })}
            onChange={handleUpdateMail}
            placeholder={t("modals.personalInfo.mail.champs.mail")}
            type="email"
          />
          {stateMail.focus == false && stateMail.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>{t("modals.personalInfo.mail.champs.error")}</span>
            </div>
          )}
        </div>
      </div>
      <button
        onClick={handleGoNewPass}
        className="next-btn | btn flex"
        disabled={!stateMail.good}
      >
        {t("modals.personalInfo.mail.button")}
      </button>
    </>
  );
}
