import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentScrollY } from "../../../services/useCurrentScrollY";
import "../../../styles/components/politiqueContent.css";
export default function ConfidentialiteContentEn() {
  const { t } = useTranslation();
  const { checkingPosY } = useCurrentScrollY(100);
  return (
    <section
      style={{ paddingBottom: 30 }}
      className="policy-section | flcolm max-container rlve"
    >
      <div className="content | flcolm">
        {checkingPosY && (
          <div className="title second | max-container">
            <label>Privacy Policy</label>
          </div>
        )}
        <div className="title | flcolm"></div>

        <h1 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Privacy Policy
        </h1>

        <p>
          Trouvechap aims to create connections between people for a more open
          and inclusive world. In other words, to create a world where everyone
          can feel at home everywhere. We are a community built on trust. And to
          build trust, it is essential to be transparent about how we use your
          information and protect your privacy. This Privacy Policy describes
          how Trouvechap processes personal information relating to your use of
          the Trouvechap platform. Depending on where you live and what you do
          on the Trouvechap platform, the additional privacy pages indicated
          below may apply to you.
        </p>

        <h2>DEFINITIONS</h2>
        <p>
          Terms not defined in this Privacy Policy have the same definition as
          indicated in our Terms of Service <h3>("Terms")</h3>.
        </p>
        <h2>PERSONAL INFORMATION WE COLLECT</h2>
        <p>
          <h3>Information needed to use the Trouvechap platform.</h3>
        </p>
        <p>
          We collect personal information about you when you use the Trouvechap
          platform. Without it, we would not be able to provide you with the
          requested services. This information includes:
        </p>
        <ul>
          <li>
            <h3>
              Contact details, account information and profile information.
            </h3>{" "}
            Your first name, last name, phone number, postal address, email
            address, date of birth and profile picture, depending on the
            features you use.
          </li>
          <li>
            <h3>Identity verification and payment information.</h3> Images of
            your official ID (as required by applicable law), your ID number or
            other verification information, a selfie at the time of verifying
            your ID, your bank details or payment account. If you are not a
            Trouvechap user, we may receive payment information about you, for
            example when a Trouvechap user provides your payment card to make a
            reservation. If a copy of your ID is provided to us, we may scan,
            use and store the information contained in your ID to verify your
            identity and for security purposes.
          </li>
        </ul>
        <p>
          <h3>2.2 Information you choose to provide us.</h3>
        </p>
        <p>
          You may choose to provide us with additional personal information.
          This information may include:
        </p>
        <ul>
          <li>
            <h3>Additional profile information</h3> For example, gender,
            preferred language(s), city and personal description. Some of this
            information, as indicated in your account settings, is part of your
            public profile page and will therefore be publicly visible.
          </li>
          <li>
            <h3>Information about other people</h3> For example, a payment
            instrument belonging to another person. By providing us with
            personal information about other people, you certify that you have
            permission to provide that information to Trouvechap for the
            purposes described in this Privacy Policy and that you have shared
            Trouvechap's Privacy Policy with them.
          </li>
          <li>
            <h3>Other information.</h3> For example, information provided when
            you fill out a form, add information to your account, respond to
            surveys, post in community forums, participate in promotions,
            communicate with Trouvechap Support and other Members, import or
            manually enter address book contacts, provide your address and/or
            geolocation, or share your experience with us. This may include
            health information, if you choose to share it with us.
          </li>
        </ul>
        <p>
          <h3>
            2.3 Information automatically collected when using the Trouvechap
            platform and our payment services.
          </h3>
        </p>
        <p>
          When you use the Trouvechap platform and Payment Services, we
          automatically collect personal information and other information. This
          information may include:
        </p>
        <ul>
          <li>
            <h3>Geolocation information.</h3> A precise or approximate location
            determined from your IP address, your mobile phone's GPS or other
            device, or other information you share with us, depending on your
            device settings. We may also collect this information when you are
            not using the app if you enable this option in your settings or
            device permissions.
          </li>
          <li>
            <h3>Usage information</h3> The pages or content you view, Listing
            searches, reservations you have made, additional services you have
            added and other actions taken on the Trouvechap platform.
          </li>
          <li>
            <h3>Cookies and similar technologies</h3>
          </li>
          <li>
            <h3>Payment transaction information.</h3> The payment method used,
            date and time, payment amount, payment method expiration date and
            billing zip code, PayPal email address, IBAN information, your
            address and other associated transaction information.
          </li>
        </ul>

        <p>
          <h3>2.4 Personal information collected from third parties</h3>
        </p>
        <p>We collect personal information from other sources, including:</p>

        <ul>
          <li>
            <h3>Third-party services: </h3>If you link, connect or access the
            Trouvechap platform with a third-party service (for example, Google,
            Facebook or WeChat), you are asking that service to send us
            information such as your registration, friends list and profile, in
            accordance with that service's terms of control or the permission
            granted by you in your privacy settings for that service.
          </li>
          <li>
            <h3>Other sources </h3>To the extent permitted by applicable law, we
            may receive additional information about you, such as
            recommendations, demographic data or information to help detect
            fraud and security breaches, from third-party service providers or
            partners, and cross-reference it with information we already have
            about you. For example, we may receive background check results or
            fraud warnings from identity verification service providers as part
            of our fraud prevention, security investigation and risk assessment
            efforts. We may receive information about you and your activities on
            and off the Trouvechap platform, or about your experiences and
            interactions with our partners. We may receive health information,
            including but not limited to information about contagious diseases.
          </li>
        </ul>
        <h2>HOW WE USE THE INFORMATION WE COLLECT</h2>
        <p>
          <h3>
            3.1 Providing, improving and developing the Trouvechap platform
          </h3>
          We may process this information to:
        </p>
        <ul>
          <li>
            Enable you to access the Trouvechap platform and make and receive
            payments
          </li>
          <li>Enable you to communicate with other members</li>
          <li>Process your request</li>
          <li>Perform analytics, debugging and conduct research</li>
          <li>Provide training to user support teams</li>
          <li>
            Send you messages, updates, security alerts and account
            notifications
          </li>
          <li>Process, manage or evaluate damage claims or similar requests</li>
          <li>
            Personalize your experience based on your interactions with the
            Trouvechap platform, your search and booking history, your profile
            information and preferences and other content you submit
          </li>
          <li>Enable you to use our Business products and hosting services</li>
        </ul>
        <p>
          <h3>3.2 Create and maintain a trusted and secure environment.</h3>
          We may process this information to:
        </p>
        <ul>
          <li>
            Detect and prevent fraud, spam, abuse, security incidents and other
            harmful activities
          </li>
          <li>
            Study and combat discrimination in accordance with our
            Non-discrimination Policy
          </li>
          <li>
            Conduct fraud prevention, security investigations and risk
            assessments
          </li>
          <li>Verify or authenticate information you have provided</li>
          <li>
            Conduct checks against databases and other information sources,
            including background checks
          </li>
          <li>
            Comply with our legal obligations, protect the health and well-being
            of our residents, hosts, host employees and members of the public
          </li>
          <li>Resolve disputes with our Members</li>
          <li>Enforce our agreements with third parties</li>
          <li>
            Determine requirements for certain types of bookings, such as
            instant booking
          </li>
          <li>
            Comply with the law, respond to legal requests, prevent harm and
            protect our rights
          </li>
          <li>
            Enforce our Terms and other policies (including the
            Non-discrimination Policy)
          </li>
          <li>
            Evaluate your interactions with the Trouvechap platform as well as
            information obtained from third parties. In a limited number of
            cases, automated processes, which analyze your account and
            activities on the Trouvechap platform, as well as information about
            activities on and off the platform that may be associated with you,
            may restrict or suspend your access to the Trouvechap platform if
            these processes detect activity that may present a security risk or
            other type of risk to Trouvechap, our community or any third party.
            If you wish to contest decisions based on automated processes,
            please contact us at the coordinates listed in the Contact
            Information section below.
          </li>
        </ul>
        <p>
          <h3>
            3.3 Provide, personalize, evaluate and improve our advertising and
            marketing activities
          </h3>
          We may process this information to:
        </p>
        <ul>
          <li>
            Send you promotional and marketing messages, advertising and other
            information based on your preferences, and social media advertising
            through social media platforms
          </li>
          <li>Personalize, evaluate and improve our advertising</li>
          <li>
            Manage rewards, referral programs, surveys, sweepstakes, contests,
            or other promotional activities or events sponsored or managed by
            Trouvechap or its business partners
          </li>
          <li>
            Analyze characteristics and preferences to send you promotional and
            marketing messages, advertising and other information that may
            interest you
          </li>
          <li>Invite you to events or other occasions</li>
          <li>
            Send, with your consent, promotional messages, business information,
            advertising and other information that may interest you, based on
            your preferences
          </li>
        </ul>
        <p>
          <h3>3.4 Analyze and share your communications.</h3>
        </p>
        <p>
          We may review, examine or analyze your communications on the
          Trouvechap platform for the reasons set out in the "Our Use of
          Information We Collect" section of this policy, including for fraud
          prevention, security investigations, risk assessment, regulatory
          compliance, product development, research, analysis, enforcement of
          our Terms of Service and user support. For example, as part of our
          fraud prevention efforts, we scan and analyze messages to mask contact
          information and references to other websites, and subject to
          applicable law, we review and analyze all images uploaded by users to
          the Trouvechap platform in discussion threads, profiles, listings and
          experiences to detect certain illegal or inappropriate activities
          (such as evidence of child exploitation) for the purpose of
          identifying and reporting content violations to appropriate
          authorities. In some cases, we may also review, examine or analyze
          messages for bug resolution, product improvement and extension
          purposes. We use automated methods as much as possible. We may
          occasionally need to review communications manually, for example as
          part of fraud investigations and user support, or to evaluate and
          improve the functionality of these automated tools. We will not
          review, examine or analyze your communications to send you marketing
          messages from third parties, and we will not sell reviews or analyses
          of these communications. We may also share your communications;
        </p>
        <p>
          <h3>3.5 Link to third-party accounts.</h3>
        </p>

        <p>
          You can link your Trouvechap account to certain third-party services
          such as social networks. Your contacts on these third-party services
          are referred to as "friends". When you authorize and direct data
          sharing by creating this link:
        </p>
        <ul>
          <li>
            Some of the information provided to us from linked accounts may be
            published on your public profile.
          </li>
          <li>
            Your activities on the Trouvechap platform may be displayed to your
            friends on the Trouvechap platform or said third-party service.
          </li>
          <li>
            A link to your public profile on this third-party service may be
            included in your public Trouvechap profile.
          </li>
          <li>
            Other Trouvechap users may be able to see any mutual friends you may
            have or that you are a friend of their friend, if applicable.
          </li>
          <li>
            Other Trouvechap users may be able to see schools, hometowns or
            other groups you have in common with them as they appear on your
            linked social networking services.
          </li>
          <li>
            The information you disclose to us by linking your accounts may be
            stored, processed and transmitted for fraud prevention, security
            investigations and risk assessment purposes.
          </li>
          <li>
            The publication and display of information you provide to the
            Trouvechap platform through these links is subject to your settings
            and authorizations on the Trouvechap platform and the third-party
            service.
          </li>
        </ul>
        <p>
          <h3>3.6 Provide payment services.</h3>
          {""}Personal information is used to enable or authorize third parties
          to use payment services, including to:
        </p>
        <ul>
          <li>
            Detect and prevent money laundering, fraud, abuse and security
            incidents, and conduct risk assessments.
          </li>
          <li>
            Comply with legal and compliance obligations (such as anti-money
            laundering regulations and sanctions enforcement).
          </li>
          <li>Enforce Payment Terms and other payment policies</li>
          <li>Provide and improve payment services</li>
        </ul>
        <h2>SHARING AND DISCLOSURE</h2>
        <p>
          <h3>Sharing with your consent or at your direction</h3>
        </p>
        <p>
          When you give consent, we share your information as described at the
          time of consent, such as when you authorize a third-party application
          or website to access your Trouvechap account or when you participate
          in promotional activities offered by Trouvechap partners or third
          parties.
        </p>
        <p>
          Where permitted by law, we may use certain information about you, such
          as your email address, which we share with social media platforms, to
          generate potential hosts, drive traffic to Trouvechap or otherwise
          promote our products and services.
        </p>
        <p>
          <h3>4.2 Sharing between members</h3>
        </p>
        <p>
          To facilitate bookings or other interactions between Members (who may
          be located in jurisdictions with varying levels of data protection, or
          use service providers located in these jurisdictions), we may share
          information in situations such as:
        </p>
        <p>
          <h3>Between Resident(s) and Host(s) when: </h3>
        </p>
        <ul>
          <li>
            A booking request is made, when there is a co-host or a dispute is
            submitted, such as their profile, name, name of any additional
            Resident, cancellation history, posted comments, Resident's age
            (unless legally prohibited), dispute outcome (if applicable) and
            other information you choose to share and submit.
          </li>
          <li>
            A booking is confirmed, additional information is shared to
            facilitate trip coordination, such as profile picture and phone
            number.
          </li>
          <li>
            You confirm a booking as a Host, certain information is shared with
            the Resident (and any additional invited Resident, if applicable) to
            coordinate the booking, such as your profile, full name, phone
            number and accommodation address.
          </li>
          <li>
            You communicate with a Member, including using your name, profile
            picture and message content.
          </li>
        </ul>
        <p>
          <h3>
            Information you post in Profiles, Listings and other public
            information.
          </h3>
        </p>
        <p>
          You can make certain information publicly visible to other users, such
          as:
        </p>
        <ul>
          <li>
            Your public profile page, including your profile picture, first name
            (or initials if applicable), description and city
          </li>
          <li>
            Listing pages containing information such as the approximate or
            precise description of the accommodation or experience,
            availability, profile picture, aggregate demand-related information
            (such as page views over a certain period) and any additional
            information you choose to share
          </li>
          <li>Public comments, ratings and reviews</li>
          <li>
            Content in a discussion forum or community, blog or social media
            article
          </li>
        </ul>
        <p>
          We may display certain parts of your public profile and other content
          you make available to the public, such as listing details, on
          third-party applications, platforms and websites.
        </p>
        <p>
          Information you share publicly on the Trouvechap platform may be
          indexed through third-party search engines. In some cases, you will be
          able to disable this feature in your account settings.
        </p>
        <p>
          <h3>4.4 Host service providers</h3>
        </p>

        <p>
          Hosts may rely on third-party services to manage or provide their
          services, such as cleaning or locksmith services. Hosts may use
          Trouvechap platform features to share information about the Resident
          (including arrival and departure information, name and phone number)
          with such third-party service providers.
        </p>
        <p>
          <h3>
            4.5 Compliance with law, response to law enforcement requests,
            prevention of harm and protection of our rights
          </h3>
        </p>
        <p>
          We may disclose your information to courts, law enforcement
          authorities, government or public authorities, tax authorities,
          authorized third parties, or other Members if and to the extent we are
          required or permitted to do so by law, or where disclosure is
          reasonably necessary to: comply with our legal obligations; comply
          with a valid judicial requirement (such as a court order or judgment)
          or to respond to claims directed against Trouvechap; respond to a
          valid legal request in connection with a criminal investigation into
          illegal activity or suspected illegal activity, or to respond to any
          other activity that may expose Trouvechap, you or any other user to
          legal or regulatory liability, enforce and administer our contracts
          with Members, including our Terms, Additional Legal Terms, and
          Policies; or protect the rights, property or personal safety of
          Trouvechap, its employees, its members or members of the public.{" "}
        </p>
        <p>
          Where appropriate, we may notify members of the existence of legal
          requests unless: notification is prohibited by the legal process
          itself, by a court order we receive, or by applicable law; or we
          believe that notification is unnecessary, ineffective, would create a
          risk of injury or bodily harm to an individual or group, would create
          or increase a risk of fraud or harm to Trouvechap or our members, or
          would expose Trouvechap to a claim for obstruction of justice.
        </p>
        <p>
          Where applicable law requires or permits, we may disclose information
          about Hosts or Residents to tax authorities or other government
          agencies so that tax authorities can determine compliance with
          applicable tax obligations. Applicable tax obligations include
          Trouvechap's tax obligations on its service fees, its role as a
          facilitator for accommodation taxes and withholding taxes, as well as
          individual Host tax obligations. Information that may be disclosed
          includes, but is not limited to, Host and Resident names,
          accommodation addresses, Host addresses, tax/business identification
          number(s), date of birth and/or contact information, parcel
          identification numbers, payment information, dates and transaction
          amounts, number of nights booked and number of Residents, gross and
          net value of bookings and payment amounts, taxes collected by
          Trouvechap on behalf of Residents and Hosts, to the extent such
          information is known to Trouvechap.
        </p>
        <p>
          In jurisdictions where Trouvechap encourages or requires registration,
          notification, authorization, licensing or a Host number with a
          government authority, we may share information about participating
          Hosts with the relevant authority, both during the application process
          and when listing the property, and where applicable, periodically
          thereafter, such as the Host's full name and contact information,
          accommodation address, tax identification number, registration, permit
          or license number, listing details, booking information and number of
          nights booked, subject to applicable laws.
        </p>
        <p>
          <h3>4.6 Programs with property managers and owners</h3>
        </p>
        <p>
          We may share personal information about Hosts and Residents with
          landlords, management companies, homeowner associations, property
          owners or rental management experts, (the "Property Manager"), such as
          booking information and information regarding compliance with
          applicable laws, to facilitate programs with the Property Manager. For
          example, Resident booking and Personal Information, including Resident
          contact information, may be shared with the Property Manager, complex
          or community where a Host lives and/or where the accommodation is
          located, to facilitate hosting services, compliance with applicable
          laws, security, billing and other services.
        </p>
        <p>
          <h3>4.7 Service providers</h3>
        </p>
        <p>
          We share personal information with affiliated and unaffiliated service
          providers (including their service providers) to help us manage our
          business and for their own compliance requirements, including with
          those helping us: verify your identity or authenticate your
          identification documents, compare information with public databases,
          perform criminal background checks, fraud prevention, security
          investigations and risk assessments, perform product development,
          maintenance and debugging, enable the provision of Trouvechap Services
          through third-party platforms and software tools (e.g., through
          integration with our APIs), provide user support, advertising or
          payment services, provide additional services you choose, process,
          manage or evaluate damage claims or similar claims, or review, examine
          and analyze communications on the Trouvechap Platform for certain
          purposes (such as evidence of child exploitation). For more
          information, see the Analyzing and Sharing Your Communications
          section. These service providers are contractually required to protect
          your personal information and only have access to it to perform the
          tasks mentioned above. Other Members may use services other than
          Trouvechap to process your data. This may include email or booking
          management software. These services are outside Trouvechap's control
          and will be subject to applicable laws worldwide with varying levels
          of data protection.
        </p>
        <p>
          <h3>4.8 Business Transfer</h3>
        </p>
        <p>
          If Trouvechap undertakes or is involved in any merger, acquisition,
          restructuring, asset sale or bankruptcy or insolvency proceeding, it
          will then be entitled to sell, assign or share all or any part of its
          assets, including your information in connection with such transaction
          or in contemplation of such transaction (e.g., due diligence). In this
          case, we will inform you before your personal information is
          transferred and becomes subject to a different Privacy Policy.
        </p>
        <h2>5. OTHER IMPORTANT INFORMATION</h2>
        <p>
          <h3>5.1 Third-party partners and integrations.</h3>
        </p>
        <p>
          Some parts of Trouvechap may be linked to third-party services.
          Trouvechap does not own or control these third parties. When you
          interact with these third parties and choose to use their service, you
          provide them with information about yourself. Your use of these
          services is subject to these providers' privacy policies, including
          Google Maps/Google Earth Additional Terms of Service, Google Privacy
          Policy (see here for more information about how Google uses
          information), PayPal Privacy Statement, CINETPAY Privacy Statement.
        </p>
        <h2>6. YOUR RIGHTS</h2>
        <p>
          You can exercise any of the rights described in this section in
          accordance with applicable law. Trouvechap platform users have several
          rights:{" "}
        </p>
        <ul>
          <li>Account information updates and corrections</li>
          <li>
            Refuse Trouvechap's use of your personal data for marketing purposes
            by contacting us by email or phone
          </li>
          <li>Contact us</li>
        </ul>
        <p>
          <h3>6.1 Managing your information</h3>
        </p>
        <p>
          You can view and update certain information by accessing your account
          settings. If you have connected your Trouvechap account to a
          third-party service, such as Facebook or Google, you can change your
          settings and disconnect from that service by accessing your account
          settings. You are responsible for keeping your personal information up
          to date.
        </p>
        <p>
          <h3>6.2 Data access and portability</h3>
        </p>
        <p>
          In certain jurisdictions, applicable law may give you the right to
          request copies of your personal information or information about how
          we process your personal information, or to request copies of personal
          information that you have provided to us in a structured, commonly
          used and machine-readable format, and/or to request that we transmit
          this information to another service provider (where technically
          feasible).
        </p>
        <p>
          <h3>6.3 Data deletion</h3>
        </p>
        <p>
          In certain jurisdictions, you may request deletion of your personal
          information. Please note that if you request deletion of your personal
          information, or if your account is suspended, terminated or
          voluntarily closed:
        </p>
        <ul>
          <li>
            We may retain personal information necessary for our legitimate
            interests, such as fraud detection and prevention and enhancing
            security. For example, if we suspend a Trouvechap account for fraud
            or security reasons, we may retain certain information about that
            Trouvechap account to prevent that member from opening a new
            Trouvechap account in the future.
          </li>
          <li>
            We may retain and use your personal information to the extent
            necessary to comply with our legal obligations. For example,
            Trouvechap and Trouvechap Payments may keep some of your information
            for tax, legal reporting and auditing obligations.
          </li>
          <li>
            Information you have shared with others (such as reviews, forum
            posts) may continue to be public on the Trouvechap platform even
            after your Trouvechap account is deleted.
          </li>
          <li>
            Because we protect information against accidental or malicious loss
            and destruction, residual copies of your personal information may
            not be deleted from our backup systems for a limited period.
          </li>
        </ul>
        <h2>7. SECURITY</h2>
        <p>
          While no organization can guarantee perfect security, we implement and
          continuously update our administrative, technical and physical
          security measures to protect your information against unauthorized
          access, loss, destruction or modification.
        </p>
        <h2>8. CHANGES TO THIS PRIVACY POLICY</h2>
        <p>
          We reserve the right to modify this Privacy Policy at any time in
          accordance with applicable law. If we do, we will post the revised
          version of the Privacy Policy and update the "Last Updated Date" at
          the beginning of it. In case of material changes, we will also notify
          you by email at least thirty (30) days before the effective date of
          the new version. If you do not agree to the new terms of the Privacy
          Policy, we invite you to delete your account. If you do not delete
          your account before the effective date of the new Privacy Policy, your
          continued access and use of the Trouvechap platform will be subject to
          the new Privacy Policy.
        </p>
        <h2>9. CONTACT INFORMATION AND RESPONSIBLE TROUVECHAP ENTITIES</h2>
        <p>
          For any questions or complaints regarding this Privacy Policy or
          Trouvechap's processing of personal information: if you reside in
          Ivory Coast, send us an email at contact@trouvechap.com or call us at
          the following numbers
        </p>
        <p>+225 07 12 94 39 44 ; +225 01 43 44 94 94 ; +225 05 56 09 46 36 .</p>
      </div>
    </section>
  );
}
