import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo_tc from "../images/logos/logo-tc.svg";

export default function TcLoading() {
  const [waitForShowMe, setWaitForShowMe] = useState(false);
  const { t } = useTranslation();
  //vider la cache du navigateur et liberer la memoire ram du navigateur
  useEffect(() => {
    const freeBrowserMemory = () => {
      if (
        window &&
        window.performance &&
        window.performance.clearResourceTimings
      ) {
        window.performance.clearResourceTimings();
      }
    };
    freeBrowserMemory();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setWaitForShowMe(true);
    }, 300);
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100dvh",
        zIndex: 99,
      }}
      className="tc-loading flcolm addgap8 jcenter liner"
    >
      <img className="flash-me" src={logo_tc} alt="logo-trouvechap" />
      {waitForShowMe ? (
        <span className="show-slowly-anim">{t("loading.txt")}</span>
      ) : (
        <span></span>
      )}
    </div>
  );
}
