import { useJsApiLoader } from "@react-google-maps/api";
import Cookies from "js-cookie";
import { Suspense, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import ConnexionPanel from "./components/biggest/ConnexionPanel";
import Header from "./components/biggest/Header";
import InitialyzeService from "./components/biggest/InitialyzeService";
import ScreenBlockLoader from "./components/biggest/ScreenBlockLoader";
import i18n from "./globalTraduction";
import AddResidencePage from "./pages/AddResidencePage";
import ConditionsPage from "./pages/ConditionsPage";
import ConfidentialitePage from "./pages/ConfidentialitePage";
import FavorisPage from "./pages/FavorisPage";
import ListerResidenceFaq from "./pages/ListerResidenceFaq";
import MentionLegalesPage from "./pages/MentionLegalesPage";
import NotFoundPage from "./pages/NotFoundPage";
import NotificationPage from "./pages/NotificationPage";
import ProfilePage from "./pages/ProfilePage";
import ProfileReservation from "./pages/ProfileReservation";
import ReservationPaiement from "./pages/ReservationPaiement";
import ResidencePage from "./pages/ResidencePage";
import StorePage from "./pages/StorePage";
import TcLoading from "./pages/TcLoading";
import VerifyEmail from "./pages/VerifyEmail";
import { decryptData, encryptData } from "./services/encryption.service";
import { post } from "./services/useFetchAction";
import { useStorePageManager } from "./store/PageManage";
import { useStoreUserManager } from "./store/UserManage";
import { UserPreferences } from "./store/UserPreferences";
import "./styles/components/datepicker.css";
import "./styles/components/inputHeader.css";
import "./styles/initialize/App.css";
import "./styles/initialize/auto-complete.css";
const LazyHomePage = lazy(() => import("./pages/HomePage"));
const LazyHotePage = lazy(() => import("./pages/HotePage"));
function App() {
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_ADD,
    libraries: ["places"],
  });
  const pageOverflow = useStorePageManager((state) => state.overflow);
  const bodyElement = document.body;
  const currentLanguage = i18n.language;
  const language = UserPreferences((state) => state.language);
  useEffect(() => {
    if (language === "en") {
      i18n.changeLanguage("en");
      localStorage.setItem("tc-language-active", "en");
    } else {
      localStorage.setItem("tc-language-active", "fr");
    }
  }, []);
  useEffect(() => {
    if (!bodyElement) return;
    bodyElement.style.overflow = pageOverflow ? "auto" : "hidden";
  }, [pageOverflow, bodyElement]);

  const handleChangementChemin = () => {
    const pathname = window.location.pathname;

    if (pathname === "/store") {
      useStorePageManager.getState().handleUpdateStorePage(true);
    } else {
      useStorePageManager.getState().handleUpdateStorePage(false);
    }
    if (pathname === "/newresidenceinfo") {
      useStorePageManager.getState().handleUpdateFaqPage(true);
    } else {
      useStorePageManager.getState().handleUpdateFaqPage(false);
    }
  };

  useEffect(() => {
    const handlePopstate = () => {
      handleChangementChemin();
    };

    window.addEventListener("popstate", handlePopstate);
    handleChangementChemin();

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const [showCookiesPanel, setShowCookiesPanel] = useState(false);
  const checkIfCookiesAlreadyShow = () => {
    const cookieData = Cookies.get("cookieesInfo");
    if (cookieData) return false;
    return true;
  };

  const handleSaveToCookies = () => {
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 1);
    Cookies.set("cookieesInfo", true, { expires: expirationDate });
    setShowCookiesPanel(false);
  };

  useEffect(() => {
    setShowCookiesPanel(checkIfCookiesAlreadyShow());
  }, []);

  const [canContinue, setCanContinue] = useState(false);

  const handleAutoConnect = async (dataEncrypted) => {
    const dataDecrypted = await decryptData(dataEncrypted);
    if (!dataDecrypted) {
      useStoreUserManager.getState().handleUpdateUser({
        lastname: "",
        firstname: "",
        connected: false,
        profil: "",
        mail: "",
        contact: "",
      });
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
      return;
    }
    await post("/users/autologin", {
      email: dataDecrypted.email,
      token: dataDecrypted.token,
    })
      .then(async (res) => {
        let dataUserSave = {
          token: res.data.token,
          email: res.data.email,
        };
        let dataToken = {
          access_token: res.data.access_token,
          refresh_token: res.data.refresh_token,
        };
        let dataEncrypted = await encryptData(dataUserSave);
        let encryptToken = await encryptData(dataToken);
        await useStoreUserManager.getState().handleUpdateUser({
          id: res.data.id,
          lastname: res.data.lastname,
          firstname: res.data.firstname,
          location: res.data.location,
          connected: true,
          profil: res.data.profile + `${res.data.enableHost ? "/hote" : ""}`,
          mail: res.data.email,
          contact: res.data.contact,
          emailVerified: res.data.mailActivatedAt && true,
          identityDoc: res.data.identityDoc ?? null,
          pieceChecked: res.data.identityDoc ? 1 : 0,
          profilImgUrl: res.data.avatar ?? null,
          contactChecked: res.data.contactActivatedAt && true,
          isSocial: res.data.facebookEmail
            ? true
            : res.data.googleEmail
            ? true
            : false,
          typeSocial: res.data.facebookEmail
            ? "facebook"
            : res.data.googleEmail
            ? "google"
            : "",
          enableHost: res.data.enableHost,
          payment_method_id: res.data.payment_method_id ?? 0,
        });
        await localStorage.setItem("token", encryptToken);
        await localStorage.setItem("user", dataEncrypted);
        setCanContinue(true);
      })
      .catch(async (err) => {
        await useStoreUserManager.getState().handleDisconnectUser();
        setCanContinue(true);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      const dataEncrypted = localStorage.getItem("user");
      if (dataEncrypted) {
        await handleAutoConnect(dataEncrypted);
      } else {
        await useStoreUserManager.getState().handleDisconnectUser();
        setCanContinue(true);
      }
    };
    fetchData();
  }, []);

  if (!isLoaded || !canContinue) return <TcLoading />;
  return (
    <>
      <Suspense fallback={<TcLoading />}>
        <BrowserRouter>
          <Header />
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Routes>
              <Route exact path="/" element={<LazyHomePage />} />
              <Route exact path="/hote" element={<LazyHotePage />} />
              {/* <Route exact path="/" element={<HomePage />} />
            <Route exact path="/hote" element={<HotePage />} /> */}
              <Route exact path="/checkmyemail" element={<VerifyEmail />} />
              <Route exact path="/store" element={<StorePage />} />

              <Route exact path="/profile" element={<ProfilePage />} />
              <Route
                exact
                path="/profilereservation"
                element={<ProfileReservation />}
              />
              <Route exact path="/conditions" element={<ConditionsPage />} />
              <Route exact path="/legales" element={<MentionLegalesPage />} />
              <Route
                exact
                path="/politique"
                element={<ConfidentialitePage />}
              />
              <Route
                exact
                path="/notifications"
                element={<NotificationPage />}
              />
              <Route exact path="/favoris" element={<FavorisPage />} />
              <Route
                exact
                path="/addresidence"
                element={<AddResidencePage />}
              />
              <Route
                exact
                path="/residence/:slug"
                element={<ResidencePage />}
              />
              <Route
                exact
                path="/reservation/:id"
                element={<ReservationPaiement />}
              />
              <Route
                exact
                path="/newresidenceinfo"
                element={<ListerResidenceFaq />}
              />
              <Route exact path="/*" element={<NotFoundPage />} />
              {/* <Route exact path="/test" element={<TestingPage />} /> */}
            </Routes>
          </QueryParamProvider>

          <InitialyzeService />
          <ConnexionPanel />
          <ScreenBlockLoader />
          {showCookiesPanel && (
            <div className="modal-content full-panel-askcooking rlve">
              <div className="background-touch"></div>
              <div className="information | flrow addgap16 liner">
                <p className="p-just -l">
                  {t("cookies.p1")}
                  <button
                    className="p-just -l"
                    onClick={() => {
                      handleSaveToCookies();
                      window.location.href = "/politique";
                    }}
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    {t("cookies.p2")}
                  </button>
                </p>
                <button
                  style={{
                    borderRadius: 4,
                  }}
                  onClick={handleSaveToCookies}
                  className="btn opt-btn"
                >
                  {t("cookies.btn")}
                </button>
              </div>
            </div>
          )}
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
