/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import "../../styles/components/cardResi.css";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { requestEndpoint } from "../../data/requestEndpoint";
import { Heart, Star } from "../../images/icons/JsxIcons";
import Residence from "../../models/residence";
import { NotificationService } from "../../services/Notification.service";
import { post, remove } from "../../services/useFetchAction";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import {
  checkDateJust,
  formatStringToOneUpperCase,
  handlePreviewPrice,
} from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import { useStoreUserManager } from "../../store/UserManage";
import { UserPreferences } from "../../store/UserPreferences";
import { useStoreCurrencies } from "../../store/dataCurrencies";

export default function CardResidence({
  residence = new Residence(),
  updateFavorisList = null,
  favorisPage = false,
  lastIdTouched = null,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const language = UserPreferences((state) => state.language);
  const showDevise = UserPreferences((state) => state.showDevise);
  const sign = useStoreCurrencies((state) => state.sign);
  const abortController = new AbortController();
  const userData = useStoreUserManager((state) => state.user);
  const favorisUser = useStoreUserManager((state) => state.favorites);
  const handleUpdateFavorisUser = useStoreUserManager(
    (state) => state.handleUpdateFavorisUser
  );
  const [animMe, setAnimMe] = useState(false);
  const [loadingFav, setLoadingFav] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const cancelToken = useRef(null);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const [isFav, setIsFav] = useState(false);
  const [baseFav, setBaseFav] = useState(false);

  useEffect(() => {
    let find = false;

    for (let index = 0; index < favorisUser.length; index++) {
      const element = favorisUser[index];
      if (element == residence.id) {
        find = true;
        setIsFav(true);
        setBaseFav(true);
        break;
      }
    }
    if (find == false) {
      setIsFav(false);
      setBaseFav(false);
    }
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel(
          "Requête annulée car le composant est démonté."
        );
      }
    };
  }, [favorisUser]);

  const handleFavoris = async (e) => {
    e.stopPropagation();
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }

    document.body.focus();
    setLoadingFav(true);
    if (userData?.connected == false) {
      useStorePageManager.getState().handleUpdateConnexionPanelState(true);
      NotificationService.addToQueue(
        1,
        t("card.notification.youNeedConnectTxt")
      );
      return;
    }
    let favState = !isFav;
    setIsFav(favState);

    if (cancelToken.current) {
      cancelToken.current.cancel(
        "Requête annulée car une nouvelle a été déclenchée."
      );
    }

    cancelToken.current = axios.CancelToken.source();
    let response = null;
    try {
      if (favState) {
        response = await post(
          requestEndpoint.favoritesAdd,
          {
            residenceId: residence.id,
          },
          {},
          {
            cancelToken: cancelToken.current.token,
            signal: abortController.signal,
          }
        );
        // console.log(response);
        NotificationService.addToQueue(
          0,
          t("card.notification.addFavorisDone")
        );
        setIsFav(true);
        setBaseFav(true);
      } else {
        const url =
          `${requestEndpoint.favoritesDelete}?residenceId=` + residence.id;
        response = await remove(
          url,
          {},
          {},
          { cancelToken: cancelToken.current }
        );
        // console.log(response);

        setIsFav(false);
        setBaseFav(false);
      }
      let newFav = [];
      if (favState) {
        favorisUser.map((fav) => {
          newFav.push(fav);
        });
        newFav.push(residence.id);
      } else {
        favorisUser.map((fav) => {
          if (fav != residence.id) newFav.push(fav);
        });
      }
      handleUpdateFavorisUser(newFav);
      if (updateFavorisList) updateFavorisList(residence.id);
      setLoadingFav(false);
    } catch (error) {
      // console.log(error?.response?.status);
      let status = error?.response?.status;
      if (status == 401)
        NotificationService.addToQueue(
          1,
          t("card.notification.errorSendFavorite")
        );
      if (axios.isCancel(error)) return;
      setIsFav(baseFav);
    }
  };
  useEffect(() => {
    if (loadingFav) return;
    let find = false;
    for (let index = 0; index < favorisUser.length; index++) {
      const element = favorisUser[index];
      if (element == residence.id) {
        find = true;
        setIsFav(true);
        setBaseFav(true);
        break;
      }
    }
    if (find == false) {
      setIsFav(false);
      setBaseFav(false);
    }
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel(
          "Requête annulée car le composant est démonté."
        );
      }
    };
  }, [favorisUser, loadingFav]);

  const [loadingPreview, setLoadingPreview] = useState(true);
  const [reductionApply, setReductionApply] = useState(false);
  const [dayDifference, setDayDifference] = useState(1);
  const [totalPrice, setTotalPrice] = useState({
    normalPrice: 0,
    discountPrice: 0,
    discountAmount: 0,
    discountPercent: 0,
    subtotal: 0,
    fee: 0,
    total: 0,
    averagePricePerNightWithoutFee: 0,
  });
  const [timeoutId, setTimeoutId] = useState(null);
  const storeParametersSender = useStoreManager(
    (state) => state.searchStoreParams
  );
  const [dateNotValid, setDateNotValid] = useState(false);
  useEffect(() => {
    setDateNotValid(false);
    let startDate = new Date();
    let endDate = new Date(new Date().getTime() + 86400000);
    let dateNotValid = false;

    const searchStoreParams = useStoreManager.getState().searchStoreParams;
    let startDateUrl = checkDateJust(searchStoreParams.startDate)
      ? searchStoreParams.startDate
      : null;
    let endDateUrl = checkDateJust(searchStoreParams.endDate)
      ? searchStoreParams.endDate
      : null;

    if (!startDateUrl || !endDateUrl) {
      setDateNotValid(true);
      dateNotValid = true;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      startDate = new Date(startDate);
      endDate = new Date(endDate);
    } else {
      startDate = new Date(startDateUrl);
      endDate = new Date(endDateUrl);
    }

    //genere un timeout entre 30 et 100
    let timeout = Math.floor(Math.random() * (45 - 30 + 1));
    let timer = setTimeout(() => {
      try {
        handlePreviewPrice(
          dateNotValid ? null : startDate,
          dateNotValid ? null : endDate,
          1,
          residence.id,
          false
        )
          .then((data) => {
            setTotalPrice(data);
            if (data.discountAmount > 0) setReductionApply(true);
            setLoadingPreview(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log("error", error);
      }
    }, timeout);
    setTimeoutId(timer);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [storeParametersSender]);
  const [hrefUrl, setHrefUrl] = useState("");
  const location = useLocation();
  const handleGoToResidencepage = async () => {
    const searchStoreParams = useStoreManager.getState().searchStoreParams;
    //on verifie si le pathname contient /store
    if (!location.pathname.includes("/store")) {
      useStorePageManager.getState().handleUdpateStoreMap(false);
      setHrefUrl(
        `/residence/${
          residence.slug
        }?startDate=${""}&endDate=${""}&nbrPersonne=${1}`
      );
      return;
    }
    let startDateUrl = checkDateJust(searchStoreParams.startDate)
      ? searchStoreParams.startDate
      : "";
    let endDateUrl = checkDateJust(searchStoreParams.endDate)
      ? searchStoreParams.endDate
      : "";
    if (startDateUrl) {
      startDateUrl = new Date(startDateUrl).toISOString();
    }
    if (endDateUrl) {
      endDateUrl = new Date(endDateUrl).toISOString();
    }
    setHrefUrl(
      `/residence/${
        residence.slug
      }?startDate=${startDateUrl}&endDate=${endDateUrl}&nbrPersonne=${
        searchStoreParams.numPeople ?? 1
      }`
    );
    return;
  };
  useEffect(() => {
    handleGoToResidencepage();
  }, []);
  const [currentPrice, setCurrentPrice] = useState(0);

  useEffect(() => {
    if (!residence) return;
    setCurrentPrice(residence.price);
  }, [residence]);

  let addreaa =
    language === "fr"
      ? residence?.address ?? residence.location
      : residence.addressEn ?? residence.address;
  const [nameToShow, setNameToShow] = useState("");
  useEffect(() => {
    if (!residence) return;
    let name = formatStringToOneUpperCase(residence.name.toLocaleLowerCase());
    setNameToShow(name);
  }, [residence]);

  const [randomPixel, setRandomPixel] = useState(100);
  //get a random number between 100 and 250
  useEffect(() => {
    let min = 125;
    let max = 300;
    let random = Math.floor(Math.random() * (max - min + 1)) + min;
    setRandomPixel(random);
    let timer = null;
    let timer2 = null;
    if (lastIdTouched) {
      if (lastIdTouched == residence.id) {
        let element = document.getElementById(`card-resi-${residence.id}`);
        if (element) {
          setAnimMe(true);
          timer = setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
          }, 100);
          timer2 = setTimeout(() => {
            setAnimMe(false);
          }, 750);
        }
        useStorePageManager.getState().handleUpdateLastResidenceTouched(null);
      }
    }
    return () => {
      if (timer) clearTimeout(timer);
      if (timer2) clearTimeout(timer2);
    };
  }, []);
  let averageP = 0;
  if (totalPrice?.averagePricePerNight) {
    averageP = totalPrice.averagePricePerNight;
  }
  if (dateNotValid && totalPrice?.oneDayFee) {
    averageP = residence.price + totalPrice.oneDayFee;
  }

  let pbFrais = 0;
  if (totalPrice?.fee && totalPrice?.oneDayFee) {
    pbFrais = residence.price + totalPrice.oneDayFee;
  }
  if (dateNotValid == false && totalPrice?.state) {
    if (totalPrice.state === "UP") pbFrais = totalPrice.averagePricePerNight;
  }

  return (
    <div className={`card-resi-content rlve ${animMe ? "anim-me-card" : ""}`}>
      <Link
        to={hrefUrl}
        id={`card-resi-${residence.id}`}
        onClick={handleGoToResidencepage}
        className={`card-resi ${
          favorisPage && baseFav != isFav ? "load-not-touch" : ""
        } | rlve flcolm`}
      >
        <div className="top-card | rlve">
          <img
            src={
              residence.medias
                ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${residence.medias[0]?.filename}`
                : residence.thumb
            }
            alt="img-residence"
            onLoad={handleImageLoad}
            style={{ display: imageLoaded ? "inline" : "none" }}
          />
          {!imageLoaded && (
            <div
              style={{ width: "101%", height: "100%" }}
              className="skeleton center-absolute"
            ></div>
          )}
          {reductionApply && dateNotValid == false && (
            <span className="discount-txt">-{totalPrice.discountPercent}%</span>
          )}
        </div>
        <div className="bot-val | flcolm addgap8">
          <label>{addreaa}</label>
          <h3>{nameToShow}</h3>
          <div className="price-note | flrow btw addgap8 liner">
            {!loadingPreview ? (
              <>
                {reductionApply && dateNotValid == false ? (
                  <div style={{ columnGap: 6 }} className="flrow liner">
                    <span className="flrow liner txt-normal">
                      {handleFormatToLocalString(pbFrais, language, sign)}{" "}
                      {showDevise}
                    </span>
                    <span className="flrow liner txt-reduc">
                      {handleFormatToLocalString(averageP, language, sign)}{" "}
                      {showDevise}
                      {" / "}
                      {t("card.txt.nightTxt")}
                    </span>
                  </div>
                ) : (
                  <span className="flrow liner">
                    {handleFormatToLocalString(pbFrais, language, sign)}{" "}
                    {showDevise}
                    {" / "}
                    {t("card.txt.nightTxt")}
                  </span>
                )}
              </>
            ) : (
              <span
                className="skeleton span-skeleton"
                style={{
                  height: "20px",
                  width: randomPixel,
                }}
              ></span>
            )}

            <hr />
            <div className="star-resi | flrow liner">
              <span>
                {residence.stars
                  ? parseFloat(residence.stars?.toString())
                      .toFixed(1)
                      .replace(".", language == "fr" ? "," : ".")
                  : t("card.txt.noneTxt")}
              </span>
              <Star
                color={residence.stars ? "#FACC15" : "var(--shade-black)"}
              />
            </div>
          </div>
        </div>
      </Link>
      <button className="btn-fav" onClick={handleFavoris}>
        <Heart
          color={isFav ? "var(--destructive-500)" : "transparent"}
          stroke={isFav ? "var(--destructive-500)" : "#1E1A21"}
        />
      </button>
    </div>
  );
}
