import React from "react";
import { useCurrentScrollY } from "../../../services/useCurrentScrollY";
import "../../../styles/components/politiqueContent.css";
export default function ConditionsContentEn() {
  const { checkingPosY } = useCurrentScrollY(100);
  return (
    <section
      style={{ paddingBottom: 30 }}
      className="policy-section | flcolm max-container rlve"
    >
      <div className="content | flcolm">
        {checkingPosY && (
          <div className="title second | max-container">
            <label>Terms of Service</label>
          </div>
        )}

        <h1
          style={{
            fontWeight: "bold",
            marginBottom: "10px",
            textTransform: "uppercase",
          }}
        >
          TERMS OF SERVICE
        </h1>

        <p>
          These Terms of Service for Trouvechap users in Côte d'Ivoire (the
          "Terms") constitute a legally binding contract between you and
          Trouvechap that governs your right to use Trouvechap's websites,
          applications, and other offerings (collectively, the "Trouvechap
          Platform"). When used in these terms, "Trouvechap," "we," "our," or
          "us" refers to the Trouvechap entity with which you are entering into
          a contract.
        </p>

        <p>
          The Trouvechap Platform provides an online tool that allows users
          ("Members") to publish, offer, search for, and book services.
        </p>

        <p>
          We apply other Terms and Policies in addition to these Terms, such as
          our Privacy Policy, which describes our collection and use of personal
          data, and our Payment Terms, which govern all payment services
          provided to members by Trouvechap payment entities (collectively,
          "Trouvechap Payments").
        </p>

        <p>
          If you are a Host, you must understand and comply with all laws,
          rules, regulations, and contracts with third parties that apply to
          your Host Services.
        </p>

        <p>
          You must create an account to access and use many features of the
          Trouvechap Platform. You must also ensure that your account
          information remains accurate. As the provider of the Trouvechap
          Platform, Trouvechap does not own, control, offer, or manage listings,
          Host Services, or any tourism services. Trouvechap is not a party to
          contracts made directly between Hosts and Residents, and Trouvechap is
          not a real estate agent, travel agency, or insurer, nor is it an
          organizer or retailer of package tours. Trouvechap does not act as an
          agent for any Member.
        </p>

        <h2>a. Terms of Contract with Trouvechap</h2>
        <p>
          Trouvechap may enter into contractual agreements with accommodation
          owners who wish to outsource the management of their residences. These
          specific contracts are designed to meet the particular needs of
          owners, thus allowing personalized property management. In cases where
          owners want Trouvechap to manage their residences by acting as hosts,
          Trouvechap will fulfill the responsibilities inherent to this role.
          This approach ensures management that aligns with owners'
          expectations. Hosts may also be referred to as "Hébergeurs"
          (Accommodation Providers).
        </p>

        <h2>b. Definitions</h2>
        <p>
          <h3>1. Host:</h3> Hosts on Trouvechap are individuals or entities
          offering accommodations and services on the platform. They may be
          referred to in different ways, including:
        </p>

        <ul>
          <li>
            <h3>Property Owners</h3>These are natural or legal persons who own
            the accommodations offered on Trouvechap.
          </li>
          <li>
            <h3>Managers</h3>Some Hosts may delegate complete management of
            their residences to managers, who are responsible for overseeing all
            aspects of the accommodation according to the owners' requests.
          </li>
          <li>
            <h3>Agents</h3>These are individuals who find potential clients for
            accommodations, playing a key role in promoting the properties.
          </li>
          <li>
            <h3>Co-hosts</h3>Refers to collaborators or partners involved in
            managing or promoting accommodations, in collaboration with primary
            Hosts.
          </li>
          <li>
            <h3>Accommodation Providers</h3>Hosts may also be called
            "Hébergeurs," emphasizing their role in providing accommodations.
          </li>
        </ul>

        <p>
          <h3>2. Residents, Clients, or Travelers:</h3> Trouvechap users who
          search for, book, or use accommodation services. They may be referred
          to interchangeably as residents, clients, or travelers.
        </p>

        <p>
          <h3>3. Types of Accommodations:</h3> Trouvechap offers different types
          of accommodations, including:
        </p>

        <ul>
          <li>
            <h3>Entire Furnished Residence:</h3> A complete dwelling including
            common areas such as living room, pool, kitchen, offered for rent.
          </li>
          <li>
            <h3>Rooms:</h3> Individual rooms within a residence, offered for
            rent.
          </li>
        </ul>

        <p>
          <h3>4. Management Types:</h3> Hosts can adopt different management
          styles based on their preferences, resulting in various
          responsibilities. These management types include:
        </p>

        <ul>
          <li>
            <h3>Owner Management:</h3> Property owners directly manage their
            residences and services.
          </li>
          <li>
            <h3>Manager Management:</h3> Managers are responsible for all
            operations related to the accommodation, acting on behalf of owners.
          </li>
          <li>
            <h3>Agency:</h3> Agents focus on finding clients for accommodations,
            without involving direct operational management.
          </li>
        </ul>
        <h1>Terms for Travelers</h1>
        <h2>a. Our Mission</h2>
        <p>
          At Trouvechap, our mission is to revolutionize the African
          accommodation market by providing a convenient and secure platform for
          booking residential properties, offering local mobile payment options
          alongside traditional payment methods, and ensuring quality service.
        </p>

        <h2>b. Search and Booking on TROUVECHAP</h2>
        <p>
          <h3>1. Search:</h3> How to find residences? You can search for
          accommodation services using criteria such as accommodation type,
          destination, dates and number of guests, type of social activities,
          etc.
        </p>
        <p>
          How are search results presented? Search results are based on their
          relevance to your search selection filters, ratings, popularity, and
          more.
        </p>

        <p>
          <h3>2. Booking:</h3> When you book, you agree to pay all fees,
          including the price, TROUVECHAP service fees, money transfer fees,
          etc. Once confirmed, a "Booking" is formed between you and the host,
          with specified conditions. In addition to these Terms, you must
          respect and assume responsibility for all conditions related to the
          booking. This includes, but is not limited to, cancellation policies
          and all other rules, standards, policies, or requirements specified in
          the Listing or during the payment process associated with the booking.
          It is your responsibility to read and understand these rules,
          standards, policies, and requirements before proceeding with the
          booking.
        </p>

        <p>
          <h3>3. Accommodation Bookings:</h3> An Accommodation booking grants a
          limited license to access, occupy, and use the accommodation. The Host
          retains the right to re-enter the accommodation during your stay, to
          the extent reasonably necessary, authorized by your agreement with the
          Host, and compliant with applicable law. If you exceed the check-out
          time, the Host has the right to make you leave in accordance with the
          law and may impose reasonable penalties for undue extension of stay.
          It is imperative to comply with all usage policies displayed on the
          host's accommodation listing.
        </p>

        <h2>c. Cancellations, Travel Issues, Refunds, and Booking Changes</h2>
        <p>
          <h3>1. Cancellations and Travel Issues:</h3> Refunds depend on the
          cancellation policy applicable to this booking. In case of unforeseen
          events, the Extenuating Circumstances Policy may apply. If the Host
          cancels or if you face an issue related to your stay (as defined in
          our Accommodation Replacement and Refund Policy), you may be entitled
          to assistance for a new booking or a partial or full refund in
          accordance with the Accommodation Replacement and Refund Policy.
        </p>

        <h2>d. Booking Changes</h2>
        <p>
          Guests and hosts are responsible for booking modifications and
          associated costs.
        </p>

        <h2>e. Your Responsibilities</h2>
        <p>
          You are responsible for your actions and those of people you invite,
          including leaving the accommodation in its initial state.
        </p>

        <h2>f. Assumption of Risks</h2>
        <p>
          You assume the risks associated with using TROUVECHAP, including risks
          inherent to activities offered by hosts.
        </p>
        <p>
          You assume responsibility for your actions and omissions, as well as
          those of any person you invite to accompany you (or to whom you grant
          access) regarding an Accommodation. This includes, for example:
        </p>
        <p>
          The responsibility to maintain an Accommodation (and associated
          personal property) in its initial state upon your arrival,
        </p>
        <p>
          The obligation to settle all amounts related to compensation claims
          necessary to cover damages caused by you, your travel companions, or
          your pets to an Accommodation, and Respect for integrity, respectful
          treatment of others, and compliance with applicable laws at all times.
          If you make a booking for an additional Traveler who is a minor, or if
          you bring a minor to a Host Service, you must have legal authority to
          act on behalf of the minor, and you are solely responsible for
          supervising this minor.
        </p>
        <p>
          It is important to note that Trouvechap is not held responsible if the
          traveler does not take their responsibilities. The responsibility lies
          with the traveler, and any failure on their part cannot be attributed
          to Trouvechap.
        </p>

        <h1>Terms for Hosts</h1>
        <h2>a. Hosting on TROUVECHAP</h2>
        <p>
          <h3>TROUVECHAP Features for Hosts</h3>
          As a host, TROUVECHAP offers you the opportunity to share your
          accommodation with the traveler community and earn money. Creating a
          Listing is simplified to the maximum. You have complete control over
          how you welcome Travelers, with the freedom to set your own price,
          availability, and specific rules for each Listing.
        </p>

        <p>
          <h3>Contract with Travelers</h3>
          By accepting a booking, you enter into a direct contract with the
          traveler, responsible for providing the service according to your
          listing. When you approve a booking request or receive a booking
          confirmation on the Trouvechap platform, you establish a direct
          contract with the Traveler. This means that you:
        </p>
        <ul>
          <li>
            are responsible for providing the Host Service in accordance with
            the conditions and rate specified in your Listing.
          </li>
          <li>
            are responsible for maintaining the integrity of the listings you
            publish:
          </li>
        </ul>
        <p>By ensuring the accuracy of your listing's location.</p>
        <p>
          By associating photos with your listings that are truthful and
          accurately represent the reality of the proposed accommodation.
        </p>
        <p>
          You also agree to pay applicable fees such as money transfer fees and
          Trouvechap service fees for each booking. Due amounts will be deducted
          from your payment unless otherwise agreed between you and us. Any
          conditions or terms that you include in an additional agreement with
          Travelers must be clearly disclosed in your Listing description.
        </p>

        <h2>Independent Relationship with Trouvechap</h2>
        <p>
          <h3>1. Significance</h3>
          As a host on Trouvechap, you benefit from significant independence in
          managing your accommodations and services. Here are the main elements
          that characterize this independent relationship:
        </p>
        <p>
          Your relationship with Trouvechap is defined as that of an independent
          entity or individual. You are neither an employee, nor an agent, nor a
          co-entrepreneur, nor a partner of Trouvechap.
        </p>
        <p>
          You assume responsibility for your actions and omissions regarding an
          Accommodation. This includes, for example:
        </p>
        <p>
          <h3>2. Control over Host Services</h3>You maintain complete control
          over the provision of your host services. This includes the decision
          to provide services, the conditions under which they are offered, and
          the prices set.
        </p>
        <p>
          <h3>3. Responsibilities for Legal Authorizations</h3>Hosts are
          responsible for ensuring their residences comply with current legal
          authorizations. Trouvechap cannot be held responsible for consequences
          related to possible violations.
        </p>
        <p>
          <h3>4. Host and Traveler Responsibilities</h3>Hosts are required to
          respect their responsibilities and ensure travelers respect theirs as
          well. Trouvechap is not responsible for problems arising from
          travelers' actions, and these problems should not be attributed to
          Trouvechap.
        </p>
        <p>
          Contract Terms with Trouvechap: Trouvechap may enter into contractual
          agreements with accommodation owners wishing to outsource the
          management of their residences. These specific contracts are designed
          to meet the particular needs of owners, thus allowing personalized
          property management. In cases where owners want Trouvechap to manage
          their residences by acting as hosts, Trouvechap will fulfill the
          responsibilities inherent to this role. This approach ensures
          management faithful to owners' expectations.
        </p>
        <p>
          These principles aim to establish transparent collaboration while
          preserving hosts' independence on the Trouvechap platform.
        </p>

        <h2>e. Managing Your Listing</h2>
        <p>
          <h3>1. Creating and Managing Your Listing</h3>You have control over
          your listing, including price, availability, and rules.
        </p>
        <p>
          The Trouvechap platform provides you with tools facilitating easy
          setup and management of a Listing. Your Listing must include
          comprehensive and accurate information about your Host Service,
          including your rate, any additional fees, and all rules or
          requirements applicable to your Travelers or your Listing. You are
          responsible for your actions and omissions, as well as regularly
          updating and maintaining the accuracy of your listing information
          (including availability dates on your calendar) and its content (such
          as photos) at all times. You can manage a single Listing per
          Accommodation, but multiple Listings are allowed for the same entire
          residence if it offers multiple Accommodations.
        </p>

        <h2>f. Know Your Legal Obligations</h2>
        <p>
          You must understand and comply with applicable laws, rules, and
          contracts.
        </p>
        <p>
          It is your responsibility to understand and comply with all laws,
          regulations, rules and agreements with third parties that may apply to
          your Listing or Host Services. For example, some lease agreements or
          condominium regulations may restrict or prohibit subletting,
          short-term rentals and extended stays. Local zoning plans or similar
          regulations may restrict short-term rental of residential properties
          in certain cities. Some local communities, including municipalities,
          may require Hosts to register, obtain permits or licenses before
          providing certain Host Services such as short-term rentals, extended
          stays, food preparation, alcohol sales, guided tours or vehicle use.
          In some locations, the Host Services you plan to offer may be
          prohibited. Local jurisdictions may require registration of Travelers
          staying in your Accommodation. Some regulations create rental rights
          for Travelers and impose additional obligations on Hosts. For example,
          some laws govern rental, rent control and eviction for extended stays.
          It is essential to check national and local rules applicable to the
          Host Services you plan to offer. Information provided about legal
          requirements is for informational purposes only, and you must
          independently verify your obligations. You are also responsible for
          handling and using personal data of Travelers and other individuals in
          accordance with privacy laws and these Terms, including our Privacy
          Rules applicable to Hosts. If in doubt about the application of local
          legislation, it is recommended to consult a legal advisor such as a
          lawyer.
        </p>
        <h2>g. Search Results Ranking</h2>
        <p>
          The positioning of Listings in search results on the TROUVECHAP
          platform is influenced by various factors, including:
        </p>
        <ul>
          <li>
            Traveler Search Parameters: Such as number of Travelers, date and
            duration of trip, price range, amenities, social activities,
            accommodation type, destination
          </li>
          <li>
            Listing Characteristics: Such as price, calendar availability,
            number and quality of images, reviews, type of Host Service, Host
            status, listing age, average rating from Travelers.
          </li>
          <li>
            Booking Experience: Including Host's cancellation history and use of
            user support, as well as ease of booking.
          </li>
          <li>
            Host Requirements: Such as minimum or maximum number of nights,
            booking deadline, types of social activities allowed, house rules,
            cancellation policies.
          </li>
          <li>
            Traveler Preferences: Such as previous trips, saved Listings,
            location from which Traveler is searching.
          </li>
          <p>
            Search results may vary between our mobile app and website.
            TROUVECHAP offers Hosts the opportunity to promote their Listings in
            search results for additional fees. For more information about
            Listing ranking criteria and how we identify promoted content,
            please consult our Search Marketing and Optimization.
          </p>
        </ul>
        <h2>h. Your Obligations</h2>
        <p>
          You are held responsible for your own actions and omissions, as well
          as those of people you authorize to participate in providing your Host
          Services. This includes responsibility for rates you set, rules you
          establish, and requirements specified in your listing. You must
          clearly detail all fees and charged items in your listing description.
          It's important to note that you cannot request additional fees outside
          the TROUVECHAP platform, disclose contact information, or take other
          actions contrary to our TROUVECHAP Policy on off-platform use.
          Following these guidelines helps maintain our community's integrity
          and ensures positive experiences for all users.
        </p>
        <h2>i. Hosting Travelers as a Team or Organization</h2>
        <p>
          If you collaborate as a member of a team, business, or other
          organization to offer Host Services, the entity as a whole and each
          individual involved in providing Host Services are considered Hosts
          and thus subject to these Terms. When you accept terms or enter into
          contracts, you attest and warrant that you have the necessary
          authority to enter into contracts on behalf of your team, business, or
          other organization, and that each entity you use complies with the
          laws of where it is established. If you exercise additional
          responsibilities, you certify and warrant that you are authorized to
          exercise these responsibilities. If you request TROUVECHAP to transfer
          part of your payment to other Hosts or make payments to a third party,
          you must have the necessary authorization to do so. You assume
          responsibility for payment amounts and the accuracy of information you
          provide. This approach ensures transparency and integrity of
          transactions within our community.
        </p>
        <h2>j. Cancellations, Travel Issues, and Booking Changes</h2>
        <p>
          <h3>1. Cancellations and Travel Issues</h3> Refunds depend on the
          cancellation policy. Hosts should not cancel without valid reason.
        </p>
        <p>
          Generally, if a Traveler decides to cancel a booking, the amount paid
          to you depends on the specific cancellation terms associated with that
          booking. As a Host, it is imperative not to cancel a Traveler's
          booking without valid reason, in accordance with our cancellation
          policy. Canceling a booking without valid reason may result in
          cancellation fees and other consequences. In cases where:
        </p>
        <p>
          A Traveler faces travel issues (according to the Accommodation
          Replacement and Refund Policy),
        </p>
        <p>
          A force majeure event occurs, or A booking is canceled in accordance
          with the Terms, the amount paid to you is adjusted based on the refund
          we grant to the Traveler, as well as any reasonable costs we incur due
          to the cancellation. If a Traveler receives a refund after you have
          already been paid, or if the refund amount and costs incurred by
          TROUVECHAP exceed your payment, TROUVECHAP may recover this amount
          from you, including by deducting the refund from your future payments.
          You agree that the Accommodation Replacement and Refund Policy,
          TROUVECHAP's Force Majeure Policy, and these Terms take precedence
          over your own cancellation terms in situations where they authorize
          booking cancellation or issuing refunds to Travelers. If TROUVECHAP
          considers refunding a Traveler according to one of these Policies, any
          payment for this booking may be held until a refund decision is made.
          Consult each policy for details on what is covered and your payment
          amount in different situations. You have the option to contest a
          TROUVECHAP decision by contacting our user support.
        </p>
        <p>
          <h3>2. Booking Changes</h3>Hosts and guests are responsible for
          booking changes and associated costs.
        </p>
        <h1>4. General Terms of Use</h1>
        <h2>a. Trouvechap Review Policy</h2>
        <h3>1. Introduction</h3>
        <p>
          At Trouvechap, trust between hosts and residents is essential for
          ensuring an exceptional stay. With this in mind, our Review Policy
          aims to ensure authenticity, reliability, and usefulness of feedback
          within our community. These reviews play a crucial role in
          decision-making for residents and hosts, while offering honest
          perspectives to promote continuous improvement.
        </p>
        <h3>2. Review Impartiality</h3>
        <p>
          We insist on the impartiality of reviews within the Trouvechap
          community. Thus, no member of our community should coerce, intimidate,
          extort, threaten, induce, or manipulate others to influence a review.
          Exchanges of compensation, such as benefits in exchange for positive
          reviews, or threats of retaliation for negative reviews, are strictly
          prohibited.
        </p>
        <p>
          Reviews must be provided based on real experience and cannot be
          exchanged for material benefits. They must reflect the reality of the
          stay or experience and cannot be used in a misleading way. Any use of
          biased or inaccurate reviews to harm competition is also prohibited.
        </p>
        <h3>3. Review Relevance</h3>
        <p>
          Reviews must contain relevant information about the author's
          experience with the host, resident, stay. Any review going beyond the
          reality of the stay may be subject to removal. Additionally, reviews
          must respect Trouvechap's Content Policy, excluding any explicit,
          discriminatory, harmful, fraudulent, illegal content or violations of
          our community principles.
        </p>
        <h3>4. Review Reporting</h3>
        <p>
          Any member of the Trouvechap community is encouraged to report any
          review violating this policy. We take reports seriously and may
          proceed with review removal as well as take additional measures,
          including restricting, suspending, or removing the associated
          Trouvechap account, in cases of clear violation.
        </p>
        <p>
          This policy may be applied differently according to local laws in
          effect, and Trouvechap reserves the right to evolve this policy to
          better serve its community.
        </p>
        <h2>b. Trouvechap Content Policy</h2>
        <h3>1. Introduction</h3>
        <p>
          Publishing content on Trouvechap commits each member to respect
          fundamental rules. The term "content" encompasses writings,
          photographs, images (such as posters or paintings hung on a wall),
          audio recordings, videos, and other types of content, whether listing
          titles and descriptions, profile pages, public and private comments,
          and messages sent to Trouvechap, hosts, or residents.
        </p>
        <h3>2. Non-Compliant Content Removal</h3>
        <p>
          Trouvechap reserves the right to remove any content that violates this
          policy, Terms of Service, community values, or Review Policy. This
          action may be taken in whole or in part, at our sole discretion. In
          cases of repeated or serious violations, stricter measures, such as
          restricting, suspending, or removing the associated Trouvechap
          account, may be applied.
        </p>
        <h3>3. Unauthorized Content on Trouvechap</h3>
        <p>
          Certain content is not authorized on Trouvechap, including any content
          created solely for advertising or commercial purposes, unwanted
          contacts, or any harmful or illegal content. The policy also applies
          to listing titles, listing or profile pages, message content, and
          reviews.
        </p>
        <p>
          Any content created solely for advertising or any type of commercial
          content, including logos, links, or company names
        </p>
        <p>
          Unwanted content and contacts, or any content distributed repeatedly
          or that may disturb
        </p>
        <p>
          Any content that endorses or encourages illegal or harmful activities,
          or that is sexual, violent, shocking, threatening, or defamatory
        </p>
        <p>
          Any discriminatory content (see our Non-discrimination Policy for more
          information)
        </p>
        <p>
          Any content proving to be an attempt at identity theft, account or
          company name impersonation, including attempting to pose as a
          Trouvechap representative
        </p>
        <p>
          Any illegal content violating the rights of a third party or entity,
          including intellectual property rights and privacy rights.
        </p>
        <p>
          Any content presenting personal or confidential information of another
          person, including content explicit enough to identify a property's
          address.
        </p>
        <h3>4. Specific Violations</h3>
        <p>
          We detail specific violations related to certain types of content:
        </p>
        <ul>
          <li>
            <h3>Listing Titles: </h3> requirement for relevant information and
            prohibition of inappropriate symbols or emoticons.
          </li>
          <li>
            <h3>Listing or Profile Pages: </h3> obligation for accuracy and
            honesty in provided information, prohibition of fraudulent or
            misleading content.
          </li>
          <li>
            <h3>Messages: </h3> necessity for respectful messages, excluding any
            explicit, discriminatory, harmful, fraudulent, illegal content or
            contrary to community principles.
          </li>
          <li>
            <h3>Reviews: </h3> respect for Trouvechap's Review Policy, excluding
            any explicit, discriminatory, harmful, fraudulent, illegal content
            or contrary to community principles.
          </li>
        </ul>
        <h3>5. Covid-19 Related Content</h3>
        <p>
          In the context of the Covid-19 pandemic, we have a specific policy to
          combat the spread of misinformation. Advice is detailed in
          Trouvechap's Content and Coronavirus Policy.
        </p>
        <h3>6. Sexual and Inappropriate Content</h3>
        <p>
          Sexual, explicit, or inappropriate content has no place on Trouvechap.
          This includes, but is not limited to, pornographic images or texts,
          obscene comments, or unsolicited sexual propositions. Members are
          encouraged to report such content for immediate action.
        </p>
        <h3>7. Irrelevant Reviews</h3>
        <p>
          Reviews that are not relevant to an accommodation listing, such as
          personal discussions, spam, or content unrelated to the booking or
          stay, are also considered non-compliant with our policy. These reviews
          may be removed, and sanctions may be applied in case of recurrence.
          Reviews deemed irrelevant also violate Trouvechap's Review Policy.
        </p>
        <h3>8. Content Reporting</h3>
        <p>
          Members are invited to report any content violating this policy,
          either directly on the application or by contacting Trouvechap.
          Reports may result in content removal, with additional measures in
          cases of serious or repeated violations.
        </p>
        <h2>c. Trouvechap Platform Service Fees</h2>
        <h3>1. Service Fees for Trouvechap Travelers</h3>
        <p>
          Trouvechap applies a service fee of 7% of the booking subtotal for
          each transaction made on our platform. These fees include costs
          associated with financial transactions, including online payment and
          money transfer fees.
        </p>
        <p>
          For transactions made in Ivory Coast, CinetPay fees are integrated
          into these 7% and vary depending on the payment method used:
        </p>
        <ul>
          <li>Moov Money Ivory Coast: Minimum 3.5%</li>
          <li>MTN Money Ivory Coast: Minimum 3.5%</li>
          <li>Orange Money Ivory Coast: Minimum 3.5%</li>
          <li>Visa - Mastercard: 3.5%</li>
        </ul>
        <p>
          The surplus needed to reach the total of 7% represents Trouvechap's
          service fees. It's important to note that CinetPay fees may vary
          depending on the countries in which they operate. For specific details
          on CinetPay rates, please consult their pricing page{" "}
          <a href="https://cinetpay.com/pricing">here</a>.
        </p>
        <p>
          Additionally, WAVE commissions also apply to travelers. WAVE
          commissions amount to 1% including tax of the total amount related to
          disbursements made by the Client (API Payout). You can consult details
          on WAVE rates <a href="https://www.wave.com/fr/">here</a>.
        </p>
        <h2>2. Service Fees for Trouvechap Hosts</h2>
        <p>
          Trouvechap applies a service fee of 10% on the total booking amount
          for hosts. These fees encompass various costs related to services
          provided by Trouvechap, including transaction processing fees.
        </p>
        <p>
          For transactions made in Ivory Coast, CinetPay fees, which depend on
          the payment method, are also included in the 10%:
        </p>
        <ul>
          <li>Moov Money Ivory Coast: Minimum 3.5%</li>
          <li>MTN Money Ivory Coast: Minimum 3.5%</li>
          <li>Orange Money Ivory Coast: Minimum 3.5%</li>
          <li>Visa - Mastercard: Up to 4%</li>
        </ul>
        <p>
          The remaining amount after deducting CinetPay fees constitutes
          Trouvechap's service fees for hosts. It's important to note that Visa
          and MasterCard fees may vary and reach up to 4%, added to Trouvechap's
          service fees. For specific details on CinetPay rates, please consult
          their pricing page <a href="https://cinetpay.com/pricing">here</a>.
        </p>
        <p>
          Additionally, WAVE commissions also apply to travelers. WAVE
          commissions amount to 1% including tax of the total amount related to
          disbursements made by the Client (API Payout). You can consult details
          on WAVE rates <a href="https://www.wave.com/fr/">here</a>.
        </p>
        <h2>d. Basic Rules for Hosts</h2>
        <p>
          To ensure comfortable and safe stays for residents, hosts must respect
          our rules in four key areas:
        </p>
        <h3>1. Booking Commitment</h3>
        <p>
          Hosts must honor accepted bookings and provide a reliable arrival
          experience.
        </p>
        <ul>
          <li>
            <h3>Cancellation:</h3> Hosts must not cancel confirmed bookings,
            except for valid reasons beyond their control. Even in such cases,
            hosts must do their best to cancel a booking as soon as possible and
            contact Trouvechap if they need assistance.
          </li>
          <li>
            <h3>Arrival:</h3> Hosts must give their residents the ability to
            easily access the accommodation upon arrival by providing clear
            instructions, updated access codes, etc.
          </li>
        </ul>
        <h3>2. Quick Communication</h3>
        <p>
          Hosts must be available to respond to residents' requests for
          information or react to unexpected events that may occur before and
          during a stay.
        </p>
        <ul>
          <li>
            <h3>Response Time:</h3> Hosts must respond quickly to travelers'
            requests, ideally within one hour of the request. This ensures
            effective communication to quickly resolve any concerns or questions
            raised by the traveler.
          </li>
        </ul>
        <h3>3. Listing Accuracy</h3>
        <p>
          The listing must accurately describe the accommodation, reflecting the
          features and amenities available from arrival to departure.
        </p>
        <p>
          Booking Details: Hosts cannot modify elements of an accepted booking
          without the prior consent of the resident.
        </p>
        <p>
          Location: Information about the location in the listing must be
          accurate, including possible noise levels.
        </p>
        <h3>4. Cleanliness of Accommodation</h3>
        <p>
          All accommodations must be clean and free from health risks before
          residents arrive.
        </p>
        <p>
          Health and Safety: Accommodations must not present any health risks.
        </p>
        <p>Cleanliness: Hosts must offer accommodations of high cleanliness.</p>
        <p>
          Resident Turnover: Hosts must clean the accommodation between each
          stay.
        </p>
        <p>
          If a traveler notices a breach of basic rules, they are encouraged to
          contact Trouvechap first. The traveler can illustrate the problem with
          photos on the messaging support or on Trouvechap's WhatsApp and
          contact Trouvechap if direct resolution with the host is difficult to
          obtain. Leaving an honest review can also help improve the experience
          for future travelers.
        </p>
        <h3>5. Repeated Violations</h3>
        <p>
          In cases of repeated or serious violations of basic rules, Trouvechap
          may take measures ranging from warnings to suspension or deletion of
          the host account, depending on the nature of the violation. Hosts have
          the opportunity to appeal decisions made by Trouvechap.
        </p>
        <p>
          We are committed to enforcing basic rules. When a violation of basic
          rules is reported, Trouvechap attempts to contact the host to
          understand what happened.
        </p>
        <p>
          For this, we may need to inform hosts of this policy and issue
          warnings. When repeated or serious violations of basic rules are
          reported, hosts or their listings may be suspended or removed from the
          platform.
        </p>
        <p>
          Depending on the nature of the violation, Trouvechap may also take
          other measures, such as canceling an upcoming or ongoing booking,
          refunding a resident from the payment due to the host, and/or
          requiring hosts to provide proof that they have resolved the issues
          before they can start hosting residents again.
        </p>
        <p>
          Additionally, a host who cancels a confirmed booking or is found
          responsible for a cancellation may be subject to other sanctions under
          our Host Cancellation Policy. Trouvechap may not apply cancellation
          fees and, in some cases, avoid other measures if the host cancels due
          to other valid reasons beyond their control.
        </p>
        <h2>e. Policy on Termination, Suspension, and Other Measures</h2>
        <h3>1. Application of Basic Rules</h3>
        <p>
          This section sets out the basic rules governing termination,
          suspension, and other measures taken by Trouvechap in case of policy
          violations. These measures are essential to ensure compliance with
          standards and safety within the Trouvechap community.
        </p>
        <h3>2. Contact with Host in Case of Violation</h3>
        <p>
          In case of violation of Trouvechap's Terms of Use or other relevant
          policies, Trouvechap may contact the host to discuss the alleged
          violation. This contact may take the form of an email notification, an
          alert in the host's dashboard, or direct communication through other
          appropriate means.
        </p>
        <h3>3. Warnings and Possible Sanctions</h3>
        <p>
          After identifying a violation, Trouvechap may issue a warning to the
          concerned host. Warnings serve to inform users of their non-compliance
          and offer the opportunity to remedy the situation.
        </p>
        <p>
          In case of serious or repeated violations, Trouvechap reserves the
          right to apply sanctions. These sanctions may include temporary
          suspension of the host, deactivation of certain features, or even
          permanent termination of the account. Decisions will depend on the
          severity of the violation, its impact on the community, and the host's
          responsiveness to previous warnings.
        </p>
        <p>Example of violation:</p>
        <p>
          Depending on the nature of the violation, Trouvechap may also take
          other measures, such as canceling an upcoming or ongoing booking,
          refunding a resident from the payment due to the host, and/or
          requiring hosts to provide proof that they have resolved the issues
          before they can start hosting residents again.
        </p>
        <p>
          Additionally, a host who cancels a confirmed booking or is found
          responsible for a cancellation may be subject to other sanctions under
          our Host Cancellation Policy. Trouvechap may not apply cancellation
          fees and, in some cases, avoid other measures if the host cancels due
          to other valid reasons beyond their control.
        </p>
        <h3>4. Appealing Decisions</h3>
        <h3>4.1 Appeal Procedure</h3>
        <p>
          Hosts have the right to appeal decisions made by Trouvechap in cases
          of termination, suspension, or other disciplinary measures. To appeal,
          the host must submit a written request to Trouvechap's support team
          within fourteen (14) days of receiving notification of the contested
          decision.
        </p>
        <h3>4.2 Considerations When Reviewing Appeals</h3>
        <p>
          The support team will review each appeal objectively. The host has the
          opportunity to present additional evidence or explanations supporting
          their position. Trouvechap is committed to ensuring a fair appeal
          process and considering all relevant elements before making a final
          decision.
        </p>
        <p>
          The decision resulting from the appeal is final and binding. Sanctions
          decided after the appeal will be applied in accordance with
          Trouvechap's policies.
        </p>
        <p>
          This policy aims to maintain a safe, transparent environment that
          complies with ethical standards within the Trouvechap community. Hosts
          are encouraged to strictly follow the stated rules to ensure a
          positive experience for all platform members.
        </p>
        <h2>f. Policy on Modifying These Terms</h2>
        <h3>1. Introduction</h3>
        <p>
          The Trouvechap platform reserves the right to periodically adjust
          these terms to reflect legislative changes, new features, or any other
          changes deemed necessary. We encourage you to regularly review these
          terms to stay informed of updates.
        </p>
        <h3>2. Modification Process</h3>
        <p>
          When adjustments are made to these terms, we update the revised terms
          on the Trouvechap platform while updating the "last updated" date at
          the top of the terms page.
        </p>
        <h3>3. Notification of Changes</h3>
        <p>
          Any significant modification will be communicated to you by email at
          least thirty (30) days before it takes effect. We are committed to
          informing you clearly and comprehensibly about the nature of the
          changes made.
        </p>
        <h3>4. Acceptance of Revised Terms</h3>
        <p>
          When substantial modifications are proposed, you will be explicitly
          invited to accept the revised terms. This notice will also inform you
          of your right to refuse these changes, the time limit to do so, and
          your ability to terminate the contract at any time before the
          effective date of the modifications, in accordance with the provisions
          set out in these terms.
        </p>
        <h3>5. Refusal of Changes</h3>
        <p>
          You have the right to refuse proposed changes. If you choose to
          refuse, this may result in limitations on the use of certain features
          or, in some cases, termination of the contract.
        </p>
        <h3>6. Contract Termination</h3>
        <p>
          For changes that are (i) not material and do not affect essential
          provisions, such as those defining the nature and scope of services
          provided by Trouvechap, or (ii) required by law, a legally binding
          court decision, or a mandatory order from a competent authority, your
          continued use of the Trouvechap platform after the effective date of
          the changes will be interpreted as acceptance of the revised terms. We
          strive to ensure transparency and clarity in all communications
          regarding changes to the terms of use.
        </p>
        <h2>g. Force Majeure Policy</h2>
        <h3>1. General Information</h3>
        <p>
          This Force Majeure Policy defines the procedures to follow when
          unforeseen events beyond the control of Trouvechap users occur, making
          it impossible or illegal to fulfill commitments made in the context of
          a booking. This policy applies to accommodation bookings on the
          Trouvechap platform.
        </p>
        <h3>2. Cancellation Management</h3>
        <p>
          When this policy authorizes cancellation, it takes precedence over the
          standard cancellation policy of the booking. Residents affected by an
          event covered by this policy may cancel their booking and receive,
          depending on the circumstances, a monetary refund, travel credit, or
          other compensation. Hosts affected by a covered event may cancel
          without negative consequences, although their calendars may be blocked
          for the dates of the cancelled booking.
        </p>
        <h3>3. Covered Events</h3>
        <h3>3.1. Changes in Government Travel Requirements</h3>
        <p>
          This category covers unexpected changes in visa or passport
          requirements imposed by a government agency, preventing physical
          presence at the booking location.
        </p>
        <h3>3.2. Emergency Situations and Epidemics</h3>
        <p>
          Local or national emergency situations declared by authorities,
          epidemics, pandemics, and public health emergencies are included in
          this category.
        </p>
        <h3>3.3. Government Travel Restrictions</h3>
        <p>
          Travel restrictions imposed by a government authority, preventing
          travel to, stay at, or return from the place of stay, are covered by
          this policy.
        </p>
        <h3>3.4. Military Operations and Other Conflicts</h3>
        <p>
          Acts of war, conflicts, invasions, civil wars, terrorism, explosions,
          bombings, rebellions, riots, insurrections, civil unrest, and civil
          disorder are included in this category.
        </p>
        <h3>3.5. Natural Disasters</h3>
        <p>
          Natural disasters, force majeure events, large-scale outages affecting
          essential services, volcanic eruptions, tsunamis, and other severe and
          abnormal weather phenomena fall into this category. However, weather
          or natural conditions frequent enough to be predictable at the
          location concerned are not covered.
        </p>
        <h3>3.6. Events Not Covered</h3>
        <p>
          Any event not included in the above categories is not covered by this
          policy. Examples of uncovered situations include, but are not limited
          to, illnesses, conditions or injuries, legal obligations such as jury
          duty, court appearances or military obligations, resident notices or
          other government advisories that do not result in travel bans, and
          cancellations or rescheduling of events for which a booking was made.
        </p>
        <h3>3.7. Next Steps</h3>
        <p>
          If an event covered by this policy occurs, affected users should
          follow the cancellation instructions provided by Trouvechap. In cases
          where Trouvechap has not issued a notification or published
          information about the event, users are invited to contact Trouvechap
          to cancel their booking. In all cases, users should be prepared to
          provide documents justifying the event's impact on their booking.
        </p>
        <h3>3.8. Contact for Questions</h3>
        <p>
          For any questions regarding this policy, users are encouraged to
          contact Trouvechap, which will be happy to provide additional
          information and assistance if needed.
        </p>
        <h2>h. Disclaimer Policy</h2>
        <h3>1. Limited Warranties</h3>
        <p>
          Trouvechap does not guarantee the existence, conduct, performance,
          safety, quality, legality, or suitability of any resident or host
          using the platform. Terms such as "verified" or similar only indicate
          that a resident or Trouvechap has undergone appropriate verification
          or identification procedures, without implying any additional
          guarantees.
        </p>
        <h3>2. Service Interruptions</h3>
        <p>
          Trouvechap is not responsible for Internet outages or cuts,
          telecommunications infrastructure, or any other event beyond its
          control that may result in interruptions in accessibility to the
          Trouvechap platform.
        </p>
        <h3>3. Liability Exclusions</h3>
        <p>
          In providing its services, Trouvechap reserves the right to
          temporarily limit the accessibility of the platform or certain of its
          features. This limitation may occur due to limited capacity, to
          preserve the security or integrity of Trouvechap's servers, or to
          perform maintenance operations aimed at ensuring proper functioning or
          improving the platform.
        </p>
        <h3>4. Policy on Source of Funds and Lawful Use</h3>
        <p>
          At Trouvechap, we value transparency and accountability in all
          transactions conducted on our platform. It is essential to emphasize
          that Trouvechap is not a financial institution, and therefore, we do
          not directly verify users' source of funds.
        </p>
        <h3>4.1. User Responsibility</h3>
        <p>
          Trouvechap users, whether travelers or hosts, are required to ensure
          that funds used in their transactions are legitimate and come from
          lawful sources in accordance with applicable laws.
        </p>
        <h3>4.2. Usage Policies</h3>
        <p>
          Users must adhere to Trouvechap's usage policies, which clearly
          stipulate that funds used on the platform must be legitimate and
          comply with applicable financial regulations.
        </p>
        <h3>4.3. Transparency and Communication</h3>
        <p>
          We encourage transparent communication between users. It is everyone's
          responsibility to ensure that funds used are legitimate. Travelers and
          hosts are invited to be honest and transparent about the source of
          their funds.
        </p>
        <h3>4.4. Collaboration with Financial Partners</h3>
        <p>
          Although Trouvechap is not a bank, we explore collaborations with
          financial partners and third-party payment services to strengthen
          transaction security and ensure fund legitimacy.
        </p>
        <h3>4.5. Legal Support</h3>
        <p>
          We recommend all users consult a legal professional to ensure their
          activities on Trouvechap comply with local and international laws.
        </p>
        <p>
          <h3>
            Clarified Responsibility: Trouvechap is not held responsible if the
            Policy on Source of Funds and Lawful Use is not respected by
            travelers. No liability can be attributed to Trouvechap in such
            cases.
          </h3>
        </p>
        <p>
          The liability of Trouvechap, its legal representatives, directors,
          employees, or other agents is limited by applicable legal provisions.
          Trouvechap may be held liable in case of intentional or gross fault on
          its part, its legal representatives, directors, or agents. In case of
          breaches of contractual obligations, liability is limited to
          foreseeable typical damages.
        </p>
        <p>
          Any exclusion or limitation of liability also applies to the personal
          liability of legal representatives, directors, employees, and other
          agents of Trouvechap.
        </p>
        <p>
          This disclaimer policy aims to establish limited warranties, define
          possible service interruptions, and specify liability exclusions
          related to the provision of Trouvechap services.
        </p>
        <h3>2. Limitation of Liability</h3>
        <p>
          To the extent permitted by applicable law, any liability of
          Trouvechap, its legal representatives, directors, employees and other
          agents is limited in cases where liability cannot be excluded. This
          limitation also applies to the personal liability of legal
          representatives, directors, employees and other agents of Trouvechap.
        </p>
        <p>
          This policy aims to define Trouvechap's liability in case of fault,
          paying particular attention to situations where liability may be
          incurred and establishing a limit on the extent of this liability.
        </p>
        <h2>j. Indemnification Policy</h2>
        <h3>1. Scope</h3>
        <p>
          To the maximum extent permitted by applicable law, you agree to
          release, defend (at Trouvechap's option), indemnify and hold
          Trouvechap harmless from all claims, liabilities, damages, losses and
          expenses arising from or in connection with:
        </p>
        <h3>2. Indemnification Obligation</h3>
        <p>
          Your violation of these Terms, Policies or Standards, your improper
          use of the Trouvechap platform, your interaction with any Member, stay
          in accommodation, but not limited to, any injury, loss or damage
          (compensatory, direct, incidental, intangible or otherwise) of any
          kind arising in connection with or as a result of a Listing, stay,
          participation or use of this type, your failure, or our failure to
          follow your instructions, to accurately declare, collect or remit
          taxes, or your violation of any law or regulation or third party
          rights such as intellectual property or privacy rights, will result in
          your obligation to indemnify. This indemnification obligation applies
          only to the extent that the claims, liabilities, damages, losses and
          expenses were actually caused by your culpable breach of a contractual
          obligation.
        </p>
        <p>
          This indemnification obligation applies only to the extent that the
          claims, liabilities, damages, losses and expenses were actually caused
          by your culpable breach of a contractual obligation.
        </p>
        <h3>3. Applicable Law and Jurisdiction</h3>
        <p>
          These Terms are governed by and construed in accordance with the laws
          of Côte d'Ivoire. If you act as a consumer and the mandatory consumer
          protection legislation of your country of residence contains
          provisions that are more advantageous to you, such provisions shall
          apply regardless of the choice of Ivorian law. As a consumer, you may
          bring any legal action relating to these Terms before the competent
          court of your place of residence or the competent court of Côte
          d'Ivoire. If Trouvechap wishes to enforce its rights against you as a
          consumer, this can only be done before the courts of the jurisdiction
          to which you belong. If you act as a professional, you agree to submit
          to the exclusive jurisdiction of the courts of Côte d'Ivoire.
        </p>
        <h2>k. Miscellaneous</h2>
        <h3>1. No Waiver</h3>
        <p>
          Trouvechap's failure to enforce any right or provision of these Terms
          will not constitute a waiver of such right or provision unless
          expressly acknowledged and agreed to in writing. Unless expressly
          stated otherwise in these Terms, the exercise by either party of any
          remedy available to it under these Terms shall be without prejudice to
          its other remedies under these Terms or the law.
        </p>
        <h3>2. Assignment</h3>
        <p>
          You may not assign, transfer or delegate this contract or your rights
          and obligations under these Terms without Trouvechap's prior written
          consent. Trouvechap may, at its sole discretion and without
          restriction, assign, transfer or delegate this contract and any rights
          and obligations hereunder upon 30 days' notice. You retain the right
          to terminate the Terms at any time.
        </p>
        <h3>3. Notice</h3>
        <p>
          Unless otherwise indicated, all notices or other communications
          permitted or required hereunder, intended for Members, will be made
          electronically and provided by Trouvechap by email, notification on
          the Trouvechap platform, messaging service (including SMS) or any
          other means of contact we provide to you. If a notification concerns a
          booking in the West Africa region, you consent and acknowledge that
          such electronic notifications, rather than a written statement,
          satisfy Trouvechap's obligations under applicable law.
        </p>
        <h3>4. Third Party Services</h3>
        <p>
          The Trouvechap platform may include links to third-party websites,
          applications, services or resources (collectively called "Third Party
          Services") subject to separate terms and privacy practices. Trouvechap
          assumes no responsibility for any aspect of these Third Party
          Services, and links to such Third Party Services do not constitute an
          endorsement by them.
        </p>
        <h3>5. API and Partner General Terms</h3>
        <ul>
          <li>
            <h3>Exchange Rate API.</h3> Use of the Exchange Rate API is subject
            to{" "}
            <a href="https://www.ideracorp.com/Legal/Terms-of-Use">
              IDERA Corporation Terms of Use
            </a>
            .
          </li>
          <li>
            <h3>Cinetpay API.</h3> Use of the Cinetpay API is subject to{" "}
            <a href="https://cinetpay.com/legal/cgv">
              Cinetpay General Terms of Sale
            </a>
            .
          </li>
          <li>
            <h3>JustTranslate.</h3> JustTranslate terms of use are not available
          </li>
          <li>
            <h3>Paypal Business.</h3> Use of Paypal is subject to
            <a href="https://www.paypal.com/fr/webapps/mpp/ua/legalhub-full?locale.x=fr_FR">
              Paypal Terms of Use
            </a>
            .
          </li>
          <li>
            <h3>Wave Business API.</h3> Use of the Wave Business API is subject
            to{" "}
            <a href="https://www.wave.com/fr/terms_and_conditions/">
              Wave General Terms
            </a>
            .
          </li>
          <li>
            <h3>Djamo.</h3> Use of Djamo is subject to{" "}
            <a href="https://www.djamo.com/ci/conditions-generales-et-tarifaires/">
              Djamo General and Pricing Terms
            </a>
            .
          </li>
          <li>
            <h3>Google Maps API.</h3>Use of the Google Maps API is subject to{" "}
            <a href="https://cloud.google.com/maps-platform/terms">
              Google General Terms
            </a>
            .
          </li>
          <li>
            <h3>InfoBip.</h3>Use of InfoBip is subject to{" "}
            <a href="https://www.infobip.com/policies/service-terms-conditions">
              InfoBip General Terms
            </a>
            .
          </li>
          <li>
            <h3>Facebook API.</h3>Use of the Facebook API is subject to{" "}
            <a href="https://www.facebook.com/legal/terms">
              Facebook General Terms
            </a>
            .
          </li>
        </ul>
        <p>
          Please review the specific terms of use for each service to understand
          how they may affect your use of Trouvechap.
        </p>
        <p>
          By using the Trouvechap platform via our website or downloading our
          application from Google Play Store, or other platforms, you also
          accept the terms of use of our partners and API providers. Please
          review the specific terms of use for each service to understand how
          they may affect your use of Trouvechap.
        </p>
        <h3>6. Trouvechap Platform Content</h3>
        <p>
          Content provided by the Trouvechap platform may be protected by
          copyright, trademarks and/or other laws of Côte d'Ivoire and other
          countries. You acknowledge that all intellectual property rights
          relating to this content are the exclusive property of Trouvechap
          and/or its licensors. You agree not to remove, alter or obscure any
          copyright, trademark, service mark or other proprietary rights
          notices. You are not authorized to use, copy, adapt, modify, create
          derivative works, distribute, license, sell, transfer, publicly
          display, publicly perform, transmit, broadcast or exploit in any way
          any Content accessible through the Trouvechap platform, unless you are
          the legal owner of such Content or it is expressly authorized by these
          Terms. Subject to your compliance with these Terms, Trouvechap grants
          you a limited, non-exclusive, non-transferable, revocable license that
          cannot be sublicensed to (i) download and use the application on your
          personal device(s), and (ii) access Content made available to you on
          or through the Trouvechap platform, solely for personal and
          non-commercial purposes.
        </p>
        <h3>7. Force Majeure</h3>
        <p>
          Trouvechap disclaims any liability for delay or failure to perform
          resulting from circumstances beyond its reasonable control, including
          but not limited to war, terrorism, riots, embargoes, civil or military
          authority actions, fires, floods, accidents, epidemics or diseases,
          strikes, or shortages of transportation, fuel, energy, labor or
          materials.
        </p>
        <h3>8. Emails and SMS</h3>
        <p>
          You will receive our communications via the email address or other
          contact information you provided when creating your Trouvechap
          account. Your participation in programs involving email sending will
          not impact the frequency of these communications, but note that you
          may receive additional emails specific to the programs you have
          subscribed to. Promotional emails may also be sent to you, at no
          charge, although third-party data rates may apply. You have the
          ability to manage promotional emails you receive by adjusting
          notification preferences in your account settings. Please note that
          disabling certain communication settings or not having a Trouvechap
          account may prevent you from receiving certain promotions. Accepting
          to receive SMS from us will subject you to our SMS Terms.
        </p>
        <h3>9. Contact Us</h3>
        <p>
          For any questions regarding these Terms, please email us at:{" "}
          <a href="mailto:contact@trouvechap.com">contact@trouvechap.com</a>
        </p>
        <h3>10. Data Access</h3>
        <p>
          Professional Users have the right to access personal data and other
          information present in their Trouvechap account and Host dashboard.
          This data is provided by the Professional User, their Travelers, or
          generated through the use of the Trouvechap platform. It is essential
          for providing Host Services and allows access to aggregate information
          about searches, bookings, and Listing performance. For detailed
          information about the categories of personal data and other types of
          data collected, their use, processing, disclosure, retention, and to
          exercise your data protection rights, please consult our{" "}
          <a href="/politique">Privacy Policy</a>.
        </p>
      </div>
    </section>
  );
}
